import { saveAs } from 'file-saver';
import { Icon } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAxios } from '../../context/AxiosContext';
import Spinner from '../../helpers/Spinner';
import MultiSelectDropdownWithAll from './MultiSelectDropdownWithAll';
import Dropdown from './Dropdown';
import { useHistory, useLocation } from 'react-router';
import { useAppInsights } from '../../context/TelemetryContext';

const ActivityGridModal = ({}) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const { axiosGetService, axiosPostService, axiosGetFileService } = useAxios();
	const projectTypeData = [
		{
			id: 1,
			name: 'Consulting',
		}
	];
	// project type state
	const [selectedProjectType, setSelectedProjectType] = useState(null);
	const [selectedPhase, setSelectedPhase] = useState(null);
	const [phases, setPhases] = useState([]);
	//solution method state
	const [selectedSolutionMethod, setSelectedSolutionMethod] = useState(null);
	const [solutionMethods, setSolutionMethods] = useState([]);
	const [activitySearchValue, setActivitySearchValue] = useState('');
	const [selectedComplexities, setSelectedComplexities] = useState([]);
	const [availableComplexites, setAvailableComplexites] = useState([]);
	const [showSpinner, setShowSpinner] = useState(false);
	const [isWithRefered, setIsWithRefered] = useState(false);
	const [isWithGroups, setIsWithGroups] = useState(false);
	const [showJsonOptions, setShowJsonOptions] = useState(false);
	const [phasesData, setPhasesData] = useState(null);
	const [processGroups, setProcessGroups] = useState([]);
	const [deliverables, setDeliverables] = useState([]);
	const [expandedPhases, setExpandedPhases] = useState([]);
	const [showFilterOptions, setShowFilterOptions] = useState(true);
	const [totalResultCount, setTotalResultCount] = useState(0);
	const findSelectedItem = (value, item) => {
		const filteredData = value.filter(({ id }) => id === item);
		if (filteredData.length) {
			return true;
		}
		return false;
	};
	const apis = {
		json: { apiName: 'getjsondata', fileExtension: '.json' },
		mpp: { apiName: 'getmppdata', fileExtension: '.xml' },
		excel: { apiName: 'downloadactivitygridexcel', fileExtension: '.xlsx' },
	};
	const { track } = useAppInsights();
	const getSolutionMethods = async () => {
		await axiosGetService(
			`/api/sdmsolutionmethod/methodsbytypeforfilters?methodTypeId=${
				selectedProjectType?.id ?? 0
			}&isSvgRequired=${false}`
		).then((response) => {
			const methods = response.data.map((item) => {
				return {
					id: item.solutionMethodId,
					name: item.solutionMethodName,
				};
			});
			if (!findSelectedItem(methods, selectedSolutionMethod?.id)) {
				setSelectedSolutionMethod(null);
			}
			setSolutionMethods(methods);
		});
	};
	const getPhases = async () => {
		await axiosGetService(
			`api/sdmphase/getphases?searchValue=0&methodIds=${
				selectedSolutionMethod?.id ?? 0
			}&top=${0}`
		).then((response) => {
			const data = response.data.phases.map((item) => {
				return {
					name: item.phaseName,
					id: item.phaseId,
				};
			});
			if (!findSelectedItem(data, selectedPhase?.id)) {
				setSelectedPhase(null);
			}
			setPhases(data);
		});
	};

	const getApiUrl = (api) => {
		return `api/sdmactivity/${api.apiName}?methodId=${
			selectedSolutionMethod.id
		}&${
			api.fileExtension !== apis.mpp.fileExtension
				? 'complexityIds'
				: 'complexityId'
		}=${
			selectedComplexities.length > 0
				? selectedComplexities.map((complexity) => complexity.id).toString()
				: 0
		}&phaseId=${
			selectedPhase?.id ?? 0
		}&groupIds=${0}&subGroupIds=${0}&searchValue=${0}&isWithRefered=${isWithRefered}&isWithGroups=${isWithGroups}`;
	};
	const downloadActivityGridData = (api) => {
		if (!selectedSolutionMethod)
			toast.error('Solution Method is required', { position: 'top-right' });
		else if (selectedComplexities.length === 0)
			toast.error('Complexity is required', { position: 'top-right' });
		else if (
			apis.mpp.fileExtension === api.fileExtension &&
			selectedComplexities.length > 1
		)
			toast.error('Multiple Complexites cannot be selected for MPP');
		else {
			setShowSpinner(true);
			if (apis.excel.fileExtension === api.fileExtension) {
				axiosGetFileService(getApiUrl(api)).then((response) => {
					saveAs(
						new Blob([response.data]),
						`${selectedSolutionMethod.name}-ActivityGridData${api.fileExtension}`
					);
					toast.info('File Successfully Downloaded', {
						position: 'top-right',
					});
					setShowSpinner(false);
				});
			} else {
				axiosGetService(getApiUrl(api)).then((response) => {
					saveAs(
						new Blob([response.data.fileData], {
							type: 'text/plain;charset=utf-8',
						}),
						`${selectedSolutionMethod.name}-ActivityGridData${api.fileExtension}`
					);
					toast.info('File Successfully Downloaded', {
						position: 'top-right',
					});
					setShowSpinner(false);
				});
			}
			track(1, 2004, selectedSolutionMethod.id, selectedSolutionMethod.name);
		}
	};

	const getComplexities = async () => {
		await axiosGetService(`api/activities/projectcomplexity`).then((res) => {
			const data = res.data.map((item) => {
				return {
					name: item.name,
					id: item.id,
				};
			});
			setAvailableComplexites(data);
		});
	};
	const getActivityGridData = async () => {
		if (!selectedSolutionMethod)
			toast.error('Solution Method is required', { position: 'top-right' });
		else if (selectedComplexities.length === 0)
			toast.error('Complexity is required', { position: 'top-right' });
		else {
			setShowSpinner(true);
			setExpandedPhases([]);
			const response = await axiosGetService(
				`api/sdmactivity/getactivitygriddata?methodId=${
					selectedSolutionMethod.id
				}&phaseId=${selectedPhase?.id ?? 0}&complexityIds=${
					selectedComplexities.length > 0
						? selectedComplexities.map((complexity) => complexity.id).toString()
						: 0
				}&searchValue=${activitySearchValue || 0}`
			);
			response.data.deliverables = response.data.deliverables.map(
				({ id, isDeliverable }) => {
					return { id, name: isDeliverable };
				}
			);
			const phaseData = {};
			response.data.phases.map((phase) => {
				const {
					name,
					phaseId: id,
					activities,
					solutionMethodId,
					solutionMethodName,
					projectTypeName,
				} = phase;
				const activitiesData = {};
				activities.forEach((activity) => {
					if (activitiesData[activity.id]) {
						activitiesData[activity.id].complexityLevelDetails[
							activity.complexityId
						] = {
							complexityId: activity.complexityId,
							complexityName: activity.complexityName,
							isLite: activity.isLite,
							isFull: activity.isFull,
							isCritical: activity.isCritical,
							isProcessStep: activity.isProcessStep,
						};
					} else {
						activitiesData[activity.id] = {
							id: activity.id,
							title: activity.title,
							deliverable: response.data.deliverables.find(
								({ id }) => id === activity.deliverableId
							),
							processGroup: response.data.processGroups.find(
								({ id }) => id === activity.processGroupId
							),
							secureByDefault: activity.secureByDefault,
							sequence: activity.sequence,
							complexityLevelDetails: {
								[activity.complexityId]: {
									complexityId: activity.complexityId,
									complexityName: activity.complexityName,
									isLite: activity.isLite,
									isFull: activity.isFull,
									isCritical: activity.isCritical,
									isProcessStep: activity.isProcessStep,
								},
							},
						};
					}
				});
				phaseData[id] = {
					name,
					id,
					solutionMethodId,
					solutionMethodName,
					projectTypeName,
					activities: activitiesData,
					activityCount: activities.length,
				};
			});
			setPhasesData(phaseData);
			setShowSpinner(false);
			setDeliverables(response.data.deliverables);
			setProcessGroups(response.data.processGroups);
			setTotalResultCount(response.data.count);
		}
	};
	const saveActivityGrid = async () => {
		const postBody = { phases: [] };
		setShowSpinner(true);
		for (const phaseKey in phasesData) {
			const phase = phasesData[phaseKey];
			const postActivites = [];
			for (const activityKey in phase.activities) {
				const activity = phase.activities[activityKey];
				for (const complexityKey in activity.complexityLevelDetails) {
					const activityContent =
						activity.complexityLevelDetails[complexityKey];
					postActivites.push({
						id: activity.id,
						complexityId: activityContent.complexityId,
						isCritical: activityContent.isCritical,
						isFull: activityContent.isFull,
						isLite: activityContent.isLite,
						isProcessStep: activityContent.isProcessStep,
						sequence: activity.sequence,
						deliverableId: activity.deliverable?.id,
						processGroupId: activity.processGroup?.id,
						secureByDefault: activity.secureByDefault,
						minimumComplexityId: Object.keys(
							activity.complexityLevelDetails
						)[0],
					});
				}
			}
			postBody.phases.push({ id: phase.id, activities: postActivites });
		}
		const response = await axiosPostService(
			`api/admin/activitiesgrid`,
			postBody
		);
		if (response.data.status)
			toast.info('Activities updated successfully', { position: 'top-right' });
		else toast.error(response.data.errorMsg, { position: 'top-right' });
		setShowSpinner(false);
	};
	const setActivityProperty = (
		phaseKey,
		activityKey,
		propertyName,
		propertyValue
	) => {
		const newPhaseData = { ...phasesData };
		newPhaseData[phaseKey].activities[activityKey][propertyName] =
			propertyValue;
		setPhasesData(newPhaseData);
	};
	const setComplexityProperty = (
		phaseKey,
		activityKey,
		complexityKey,
		propertyName,
		propertyValue
	) => {
		const newPhaseData = { ...phasesData };
		newPhaseData[phaseKey].activities[activityKey].complexityLevelDetails[
			complexityKey
		][propertyName] = propertyValue;
		setPhasesData(newPhaseData);
	};
	const togglePhaseExpand = (phaseKey) => {
		if (expandedPhases.includes(phaseKey)) {
			setExpandedPhases(expandedPhases.filter((key) => key !== phaseKey));
		} else {
			setExpandedPhases([...expandedPhases, phaseKey]);
		}
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getSolutionMethods();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedProjectType]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getPhases();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedSolutionMethod]);
	useEffect(() => {
		getComplexities();
	}, []);
	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles RaciGrid activityGrid"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => history.push(pathname)}
			show={true}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Activity Grid
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="methodOwnerMainContainer">
					<div className="methodOwnerPage">
						<div className="methodOwnerContainer">
							<div className="methodLeftContainer">
								<ul className="methodPhaseLeftMenu">
									<li className="activePhaseTab">
										<span>Edit Activity Grid</span>
									</li>
								</ul>
							</div>
							<div className="middleDetailsContainer">
								{!showSpinner ? (
									<div className="methodOwnerMiddleContainer">
										<div className="RaciGridFiltersPanel">
											<div className="cloneActivityButtonAction">
												<div className="addActivityFields">
													<div className="cloneActivityContainer"></div>
													<div className="cloneActivityFiltersWrapper">
														{showFilterOptions ? (
															<>
																<div>
																	<div className="cloneActivitySolTypeFilters">
																			<div className='hidingConsultingSupport'>
																			<Dropdown
																				id="projectTypes"
																				className="activityReferProjectSelectionDropdown"
																				data={projectTypeData}
																				value={selectedProjectType}
																				setValue={setSelectedProjectType}
																				label="Project Type"
																				enableDropdown={true}
																			/>
																		</div>
																		<div className="multiSelectdropDown CloseMultiSelectDp">
																			<div
																				title="Select Solution method"
																				id="activityReferSolutionMethodDropdown"
																			>
																				<Dropdown
																					id="solutionMethods"
																					className="activityReferSolutionMethodDropdown "
																					data={solutionMethods}
																					value={selectedSolutionMethod}
																					setValue={setSelectedSolutionMethod}
																					label="Solution Method"
																					enableDropdown={true}
																					required={true}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className="cloneActivitySolTypeFilters">
																		<div className="multiSelectdropDown CloseMultiSelectDp">
																			<div
																				title="Select Phases"
																				id="activityReferSolutionMethodphasesDropdown"
																			>
																				<Dropdown
																					id="phases"
																					className="activityReferSolutionMethodphasesDropdown"
																					data={phases}
																					value={selectedPhase}
																					setValue={setSelectedPhase}
																					label="Phase"
																					enableDropdown={true}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className="cloneActivitySolTypeFilters">
																		<div className="multiSelectdropDown CloseMultiSelectDp">
																			<div
																				title="Select Complexities"
																				id="activityReferSolutionMethodphasesDropdown"
																			>
																				<MultiSelectDropdownWithAll
																					id="complexities"
																					className="activityReferSolutionMethodphasesDropdown"
																					data={availableComplexites}
																					value={selectedComplexities}
																					setValue={setSelectedComplexities}
																					label="Complexities"
																					enableDropdown={true}
																					required={true}
																				/>
																			</div>
																		</div>
																	</div>
																</div>
															</>
														) : (
															<></>
														)}

														<div className="cloneActivityFieldsWrapper showGridFiltersToggle">
															<Icon iconName="FilterSolid" aria-hidden="true" />
															<Icon
																iconName={
																	showFilterOptions
																		? 'ChevronUp'
																		: 'ChevronDown'
																}
																className="cloneDropDownArrow"
																onClick={() =>
																	setShowFilterOptions(!showFilterOptions)
																}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		setShowFilterOptions(!showFilterOptions);
																	}
																}}
																aria-label="Show/Hide Activity Filters"
																role="button"
																tabIndex={0}
															/>
														</div>
														<div className="cloneActivityFieldsWrapper">
															<input
																title=""
																placeholder="Activity name"
																value={activitySearchValue}
																onChange={(e) =>
																	setActivitySearchValue(e.target.value)
																}
															/>
															<div className="getActivityGridDetails">
																<span
																	className="getGridDataButton"
																	onClick={getActivityGridData}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			getActivityGridData();
																		}
																	}}
																	aria-label="Get Activity Grid Data"
																	role="button"
																	tabIndex={0}
																></span>
															</div>
															<div className="resultsCountOfGrid">
																{phasesData && (
																	<span>{totalResultCount} Results found</span>
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="addNewPhaseButtonContainer exportToJsonDiv">
											<div
												class="addNewPhaseActionButton"
												onClick={() => setShowJsonOptions(!showJsonOptions)}
												onKeyPress={(e) => {
													if (e.which === 13) {
														setShowJsonOptions(!showJsonOptions);
													}
												}}
												aria-label="Show/Hide export document"
												aria-expanded={showJsonOptions.toString()}
												role="button"
												tabIndex={0}
											>
												Click to export document
											</div>
											{showJsonOptions ? (
												<>
													<div class="addActivityFields">
														<div>
															<div class="addRoleNameContainer">
																<label className="NotificationChkboxContainer">
																	<input
																		type="checkbox"
																		checked={isWithRefered}
																		onChange={(e) =>
																			setIsWithRefered(!isWithRefered)
																		}
																	/>
																	<span className="CustomCheckmark"></span>
																	<span class="complexityLabel">
																		Referred Artifacts
																	</span>
																</label>
															</div>
															<div class="addRoleNameContainer">
																<label className="NotificationChkboxContainer">
																	<input
																		type="checkbox"
																		checked={isWithGroups}
																		onChange={(e) =>
																			setIsWithGroups(!isWithGroups)
																		}
																	/>
																	<span className="CustomCheckmark"></span>
																	<span class="complexityLabel">
																		Groups and Subgroups
																	</span>
																</label>
															</div>
														</div>
														<div className="generatingButtons">
															<Button
																onClick={() =>
																	downloadActivityGridData(apis.json)
																}
															>
																Generate JSON
															</Button>
															<Button
																onClick={() =>
																	downloadActivityGridData(apis.mpp)
																}
															>
																Generate XML from MPP
															</Button>
															<Button
																onClick={() =>
																	downloadActivityGridData(apis.excel)
																}
															>
																Generate Excel
															</Button>
														</div>
													</div>
												</>
											) : (
												<></>
											)}
										</div>
										{phasesData && (
											<div className="gridViewResultsTable">
												<div class="addRolesListContainer addDeliveryPlaybookContainer">
													<ul>
														<li>
															<div class="genericTableList">
																<div class="genericTableHeader">
																	<div class="genericTableTitle">
																		Phase Name
																	</div>
																	<div class="genericTablePhase">
																		Activity Name
																	</div>
																	<div class="genericTableUpload">
																		Complexity
																	</div>
																	<div class="genericTableApproveSentBack">
																		Is Deliverable
																	</div>
																	<div class="genericTableDownload">
																		Process Group
																	</div>
																	<div class="genericTablePreview">
																		Is Critical
																	</div>
																	<div class="genericTablePreview">
																		Is Process Step
																	</div>
																	<div class="genericTablePreview">Is Lite</div>
																	<div class="genericTablePreview">Is Full</div>
																	<div class="genericTablePreview">
																		Is Secure by Default
																	</div>
																</div>
																<ul>
																	{Object.keys(phasesData).map((phaseKey) => (
																		<Phase
																			phaseKey={phaseKey}
																			setActivityProperty={setActivityProperty}
																			setComplexityProperty={
																				setComplexityProperty
																			}
																			phasesData={phasesData}
																			deliverables={deliverables}
																			proceessGroups={processGroups}
																			expandedPhases={expandedPhases}
																			togglePhaseExpand={togglePhaseExpand}
																			pageType={'phasePage'}
																		/>
																	))}
																</ul>
															</div>
														</li>
													</ul>
												</div>
											</div>
										)}
									</div>
								) : (
									<Spinner />
								)}
							</div>
							<div className="saveContinueDetailsContainer">
								<button
									className="forwardButton"
									onClick={saveActivityGrid}
									onKeyPress={(e) => {
										if (e.which === 13) {
											saveActivityGrid();
										}
									}}
									aria-label="Save Activity Grid"
									tabIndex={0}
								>
									<span className="saveContinueButton">Save</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

const Phase = ({
	phasesData,
	phaseKey,
	setActivityProperty,
	setComplexityProperty,
	deliverables,
	proceessGroups,
	expandedPhases,
	togglePhaseExpand,
}) => {
	const firstActivity = Object.values(phasesData[phaseKey].activities)[0];
	const firstActivityContent = Object.values(
		firstActivity.complexityLevelDetails
	)[0];
	return (
		<li>
			<div class="genericTableContainer">
				<div class="genericTableTitle">
					{phasesData[phaseKey].name}
					<div className="gridViewInfoIcon">
						<Icon iconName="Info" />
						<div class="tabsTitleToolTip">
							<span class="tabsToolTipArrow"></span>
							<h5>Phase Details</h5>
							<h6>{phasesData[phaseKey].projectTypeName}</h6>
							<span class="detailsOfCloneActivity">
								<span class="detailsCloneActivitySol">
									<span>{phasesData[phaseKey].solutionMethodName}</span>
									:&nbsp;
									<span>{phasesData[phaseKey].name}</span>
								</span>
							</span>
						</div>
					</div>
					<div className="countOfActivitiesInGrid">
						<Icon
							iconName={
								!expandedPhases.includes(phaseKey) ? 'ChevronDown' : 'ChevronUp'
							}
							onClick={() => togglePhaseExpand(phaseKey)}
						/>
						{expandedPhases.includes(phaseKey) || (
							<span>+{phasesData[phaseKey].activityCount - 1} More</span>
						)}
					</div>
				</div>
				<div class="genericTablePhase">{firstActivity.title}</div>
				<div class="genericTableUpload">
					{firstActivityContent.complexityName}
				</div>
				<div class="genericTableApproveSentBack">
					{' '}
					<Dropdown
						id={`${firstActivity.id}_${firstActivityContent.complexityId}_deleiverable`}
						className="activityReferProjectSelectionDropdown"
						data={deliverables}
						value={firstActivity.deliverable}
						setValue={(val) =>
							setActivityProperty(
								phaseKey,
								firstActivity.id,
								'deliverable',
								val
							)
						}
						label="Select Is Deliverable"
						enableDropdown={true}
						disableNone={true}
					/>
				</div>
				<div class="genericTableDownload">
					{' '}
					<Dropdown
						id={`${firstActivity.id}_${firstActivityContent.complexityId}_processGroup`}
						className="activityReferProjectSelectionDropdown"
						data={proceessGroups}
						value={firstActivity.processGroup}
						setValue={(val) =>
							setActivityProperty(
								phaseKey,
								firstActivity.id,
								'processGroup',
								val
							)
						}
						label="Select Is Process Group"
						enableDropdown={true}
						disableNone={true}
					/>
				</div>
				<div class="genericTablePreview">
					<label class="NotificationChkboxContainer">
						<input
							name="Consulting"
							type="checkbox"
							aria-label="Is Critical Checkbox"
							checked={firstActivityContent.isCritical}
							onChange={(e) =>
								setComplexityProperty(
									phaseKey,
									firstActivity.id,
									firstActivityContent.complexityId,
									'isCritical',
									e.target.checked
								)
							}
						/>
						<span class="CustomCheckmark"></span>
					</label>
				</div>
				<div class="genericTablePreview">
					<label class="NotificationChkboxContainer">
						<input
							name="Consulting"
							type="checkbox"
							aria-label="Is Process step Checkbox"
							checked={firstActivityContent.isProcessStep}
							onChange={(e) =>
								setComplexityProperty(
									phaseKey,
									firstActivity.id,
									firstActivityContent.complexityId,
									'isProcessStep',
									e.target.checked
								)
							}
						/>
						<span class="CustomCheckmark"></span>
					</label>
				</div>
				<div class="genericTablePreview">
					<label class="NotificationChkboxContainer">
						<input
							name="Consulting"
							type="checkbox"
							aria-label="Is Lite Checkbox"
							checked={firstActivityContent.isLite}
							onChange={(e) =>
								setComplexityProperty(
									phaseKey,
									firstActivity.id,
									firstActivityContent.complexityId,
									'isLite',
									e.target.checked
								)
							}
						/>
						<span class="CustomCheckmark"></span>
					</label>
				</div>
				<div class="genericTablePreview">
					<label class="NotificationChkboxContainer">
						<input
							name="Consulting"
							type="checkbox"
							aria-label="Is Full Checkbox"
							checked={firstActivityContent.isFull}
							onChange={(e) =>
								setComplexityProperty(
									phaseKey,
									firstActivity.id,
									firstActivityContent.complexityId,
									'isFull',
									e.target.checked
								)
							}
						/>
						<span class="CustomCheckmark"></span>
					</label>
				</div>
				<div class="genericTablePreview">
					<label class="NotificationChkboxContainer">
						<input
							name="Consulting"
							type="checkbox"
							aria-label="Is Secure By Default Checkbox"
							checked={firstActivity.secureByDefault}
							onChange={(e) =>
								setActivityProperty(
									phaseKey,
									firstActivity.id,
									'secureByDefault',
									e.target.checked
								)
							}
						/>
						<span class="CustomCheckmark"></span>
					</label>
				</div>
			</div>
			{expandedPhases.includes(phaseKey) && (
				<div className="gridToggleDiv">
					{Object.keys(phasesData[phaseKey].activities).map(
						(activityKey, activityIndex) => (
							<Activity
								phaseKey={phaseKey}
								setActivityProperty={setActivityProperty}
								setComplexityProperty={setComplexityProperty}
								phasesData={phasesData}
								activityKey={activityKey}
								activityIndex={activityIndex}
								deliverables={deliverables}
								proceessGroups={proceessGroups}
							/>
						)
					)}
				</div>
			)}
		</li>
	);
};

const Activity = ({
	phaseKey,
	setActivityProperty,
	setComplexityProperty,
	phasesData,
	activityKey,
	activityIndex,
	deliverables,
	proceessGroups,
}) => {
	const activity = phasesData[phaseKey].activities[activityKey];
	return Object.keys(activity.complexityLevelDetails).map(
		(complexityKey, complexityIndex) => (
			<ActivityComplexity
				activity={activity}
				activityKey={activityKey}
				phaseKey={phaseKey}
				setActivityProperty={setActivityProperty}
				setComplexityProperty={setComplexityProperty}
				complexityKey={complexityKey}
				complexityIndex={complexityIndex}
				activityIndex={activityIndex}
				deliverables={deliverables}
				proceessGroups={proceessGroups}
			/>
		)
	);
};

const ActivityComplexity = ({
	activity,
	activityKey,
	phaseKey,
	setActivityProperty,
	setComplexityProperty,
	complexityKey,
	complexityIndex,
	activityIndex,
	deliverables,
	proceessGroups,
}) => {
	{
		const activityContent = activity.complexityLevelDetails[complexityKey];
		if (activityIndex === 0 && complexityIndex === 0) return <></>;
		return (
			<div class="genericTableContainer">
				<div class="genericTableTitle"></div>
				<div class="genericTablePhase">{activity.title}</div>
				<div class="genericTableUpload">{activityContent.complexityName}</div>
				<div class="genericTableApproveSentBack">
					<Dropdown
						id={`${activityKey}_${complexityKey}_deleiverable`}
						className="activityReferProjectSelectionDropdown"
						data={deliverables}
						value={activity.deliverable}
						setValue={(val) =>
							setActivityProperty(phaseKey, activityKey, 'deliverable', val)
						}
						label="Select Is Deliverable"
						enableDropdown={complexityIndex === 0}
						disableNone={true}
					/>
				</div>
				<div class="genericTableDownload">
					<Dropdown
						id={`${activityKey}_${complexityKey}_processGroup`}
						className="activityReferProjectSelectionDropdown"
						data={proceessGroups}
						value={activity.processGroup}
						setValue={(val) =>
							setActivityProperty(phaseKey, activityKey, 'processGroup', val)
						}
						label="Select Is Process Group"
						enableDropdown={complexityIndex === 0}
						disableNone={true}
					/>
				</div>
				<div class="genericTablePreview">
					<label class="NotificationChkboxContainer">
						<input
							name="Consulting"
							type="checkbox"
							checked={activityContent.isCritical}
							onChange={(e) =>
								setComplexityProperty(
									phaseKey,
									activityKey,
									complexityKey,
									'isCritical',
									e.target.checked
								)
							}
						/>
						<span class="CustomCheckmark"></span>
					</label>
				</div>
				<div class="genericTablePreview">
					<label class="NotificationChkboxContainer">
						<input
							name="Consulting"
							type="checkbox"
							checked={activityContent.isProcessStep}
							onChange={(e) =>
								setComplexityProperty(
									phaseKey,
									activityKey,
									complexityKey,
									'isProcessStep',
									e.target.checked
								)
							}
						/>
						<span class="CustomCheckmark"></span>
					</label>
				</div>
				<div class="genericTablePreview">
					<label class="NotificationChkboxContainer">
						<input
							name="Consulting"
							type="checkbox"
							checked={activityContent.isLite}
							onChange={(e) =>
								setComplexityProperty(
									phaseKey,
									activityKey,
									complexityKey,
									'isLite',
									e.target.checked
								)
							}
						/>
						<span class="CustomCheckmark"></span>
					</label>
				</div>
				<div class="genericTablePreview">
					<label class="NotificationChkboxContainer">
						<input
							name="Consulting"
							type="checkbox"
							checked={activityContent.isFull}
							onChange={(e) =>
								setComplexityProperty(
									phaseKey,
									activityKey,
									complexityKey,
									'isFull',
									e.target.checked
								)
							}
						/>
						<span class="CustomCheckmark"></span>
					</label>
				</div>
				<div class="genericTablePreview">
					<label class="NotificationChkboxContainer">
						<input
							name="Consulting"
							type="checkbox"
							checked={complexityIndex === 0 && activity.secureByDefault}
							disabled={complexityIndex !== 0}
							onChange={(e) =>
								setActivityProperty(
									phaseKey,
									activityKey,
									'secureByDefault',
									e.target.checked
								)
							}
						/>
						<span class="CustomCheckmark"></span>
					</label>
				</div>
			</div>
		);
	}
};

export default ActivityGridModal;
