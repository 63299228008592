import { saveAs } from 'file-saver';
import { Icon } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAxios } from '../../context/AxiosContext';
import Spinner from '../../helpers/Spinner';
import MultiSelectDropdownWithAll from './MultiSelectDropdownWithAll';
import { useHistory, useLocation } from 'react-router';
import { useAppInsights } from '../../context/TelemetryContext';
import MultiSelectDropdown from './MultiSelectDropdown';

const RaciGridModal = ({}) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const { axiosGetService, axiosPostService, axiosGetFileService } = useAxios();
	const { track } = useAppInsights();
	const projectTypeData = [
		{
			id: 1,
			name: 'Consulting',
		},
		{
			id: 2,
			name: 'Support',
		},
	];
	// project type state
	const [selectedProjectType, setSelectedProjectType] = useState([]);
	const [selectedPhases, setSelectedPhases] = useState([]);
	const [phases, setPhases] = useState([]);
	//solution method state
	const [selectedSolutionMethods, setSelectedSolutionMethods] = useState([]);
	const [solutionMethods, setSolutionMethods] = useState([]);
	const [activitySearchValue, setActivitySearchValue] = useState('');
	const [selectedComplexities, setSelectedComplexities] = useState([]);
	const [availableComplexites, setAvailableComplexites] = useState([]);
	const [showSpinner, setShowSpinner] = useState(false);
	const [phasesData, setPhasesData] = useState(null);
	const [expandedPhases, setExpandedPhases] = useState([]);
	const [showFilterOptions, setShowFilterOptions] = useState(true);
	const [totalResultCount, setTotalResultCount] = useState(0);
	const [roles, setRoles] = useState([]);
	const findSelectedItem = (value, item) => {
		const filteredData = value.filter(({ id }) => id === item);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	const getSolutionMethods = async () => {
		const selecteProject = selectedProjectType.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`/api/sdmsolutionmethod/methodsbytypeforfilters?methodTypeId=${
				selecteProject.length === 1 ? selecteProject.toString() : 0
			}&isSvgRequired=${false}`
		).then((response) => {
			const methods = response.data.map((item) => {
				return {
					id: item.solutionMethodId,
					name: item.solutionMethodName,
				};
			});
			const filterTag = selectedSolutionMethods.filter(({ id }) =>
				findSelectedItem(methods, id)
			);
			if (
				JSON.stringify(filterTag) !== JSON.stringify(selectedSolutionMethods)
			) {
				setSelectedSolutionMethods(filterTag);
			}
			setSolutionMethods(methods);
		});
	};
	const getPhases = async () => {
		const solutionMethodsTag = selectedSolutionMethods.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`api/sdmphase/getphases?searchValue=0&methodIds=${
				solutionMethodsTag.length > 0 ? solutionMethodsTag.toString() : 0
			}&top=${0}`
		).then((response) => {
			const data = response.data.phases.map((item) => {
				return {
					name: item.phaseName,
					id: item.phaseId,
				};
			});
			const filterTag = selectedPhases.filter(({ id }) =>
				findSelectedItem(data, id)
			);
			if (JSON.stringify(filterTag) !== JSON.stringify(selectedPhases)) {
				setSelectedPhases(filterTag);
			}
			setPhases(data);
		});
	};
	const downloadRACIData = () => {
		if (selectedSolutionMethods.length === 0)
			toast.error('Solution Method is required', { position: 'top-right' });
		else if (selectedComplexities.length === 0)
			toast.error('Complexity is required', { position: 'top-right' });
		else {
			setShowSpinner(true);
			axiosGetFileService(
				`api/sdmrole/downloadactivityraciexcel?projectTypeIds=${
					selectedProjectType.length > 0
						? selectedProjectType.map((type) => type.id).toString()
						: 0
				}&methodIds=${
					selectedSolutionMethods.length > 0
						? selectedSolutionMethods.map((method) => method.id).toString()
						: 0
				}&phaseIds=${
					selectedPhases.length > 0
						? selectedPhases.map((phase) => phase.id).toString()
						: 0
				}&groupIds=${0}&subgroupIds=${0}&complexityIds=${
					selectedComplexities.length > 0
						? selectedComplexities.map((complexity) => complexity.id).toString()
						: 0
				}&searchValue=${
					activitySearchValue.length > 0 ? activitySearchValue : 0
				}`
			).then((response) => {
				saveAs(new Blob([response.data]), `RACIData.xlsx`);
				toast.info('File Successfully Downloaded', {
					position: 'top-right',
				});
				track(1, 2005, 'RACI Excel', 'RACI Excel');
				setShowSpinner(false);
			});
		}
	};
	const getComplexities = async () => {
		await axiosGetService(`api/activities/projectcomplexity`).then((res) => {
			const data = res.data.map((item) => {
				return {
					name: item.name,
					id: item.id,
				};
			});
			setAvailableComplexites(data);
		});
	};

	const getRACIGridData = async () => {
		if (selectedSolutionMethods.length === 0)
			toast.error('Solution Method is required', { position: 'top-right' });
		else if (selectedComplexities.length === 0)
			toast.error('Complexity is required', { position: 'top-right' });
		else {
			setShowSpinner(true);
			setExpandedPhases([]);
			const response = await axiosGetService(
				`api/sdmactivity/getactivityracidata?methodIds=
				${selectedSolutionMethods.map(({ id }) => id).toString()}
					&phaseIds=${selectedPhases.length > 0 ? selectedPhases.map(({ id }) => id) : 0}
					&complexityIds=${
						selectedComplexities.length > 0
							? selectedComplexities
									.map((complexity) => complexity.id)
									.toString()
							: 0
					}&searchValue=${activitySearchValue || 0}`
			);
			setRoles(
				response.data.allRoles.map(({ id, name }) => {
					return { id: id.toString(), name };
				})
			);
			const phaseData = {};
			response.data.phases.map((phase) => {
				const {
					name,
					phaseId: id,
					activities,
					solutionMethodId,
					solutionMethodName,
					projectTypeName,
				} = phase;
				const activitiesData = {};
				activities.forEach((activity) => {
					if (activitiesData[activity.id]) {
						activitiesData[activity.id].complexityLevelDetails[
							activity.complexityId
						] = {
							complexityId: activity.complexityId,
							complexityName: activity.complexityName,
							accountableRoleIds:
								activity.accountableRoleIds?.split(',').map((roleId) => {
									return { id: roleId };
								}) ?? [],
							informedRoleIds:
								activity.accountableRoleIds?.split(',').map((roleId) => {
									return { id: roleId };
								}) ?? [],
							responsibleRoleIds:
								activity.responsibleRoleIds?.split(',').map((roleId) => {
									return { id: roleId };
								}) ?? [],
							consultedRoleIds:
								activity.consultedRoleIds?.split(',').map((roleId) => {
									return { id: roleId };
								}) ?? [],
						};
					} else {
						activitiesData[activity.id] = {
							id: activity.id,
							title: activity.title,
							activityId: activity.activityId,
							complexityLevelDetails: {
								[activity.complexityId]: {
									complexityId: activity.complexityId,
									complexityName: activity.complexityName,
									accountableRoleIds:
										activity.accountableRoleIds?.split(',').map((roleId) => {
											return { id: roleId };
										}) ?? [],
									informedRoleIds:
										activity.informedRoleIds?.split(',').map((roleId) => {
											return { id: roleId };
										}) ?? [],
									responsibleRoleIds:
										activity.responsibleRoleIds?.split(',').map((roleId) => {
											return { id: roleId };
										}) ?? [],
									consultedRoleIds:
										activity.consultedRoleIds?.split(',').map((roleId) => {
											return { id: roleId };
										}) ?? [],
								},
							},
						};
					}
				});
				phaseData[id] = {
					name,
					id,
					solutionMethodId,
					solutionMethodName,
					projectTypeName,
					activities: activitiesData,
					activityCount: activities.length,
				};
			});
			setPhasesData(phaseData);
			setShowSpinner(false);
			setTotalResultCount(response.data.count);
		}
	};

	const setComplexityProperty = (
		phaseKey,
		activityKey,
		complexityKey,
		propertyName,
		propertyValue
	) => {
		const newPhaseData = { ...phasesData };
		newPhaseData[phaseKey].activities[activityKey].complexityLevelDetails[
			complexityKey
		][propertyName] = propertyValue;
		setPhasesData(newPhaseData);
	};
	const togglePhaseExpand = (phaseKey) => {
		if (expandedPhases.includes(phaseKey)) {
			setExpandedPhases(expandedPhases.filter((key) => key !== phaseKey));
		} else {
			setExpandedPhases([...expandedPhases, phaseKey]);
		}
	};

	const saveRACIGrid = async () => {
		const postBody = { phases: [] };
		setShowSpinner(true);
		for (const phaseKey in phasesData) {
			const phase = phasesData[phaseKey];
			const postActivites = [];
			for (const activityKey in phase.activities) {
				const activity = phase.activities[activityKey];
				for (const complexityKey in activity.complexityLevelDetails) {
					const activityContent =
						activity.complexityLevelDetails[complexityKey];
					postActivites.push({
						id: activity.id,
						title: activity.title,
						activityId: activity.activityId,
						complexityId: activityContent.complexityId,
						complexityName: activityContent.complexityName,
						responsible: activityContent.responsibleRoleIds.map(({ id }) => id),
						accountable: activityContent.accountableRoleIds.map(({ id }) => id),
						consulted: activityContent.consultedRoleIds.map(({ id }) => id),
						informed: activityContent.informedRoleIds.map(({ id }) => id),
					});
				}
			}
			postBody.phases.push({ id: phase.id, activities: postActivites });
		}
		const response = await axiosPostService(
			`api/admin/activitiesracigrid`,
			postBody
		);
		if (response.data.status)
			toast.info('Activites updated successfully', { position: 'top-right' });
		else toast.error(response.data.errorMsg, { position: 'top-right' });
		setShowSpinner(false);
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getSolutionMethods();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedProjectType]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getPhases();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedSolutionMethods]);
	useEffect(() => {
		getComplexities();
	}, []);
	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles RaciGrid"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => history.push(pathname)}
			show={true}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage RACI Grid
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="methodOwnerMainContainer">
					<div className="methodOwnerPage">
						<div className="methodOwnerContainer">
							<div className="methodLeftContainer">
								<ul className="methodPhaseLeftMenu">
									<li className="activePhaseTab">
										<span>Edit RACI Grid</span>
									</li>
								</ul>
							</div>
							<div className="middleDetailsContainer">
								{!showSpinner ? (
									<div className="methodOwnerMiddleContainer">
										<div className="RaciGridFiltersPanel">
											<div className="cloneActivityButtonAction">
												<div className="addActivityFields">
													<div className="cloneActivityContainer"></div>
													<div className="cloneActivityFiltersWrapper">
														{showFilterOptions ? (
															<div>
																<div className="cloneActivitySolTypeFilters">
																	<div className='hidingConsultingSupport'>
																		<MultiSelectDropdownWithAll
																			id="projectTypes"
																			className="activityReferProjectSelectionDropdown"
																			data={projectTypeData}
																			value={selectedProjectType}
																			setValue={setSelectedProjectType}
																			label="Project Type"
																			enableDropdown={true}
																		/>
																	</div>
																	<div className="multiSelectdropDown CloseMultiSelectDp">
																		<div
																			title="Select Solution Method"
																			id="activityReferSolutionMethodDropdown"
																		>
																			<MultiSelectDropdownWithAll
																				id="solutionMethods"
																				className="activityReferSolutionMethodDropdown "
																				data={solutionMethods}
																				value={selectedSolutionMethods}
																				setValue={setSelectedSolutionMethods}
																				label="Solution Methods"
																				enableDropdown={true}
																				required={true}
																			/>
																		</div>
																	</div>
																</div>
																<div className="cloneActivitySolTypeFilters">
																	<div className="multiSelectdropDown CloseMultiSelectDp">
																		<div
																			title="Select Phases"
																			id="activityReferSolutionMethodphasesDropdown"
																		>
																			<MultiSelectDropdownWithAll
																				id="phases"
																				className="activityReferSolutionMethodphasesDropdown"
																				data={phases}
																				value={selectedPhases}
																				setValue={setSelectedPhases}
																				label="Phases"
																				enableDropdown={true}
																			/>
																		</div>
																	</div>
																</div>
																<div className="cloneActivitySolTypeFilters">
																	<div className="multiSelectdropDown CloseMultiSelectDp">
																		<div
																			title="Select Complexities"
																			id="activityReferSolutionMethodphasesDropdown"
																		>
																			<MultiSelectDropdownWithAll
																				id="complexities"
																				className="activityReferSolutionMethodphasesDropdown"
																				data={availableComplexites}
																				value={selectedComplexities}
																				setValue={setSelectedComplexities}
																				label="Complexities"
																				enableDropdown={true}
																				required={true}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														) : (
															<></>
														)}

														<div className="cloneActivityFieldsWrapper showGridFiltersToggle">
															<Icon iconName="FilterSolid" aria-hidden="true" />

															<Icon
																iconName={
																	showFilterOptions
																		? 'ChevronUp'
																		: 'ChevronDown'
																}
																aria-hidden="true"
																className="cloneDropDownArrow"
																onClick={() =>
																	setShowFilterOptions(!showFilterOptions)
																}
															/>
														</div>
														<div className="cloneActivityFieldsWrapper raciGridSearchBox">
															<input
																title=""
																placeholder="Activity name"
																value={activitySearchValue}
																onChange={(e) =>
																	setActivitySearchValue(e.target.value)
																}
															/>
															<div className="getActivityGridDetails">
																<span
																	className="getGridDataButton"
																	onClick={getRACIGridData}
																></span>
															</div>
															<div className="raciExportDiv">
																<span
																	className="getGridDataExportButton"
																	onClick={downloadRACIData}
																></span>
															</div>
															<div className="resultsCountOfGrid">
																{phasesData && (
																	<span>{totalResultCount} Results found</span>
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										{phasesData && (
											<div className="RaciGridFiltersResults">
												<div className="gridViewResultsTable">
													<div class="addRolesListContainer addDeliveryPlaybookContainer">
														<ul>
															<li>
																<div class="genericTableList">
																	<div class="genericTableHeader">
																		<div class="genericTableTitle">
																			Phase Name
																		</div>
																		<div class="genericTablePhase">
																			Activity Name
																		</div>
																		<div class="genericTableUpload">
																			Complexity
																		</div>

																		<div class="genericTablePreview">
																			Responsible
																		</div>
																		<div class="genericTablePreview">
																			Accountable
																		</div>
																		<div class="genericTablePreview">
																			Consulted
																		</div>
																		<div class="genericTablePreview">
																			Informed
																		</div>
																	</div>
																	<ul>
																		{Object.keys(phasesData).map((phaseKey) => (
																			<Phase
																				phaseKey={phaseKey}
																				setComplexityProperty={
																					setComplexityProperty
																				}
																				phasesData={phasesData}
																				roles={roles}
																				expandedPhases={expandedPhases}
																				togglePhaseExpand={togglePhaseExpand}
																				key={`phase_${phaseKey}`}
																				pageType={'phasePage'}
																			/>
																		))}
																	</ul>
																</div>
															</li>
														</ul>
													</div>
												</div>
											</div>
										)}
									</div>
								) : (
									<Spinner />
								)}
							</div>
							<div
								className="saveContinueDetailsContainer"
								onClick={saveRACIGrid}
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										saveRACIGrid();
									}
								}}
							>
								<button className="forwardButton">
									<span className="saveContinueButton">Save</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

const Phase = ({
	phasesData,
	phaseKey,
	setComplexityProperty,
	expandedPhases,
	togglePhaseExpand,
	roles,
}) => {
	const firstActivity = Object.values(phasesData[phaseKey].activities)[0];
	const firstActivityContent = Object.values(
		firstActivity.complexityLevelDetails
	)[0];
	return (
		<li>
			<div class="genericTableContainer">
				<div class="genericTableTitle">
					{phasesData[phaseKey].name}
					<div className="gridViewInfoIcon">
						<Icon iconName="Info" />
						<div class="tabsTitleToolTip">
							<span class="tabsToolTipArrow"></span>
							<h5>Phase Details</h5>
							<h6>{phasesData[phaseKey].projectTypeName}</h6>
							<span class="detailsOfCloneActivity">
								<span class="detailsCloneActivitySol">
									<span>{phasesData[phaseKey].solutionMethodName}</span>
									:&nbsp;
									<span>{phasesData[phaseKey].name}</span>
								</span>
							</span>
						</div>
					</div>
					<div className="countOfActivitiesInGrid">
						<Icon
							iconName={
								!expandedPhases.includes(phaseKey) ? 'ChevronDown' : 'ChevronUp'
							}
							onClick={() => togglePhaseExpand(phaseKey)}
						/>
						{expandedPhases.includes(phaseKey) || (
							<span>+{phasesData[phaseKey].activityCount - 1} More</span>
						)}
					</div>
				</div>
				<div class="genericTablePhase">{firstActivity.title}</div>
				<div class="genericTableUpload">
					{firstActivityContent.complexityName}
				</div>
				<div class="genericTablePreview">
					<MultiSelectDropdown
						id={`${firstActivity.id}_${firstActivityContent.complexityId}_responsibleRoleIds`}
						className="activityReferSolutionMethodphasesDropdown"
						data={roles}
						value={firstActivityContent.responsibleRoleIds}
						setValue={(val) =>
							setComplexityProperty(
								phaseKey,
								firstActivity.id,
								firstActivityContent.complexityId,
								'responsibleRoleIds',
								val
							)
						}
						label="Responsible Roles"
						enableDropdown={true}
					/>
				</div>
				<div class="genericTablePreview">
					<MultiSelectDropdown
						id={`${firstActivity.id}_${firstActivityContent.complexityId}_accountableRoleIds`}
						className="activityReferSolutionMethodphasesDropdown"
						data={roles}
						value={firstActivityContent.accountableRoleIds}
						label="Accountable Roles"
						enableDropdown={true}
						setValue={(val) =>
							setComplexityProperty(
								phaseKey,
								firstActivity.id,
								firstActivityContent.complexityId,
								'accountableRoleIds',
								val
							)
						}
					/>
				</div>
				<div class="genericTablePreview">
					<MultiSelectDropdown
						id={`${firstActivity.id}_${firstActivityContent.complexityId}_consultedRoleIds`}
						className="activityReferSolutionMethodphasesDropdown"
						data={roles}
						value={firstActivityContent.consultedRoleIds}
						label="Consulted Roles"
						enableDropdown={true}
						setValue={(val) =>
							setComplexityProperty(
								phaseKey,
								firstActivity.id,
								firstActivityContent.complexityId,
								'consultedRoleIds',
								val
							)
						}
					/>
				</div>
				<div class="genericTablePreview">
					<MultiSelectDropdown
						id={`${firstActivity.id}_${firstActivityContent.complexityId}_informedRoleIds`}
						className="activityReferSolutionMethodphasesDropdown"
						data={roles}
						value={firstActivityContent.informedRoleIds}
						setValue={(val) =>
							setComplexityProperty(
								phaseKey,
								firstActivity.id,
								firstActivityContent.complexityId,
								'informedRoleIds',
								val
							)
						}
						label="Informed Roles"
						enableDropdown={true}
					/>
				</div>
			</div>
			{expandedPhases.includes(phaseKey) && (
				<div className="gridToggleDiv">
					{Object.keys(phasesData[phaseKey].activities).map(
						(activityKey, activityIndex) => (
							<Activity
								phaseKey={phaseKey}
								setComplexityProperty={setComplexityProperty}
								phasesData={phasesData}
								activityKey={activityKey}
								activityIndex={activityIndex}
								roles={roles}
								key={`activity_${activityKey}`}
							/>
						)
					)}
				</div>
			)}
		</li>
	);
};

const Activity = ({
	phaseKey,
	setComplexityProperty,
	phasesData,
	activityKey,
	activityIndex,
	roles,
}) => {
	const activity = phasesData[phaseKey].activities[activityKey];
	return Object.keys(activity.complexityLevelDetails).map(
		(complexityKey, complexityIndex) => (
			<ActivityComplexity
				activity={activity}
				activityKey={activityKey}
				phaseKey={phaseKey}
				setComplexityProperty={setComplexityProperty}
				complexityKey={complexityKey}
				complexityIndex={complexityIndex}
				activityIndex={activityIndex}
				roles={roles}
				key={`complexity_${activityKey}_${complexityKey}`}
			/>
		)
	);
};

const ActivityComplexity = ({
	activity,
	activityKey,
	phaseKey,
	setComplexityProperty,
	complexityKey,
	complexityIndex,
	activityIndex,
	roles,
}) => {
	{
		const activityContent = activity.complexityLevelDetails[complexityKey];
		if (activityIndex === 0 && complexityIndex === 0) return <></>;
		return (
			<div class="genericTableContainer">
				<div class="genericTableTitle"></div>
				<div class="genericTablePhase">{activity.title}</div>
				<div class="genericTableUpload">{activityContent.complexityName}</div>
				<div class="genericTablePreview">
					<MultiSelectDropdown
						id={`${activityKey}_${complexityKey}_responsibleRoleIds`}
						className="activityReferSolutionMethodphasesDropdown"
						data={roles}
						value={activityContent.responsibleRoleIds}
						setValue={(val) =>
							setComplexityProperty(
								phaseKey,
								activityKey,
								complexityKey,
								'responsibleRoleIds',
								val
							)
						}
						label="Responsible Roles"
						enableDropdown={true}
					/>
				</div>
				<div class="genericTablePreview">
					<MultiSelectDropdown
						id={`${activityKey}_${complexityKey}_accountableRoleIds`}
						className="activityReferSolutionMethodphasesDropdown"
						data={roles}
						value={activityContent.accountableRoleIds}
						setValue={(val) =>
							setComplexityProperty(
								phaseKey,
								activityKey,
								complexityKey,
								'accountableRoleIds',
								val
							)
						}
						label="Accountable Roles"
						enableDropdown={true}
					/>
				</div>
				<div class="genericTablePreview">
					<MultiSelectDropdown
						id={`${activityKey}_${complexityKey}_consultedRoleIds`}
						className="activityReferSolutionMethodphasesDropdown"
						data={roles}
						value={activityContent.consultedRoleIds}
						setValue={(val) =>
							setComplexityProperty(
								phaseKey,
								activityKey,
								complexityKey,
								'consultedRoleIds',
								val
							)
						}
						label="Consulted Roles"
						enableDropdown={true}
					/>
				</div>
				<div class="genericTablePreview">
					<MultiSelectDropdown
						id={`${activityKey}_${complexityKey}_informedRoleIds`}
						className="activityReferSolutionMethodphasesDropdown"
						data={roles}
						value={activityContent.informedRoleIds}
						setValue={(val) =>
							setComplexityProperty(
								phaseKey,
								activityKey,
								complexityKey,
								'informedRoleIds',
								val
							)
						}
						label="Informed Roles"
						enableDropdown={true}
					/>
				</div>
			</div>
		);
	}
};

export default RaciGridModal;
