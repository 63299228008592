import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import $ from 'jquery';
import ActivityRaciMatrix from '../ActivityRaciMatrix';
import PreviewCommonModal from '../Activity/PreviewCommonModal';
import { useAxios } from '../../context/AxiosContext';


const getRolesName = (roles) => roles.map(({ name }) => name);

const ActivityPreview = ({
	showActivityPreview,
	setShowActivityPreview,
	complexityLevelDetails,
	selectedComplexity,
	hashTags,
	activityTitle,
	tools,
	topics,
	procedures,
	businessRuleContent,
	complexities,
	owner
}) => {
	const { axiosGetService  } = useAxios();
	const [entryCriteria, setEntryCriteria] = useState([
		...complexityLevelDetails[selectedComplexity].entryCriteria.filter(
			(item) => item.criteriaTypeName === 'Entry Criteria'
		),
	]);
	const [input, setInput] = useState([
		...complexityLevelDetails[selectedComplexity].entryCriteria.filter(
			(item) => item.criteriaTypeName !== 'Entry Criteria'
		),
	]);
	const [exitCriteria, setExitCriteria] = useState([
		...complexityLevelDetails[selectedComplexity].exitCriteria.filter(
			(item) => item.criteriaTypeName === 'Exit Criteria'
		),
	]);
	const [output, setOutput] = useState([
		...complexityLevelDetails[selectedComplexity].exitCriteria.filter(
			(item) => item.criteriaTypeName !== 'Exit Criteria'
		),
	]);
	const [sdmContentErrorMessage, setSDMContentErrorMessage] = useState('');
	const [raciData] = useState(() => ({
		responsibleList: getRolesName(
			complexityLevelDetails[selectedComplexity].responsibleList
		),
		accountableList: getRolesName(
			complexityLevelDetails[selectedComplexity].accountableList
		),
		consultedList: getRolesName(
			complexityLevelDetails[selectedComplexity].consultedList
		),
		informedList: getRolesName(
			complexityLevelDetails[selectedComplexity].informedList
		),
	}));
	useEffect(() => {
		getSDMContentErrorMessage()
	}, [])
	const getSDMContentErrorMessage = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=Activity_EmptyContentMessage`).then(
			(response) => {
				setSDMContentErrorMessage(response.data[0].value);
			}
		);
	};

	$(document).ready(function () {
		$('figure').each(function (index, element) {
			const type = element.className;
			if (type && type.split(' ')[0] === 'image') {
				const imgElement = element.children[0];
				if (typeof $(element).attr('style') !== 'undefined') {
					$(imgElement).attr({ style: $(element).attr('style') });
				} else {
					$(imgElement).attr({ style: ' width: 100%' });
				}
			}
		});
	});
	return (
		<Modal
			className="PreviewAndSubmitPopUp p-0"
			show={showActivityPreview}
			onHide={() => {
				setShowActivityPreview(false);
			}}
			dialogClassName="NewPreviewAndSubmitPopUp"
		>
			<Modal.Header>
				<Modal.Title>
					Preview
					<div className="discardAndPreviewButton mr-0">
						<Icon
							iconName="Cancel"
							title="Cancel"
							tabIndex={0}
							onClick={() => setShowActivityPreview(false)}
						/>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<PreviewCommonModal complexityLevelDetails={complexityLevelDetails}
				selectedComplexity={selectedComplexity} 
				activityTitle={activityTitle}
				tools={tools}
				topics={topics}
				procedures={procedures}
				businessRuleContent={businessRuleContent}
				entryCriteria={entryCriteria}
				exitCriteria={exitCriteria}
				complexities={complexities}
				owner={owner}
				sdmContentErrorMessage={sdmContentErrorMessage}
				/>
			</Modal.Body>
		</Modal>
	);
};

export default ActivityPreview;
