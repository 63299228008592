import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { NavLink, useRouteMatch, Link, useHistory } from 'react-router-dom';
import { v4 as uniqueIdGenerator } from 'uuid';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import ActivityHistory from '../Activities/ActivityVersionsHistory';
import Spinner from '../../helpers/Spinner';
import ActivityTemplateBuilder from '../ActivityTemplateBuilder';
import { toast } from 'react-toastify';
import fileIcons from '../../static/TemplateIcons';
import { handleTernaryCondition } from '../../helpers/Validation';

import '../Activities/Activities.css';
const getDynamicFilterData = (phaseDynamicFilterTag, role) => {
	const selectedFiltersIds = [];
	const filterIds = Object.keys(phaseDynamicFilterTag);

	for (const id of filterIds) {
		if (phaseDynamicFilterTag[Number(id)].selectedTagId.length > 0) {
			selectedFiltersIds.push(
				`${Number(id)}` +
				'-' +
				phaseDynamicFilterTag[Number(id)].selectedTagId.toString()
			);
		}
	}
	if (role.id !== 0) {
		selectedFiltersIds.push(`16-${role.id}`);
	}
	return selectedFiltersIds.length > 0 ? selectedFiltersIds.join('|') : 0;
};
const NewActivities = ({
	data,
	templateDownload,
	setTemplateDownload,
	phaseType,
	selectedRoles,
	isParentActivity,
	activityGroups,
	setShowActivityDeleteModal,
	selectedActivity,
	setSelectedActivity,
	coOwners,
	userAlias,
	searchInput,
	selectedComplexity,
	isMethodOwnerOrCoOwner,
	setShowSubgroupForm,
	setActivityBuilderTabId,
	setShowActivityBuilder,
	setSelectedGroupDetails,
	setShowSubgroupEditForm,
	setSelectedSubgroupDetails,
	setShowDereferGroupModal,
	projectTypeId,
	solutionMethodId,
	setShowDereferSolutionMethodModal,
	setSelectedSolutionMethod,
	solutionMethodsData,
	setSolutionMethodsData,
	phasesData,
	setPhasesData,
	isPhaseRefered,
	activityPhaseId,
	groupDeleteOption,
	subGroupDeleteOption,
	pageType,
	phaseContent,
	setActivityClicked,
	activityClicked,
}) => {
	const history = useHistory();
	const { url } = useRouteMatch();
	const { axiosGetService } = useAxios();
	const { track } = useAppInsights();
	const {
		phase,
		parentPhase,
		complexity,
		setExpandedActivityGroups,
		expandedActivityGroups,
		complexityValidActivities,
		phaseHashtags,
		role,
		phaseDynamicFilterTag,
		endUserView,
	} = useLeftMenuCollapse();
	const [filteredData, setFilteredData] = useState([]);
	const [solutionMethodsExpand, setSolutionMethodsExpand] = useState([]);
	const [complexityTag, setComplexityTag] = useState();

	const phaseData = handleTernaryCondition(
		phaseType === 'phase',
		phase,
		parentPhase
	);

	const handleGroupExpand = (type) => {
		const subgroupIds = [];
		for (const groupActivity of data.activities) {
			if (groupActivity.isSubgroup) {
				subgroupIds.push(
					`${phaseData.title}_${groupActivity.subGroupId}_${groupActivity.subGroupName}`
				);
			}
		}
		const id = `${phaseData.title}_${data.groupId}`;
		if (!expandedActivityGroups.includes(id)) {
			setExpandedActivityGroups((prevState) => [...prevState, id]);
		} else if (type !== 'filterUpdate') {
			const newt = expandedActivityGroups.filter((item) => item !== id);
			setExpandedActivityGroups(newt);
		}
		if (type === 'filterUpdate') {
			setExpandedActivityGroups((prevState) => [...prevState, ...subgroupIds]);
		}
	};

	const handleSolutionMethodGroupExpand = (type, phaseName, groupId) => {
		const id = `${phaseName}_${groupId}`;
		if (!solutionMethodsExpand.includes(id)) {
			setSolutionMethodsExpand((prevState) => [...prevState, id]);
		} else if (type !== 'filterUpdate') {
			const newt = solutionMethodsExpand.filter((item) => item !== id);
			setSolutionMethodsExpand(newt);
		}
	};

	const handleSubgroupExpand = (type, subgroupId, subGroupName) => {
		const id = `${phaseData.title}_${subgroupId}_${subGroupName}`;
		if (!expandedActivityGroups.includes(id)) {
			setExpandedActivityGroups((prevState) => [...prevState, id]);
		} else if (type !== 'filterUpdate') {
			const newt = expandedActivityGroups.filter((item) => item !== id);
			setExpandedActivityGroups(newt);
		}
	};

	const getSolutionMethodData = async (item) => {
		await axiosGetService(
			`/api/sdmsolutionmethod/getmergedphases/${item.solutionMethodId}/${item.isMethodOwnerOrCoOwner && !endUserView
			}/${0}/${0}
			/${0}/${0}/false/0`
		).then((response) => {
			const data = [];
			for (const phase of response.data.phasesList) {
				data.push({
					...phase,
					groups: cloneGroup(phase.activityGroupsData),
				});
			}
			setSolutionMethodsData({
				...solutionMethodsData,
				[item.solutionMethodId]: data,
			});
		});
	};

	const getPhaseData = async (phaseId, solutionMethodId) => {
		const encodedHashtags = phaseHashtags.map((item) => {
			return encodeURIComponent(item);
		});
		await axiosGetService(
			`api/sdmphase/getphasemergedactivities/${solutionMethodId}/${phaseId}/${complexity.id
			}/${coOwners.includes(userAlias) && !endUserView}/${getDynamicFilterData(
				phaseDynamicFilterTag,
				role
			)}/${handleTernaryCondition(
				searchInput.trim().length > 0,
				encodeURIComponent(searchInput.trim()),
				0
			)}/${handleTernaryCondition(
				phaseHashtags.length > 0,
				encodedHashtags.toString(),
				0
			)}/false/0`
		).then((response) => {
			setPhasesData((prevState) => ({
				...prevState,
				[phaseId]: cloneGroup(response.data.activityGroupsData),
			}));
		});
	};
	
	const handleSolutionMethodPhaseExpand = (phaseId, solutionMethodId) => {
		const id = `${phaseId}_${solutionMethodId}`;
		if (!solutionMethodsExpand.includes(id)) {
			setSolutionMethodsExpand((prevState) => [...prevState, id]);
		} else {
			const newt = solutionMethodsExpand.filter((item) => item !== id);
			setSolutionMethodsExpand(newt);
		}
		if (!phasesData[phaseId]) {
			getPhaseData(phaseId, solutionMethodId);
		}
	};

	const handleSolutionMethodsExpand = (type, solutionMethod) => {
		const id = `${solutionMethod.solutionMethodName}_${solutionMethod.solutionMethodId}`;
		if (!solutionMethodsExpand.includes(id)) {
			setSolutionMethodsExpand((prevState) => [...prevState, id]);
		} else if (type !== 'filterUpdate') {
			const newt = solutionMethodsExpand.filter((item) => item !== id);
			setSolutionMethodsExpand(newt);
		}
		if (!solutionMethodsData[solutionMethod.solutionMethodId]) {
			getSolutionMethodData(solutionMethod);
		}
	};

	const getPhaseDataWithFilter = async (solutionMethodId, phaseId) => {
		const encodedHashtags = phaseHashtags.map((item) => {
			return encodeURIComponent(item);
		});
		await axiosGetService(
			`api/sdmphase/getphasemergedactivities/${solutionMethodId}/${phaseId}/${complexity.id
			}/${coOwners.includes(userAlias) && !endUserView}/${getDynamicFilterData(
				phaseDynamicFilterTag,
				role
			)}/${handleTernaryCondition(
				searchInput.trim().length > 0,
				encodeURIComponent(searchInput.trim()),
				0
			)}/${handleTernaryCondition(
				phaseHashtags.length > 0,
				encodedHashtags.toString(),
				0
			)}/false/0`
		).then((response) => {
			setPhasesData((prevState) => ({
				...prevState,
				[phaseId]: cloneGroup(response.data.activityGroupsData),
			}));
		});
	};

	const checkPhaseData = () => {
		for (const solutionMethodId in solutionMethodsData) {
			if (solutionMethodsData[solutionMethodId]) {
				for (const phase of solutionMethodsData[solutionMethodId]) {
					getPhaseDataWithFilter(solutionMethodId, phase.phaseId);
				}
			}
		}
	};

	const cloneActivity = (activities, activityCount) => {
		const activityClone = [];
		for (const activity in activities) {
			if (activity.includes('activity')) {
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: false,
				});
			} else if (activity.includes('subGroups')) {
				const subgroupActivities = [];
				for (const subgroupActivity in activities[activity]) {
					if (subgroupActivity.includes('activity')) {
						subgroupActivities.push({
							...activities[activity][subgroupActivity],
							dragNDropId: uniqueIdGenerator(),
						});
					}
				}
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: true,
					activities: subgroupActivities,
				});
				activityCount += subgroupActivities.length;
			}
		}
		return activityClone;
	};

	const cloneGroup = (data) => {
		let activityCount = 0;
		const activityGroupClone = [];
		for (const group in data) {
			if (group.includes('activityGroups') && group.includes('activity')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: data[group].groupId,
					groupName: data[group].groupName,
					isParentActivity: data[group].isParentActivity,
					activities: cloneActivity(data[group], activityCount),
					approverMembersList: data[group].approverMembersList,
					reviewerMembersList: data[group].reviewerMembersList,
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isSolutionMethod: false,
					isGroupRefered: data[group].isGroupRefered,
					isGroupReferredWithActivity: data[group].isGroupReferredWithActivity,
				};
				activityCount += groupClone.activities.length;
				activityGroupClone.push(groupClone);
			} else if (group.includes('activity')) {
				const orphanActivityClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: 0,
					groupName: '',
					isParentActivity: false,
					activities: [
						{
							...data[group],
							dragNDropId: uniqueIdGenerator(),
							isSubgroup: false,
						},
					],
					approverMembersList: [],
					reviewerMembersList: [],
					isMethodOwnerOrCoOwner: [],
					isSolutionMethod: false,
				};
				activityCount += orphanActivityClone.activities.length;
				activityGroupClone.push(orphanActivityClone);
			} else if (group.includes('solutionMethod')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					solutionMethodId: data[group].solutionMethodId,
					solutionMethodName: data[group].solutionMethodName,
					activities: [],
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isSolutionMethod: true,
				};
				activityCount += groupClone.activities.length;
				activityGroupClone.push(groupClone);
			}
		}
		return activityGroupClone;
	};

	const renderOrphanActivity = (item, group) => {
		return (
			<>
				{group.activities.length !== 0 &&
					group.activities.map((activity) => (
						<div
							className="activityListMainContainer"
							key={activity.dragNDropId}
						>
							<div className="activityContainer">
								<span className="activitytypeColor newActivityTypeColor" />
								{activity.isMethodOwnerOrCoOwner && activity.isDrafted && !endUserView && (
									<span
										className={handleTernaryCondition(
											activity.isDrafted,
											'draftActivity',
											'nonDraftActivity'
										)}
									>
										Draft
									</span>
								)}
								<div className="activityTitle  newActivityTitle" title={activity.activityTitle}>
									<span className="titleOfActivity activityGroupParent">
										{activity.activityIdentifier && (
											<>
												<NavLink
													activeClassName="activityDetail"
													to={`${url}/activity/${activity.activityIdentifier}?complexity=${complexity.id}`}
													// to={`/project/${projectTypeId}/method/${solutionMethodId}/phase/${activity.phaseId}/parentPhase/null/activity/${activity.activityIdentifier}?complexity=${complexity.id}`}
													key={activity.dragNDropId}
													tabIndex={0}
													aria-label={activity.activityTitle}
													onKeyPress={(e) => {
														trackUserEvent(
															e,
															1,
															2,
															'Activity',
															activity.activityTitle,
															{},
															track
														);
														setActivityClicked(true);
														setSelectedActivity(item);
													}}
													onClick={(e) => {
														trackUserEvent(
															{ which: 13 },
															1,
															2,
															'Activity',
															activity.activityTitle,
															{},
															track
														);
														setActivityClicked(true);
														setSelectedActivity(item);
													}}
												>
													{activity.activityTitle}
												</NavLink>
											</>
										)}
										{!activity.activityIdentifier && (
											<>{activity.activityTitle}</>
										)}
									</span>
								</div>

								<Info
									templateCount={activity.templateCount}
									activityId={activity.activityIdentifier}
									templateDownload={templateDownload}
									setTemplateDownload={setTemplateDownload}
									data={group}
									complexityEnabled={
										phase.enableComplexity ||
										(phase.id === null && parentPhase.enableComplexity)
									}
									phaseData={item}
									activity={activity}
									complexityId={complexity.id}
									url={url}
									coOwners={coOwners}
									userAlias={userAlias}
									setShowActivityDeleteModal={setShowActivityDeleteModal}
									setSelectedActivity={setSelectedActivity}
									isMethodOwnerOrCoOwner={isMethodOwnerOrCoOwner}
									groupId={group.groupId}
									activityPhaseId={activityPhaseId}
									solutionMethodId={solutionMethodId}
								/>
							</div>
						</div>
					))}
			</>
		);
	};

	const renderGroup = (item, group, isDelivery) => {
		return (
			<div className="activityListMainContainer activityGroupParent" role="combobox">
				<div className="activityContainer activityGroup">
					<span className="activitytypeColor newActivityTypeColor" />
					<div
						className="activityTitle  newActivityTitle"
						title={group.groupName}
						onKeyPress={(e) => {
							if (e.which === 13) {
								handleSolutionMethodGroupExpand(
									'userUpdate',
									item.phaseName,
									group.groupId
								);
							}
						}}
						onClick={() =>
							handleSolutionMethodGroupExpand(
								'userUpdate',
								item.phaseName,
								group.groupId
							)
						}
						aria-expanded={handleTernaryCondition(
							solutionMethodsExpand.includes(
								`${item.phaseName}_${group.groupId}`
							)
								? 'true'
								: 'false'
						)}
					>
						<Icon
							iconName={handleTernaryCondition(
								solutionMethodsExpand.includes(
									`${item.phaseName}_${group.groupId}`
								),
								'CaretDownSolid8',
								'CaretRightSolid8'
							)}
						/>
						<span aria-label={group.groupName} className="titleOfActivity">
							{group.groupName}
						</span>

						<span
							className="groupColor"
							style={{ background: group.colourCode }}
						></span>
					</div>
				</div>
				{solutionMethodsExpand.includes(
					`${item.phaseName}_${group.groupId}`
				) ? (
					group.activities.length !== 0 ? (
						group.activities.map((activity) => (
							<div key={item.dragNDropId}>
								{activity.isSubgroup ? (
									<div
										className={handleTernaryCondition(
											activity.isParentActivity || isDelivery,
											'delivery subGroupContainer',
											handleTernaryCondition(
												!activity.isParentActivity,
												'orangeDelivery subGroupContainer',
												'subGroupContainer'
											)
										)}
										tabIndex={0}
									>
										<div className="activityChildContainer" role="tree">
											<div class="activityListMainContainer activityGroupParent" role="none">
												<div
													class="activityContainer activityGroup"
													tabIndex="0"
													onKeyPress={(e) => {
														if (e.which === 13) {
															handleSubgroupExpand(
																'userUpdate',
																activity.subGroupId,
																activity.subGroupName
															);
														}
													}}
													onClick={() => {
														handleSubgroupExpand(
															'userUpdate',
															activity.subGroupId,
															activity.subGroupName
														);
													}}
													aria-expanded={handleTernaryCondition(
														solutionMethodsExpand.includes(
															`${phaseData.title}_${activity.subGroupId}_${activity.subGroupName}`
														)
															? 'true'
															: 'false'
													)}
													aria-label={activity.subGroupName}
													role="treeitem"
												>
													<span class="activitytypeColor newActivityTypeColor"></span>
													<div
														class="activityTitle  newActivityTitle"
														title={activity.subGroupName}
													>
														<Icon
															iconName={handleTernaryCondition(
																expandedActivityGroups.includes(
																	`${phaseData.title !== null ? phaseData.title : phaseContent.phaseName}_${activity.subGroupId}_${activity.subGroupName}`
																),
																'CaretDownSolid8',
																'CaretRightSolid8'
															)}
														/>

														<span
															aria-label={activity.subGroupName}
															class="titleOfActivity"
														>
															{activity.subGroupName}
														</span>
													</div>
												</div>
												{expandedActivityGroups.includes(
													`${phaseData.title !== null ? phaseData.title : phaseContent.phaseName}_${activity.subGroupId}_${activity.subGroupName}`
												) &&
													activity.activities.map((subgroupActivity) => (
														<div
															key={subgroupActivity.dragNDropId}
															className={handleTernaryCondition(
																subgroupActivity.isParentActivity || isDelivery,
																'delivery',
																handleTernaryCondition(
																	!subgroupActivity.isParentActivity,
																	'orangeDelivery',
																	''
																)
															)}
														>
															<div className="activityChildContainer">
																<div>
																	<div
																		className="activityChildTitle"
																		title={subgroupActivity.activityTitle}
																	>
																		{subgroupActivity.isMethodOwnerOrCoOwner &&
																			subgroupActivity.isDrafted && !endUserView && (
																				<span
																					className={handleTernaryCondition(
																						subgroupActivity.isDrafted,
																						'draftActivity',
																						'nonDraftActivity'
																					)}
																				>
																					Draft
																				</span>
																			)}

																		<span className="titleOfActivity">
																			{subgroupActivity.activityIdentifier && (
																				<>
																					<NavLink
																						activeClassName="activityDetail"
																						to={`${url}/activity/${subgroupActivity.activityIdentifier}?complexity=${complexity.id}`}
																						// to={`/project/${projectTypeId}/method/${solutionMethodId}/phase/${subgroupActivity.phaseId}/parentPhase/null/activity/${subgroupActivity.activityIdentifier}?complexity=${complexity.id}`}
																						key={subgroupActivity.dragNDropId}
																						tabIndex={0}
																						aria-label={
																							subgroupActivity.activityTitle
																						}
																						onKeyPress={(e) => {
																							trackUserEvent(
																								e,
																								1,
																								2,
																								'Activity',
																								subgroupActivity.activityTitle,
																								{},
																								track
																							);
																						}}
																						onClick={(e) => {
																							trackUserEvent(
																								{ which: 13 },
																								1,
																								2,
																								'Activity',
																								subgroupActivity.activityTitle,
																								{},
																								track
																							);
																						}}
																					>
																						{subgroupActivity.activityTitle}
																					</NavLink>
																				</>
																			)}
																			{!subgroupActivity.activityIdentifier && (
																				<>{subgroupActivity.activityTitle}</>
																			)}
																		</span>
																	</div>

																	<Info
																		templateCount={
																			subgroupActivity.templateCount
																		}
																		activityId={
																			subgroupActivity.activityIdentifier
																		}
																		templateDownload={templateDownload}
																		setTemplateDownload={setTemplateDownload}
																		data={subgroupActivity}
																		complexityEnabled={
																			phase.enableComplexity ||
																			(phase.id === null &&
																				parentPhase.enableComplexity)
																		}
																		phaseData={item}
																		activity={activity}
																		complexityId={complexity.id}
																		url={url}
																		coOwners={coOwners}
																		userAlias={userAlias}
																		setShowActivityDeleteModal={
																			setShowActivityDeleteModal
																		}
																		setSelectedActivity={setSelectedActivity}
																		isMethodOwnerOrCoOwner={
																			isMethodOwnerOrCoOwner
																		}
																		groupId={activity.subGroupId}
																		activityPhaseId={activityPhaseId}
																		solutionMethodId={solutionMethodId}
																	/>
																</div>
															</div>
														</div>
													))}
											</div>
										</div>
									</div>
								) : (
									<>
										<div
											className={handleTernaryCondition(
												activity.isParentActivity || isDelivery,
												'delivery',
												handleTernaryCondition(
													!activity.isParentActivity,
													'orangeDelivery',
													''
												)
											)}
										>
											<div className="activityChildContainer">
												<div>
													<div
														className="activityChildTitle"
														title={activity.activityTitle}
													>
														{activity.isMethodOwnerOrCoOwner &&
															activity.isDrafted && !endUserView && (
																<span
																	className={
																		activity.isDrafted
																			? 'draftActivity'
																			: 'nonDraftActivity'
																	}
																>
																	Draft
																</span>
															)}
														<span className="titleOfActivity">
															{activity.activityIdentifier && (
																<>
																	<NavLink
																		activeClassName="activityDetail"
																		to={`${url}/activity/${activity.activityIdentifier}?complexity=${complexity.id}`}
																		// to={`/project/${projectTypeId}/method/${solutionMethodId}/phase/${activity.phaseId}/parentPhase/null/activity/${activity.activityIdentifier}?complexity=${complexity.id}`}
																		key={activity.dragNDropId}
																		tabIndex={0}
																		aria-label={activity.activityTitle}
																		onKeyPress={(e) => {
																			trackUserEvent(
																				e,
																				1,
																				2,
																				'Activity',
																				activity.activityTitle,
																				{},
																				track
																			);
																		}}
																		onClick={(e) => {
																			trackUserEvent(
																				{ which: 13 },
																				1,
																				2,
																				'Activity',
																				activity.activityTitle,
																				{},
																				track
																			);
																		}}
																	>
																		{activity.activityTitle}
																	</NavLink>
																</>
															)}
															{!activity.activityIdentifier && (
																<>{activity.activityTitle}</>
															)}
														</span>
													</div>

													<Info
														templateCount={activity.templateCount}
														activityId={activity.activityIdentifier}
														templateDownload={templateDownload}
														setTemplateDownload={setTemplateDownload}
														data={group}
														complexityEnabled={
															phase.enableComplexity ||
															(phase.id === null &&
																parentPhase.enableComplexity)
														}
														phaseData={item}
														activity={activity}
														complexityId={complexity.id}
														url={url}
														coOwners={coOwners}
														userAlias={userAlias}
														setShowActivityDeleteModal={
															setShowActivityDeleteModal
														}
														setSelectedActivity={setSelectedActivity}
														isMethodOwnerOrCoOwner={isMethodOwnerOrCoOwner}
														groupId={group.groupId}
														activityPhaseId={activityPhaseId}
														solutionMethodId={solutionMethodId}
													/>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
						))
					) : (
						<div className="activityChildContainer">
							<div className="activityChildTitle noActivities">
								<span className="titleOfActivity">No Activities Found</span>
							</div>
						</div>
					)
				) : (
					<></>
				)}
			</div>
		);
	};

	const renderSolutionMethod = (solutionMethod) => {
		return (
			<div className="activityListMainContainer activityGroupParent" role="combobox">
				<div className="activityContainer activityGroup" tabIndex={0} role="none">
					<span className="activitytypeColor newActivityTypeColor" />
					<div
						className="activityTitle  newActivityTitle"
						title={solutionMethod.solutionMethodName}
					>
						<Icon
							iconName={handleTernaryCondition(
								solutionMethodsExpand.includes(
									`${solutionMethod.solutionMethodName}_${solutionMethod.solutionMethodId}`
								),
								'CaretDownSolid8',
								'CaretRightSolid8'
							)}
							tabIndex="0"
							onKeyPress={(e) => {
								if (e.which === 13) {
									handleSolutionMethodsExpand('userUpdate', solutionMethod);
								}
							}}
							onClick={() =>
								handleSolutionMethodsExpand('userUpdate', solutionMethod)
							}
							aria-expanded={handleTernaryCondition(
								solutionMethodsExpand.includes(
									`${solutionMethod.solutionMethodName}_${solutionMethod.solutionMethodId}`
								)
									? 'true'
									: 'false'
							)}
							aria-label={solutionMethod.solutionMethodName}
							role="treeitem"
						/>
						<span className="titleOfActivity">
							<Link
								to={`/project/${solutionMethod.projectTypeId}/method/${solutionMethod.solutionMethodId}`}
							>
								{solutionMethod.solutionMethodName}
							</Link>
						</span>
					</div>
					<span
						className="activityReferredIcon"
						title={`Linked solution method`}
					></span>
					{coOwners.includes(userAlias) && !endUserView && (
						<span
							className="ActivityDeleteContainer"
							onClick={() => {
								setSelectedSolutionMethod(solutionMethod);
								setShowDereferSolutionMethodModal(true);
							}}
							tabIndex="0"
							onKeyPress={(e) => {
								if (e.which === 13) {
									setSelectedSolutionMethod(solutionMethod);
									setShowDereferSolutionMethodModal(true);
								}
							}}
							title={'De-link solution method'}
							role="button"
						>
							<span className="ActivityDeleteIcon"></span>
						</span>
					)}
				</div>
				{solutionMethodsExpand.includes(
					`${solutionMethod.solutionMethodName}_${solutionMethod.solutionMethodId}`
				) &&
					(solutionMethodsData[solutionMethod.solutionMethodId] ? (
						solutionMethodsData[solutionMethod.solutionMethodId].map(
							(phase) => (
								<div key={phase.phaseId}>
									<div className={'delivery subGroupContainer'}>
										<div className="activityChildContainer" role="tree">
											<div class="activityListMainContainer activityGroupParent" role="none">
												<div class="activityContainer activityGroup" role="none">
													<span class="activitytypeColor newActivityTypeColor"></span>
													<div class="activityTitle  newActivityTitle" title={phase.phaseName}>
														<Icon
															iconName={handleTernaryCondition(
																solutionMethodsExpand.includes(
																	`${phase.phaseId}_${solutionMethod.solutionMethodId}`
																),
																'CaretDownSolid8',
																'CaretRightSolid8'
															)}
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	handleSolutionMethodPhaseExpand(
																		phase.phaseId,
																		solutionMethod.solutionMethodId
																	);
																}
															}}
															onClick={() => {
																handleSolutionMethodPhaseExpand(
																	phase.phaseId,
																	solutionMethod.solutionMethodId
																);
															}}
															aria-expanded={handleTernaryCondition(
																solutionMethodsExpand.includes(
																	`${phase.phaseId}_${solutionMethod.solutionMethodId}`
																)
																	? 'true'
																	: 'false'
															)}
															aria-label={phase.phaseName}
															role="treeitem"
														/>
														<span
															class="titleOfActivity"
															aria-expanded={handleTernaryCondition(
																solutionMethodsExpand.includes(
																	`${phase.phaseId}_${solutionMethod.solutionMethodId}`
																),
																'true',
																'false'
															)}
															title={phase.phaseName}
														>
															<Link
																to={`/project/${solutionMethod.projectTypeId}/method/${solutionMethod.solutionMethodId}/phase/${phase.phaseId}/parentPhase/null`}
															>
																{phase.phaseName}
															</Link>
														</span>
													</div>
												</div>
												{solutionMethodsExpand.includes(
													`${phase.phaseId}_${solutionMethod.solutionMethodId}`
												) && (
														<>
															{phasesData[phase.phaseId] &&
																phasesData[phase.phaseId].map((group) => (
																	<div
																		className={handleTernaryCondition(
																			group.isParentActivity ||
																			group.isSolutionMethod,
																			'delivery',
																			''
																		)}
																		key={group.dragNDropId}
																	>
																		<div className="activityChildContainer">
																			{group.isSolutionMethod ? (
																				renderSolutionMethod(group)
																			) : (
																				<></>
																			)}
																			{group.groupId ? (
																				renderGroup(
																					phasesData[phase.phaseId],
																					group,
																					true
																				)
																			) : (
																				<></>
																			)}
																			{!group.isSolutionMethod &&
																				!group.groupId ? (
																				renderOrphanActivity(
																					phasesData[phase.phaseId],
																					group
																				)
																			) : (
																				<></>
																			)}
																		</div>
																	</div>
																))}
															{!phasesData[phase.phaseId] && (
																<div className="activityChildContainer">
																	<Spinner />
																</div>
															)}
														</>
													)}
											</div>
										</div>
									</div>
								</div>
							)
						)
					) : (
						<div className="activityChildContainer">
							<Spinner />
						</div>
					))}
			</div>
		);
	};

	const matchActivityComplexityId = (complexityList) => {
		for (const id in complexityList) {
			if (complexityList[id] === complexity.id) {
				return true;
			}
		}
		return false;
	};

	const checkActivity = (activity, activities) => {
		if (phaseHashtags.length > 0 || searchInput.trim().length > 0) {
			if (
				matchActivityComplexityId(activity.complexityList) &&
				activities.includes(activity.activityId)
			) {
				return true;
			}
		} else {
			if (matchActivityComplexityId(activity.complexityList)) {
				return true;
			}
		}
	};

	const filterData = () => {
		const filteredActivities = [];
		const activities = complexityValidActivities.map(({ activityId }) => {
			return activityId;
		});
		for (const activity of data.activities) {
			const filterdActivity = { ...activity };
			if (activity.isSubgroup) {
				const updatedSubgroupActivities = [];
				for (const subgroupActivity of filterdActivity.activities) {
					if (checkActivity(subgroupActivity, activities)) {
						updatedSubgroupActivities.push(subgroupActivity);
					}
				}
				filterdActivity.activities = updatedSubgroupActivities;
				filteredActivities.push(filterdActivity);
			} else if (checkActivity(activity, activities)) {
				filteredActivities.push(filterdActivity);
			}
		}
		setFilteredData(filteredActivities);
	};

	const getComplexityTag = async () => {
		await axiosGetService(
			`api/sdmsettings?sdmKey=DefaultComplexityTag`
		).then((response) => {
			const obj = {
				id: response.data[0].value.split(",").at(0),
				name: response.data[0].value.split(",").at(1).toString()
			}
			setComplexityTag(obj);
		});
	};
	
	useEffect(() => {
		getComplexityTag();
	}, []);

	useEffect(() => {
		filterData();
	}, [
		// complexity,
		// activityGroups,
		// complexityValidActivities,
		searchInput,
		phaseHashtags,
	]);

	useEffect(() => {
		if (
			getDynamicFilterData(phaseDynamicFilterTag, role) !== 0 ||
			phaseHashtags.length > 0 ||
			searchInput.trim().length > 0 ||
			selectedComplexity !== complexityTag ||
			(selectedRoles &&
				selectedRoles !== 'Select All' &&
				selectedRoles !== 'None')
		) {
			handleGroupExpand('filterUpdate');
		}
	}, [phaseDynamicFilterTag, selectedRoles, phaseHashtags, data, searchInput]);
	
	useEffect(() => {
		const timeOutId = setTimeout(() => {
			checkPhaseData();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [ role, phaseHashtags, searchInput]);

	if (!data.groupId && !data.isSolutionMethod) {
		return (
			<>
				{filteredData.length !== 0 &&
					filteredData.map((item) => (
						<div className="activityListMainContainer newActivityContainer" key={item.dragNDropId}>
							<div className="activityContainer ">
								<span className=" newActivityTypeColor" />
								{item.isMethodOwnerOrCoOwner && item.isDrafted && !endUserView && (
									<span
										className={
											item.isDrafted ? 'draftActivity' : 'nonDraftActivity'
										}
									>
										Draft
									</span>
								)}
								<div className="activityTitle  newActivityTitle" title={item.activityTitle}>
									<span className="titleOfActivity activityGroupParent">
										{item.activityIdentifier && (
											<>
												<span title='Mandatory' className={item.isProcessStep ? 'mandatoryIcon' : '_spaceForAllignment'}></span>
									            <span  title='Business Rule' className={item.hasBusinessRule ? 'businessIcon' : '_spaceForAllignment'}></span>
												<NavLink
													activeClassName="activityDetail"
													to={`${url}/activity/${item.activityIdentifier}?complexity=${complexity.id}`}
													key={item.dragNDropId}
													tabIndex={0}
													aria-label={item.activityTitle}
													onKeyPress={(e) => {
														trackUserEvent(
															e,
															1,
															2,
															'Activity',
															item.activityTitle,
															{},
															track
														);
														setActivityClicked(true);
														setSelectedActivity(item);
													}}
													onClick={(e) => {
														trackUserEvent(
															{ which: 13 },
															1,
															2,
															'Activity',
															item.activityTitle,
															{},
															track
														);
														setActivityClicked(true);
														setSelectedActivity(item);
													}}
												>
													{item.activityTitle}
												</NavLink>
											</>
										)}
										{!item.activityIdentifier && <>{item.activityTitle}</>}
									</span>
								</div>

								<Info
									templateCount={item.templateCount}
									activityId={item.activityIdentifier}
									templateDownload={templateDownload}
									setTemplateDownload={setTemplateDownload}
									data={data}
									complexityEnabled={
										phase.enableComplexity ||
										(phase.id === null && parentPhase.enableComplexity)
									}
									phaseData={phaseData}
									coOwners={coOwners}
									userAlias={userAlias}
									activity={item}
									complexityId={complexity.id}
									url={url}
									setShowActivityDeleteModal={setShowActivityDeleteModal}
									setSelectedActivity={setSelectedActivity}
									isMethodOwnerOrCoOwner={isMethodOwnerOrCoOwner}
									groupId={data.groupId}
									activityPhaseId={activityPhaseId}
									solutionMethodId={solutionMethodId}
								/>
								{/* <span className='activitytypeColorEnd'/> */}
							</div>
						</div>
					))}
			</>
		);
	}
	if (data.isSolutionMethod) {
		return (
			<div className="referredSolutionMethod">{renderSolutionMethod(data)}</div>
		);
	}

	return (
		<>
			<div className="activityListMainContainer activityGroupParent">
				<div
					className="activityContainer activityGroup"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13) {
							handleGroupExpand('userUpdate');
						}
					}}
					onClick={() => handleGroupExpand('userUpdate')}
					aria-expanded={handleTernaryCondition(
						expandedActivityGroups.includes(
							`${phaseData.title !== null ? phaseData.title : phaseContent.phaseName}_${data.groupId}`
						),
						'true',
						'false'
					)}
					role="combobox"
					aria-label={data.groupName}
					id={data.groupName.replace(/ /g, '')}
                    aria-controls={data.groupName.replace(/ /g, '')}
				>
					<span className=" newActivityTypeColor" />
					<div className="activityTitle  newActivityTitle" 
					title={data.groupName}
					aria-label={data.groupName}
					>
						<Icon
							iconName={handleTernaryCondition(
								expandedActivityGroups.includes(
									`${phaseData.title !== null ? phaseData.title : phaseContent.phaseName}_${data.groupId}`
								),
								'CaretDownSolid8',
								'CaretRightSolid8'
							)}
						/>
						<span
							style={{paddingLeft:'13.5px'}}
							aria-controls={data.groupName.replace(/ /g, '')}
							className="titleOfActivity newActivitiesTitleOfActivity"
							role="combobox"
						    title={data.groupName}
							aria-expanded={handleTernaryCondition(
								expandedActivityGroups.includes(
									`${phaseData.title !== null ? phaseData.title : phaseContent.phaseName}_${data.groupId}`
								),
								'true',
								'false'
							)}
						>
							{data.groupName} 
						</span>
						{data.isGroupRefered && (
							<>
								<span
									className="activityReferredIcon"
									title={`Linked Group from solution method ${data.referedFromSolutionMethodName} and phase ${data.referedFromPhaseName}`}
								></span>
							</>
						)}
						{data.isMethodOwnerOrCoOwner &&
							!isPhaseRefered &&
							!data.isGroupRefered &&
							!data.isGroupReferredWithActivity &&
							!endUserView && (
								<>
									<span
										className="phaseAddIcon"
										onClick={() => {
											setShowSubgroupForm(true);
											setActivityBuilderTabId(2);
											setShowActivityBuilder(true);
											setSelectedGroupDetails(data);
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setShowSubgroupForm(true);
												setActivityBuilderTabId(2);
												setShowActivityBuilder(true);
												setSelectedGroupDetails(data);
											}
										}}
										tabIndex="0"
										title="Add subgroup"
										aria-label={`${data.groupName} add subgroup`}
										role="button"
									></span>
									<span
										class="ActivityEditIcon"
										onClick={() => {
											setShowSubgroupForm(true);
											setActivityBuilderTabId(2);
											setShowActivityBuilder(true);
											setSelectedGroupDetails(data);
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setShowSubgroupForm(true);
												setActivityBuilderTabId(2);
												setShowActivityBuilder(true);
												setSelectedGroupDetails(data);
											}
										}}
										tabIndex="0"
										title="Edit Group"
										aria-label={`${data.groupName} edit group`}
										role="button"
									></span>
									<span
										class="ActivityDeleteIcon"
										onClick={() => {
											groupDeleteOption(data);
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												groupDeleteOption(data);
											}
										}}
										tabIndex="0"
										title="Delete Group"
									    aria-label={`${data.groupName} delete group`}
										role="button"
									></span>
								</>
							)}
						{data.isGroupRefered &&
							coOwners.includes(userAlias) &&
							!endUserView && (
								<span
									className="ActivityDeleteContainer"
									onClick={() => {
										setSelectedGroupDetails(data);
										setShowDereferGroupModal(true);
									}}
									tabIndex="0"
									onKeyPress={(e) => {
										if (e.which === 13) {
											setSelectedGroupDetails(data);
											setShowDereferGroupModal(true);
										}
									}}
									title="De-link Group"
									aria-label="de-link group"
									role="button"
								>
									<span className="ActivityDeleteIcon"></span>
								</span>
							)}
						<span
							className="groupColor"
							style={{ background: data.colourCode }}
						></span>
					</div>
					{/* <span className='activitytypeColorEnd'/> */}
				</div>
				{expandedActivityGroups.includes(
					`${phaseData.title !== null ? phaseData.title : phaseContent.phaseName}_${data.groupId}`
				) &&
					(filteredData.length !== 0 ? (
						filteredData.map((item) => (
							<div key={item.dragNDropId}>
								{item.isSubgroup ? (
									<div
										className={handleTernaryCondition(
											item.isParentActivity,
											'delivery subGroupContainer',
											handleTernaryCondition(
												!item.isParentActivity,
												'orangeDelivery subGroupContainer',
												'subGroupContainer'
											)
										)}
									>
										<div className="activityChildContainer">
											<div className="activityListMainContainer activityGroupParent">
												<div
													class="activityContainer activityGroup"
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															handleSubgroupExpand(
																'userUpdate',
																item.subGroupId,
																item.subGroupName
															);
														}
													}}
													onClick={() =>
														handleSubgroupExpand(
															'userUpdate',
															item.subGroupId,
															item.subGroupName
														)
													}
													aria-expanded={handleTernaryCondition(
														expandedActivityGroups.includes(
															`${phaseData.title !== null ? phaseData.title : phaseContent.phaseName}_${item.subGroupId}_${item.subGroupName}`
														)
															? 'true'
															: 'false'
													)}
													role="combobox"
												>
													<span className="activitytypeColor newActivityTypeColor"></span>
													<div
														className="activityTitle  newActivityTitle"
														title={item.subGroupName}
													>
														<Icon
															iconName={handleTernaryCondition(
																expandedActivityGroups.includes(
																	`${phaseData.title !== null ? phaseData.title : phaseContent.phaseName}_${item.subGroupId}_${item.subGroupName}`
																),
																'CaretDownSolid8',
																'CaretRightSolid8'
															)}
														/>
														<span
															aria-label={item.subGroupName}
															className="titleOfActivity"
														>
															{item.subGroupName}
														</span>
														{item.isMethodOwnerOrCoOwner &&
															!isPhaseRefered &&
															!data.isGroupRefered &&
															!data.isGroupReferredWithActivity &&
															!endUserView && (
																<span
																	class="ActivityEditIcon"
																	onClick={() => {
																		setActivityBuilderTabId(2);
																		setShowActivityBuilder(true);
																		setSelectedSubgroupDetails(item);
																		setShowSubgroupEditForm(true);
																		setSelectedGroupDetails(data);
																	}}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			setActivityBuilderTabId(2);
																			setShowActivityBuilder(true);
																			setSelectedSubgroupDetails(item);
																			setShowSubgroupEditForm(true);
																			setSelectedGroupDetails(data);
																		}
																	}}
																	tabIndex="0"
																	title="Edit Subgroup"
																></span>
															)}
															{item.isMethodOwnerOrCoOwner &&
															!isPhaseRefered &&
															!data.isGroupRefered &&
															!data.isGroupReferredWithActivity &&
															!endUserView && (
																<span
																	class="ActivityDeleteIcon"
																	onClick={() => {
																		 subGroupDeleteOption(item);
																	}}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			 subGroupDeleteOption(item);							
																		}
																	}}
																	tabIndex="0"
																	title="Delete Subgroup"
																></span>
															)}
													</div>
												</div>
												{expandedActivityGroups.includes(
													`${phaseData.title !== null ? phaseData.title : phaseContent.phaseName}_${item.subGroupId}_${item.subGroupName}`
												) &&
													item.activities.map((activity) => (
														<div
															className={handleTernaryCondition(
																activity.isParentActivity,
																'delivery',
																handleTernaryCondition(
																	!activity.isParentActivity,
																	'orangeDelivery',
																	''
																)
															)}
														>
															<div className="activityChildContainer">
																<div>
																	<div
																		className="activityChildTitle"
																		title={activity.activityTitle}
																	>
																		{activity.isMethodOwnerOrCoOwner &&
																			activity.isDrafted && !endUserView && (
																				<span
																					className={handleTernaryCondition(
																						activity.isDrafted,
																						'draftActivity',
																						'nonDraftActivity'
																					)}
																				>
																					Draft
																				</span>
																			)}
																		<span className="titleOfActivity">
																			{activity.activityIdentifier && (
																				<>
																					<NavLink
																						activeClassName="activityDetail"
																						to={`${url}/activity/${activity.activityIdentifier}?complexity=${complexity.id}`}
																						key={activity.dragNDropId}
																						tabIndex={0}
																						aria-label={activity.activityTitle}
																						onKeyPress={(e) => {
																							trackUserEvent(
																								e,
																								1,
																								2,
																								'Activity',
																								activity.activityTitle,
																								{},
																								track
																							);
																						}}
																						onClick={(e) => {
																							trackUserEvent(
																								{ which: 13 },
																								1,
																								2,
																								'Activity',
																								activity.activityTitle,
																								{},
																								track
																							);
																						}}
																					>
																						{activity.activityTitle}
																					</NavLink>
																				</>
																			)}
																			{!activity.activityIdentifier && (
																				<>{activity.activityTitle}</>
																			)}
																		</span>
																	</div>

																	<Info
																		templateCount={activity.templateCount}
																		activityId={activity.activityIdentifier}
																		templateDownload={templateDownload}
																		setTemplateDownload={setTemplateDownload}
																		data={item}
																		complexityEnabled={
																			phase.enableComplexity ||
																			(phase.id === null &&
																				parentPhase.enableComplexity)
																		}
																		phaseData={phaseData}
																		coOwners={coOwners}
																		userAlias={userAlias}
																		activity={activity}
																		complexityId={complexity.id}
																		url={url}
																		setShowActivityDeleteModal={
																			setShowActivityDeleteModal
																		}
																		setSelectedActivity={setSelectedActivity}
																		isMethodOwnerOrCoOwner={
																			isMethodOwnerOrCoOwner
																		}
																		groupId={item.subGroupId}
																		activityPhaseId={activityPhaseId}
																		solutionMethodId={solutionMethodId}
																	/>
																</div>
															</div>
														</div>
													))}
											</div>
										</div>
									</div>
								) : (
									<>
										{(
											<div
												className={handleTernaryCondition(
													item.isParentActivity,
													'delivery',
													handleTernaryCondition(
														!item.isParentActivity,
														'orangeDelivery',
														''
													)
												)}
											>
												<div className={`activityChildContainer ${selectedActivity?.activityId===item?.activityId?'_selectedActivityOnNonRealized':''}`}>
													<div>
														<div
															className="activityChildTitle"
															title={item.activityTitle}
														>
															{item.isMethodOwnerOrCoOwner &&
																item.isDrafted && !endUserView && (
																	<span
																		className={
																			item.isDrafted
																				? 'draftActivity'
																				: 'nonDraftActivity'
																		}
																	>
																		Draft
																	</span>
																)}
															<span className="titleOfActivity">
																{item.activityIdentifier && (
																	<>
																	<span title='Mandatory' className={item.isProcessStep ? 'mandatoryIcon' : '_spaceForAllignment'}></span>
																	<span title='Business Rule' className={item.hasBusinessRule ? 'businessIcon' : '_spaceForAllignment'}></span>
																	{pageType === "nonRealizedItem" ? (
																		<NavLink 
																			activeClassName="activityDetail"
																			to={`${url}/activity/${item.activityId}?complexity=${complexity.id}`}
																			key={item.activityId}
																			tabIndex={0}
																			aria-label={item.activityTitle}
																			onKeyPress={(e) => {
																				if (e.key === 'Enter') {
																					trackUserEvent(
																						e,
																						1,
																						2,
																						'Activity',
																						item.activityTitle,
																						{},
																						track
																					);
																					setSelectedActivity(item);
																					setActivityClicked(true);
																				}
																			}}
																			onClick={(e) => {
																				setSelectedActivity(item);
																				setActivityClicked(true);
																				trackUserEvent(
																				{ which: 13 },
																				1,
																				2,
																				'Activity',
																				item.activityTitle,
																				{},
																				track
																				);
																			}}
																		>
																			{item.activityTitle}
																		</NavLink>
																		) : (
																		<NavLink
																			activeClassName="activityDetail"
																			to={`${url}/activity/${item.activityIdentifier}?complexity=${complexity.id}`}
																			key={item.dragNDropId}
																			tabIndex={0}
																			aria-label={item.activityTitle}
																			onKeyPress={(e) => {
																				trackUserEvent(
																					e,
																					1,
																					2,
																					'Activity',
																					item.activityTitle,
																					{},
																					track
																				);
																				setActivityClicked(true);
																				setSelectedActivity(item);
																			}}
																			onClick={(e) => {
																				setSelectedActivity(item);
																				setActivityClicked(true);
																				trackUserEvent(
																					{ which: 13 },
																					1,
																					2,
																					'Activity',
																					item.activityTitle,
																					{},
																					track
																				);
																			}}
																		>
																			{item.activityTitle}
																		</NavLink>
												)}
																	</>
																)}
																{!item.activityIdentifier && (
																	<>{item.activityTitle}</>
																)}
															</span>
														</div>

														<Info
															templateCount={item.templateCount}
															activityId={item.activityIdentifier}
															templateDownload={templateDownload}
															setTemplateDownload={setTemplateDownload}
															data={data}
															complexityEnabled={
																phase.enableComplexity ||
																(phase.id === null &&
																	parentPhase.enableComplexity)
															}
															phaseData={phaseData}
															coOwners={coOwners}
															userAlias={userAlias}
															activity={item}
															complexityId={complexity.id}
															url={url}
															setShowActivityDeleteModal={
																setShowActivityDeleteModal
															}
															setSelectedActivity={setSelectedActivity}
															isMethodOwnerOrCoOwner={isMethodOwnerOrCoOwner}
															groupId={data.groupId}
															activityPhaseId={activityPhaseId}
															solutionMethodId={solutionMethodId}
														/>
													</div>
												</div>
											</div>
										)}
									</>
								)}
							</div>
						))
					) : (
						<div className="activityChildContainer">
							<div className="activityChildTitle noActivities">
								<span className="titleOfActivity">No Activities Found</span>
							</div>
						</div>
					))}
			</div>
		</>
	);
};

const Info = ({
	activityId,
	templateDownload,
	setTemplateDownload,
	data, // activityGroups Data
	complexityEnabled,
	phaseData,
	templateCount,
	activity,
	complexityId,
	url,
	setShowActivityDeleteModal,
	setSelectedActivity,
	coOwners,
	userAlias,
	isMethodOwnerOrCoOwner,
	groupId,
	activityPhaseId,
	solutionMethodId,
}) => {
	const { track } = useAppInsights();

	const [showTemplates, setShowTemplates] = useState(false);
	const [showActivityTemplateBuilder, setShowActivityTemplateBuilder] =
		useState(false);
	const [selectedTemplateBuilder, setSelectedTemplateBuilder] = useState(0);

	const [templates, setTemplates] = useState([]);
	const [raci, setRACI] = useState({
		id: null,
		responsible: null,
		accountable: null,
		consulted: null,
		informed: null,
	});

	const { appState, complexityValidActivities, role, complexity, endUserView } =
		useLeftMenuCollapse();
	const { axiosGetService } = useAxios();
	const [showActivityVersionsHistory, setShowActivityVersionsHistory] =
		useState(false);
	const { defaultRole } = appState;
	const getTemplates = async () => {
		axiosGetService(
			`api/sdmactivity/getactivitytemplates/${activityId}/${complexity.id} `
		)
			.then((res) => {
				updatedState(res.data.length !== 0, setTemplates, res.data, null);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const activityShowMoreCollapse = (e) => {
		const icon = document.getElementById(`activityShowMore_${activityId}`);
		const modal = document.getElementById('templateSuggetionModal');
		updatePrimaryState(
			icon !== null &&
			!(icon.contains(e.target) || (modal && modal.contains(e.target))),
			setShowTemplates,
			false
		);
	};

	useEffect(() => {
		updatePrimaryState(
			activityId !== templateDownload || templateDownload === null,
			setShowTemplates,
			false
		);
	}, [templateDownload]);

	useEffect(() => {
		const raciData = complexityValidActivities.filter(
			(item) => item.id === activityId
		);
		updatedState(
			raciData.length === 0,
			setRACI,
			{
				id: null,
				responsible: null,
				accountable: null,
				consulted: null,
				informed: null,
			},
			raciData[0]
		);
	}, [data]);

	useState(() => {
		setShowTemplates(false);
		window.addEventListener('click', activityShowMoreCollapse);
		return () => {
			window.removeEventListener('click', activityShowMoreCollapse);
		};
	}, []);

	return (
		<>
			<div className="complexityOfActivity">
				{((!coOwners.includes(userAlias) && !activity.isMethodOwnerOrCoOwner) ||
					endUserView) &&
					role.id !== defaultRole.id && (
						<>
							<div className="raciWrapper complexityIcon"></div>
							<div
								title="Responsible"
								className={handleTernaryCondition(
									role.id === defaultRole.id,
									'raciWrapper hide',
									handleTernaryCondition(
										raci.responsible !== null,
										'raciWrapper responsible',
										'raciWrapper faded'
									)
								)}
							>
								<span>R</span>
							</div>

							<div
								title="Accountable"
								className={handleTernaryCondition(
									role.id === defaultRole.id,
									'raciWrapper hide',
									handleTernaryCondition(
										raci.accountable !== null,
										'raciWrapper accountable',
										'raciWrapper faded'
									)
								)}
							>
								<span>A</span>
							</div>

							<div
								title="Consulted"
								className={handleTernaryCondition(
									role.id === defaultRole.id,
									'raciWrapper hide',
									handleTernaryCondition(
										raci.consulted !== null,
										'raciWrapper consulted',
										'raciWrapper faded'
									)
								)}
							>
								<span>C</span>
							</div>

							<div
								title="Informed"
								className={handleTernaryCondition(
									role.id === defaultRole.id,
									'raciWrapper hide',
									handleTernaryCondition(
										raci.informed !== null,
										'raciWrapper informed',
										'raciWrapper faded'
									)
								)}
							>
								<span>I</span>
							</div>
						</>
					)}
				{activity.isRefered && (
					<span
						className="activityReferredIcon"
						title={`Linked activity from solution method ${activity.referedFromSolutionMethodName} and phase ${activity.referedFromPhaseName}`}
					></span>
				)}

				{(activity.isMethodOwnerOrCoOwner || activity.isActivityOwnerOrCoOwner) &&
					!activity.isCrowdSourced &&
					!activity.isRefered &&
					!endUserView ? (
					<>
						{activity.activityIdentifier && (
							<span
								className="activityOwnerHistoryIcon"
								title="View Edit History"
								onClick={() => {
									setShowActivityVersionsHistory(true);
								}}
								onKeyPress={() => {
									setShowActivityVersionsHistory(true);
								}}
								tabIndex="0"
								aria-label={`Click here to view Edit history modal of ${activity.activityTitle}`}
								role="button"
							></span>
						)}
						<Link
							className="ActivityEditPhaseLand"
							to={`${url}/updateActivity/${activity.activityId}/draft/${activity.id}/complexity/${activity.complexityIds}/default/${complexityId}`}
							key={activity.dragNDropId}
							tabIndex={0}
							aria-label={`Click here to Edit ${activity.activityTitle} Activity`}
							role="link"
							title="Edit Activity"
						>
							<span className="ActivityEditIcon"></span>
						</Link>

						<span
							className="ActivityDeleteContainer"
							onClick={() => {
								setSelectedActivity({
									...activity,
									groupId: handleTernaryCondition(groupId, groupId, 0),
								});
								setShowActivityDeleteModal(true);
							}}
							tabIndex="0"
							onKeyPress={(e) => {
								if (e.which === 13) {
									setSelectedActivity({
										...activity,
										groupId: handleTernaryCondition(groupId, groupId, 0),
									});
									setShowActivityDeleteModal(true);
								}
							}}
							title={handleTernaryCondition(
								activity.isRefered,
								'De-link Activity',
								'Delete Activity'
							)}
							aria-label={handleTernaryCondition(
								activity.isRefered,
								'De-link Activity',
								'Delete Activity'
							)}
							role="link"
						>
							<span className="ActivityDeleteIcon"></span>
						</span>
					</>
				) : (
					coOwners.includes(userAlias) &&
					activity.isRefered &&
					!endUserView && (
						<span
							className="ActivityDeleteContainer"
							onClick={() => {
								setSelectedActivity({
									...activity,
									groupId: handleTernaryCondition(groupId, groupId, 0),
								});
								setShowActivityDeleteModal(true);
							}}
							tabIndex="0"
							onKeyPress={(e) => {
								if (e.which === 13) {
									setSelectedActivity({
										...activity,
										groupId: handleTernaryCondition(groupId, groupId, 0),
									});
									setShowActivityDeleteModal(true);
								}
							}}
							title="De-link Activity"
						>
							<span className="ActivityDeleteIcon"></span>
						</span>
					)
				)}
				{activity.isCrowdSourced &&
					(activity.isMethodOwnerOrCoOwner || activity.isActivityOwnerOrCoOwner) &&
					!activity.isRefered &&
					!endUserView && (
						<>
							<span
								className="activityOwnerHistoryIcon"
								title="View Edit History"
								onClick={() => {
									setShowActivityVersionsHistory(true);
								}}
								onKeyPress={() => {
									setShowActivityVersionsHistory(true);
								}}
								tabIndex="0"
								aria-label={`Click here to Edit ${activity.activityTitle} Activity`}
								role="button"
							></span>
							<Link
								className="ActivityEditPhaseLand"
								to={`${url}/updateActivity/${activity.activityId}/draft/${activity.id}/complexity/${activity.complexityIds}/default/${complexityId}`}
								key={activity.dragNDropId}
								tabIndex={0}
								aria-label={activity.activityTitle}
								onKeyPress={(e) => {
									e.preventDefault();
								}}
								onClick={(e) => {
									e.preventDefault();
								}}
								title="The activity is in pending for review. You cannot edit this until new version is
							published."
							>
								<span className="ActivityEditIconDisabled disable"></span>
							</Link>
							<span
								className="ActivityDeleteContainer"
								title={`The activity is in pending for review. You cannot ${handleTernaryCondition(
									activity.isRefered,
									'De-link',
									'Delete'
								)} this`}
								tabIndex="0"
								role="button disabled"
							>
								<span className="ActivityDeleteIconDisabled disable"></span>
							</span>
						</>
					)}
			</div>

			<div className="moreEllipsis" id={`activityShowMore_${activityId}`}>
				{((activityId && !activity.isRefered) || templateCount > 0) && (
					<div
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setShowTemplates(!showTemplates);
								getTemplates();
								setTemplateDownload(activityId);
							}
						}}
						onClick={() => {
							setShowTemplates(!showTemplates);
							getTemplates();
							setTemplateDownload(activityId);
						}}
						aria-label={` Click here to download templates for ${activity.activityTitle} activity`}
						aria-expanded={showTemplates ? 'true' : 'false'}
						role="button"
					>
						<Icon
							iconName="MoreVertical"
							title="Download Templates"
							className="pointer"
						/>
					</div>
				)}

				{!(activityId && !activity.isRefered) && !templateCount > 0 && (
					<div>
						<Icon
							iconName="MoreVertical"
							title="No templates available"
							className="disabledContent"
						/>
					</div>
				)}

				{showTemplates && (
					<TemplateDownloadShowMore
						templates={templates}
						phaseData={phaseData}
						setShowActivityTemplateBuilder={setShowActivityTemplateBuilder}
						activity={activity}
						setSelectedTemplateBuilder={setSelectedTemplateBuilder}
					/>
				)}
				{showActivityVersionsHistory && (
					<ActivityHistory
						activityId={activity.activityId}
						complexityId={complexityId}
						showActivityVersionHistory={showActivityVersionsHistory}
						setShowActivityVersionHistory={setShowActivityVersionsHistory}
						activityName={activity.activityTitle}
					/>
				)}
			</div>
			{showActivityTemplateBuilder &&
				selectedTemplateBuilder === activity.activityId && (
					<ActivityTemplateBuilder
						showActivityTemplateBuilder={showActivityTemplateBuilder}
						setShowActivityTemplateBuilder={setShowActivityTemplateBuilder}
						activityId={activity.activityId}
						phaseId={activityPhaseId}
						methodId={solutionMethodId}
						complexityId={complexityId}
						existingTemplates={handleTernaryCondition(templates, templates, [])}
						setSelectedTemplateBuilder={setSelectedTemplateBuilder}
						pageType="activityBladeView"
						stateUpdate={true}
					/>
				)}
		</>
	);
};

export const TemplateDownloadShowMore = ({
	activity,
	templates,
	phaseData,
	setShowActivityTemplateBuilder,
	showActivityTemplateBuilder,
	setSelectedTemplateBuilder,
	isSearchPage,
}) => {
	const { track } = useAppInsights();

	if (isSearchPage && !templates) {
		return (
			<div className="showMoreMenu">
				<span className="showMoreArrow" />
				<span>No Templates Available</span>
			</div>
		);
	}
	return (
		<div className="showMoreMenu">
			<span className="showMoreArrow" />
			<ul className="menuItemUL" role="listbox">
				{templates !== null &&
					templates.map((item) => (
						<li
							className={handleTernaryCondition(
								item.inProgress,
								' moreItemLi templateInProgress',
								'moreItemLi'
							)}
							title={item.title}
							key={item.id}
							role="option"
						>
							<a
								role="option"
								target="_blank"
								rel="noopener noreferrer"
								href={item.fileLocation}
								className="templateLink"
								tabIndex={0}
								onKeyPress={(e) => {
									trackUserEvent(
										e,
										1,
										112,
										item.id,
										item.title,
										{
											phaseName: item.phaseName,
											complexityId: item.complexityId,
										},
										track
									);
								}}
								onClick={(e) =>
									trackUserEvent(
										{ which: 13 },
										1,
										112,
										item.id,
										item.title,
										{
											phaseName: item.phaseName,
											complexityId: item.complexityId,
										},
										track
									)
								}
							>
								<img
									src={handleTernaryCondition(
										item.inProgress,
										fileIcons['inProgress'],
										fileIcons[item.fileType] || fileIcons['defaultTemplate']
									)}
									alt="inProgressImg"
									className="ellipseMenuItemImg"
								/>
								<div className="ellipseMenuItem">
									<span>{item.title}</span>
								</div>
							</a>
						</li>
					))}
			</ul>

			{!isSearchPage && !activity.isRefered && !activity.isDrafted && (
				<div className="contributorAddTemplates">
					<span>Can't find the template you are looking for?</span>
					<span
						className={"contributorAddTemplatesButton"}
						onClick={() => {
							if (!activity.isCrowdSourced) {
								setShowActivityTemplateBuilder(true);
								setSelectedTemplateBuilder(activity.activityId);
							} else {
								toast.info('Content is pending for review', {
									position: 'top-right',
								});
							}
						}}
						onKeyPress={(e) => {
							if (e.which === 13) {
								if (!activity.isCrowdSourced) {
									setShowActivityTemplateBuilder(true);
									setSelectedTemplateBuilder(activity.activityId);
								} else {
									toast.info('Content is pending for review', {
										position: 'top-right',
									});
								}
							}
						}}
						tabIndex={0}
						role="button"
						aria-label="click to Add a new Template"
					>
						<span>Add a new Template</span>
						<span className="phaseAddIcon"></span>
					</span>
				</div>
			)}
		</div>
	);
};

const trackUserEvent = (
	event,
	eventTypeId,
	itemTypeId,
	itemId,
	itemName,
	optional,
	track
) => {
	if (event.which === 13) {
		track(eventTypeId, itemTypeId, itemId, itemName, optional);
	}
};

const updatedState = (isValid, setValue, value, updatedValue) => {
	if (isValid) {
		setValue(value);
	} else {
		setValue(updatedValue);
	}
};

const updatePrimaryState = (isValid, setValue, value) => {
	if (isValid) {
		setValue(value);
	}
};

export default NewActivities;
