import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import dompurify from 'dompurify';
import { useParams, useHistory } from 'react-router-dom';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import DescriptionCkEditor from '../CkEditor/TopicPreviewModeTrachChanges/DescriptionTrackChanges';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import { getDate } from '../../helpers/Validation';
import { axiosTokenService } from "../CkEditor/api/axiosBaseUrl";
import { getOtherUserProfile } from "../../helpers/GraphService";
import $ from 'jquery';
import { toast } from 'react-toastify';
import RejectionReasonModal from '../TopicsReviewerCrowdSourcing/RejectionReasonModal';
import 'react-toastify/dist/ReactToastify.css';
import TopicApproval from '../CkEditor/TopicPreviewModeTrachChanges/TopicApproval';
import Shapes from '../PolygonTopicBuilder/Shapes';
import '../TopicsContributorCrowdSourcing/PreviewAndSubmitPopUp.css';

function PreviewAndSubmitPopUp({
	setShowPreviewAndSubmitPopUp,
	users,
	currentUser,
	description,
	descriptionSuggestions,
	setLeavingWithoutSave,
	setSubmitMessage,
	contributorDescSuggestions,
	topicDescriptionData,
	approverDescSuggestions,
	approvedDescSuggestions,
	licenseKey,
	messagesType,
	reviewerSuggestionsInfo,
	ratingPoints,
	enableGamification,
	topicOwnerInfo,
	setTopicOwnerInfo,
	topicRelatedContent,
	topicBusinessRelatedContent,
	sdmContentErrorMessage,
	hasValidContent,
	hasValidRelatedContent,
	countPolygon,
	selectedPathPolygon,
	topicsDataPolygon,
	saveNextTopicPolygon,
	showLinkingPolygon,
	setLinkinPolygon,
}) {
	const history = useHistory();
	const { axiosPostService, axiosGetService } = useAxios();
	const config = {
		ADD_TAGS: ['iframe', 'oembed'],
		ADD_ATTR: ['url', 'allowfullscreen'],
	};
	const htmlSanitizer = dompurify.sanitize;
	const { crowdSourcingAppState, setCrowdSourcingAppState } =
		useCrowdSourcing();
	const { track } = useAppInsights();
	const { id } = useParams();
	const [modalShow, setModalShow] = useState(true);
	const [showLoader, setShowLoader] = useState(false);
	const [previewActivityDescription, setPreviewActivityDescription] =
		useState('');
	const [showRejectionReasonModal, setShowRejectionReasonModal] =
		useState(false);
	const [rejectionComments, setRejectionComments] = useState({});
	const [usersRating, setUsersRating] = useState({});

	const closeModal = () => {
		setModalShow(false);
		setShowPreviewAndSubmitPopUp(false);
		setShowRejectionReasonModal(false);
	};
	const getTopicOwnerInfo = async (email) => {
        const accessToken = await axiosTokenService();
        await getOtherUserProfile(accessToken, email).then((response) => {
			setTopicOwnerInfo(response);
        });
    };
	useEffect(() => {
		if(topicDescriptionData?.owner){
		getTopicOwnerInfo(topicDescriptionData?.owner);
		}
	}, [])

	const updateSuggestionsArray = (allSuggestions, rejectedSuggestions) => {
		if (allSuggestions.length === 0) {
			return null;
		}
		if (rejectedSuggestions.length === 0) {
			return allSuggestions;
		} else {
			const updatedSuggestions = [];
			for (const data in allSuggestions) {
				let suggestionsRejected = false;
				for (const rejectId in rejectedSuggestions) {
					if (
						JSON.parse(allSuggestions[data]).id ===
						JSON.parse(rejectedSuggestions[rejectId]).id
					) {
						if (
							JSON.parse(rejectedSuggestions[rejectId]).state === 'rejected'
						) {
							suggestionsRejected = true;
							break;
						}
					}
				}
				if (!suggestionsRejected) {
					updatedSuggestions.push(allSuggestions[data]);
				}
			}
			if (updatedSuggestions.length > 0) {
				return updatedSuggestions;
			} else {
				return null;
			}
		}
	};

	const getApprovedSuugestions = (reviewerSuggestions, approvedSuggestions) => {
		if (reviewerSuggestions) {
			const suggestionsArray = [];
			for (const revId in reviewerSuggestions) {
				let foundApprovedId = false;
				for (const apprvId in approvedSuggestions) {
					if (
						JSON.parse(approvedSuggestions[apprvId]).id ===
						JSON.parse(reviewerSuggestions[revId]).id
					) {
						if (JSON.parse(approvedSuggestions[apprvId]).state !== 'open') {
							foundApprovedId = true;
							suggestionsArray.push(approvedSuggestions[apprvId]);
							break;
						}
					}
				}
				if (!foundApprovedId) {
					suggestionsArray.push(
						JSON.stringify({
							id: JSON.parse(reviewerSuggestions[revId]).id,
							state: 'accepted',
						})
					);
				}
			}
			if (suggestionsArray.length > 0) {
				return suggestionsArray;
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	const checkContribution = (requestType) => {
		const rejectedSuggestionsIds = approvedDescSuggestions
			.filter(
				(suggestion) =>
					JSON.parse(suggestion).state === 'rejected' ||
					JSON.parse(suggestion).state === 'accepted'
			)
			.map((item) => {
				return JSON.parse(item).id;
			});
		const contributedSuggestionsIds = Object.keys(reviewerSuggestionsInfo).map(
			(suggestion) => {
				return reviewerSuggestionsInfo[suggestion].id;
			}
		);
		const contributorsRejectedIds = contributedSuggestionsIds.filter((id) =>
			rejectedSuggestionsIds.includes(id)
		);
		if (requestType === 'submit') {
			if (
				contributorsRejectedIds.length > 0 ||
				(topicDescriptionData.reviewerSuggestionsJson !== 'null' &&
					users.length > 1)
			) {
				setShowRejectionReasonModal(true);
			} else {
				submitData();
			}
		} else {
			if (contributorsRejectedIds.length > 0) {
				return true;
			}
			return false;
		}
	};

	const addReviewerComment = () => {
		const commentsInfo = Object.keys(rejectionComments).map((id) => {
			const reviewedSuggestions = Object.keys(
				reviewerSuggestionsInfo
			).map((suggestion) => {
				return JSON.parse(reviewerSuggestionsInfo[suggestion]);
			});
			const reviewed = reviewedSuggestions.filter((reviewed) => { return reviewed.id == id });
			return JSON.stringify({
				id: id,
				authorId: reviewed[0].authorId,
				data: reviewed[0].data,
				type: reviewed[0].type,
				comment: rejectionComments[id],
			});
		});
		return commentsInfo;
	};

	const postData = {
		topicId: id,
		description: description,
		publishDescription: previewActivityDescription,
		resultCalloutJson: updateSuggestionsArray(
			approverDescSuggestions,
			approvedDescSuggestions
		),
		resultReviewedJson: null,
		topicVersion: topicDescriptionData.topicVersion,
		reviewerEmail: topicDescriptionData.reviewerEmail,
		contributorCalloutJson: topicDescriptionData.calloutJson,
		title: topicDescriptionData.title,
		isEditable: true,
		lastContributorDescription: topicDescriptionData.lastContributorDescription,
		contributorReviewedJson: topicDescriptionData.reviewerSuggestionsJson,
		reviewerCalloutJson: topicDescriptionData.reviewerCalloutJson,
		reviewerReviewedJson: topicDescriptionData.reviewerReviewedJson,
	};

	useEffect(() => {
		track(2, 145, topicDescriptionData.topicId, topicDescriptionData.title);
	}, []);

	const submitData = async () => {
		const editingtateId = await axiosGetService(
			`api/sdmtopics/getTopic2/${id}`
		);
		if (editingtateId.data.stateId === 0) {
			setLeavingWithoutSave(false);
			setSubmitMessage(messagesType['publishedDocumentMessage']);
			setCrowdSourcingAppState({
				...crowdSourcingAppState,
				successMessagePopUp: true,
				userLeaveWithSave: true,
			});
			setShowPreviewAndSubmitPopUp(false);
		} else {
			const response = editingtateId.data;

			if (response.reviewerSuggestionsJson) {
				postData.resultReviewedJson = getApprovedSuugestions(
					JSON.parse(response.reviewerSuggestionsJson),
					approvedDescSuggestions
				);
			}
			postData.resultApproverComments = addReviewerComment();
			setShowLoader(true);
			track(1, 205, topicDescriptionData.topicId, topicDescriptionData.title);
			const imagesUrl = sessionStorage.getItem('crowdSourcing')
				? JSON.parse(sessionStorage.getItem('crowdSourcing'))
				: [];
			postData.imagePaths = [...topicDescriptionData.imagePaths, ...imagesUrl];
			postData.ratingPoints = usersRating;
			await axiosPostService(`api/sdmtopics/addTopic2Approver`, postData)
				.then(() => {
					setLeavingWithoutSave(false);
					setSubmitMessage(messagesType['approvalSubmissionMessage']);
					setCrowdSourcingAppState({
						...crowdSourcingAppState,
						successMessagePopUp: true,
					});
					sessionStorage.setItem('crowdSourcing', JSON.stringify([]));
					setShowPreviewAndSubmitPopUp(false);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	$(document).ready(function () {
		$('figure').each(function (index, element) {
			const type = element.className;
			if (!element.getAttribute('contenteditable')) {
				const imgElement = element.children[0];
				if (type && type.split(' ')[0] === 'image') {
					if (typeof $(element).attr('style') !== 'undefined') {
						$(imgElement).attr({ style: $(element).attr('style') });
					} else {
						$(imgElement).attr({ style: ' width: 100%' });
					}
				} else if (type === 'media') {
					const iframeUrl = $(imgElement).attr('url');
					if (imgElement.children.length < 1) {
						imgElement.innerHTML = htmlSanitizer(
							`<iframe src=${iframeUrl} style="border:none;" width="640" height="360" allowfullscreen="true"></iframe> `,
							config
						);
					}
				}
			}
		});
	});

	const getReviewerInfo = () => {
		return users.filter(({ id }) => id === topicDescriptionData.reviewerEmail);
	};

	$(document).ready(function() {
		$('._polygonShapeCrowdsourcing > .container > ul > a').each(function() {
			$(this).css('pointer-events', 'none');
		});
	});
	  
	return (
		<>
			{!licenseKey || showLoader ? (
				<Spinner />
			) : (
				<>
					{showRejectionReasonModal ? (
						<RejectionReasonModal
							users={getReviewerInfo()}
							closeModal={closeModal}
							reviewedDescriptionSuggestions={approvedDescSuggestions}
							contributorSuggestionsInfo={reviewerSuggestionsInfo}
							submit={submitData}
							rejectionComments={rejectionComments}
							setRejectionComments={setRejectionComments}
							currentUser={currentUser}
							postData={postData}
							ratingPoints={ratingPoints}
							usersRating={usersRating}
							setUsersRating={setUsersRating}
							checkRejection={checkContribution}
							enableGamification={enableGamification}
						/>
					) : (
						// <Modal
						// 	className="topicPreviewAndSubmitPopUp"
						// 	show={modalShow}
						// 	onHide={() => {
						// 		/* Not calling any function to close modal*/
						// 	}}
						// 	dialogClassName="modal-90w"
						// >
						// 	<Modal.Header>
						// 		<Modal.Title>
						// 			Preview
						// 			<div className="discardAndPreviewButton">
						// 				<Button
						// 					title="Cancel"
						// 					tabIndex={0}
						// 					onKeyPress={(e) => {
						// 						if (e.which === 13) {
						// 							closeModal();
						// 							track(
						// 								1,
						// 								148,
						// 								topicDescriptionData.topicId,
						// 								topicDescriptionData.title
						// 							);
						// 						}
						// 					}}
						// 					onClick={() => {
						// 						closeModal();
						// 						track(
						// 							1,
						// 							148,
						// 							topicDescriptionData.topicId,
						// 							topicDescriptionData.title
						// 						);
						// 					}}
						// 					className="activityDiscardButton"
						// 				>
						// 					Cancel
						// 				</Button>
						// 				<Button
						// 					title="Publish"
						// 					tabIndex={0}
						// 					onKeyPress={(e) => {
						// 						if (e.which === 13) {
						// 							checkContribution('submit');
						// 						}
						// 					}}
						// 					onClick={() => {
						// 						checkContribution('submit');
						// 					}}
						// 					className="activityPreviewButton"
						// 				>
						// 					Publish
						// 				</Button>
						// 			</div>
						// 		</Modal.Title>
						// 	</Modal.Header>
						// 	<Modal.Body>
						// 		<div className="topicsCrowdSourcingContainer">
						// 			<div class="topicsDescriptionDetails">
						// 				<div className="topicDescriptionDiv">
						// 					<div className="topicDescTitlePart">
						// 						<span className="topicVersion">
						// 							Document Version: {topicDescriptionData.topicVersion}
						// 							,&nbsp;&nbsp;
						// 							{`${topicDescriptionData.modifiedDate} `}
						// 						</span>
						// 					</div>
						// 					<h4 className="topicTitle">
						// 						{topicDescriptionData.title}
						// 					</h4>
						// 					<div class="existingHashTagsOfTopic">
						// 						{topicDescriptionData.hashTagsList.map(({ name }) => (
						// 							<span
						// 								key={name}
						// 								onClick={() => {
						// 									history.push(
						// 										`/search?searchParam=${encodeURIComponent(
						// 											name
						// 										)}`
						// 									);
						// 								}}
						// 								onKeyPress={(e) => {
						// 									if (e.which === 13) {
						// 										history.push(
						// 											`/search?searchParam=${encodeURIComponent(
						// 												name
						// 											)}`
						// 										);
						// 									}
						// 								}}
						// 							>
						// 								{name}
						// 							</span>
						// 						))}
						// 					</div>

						// 					{description ? (
						// 						<>
						// 							<DescriptionCkEditor
						// 								users={users}
						// 								currentUser={currentUser}
						// 								initialData={description}
						// 								setContent={setPreviewActivityDescription}
						// 								initalSuggestions={descriptionSuggestions}
						// 								licenseKey={licenseKey}
						// 							/>
						// 							<div className="hideApprovalCKEditor">
						// 								<TopicApproval
						// 									users={users}
						// 									currentUser={currentUser}
						// 									initialData={description}
						// 									setContent={setPreviewActivityDescription}
						// 									initalSuggestions={descriptionSuggestions}
						// 									licenseKey={licenseKey}
						// 								/>
						// 							</div>
						// 						</>
						// 					) : null}
						// 				</div>
						// 			</div>
						// 		</div>
						// 	</Modal.Body>
						// </Modal>
						<Modal className="PreviewAndSubmitPopUp" show={true} onHide={() => {	}} dialogClassName="NewPreviewAndSubmitPopUp">
						<Modal.Header>
							<Modal.Title><div className="topicOwnerPreviewPopupTitle"><span>Preview</span></div></Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className='topicLandingMainContainer mt-0'>
								<div className='topicsCrowdSourcingContainer mt-0'>
									<div className='topicsLeftAndMainContainer NewPreviewContainer'>
										<div className="topicsRightMainContainer ml-0">
											<div class="topicsDescriptionDetails" id="topicTextDescription">
												<div className="topicDescTitlePart">
													<>
														<span className="topicVersion">
															Document Version: {topicDescriptionData?.topicVersion}
															,&nbsp;&nbsp;
															{`${getDate()} `}
														</span>
														<div className="discardAndPreviewButton mr-0">
														<Button
															title="Cancel"
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	closeModal();
																	track(
																		1,
																		148,
																		topicDescriptionData?.topicId,
																		topicDescriptionData?.title
																	);
																}
															}}
															onClick={() => {
																closeModal();
																track(
																	1,
																	148,
																	topicDescriptionData?.topicId,
																	topicDescriptionData?.title
																);
															}}
															className="activityDiscardButton"
														>
															Cancel
														</Button>
														<Button
															title="Publish"
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	checkContribution('submit');
																	track(
																		1,
																		203,
																		topicDescriptionData?.topicId,
																		topicDescriptionData?.title
																	);
																}
															}}
															onClick={() => {
																checkContribution('submit');
																track(
																	1,
																	203,
																	topicDescriptionData?.topicId,
																	topicDescriptionData?.title
																);
															}}
															className="activityPreviewButton"
														>
															Submit
														</Button>
														</div>
													</>
												</div>
												<div className="topicDescriptionDivContainer">
													<div className={"topicPolygonDescriptionDiv topicPolygonDescriptionPreviewDiv _PolygonBorder"}>
														<div className="topicsOwner">
															<h1 className={"topicTitle topicTitleNewView polygonTopicTitle"} style={{paddingLeft:'9px'}}>
																{topicDescriptionData?.title.replace(/_.*/, '')}
															</h1>
														</div>
														<div className="pr-4">
														{description ? (
															<>
															<DescriptionCkEditor
																users={users}
																currentUser={currentUser}
																initialData={description}
																setContent={setPreviewActivityDescription}
																initalSuggestions={descriptionSuggestions}
																licenseKey={licenseKey}
																pageType={'previewAndSubmitPopUp'}
															/>
															<div className="hideApprovalCKEditor">
																<TopicApproval
																	users={users}
																	currentUser={currentUser}
																	initialData={description}
																	setContent={setPreviewActivityDescription}
																	initalSuggestions={descriptionSuggestions}
																	licenseKey={licenseKey}
																/>
															</div>
														</>
													) : null}
														</div>
													</div>
													<div className="topicRightDescriptionDiv _topicRightPreviewDescriptionDiv ">
														<div>
																<div className={"topicsOwnerDetails topicsOwnerDetailsNewView polygonLatestPreview"} >
																	<img src={topicOwnerInfo.photo} alt="topicOwnerImage" />
																	<div className="topicsOwnerPersonalDetails" tabIndex={0} >
																		<p>Owner</p>
																		<a href={`mailto:${topicOwnerInfo.mail}`} role="link" aria-label={`Opens in new tab ${topicOwnerInfo.firstName + ' ' + topicOwnerInfo.surname}`}>
																			<span className="alternativeOfh6">
																				{topicOwnerInfo.firstName +" " +topicOwnerInfo.surname}
																			</span>
																		</a>
																	</div>
																</div>
															<div className=''>
																{topicsDataPolygon?.length > 0 && (
																	<div className="polygonPreviewArea polygonPreviewImageContainer _polygonShapeCrowdsourcing">
																		<Shapes
																			count={countPolygon}
																			selectedPath={selectedPathPolygon}
																			topicsData={topicsDataPolygon}
																			saveNextTopic={saveNextTopicPolygon}
																			showLinking={showLinkingPolygon}
																			setLinking={setLinkinPolygon}
																		/>
																	</div>
																)}
																<div className={"topicAssociateContentPanel mt-3"}>
																	<div className='topicsContentDiv'>
																		<div className='topicsAssociateContentDiv'>
																			<h6>Associated Content</h6>
																			<div className='topicAssociatedContent'>
																				{topicRelatedContent?.length > 0 ? (
																					<>
																						{topicRelatedContent?.map((item) => 
																						item.title?.trim() && item.url?.trim() ? 
																						(
																							<a href={item.url} className='topicAssociateContentAnchor' key={item.id} target="_blank">
																								<span>
																									{item.title}
																								</span>
																							</a> 
																						): null)}
																					</>
																				) : (
																					<></>
																				)}
																			</div>
																		</div>
																		<div className='topicsAssociateContentDiv'>
																			<h6>Business Rule</h6>
																			<div className='topicAssociatedContent'>
																				{topicBusinessRelatedContent?.length > 0 ? (
																					<>
																						{topicBusinessRelatedContent.map((item) =>
																							item.name?.trim() && item.url?.trim() ? (
																								<a href={item.url} className='topicAssociateContentAnchor' key={item.id} target="_blank">
																									<span className='topicAssociateContentSpan'>{item.name}</span>
																								</a>
																							) : null
																						)}
																					</>
																				) : (
																					<></>
																				)}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>
					)}
				</>
			)}
		</>
	);
}

export default PreviewAndSubmitPopUp;
