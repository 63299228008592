import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from "react-bootstrap";
import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';
import { getOtherUserProfile } from "../../helpers/GraphService"
import { axiosTokenService } from "../CkEditor/api/axiosBaseUrl";
import { getDate } from '../../helpers/Validation';

const PreviewCommonModal = ({ complexityLevelDetails, owner, selectedComplexity, activityTitle, tools, topics, procedures, businessRuleContent,sdmContentErrorMessage, entryCriteria,
  complexities, pageType }) => {
  const [activityOwner, setActivityOwner] = useState({})
  const RaciType = ({ label, rolesData, id }) => {
    return (
      <Col
        xs={12}
        sm={12}
        md={12}
        className=" pt-2 px-0"
      >
        <h3 className="_raciHeadingInActivity" id={id}>{label}:</h3>
        {rolesData.map((role) => (
          <div className="_raciItemsInActivity" aria-label={role.name} title={role.name} key={role.name}>
            <span title={role.name}>{role.name}</span>
          </div>
        ))}
      </Col>
    );
  }
  const sanitizedContent = (content) => {
    if (!content) return '';

    if (content.includes('<img')) {
      content = content.replace(/<img/, '<img alt="Guidance Tips and Techniques" tabIndex="0"');
    }
    if (content.includes('<img')) {
      content = content.replace(/<img/, '<img alt="Supporting Activities" tabIndex="0"');
    }
    if (content.includes('<img')) {
      content = content.replace(/<img/, '<img alt="Entry Criteria" tabIndex="0"');
    }
    if (content.includes('<img')) {
      content = content.replace(/<img/, '<img alt="Tasks" tabIndex="0"');
    }

    if (content.includes('<img')) {
      content = content.replace(/<img/, '<img alt="Exit Criteria" tabIndex="0"');
    }

    if (content.includes('<h2')) {
      content = content.replace(/<h2\s+class=["']criteraType["'][^>]*>.*?<\/h2>/i, '');
    }

    return content;
  };
  const contentNotAvailable = () => {
    return <div className="_contentNotAvailable">{sdmContentErrorMessage}</div>;
  };


  function extractContentByHeading(content, headingName) {
    if (!Array.isArray(content)) {
      return [];
    }

    return content
      .map(item => (typeof item === 'string' ? JSON.parse(item) : item))
      .filter(
        parsedItem =>
          parsedItem.criteriaTypeName &&
          typeof parsedItem.criteriaTypeName === 'string' &&
          parsedItem.criteriaTypeName.toLowerCase().includes(headingName.toLowerCase())
      );
  }

  const getActivityOwnerDetails = async (email) => {
    const accessToken = await axiosTokenService();
    const userData = await getOtherUserProfile(accessToken, email);

    const modifiedUserData = { ...userData };
    setActivityOwner(modifiedUserData);
  };

  const ownerAndCollaboratorsBox = () => {
    return <Card className={`border-left-0 border-right-0 border-bottom-0 pl-3 pr-1 py-3`}>
      <Row>
        <Col xs={12} sm={12} md={12} >
          <div className="_imgContainer">
            <img className="ownerImg" src={owner?.photo} />
            <span className={`_ownerInfo _ownerForPreview`}>
              <span>Owner</span>
              <a href={`mailto:${owner?.mail}`}>
                <span className="_ownerName">{owner?.firstName + " " + owner?.surname}</span>
              </a>
            </span>

          </div>
        </Col>
      </Row>
    </Card>
  }

  return (
    <div className={`${pageType === 'nonRealizedItem' ? '_activityLandingPageNonRealized' : '_activityLandingPage'}`} >

      <Container fluid className={`m-0 _activityMainContainer `}>

        <Row className={`mr-2  d-flex flex-wrap _rowGray pt-1`}>
          <Col xs={12} sm={12} md={7} className="px-0">
            <div className={`_complexityContainer _complexityContainerUpdateForRealize pt-0`}>
              <span>Complexity&nbsp;:</span>
              <div className={`_complexityButtonsContainer ${complexities.length > 1 ? '' : '_complexityButtonsContainerSingle'}`} style={{ pointerEvents: 'none', opacity: 0.6 }}>
                {complexities.map((complexity) => (
                  <button
                    key={complexity.id}
                    className={`_complexityButtons ${selectedComplexity === complexity.id ? "_activeComplexityButtons" : ""}`}
                  >
                    {complexity.name}
                  </button>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={5} className="d-flex justify-content-end align-items-center px-0">
            <span className="_activityVersion">
              {`Document Version: ${complexityLevelDetails[selectedComplexity].activityVersion}, ${getDate()} `}
              &nbsp;&nbsp;
            </span>



          </Col>

        </Row>



        <Row className="mx-0 _allComlumns pb-3">
          <Col
            xs={12}
            sm={9}
            md={9}
            className={`pr-0 pl-0 bg-white border border-right-0 _borderRadius`}>
            <Card className={`border-bottom-0 border-right-0 p-3`}>

              <Row className={`mx-2 `}>
                <Col className="px-0">
                  <h1 title={activityTitle} className="activityLandingPageTitle _activityLandingPageTitle">{activityTitle}</h1>
                </Col>

              </Row>
              <Row className="mx-2">
                <Col className="px-0">
                  <div
                    className="desc ck-content _activityDescription _handle_ckeditorContentScroll"
                    id="description"
                    dangerouslySetInnerHTML={{
                      __html: HtmlSanitizer(
                        complexityLevelDetails[selectedComplexity]?.description &&
                          complexityLevelDetails[selectedComplexity]?.description.includes('<img') ?
                          complexityLevelDetails[selectedComplexity]?.description.replace(/<img/, '<img alt="Description" tabIndex="0"')
                          : complexityLevelDetails[selectedComplexity]?.description
                      ),
                    }}
                  />
                </Col>
              </Row>


              {(() => {
                const entryCriteriaContent = extractContentByHeading(complexityLevelDetails[selectedComplexity]?.entryCriteria, "Entry Criteria");
                const inputContent = extractContentByHeading(complexityLevelDetails[selectedComplexity]?.entryCriteria, "Input");

                const renderListContent = (content) => {
                  if (!content || content?.length === 0 || (content?.length === 1 && content[0]?.name === "")) {
                    return contentNotAvailable();
                }
                
                  return (
                    <ul className="styled-Preview-list">
                      {content
                        .filter(item => item.name && item.name.trim()) // Exclude items with empty or whitespace-only names
                        .map((item, index) => (
                          <li key={index}>{item.name}</li>
                        ))}
                    </ul>
                  );
                };


                return (
                  <Row className="border-top mx-2 mt-1 pt-1">
                    <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                      <h3 className="_headingsInActivity">Entry Criteria</h3>
                      {renderListContent(entryCriteriaContent)}
                    </Col>

                    <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                      <h3 className="_headingsInActivity">Input</h3>
                      {renderListContent(inputContent)}
                    </Col>
                  </Row>
                );
              })()}


              {(() => {
                const tasksContent = complexityLevelDetails[selectedComplexity]?.taskList || [];
                const deliverablesContent = complexityLevelDetails[selectedComplexity]?.deliverables || "";
                const templatesListContent = complexityLevelDetails[selectedComplexity]?.templatesList || [];

                const renderTasks = (tasks) => {
                  if (!tasks || tasks.length === 0) {
                    return contentNotAvailable();
                  }
                  return (
                    <ul className="styled-Preview-list ">
                      {tasks
                        .filter((task) => task.name && task.name.trim()) // Exclude empty names
                        .map((task) => (
                          <li key={task.id}>
                            <div
                              className="ck-content mb-0 TaskActivity"
                              dangerouslySetInnerHTML={{
                                __html: HtmlSanitizer(task.name), // Ensure sanitized HTML content
                              }}
                            />
                            {task.description && (
                              <ul>
                                <li>
                                  <div
                                    className="ck-content mb-0 TaskActivity"
                                    dangerouslySetInnerHTML={{
                                      __html: HtmlSanitizer(task.description),
                                    }}
                                  />
                                </li>
                              </ul>
                            )}
                          </li>
                        ))}
                    </ul>
                  );
                };

                return (
                  <Row className="border-top mx-2 mt-1 pt-1">
                    {/* Tasks Section */}
                    <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                      <h3 className="_headingsInActivity">Tasks</h3>
                      {tasksContent.length === 0 ? (
                        contentNotAvailable()
                      ) : (
                        renderTasks(tasksContent)
                      )}
                    </Col>

                    {/* Deliverables Section */}
                    <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                      <h3 className="_headingsInActivity">Deliverables</h3>

                      {complexityLevelDetails[selectedComplexity]?.deliverables?.length === 0 &&
                        complexityLevelDetails[selectedComplexity]?.templatesList?.length === 0 ? (
                          contentNotAvailable()
                      ) : (
                        <>
                          {/* Render Templates List */}
                          {templatesListContent.length > 0 && (
                            <ul className="mb-0 styled-Preview-list">
                              {templatesListContent.map((item) => (
                                <li key={item.id}>
                                  <a href={item.fileLocation} target="_blank" rel="noopener noreferrer">
                                    {item.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          )}

                          {/* Render Deliverables Content */}
                          {deliverablesContent && (
                            <div
                              className="descSA  ck-content  _activityDescription "
                              id="previewSupport"
                              dangerouslySetInnerHTML={{
                                __html: HtmlSanitizer(
                                  deliverablesContent.includes('<img')
                                    ? deliverablesContent.replace(/<img/, '<img alt="Deliverables" tabIndex="0"')
                                    : deliverablesContent
                                ),
                              }}
                            />
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                );
              })()}


              {(() => {
                const exitCriteriaContent = extractContentByHeading(
                  complexityLevelDetails[selectedComplexity]?.exitCriteria,
                  "Exit Criteria"
                );
                const outputContent = extractContentByHeading(
                  complexityLevelDetails[selectedComplexity]?.exitCriteria,
                  "Output"
                );

                const renderListContent = (content) => {
                  if (!content || content?.length === 0 || (content?.length === 1 && content[0]?.name === "")) {
                    return contentNotAvailable();
                }
                  return (
                    <ul className="styled-Preview-list">
                      {content
                        .filter(item => item.name && item.name.trim()) // Exclude empty or whitespace-only names
                        .map((item, index) => (
                          <li key={index}>{item.name}</li>
                        ))}
                    </ul>
                  );
                };

                return (
                  <Row className="border-top mx-2 mt-1 pt-1">
                    <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                      <h3 className="_headingsInActivity">Exit Criteria</h3>
                      {renderListContent(exitCriteriaContent)}
                    </Col>

                    <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                      <h3 className="_headingsInActivity">Output</h3>
                      {renderListContent(outputContent)}
                    </Col>
                  </Row>
                );
              })()}

              {(() => {
                const hasTipsAndTechniques = sanitizedContent(complexityLevelDetails[selectedComplexity]?.tipsAndTechniques);
                const hasSupportingActivities = sanitizedContent(complexityLevelDetails[selectedComplexity]?.supportingActivities);
                return (
                  <Row className="border-top mx-2 mt-1 pt-1">
                    <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                      <h3 className="_headingsInActivity">Guidance, Tips and Techniques</h3>
                      {!hasTipsAndTechniques ? contentNotAvailable() : (
                        <div className="_columnDataOfSubheadings _handle_ckeditorContentScroll">
                          <div
                            className="descSA  ck-content _activityDescription "
                            id="descTt"
                            dangerouslySetInnerHTML={{
                              __html: HtmlSanitizer(
                                complexityLevelDetails[selectedComplexity]?.tipsAndTechniques &&
                                  complexityLevelDetails[selectedComplexity]?.tipsAndTechniques.includes('<img')
                                  ? complexityLevelDetails[selectedComplexity]?.tipsAndTechniques.replace(
                                    /<img/,
                                    '<img alt="Guidance Tips and Techniques" tabIndex="0"'
                                  )
                                  : complexityLevelDetails[selectedComplexity]?.tipsAndTechniques
                              ),
                            }}
                          />
                        </div>
                      )}
                    </Col>

                    <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                      <h3 className="_headingsInActivity">Supporting Activities</h3>
                      {!hasSupportingActivities ? contentNotAvailable() : (
                        <div className="_columnDataOfSubheadings _handle_ckeditorContentScroll">
                          <div
                            className="descSA  ck-content  _activityDescription "
                            id="support"
                            dangerouslySetInnerHTML={{
                              __html: HtmlSanitizer(
                                complexityLevelDetails[selectedComplexity]?.supportingActivities &&
                                  complexityLevelDetails[selectedComplexity]?.supportingActivities.includes('<img')
                                  ? complexityLevelDetails[selectedComplexity]?.supportingActivities.replace(
                                    /<img/,
                                    '<img alt="Supporting Activities" tabIndex="0"'
                                  )
                                  : complexityLevelDetails[selectedComplexity]?.supportingActivities
                              ),
                            }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                );
              })()}


            </Card>
          </Col>

          <Col
            xs={12}
            sm={3}
            md={3}
            className={`pl-0 pr-0 bg-white _setBorderBox `}>
            {owner?.mail && ownerAndCollaboratorsBox()}

            <Card className="border-left-0 border-right-0 border-bottom-0 mx-3 py-2">

              <h4 className="_subHeadingInActivity">RACI
                <span class="_iInformationIcon" tabindex="0" role="button" aria-label="Information about RACI">
                  <div class="_tooltipRaciInfoIcon" role="tooltip">
                    <p>
                      **The RACI here depicts the ideal state where all roles are staffed on the project/engagement. In scenarios where the particular role is not staffed, the person playing the role on the project/engagement is expected to perform the activity.
                    </p>
                  </div>
                </span>
              </h4>
              <Row className="mx-0">
                <RaciType
                  label="Responsible Role"
                  rolesData={complexityLevelDetails[selectedComplexity]?.responsibleList}
                  id="previewResponsibleRole"
                />
                <RaciType
                  label="Accountable Role"
                  rolesData={complexityLevelDetails[selectedComplexity]?.accountableList}
                  id="previewAccountableRole"
                />
                {complexityLevelDetails[selectedComplexity]?.consultedList && complexityLevelDetails[selectedComplexity]?.consultedList?.length > 0 && (
                  <RaciType
                    label="Consulted Role"
                    rolesData={complexityLevelDetails[selectedComplexity]?.consultedList}
                    id="previewConsultedRole"
                  />
                )}

                {complexityLevelDetails[selectedComplexity]?.informedList && complexityLevelDetails[selectedComplexity]?.informedList?.length > 0 && (
                  <RaciType
                    label="Informed Role"
                    rolesData={complexityLevelDetails[selectedComplexity]?.informedList}
                    id="previewInformedRole"
                  />
                )}

              </Row>

            </Card>

            {(tools?.length >0) && (
              <Card className="border-left-0 border-right-0 border-bottom-0 py-2 mx-3">
                <Row>
                  <Col>
                    <>
                      <h4 className="_subHeadingInActivity">Tools</h4>
                      {tools?.map((item) =>
                        (
                          <div className="_linksInActiviy" key={item.id}>
                            <a href={item.url} rel="noopener noreferrer" title={item.name}>{item.name}</a>
                          </div>
                        )
                      )}
                    </>
                  </Col>
                </Row>
              </Card>
            )}

            {topics.length > 0 &&
              <Card className="border-left-0 border-right-0 border-bottom-0 py-2 mx-3">
                <Row>
                  <Col>
                    <>
                      <h4 className="_subHeadingInActivity">Topics</h4>
                      {topics.map((item) => (
                        <div className="_linksInActiviy" key={item.name}>
                          <a href={item.url} rel="noopener noreferrer" title={item.name}>{item.name}</a>
                        </div>
                      ))}
                    </>
                  </Col>
                </Row>
              </Card>
            }

            {procedures.length > 0 && (
              <Card className="border-left-0 border-right-0 border-bottom-0 py-2 mx-3">
                <Row>
                  <Col>
                    <>
                      <h4 className="_subHeadingInActivity">Job Aids</h4>
                      {procedures.map((item) => (
                        <div className="_linksInActiviy" key={item.name}>
                          <a href={item.url} rel="noopener noreferrer" title={item.name}>{item.name} </a>
                        </div>
                      ))}
                    </>
                  </Col>
                </Row>
              </Card>
            )}

            {businessRuleContent.length > 0 && (
              <Card className="border-left-0 border-right-0 border-bottom-0 py-2 mx-3">
                <Row>
                  <Col>
                    <>
                      <h4 className="_subHeadingInActivity">Business Rule</h4>
                      {businessRuleContent.map((item) => (
                        <div className="_linksInActiviy _linksInActiviyBR" key={item.name}>
                          <a href={item.url} target="_blank" rel="noopener noreferrer" title={item.name}>{item.name}</a>
                        </div>
                      ))}
                    </>
                  </Col>
                </Row>
              </Card>
            )}

          </Col>

        </Row>

      </Container>




    </div>
  );

};



export default PreviewCommonModal;