import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import dompurify from "dompurify";
import { Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useAppInsights } from "../../context/TelemetryContext";
import { useAxios } from "../../context/AxiosContext";
import { useCrowdSourcing } from "../../context/CrowdSourcingContext";
import DescriptionCkEditor from "../CkEditor/ActivityPreviewModeTrackChanges/DescriptionTrackChanges";
import DeliverablesCkEditor from '../CkEditor/ActivityPreviewModeTrackChanges/DeliverablesTrackChanges';
import SupportAndActivitiesCkEditor from "../CkEditor/ActivityPreviewModeTrackChanges/SupportingActivitieTrackChanges";
import TipsAndTechniquesCkEditor from "../CkEditor/ActivityPreviewModeTrackChanges/TipsAndTechniquesTrackChanges";
import Dropdown from "../Dropdown";
import Spinner from "../../helpers/CrowdSourcingSpinner";
import FileDropModal from "../TemplateCard/FileDropModal";
import PreviewModal from "../TemplateCard/PreviewModal";
import SampleTemplateModal from "../TemplateCard/SampleTemplateModal";

import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../Activity/Activity.css";
import "./PreviewAndSubmitPopUp.css";
import ActivityRaciMatrix from "../ActivityRaciMatrix";
import Activity from "../Activity/Activity";

function PreviewAndSubmitPopUp({
  activityContent,
  activityDescription,
  activitySupportingActivities,
  templateData,
  entryCriteriaData,
  exitCriteriaData,
  taskData,
  topicData,
  procedureData,
  activityToolsAndGuidance,
  activityTipsAndTechniques,
  descriptionSuggestions,
  supportingActivitiesSuggestions,
  toolsAndGuidanceSuggestions,
  entryCriteriaSuggestions,
  exitCriteriaSuggestions,
  taskSuggestions,
  tipsAndTechniquesSuggestions,
  setShowPreviewAndSubmitPopUp,
  complexities,
  complexityId,
  complexityName,
  setComplexityName,
  users,
  currentUser,
  setLeavingWithoutSave,
  setMessage,
  rejectedDescSuggestions,
  rejectedSupportSuggestioons,
  rejectedToolsSuggestions,
  rejectedTipsSuggestions,
  contributorDescSuggestions,
  contributorSupportSuggestions,
  contributorTipsSuggestions,
  contributorToolsSuggestions,
  contributorEntryCriteriaSuggestion,
  contributorExitCriteriaSuggestion,
  contributorTaskSuggestion,
  contributorsData,
  licenseKey,
  selectedToolsAndGuidanceSuggestions,
  toolsUrl,
  messagesType,
  existingHashtags,
  templateSuggestions,
  contributorTemplateSuggestion,
  entryCriteriaWithSuggestions,
  exitCriteriaWithSuggestions,
  taskWithSuggestions,
  activityDeliverables,
  deliverablesSuggestions,
  contributorDeliverablesSuggestions,
  rejectedDeliverablesSuggestions,
  taskReorder,
  entryContributionReorder,
  exitContributionReorder,
  inputContributionReorder,
  outputContributionReorder,
  taskReorderArray,
  entryCriteriaReorderArray,
  exitCriteriaReorderArray,
  inputCriteriaReorderArray,
  outputCriteriaReorderArray,
  addedTaskSequence,
  addedEntrySequence,
  addedExitSequence,
  isEnableComplexity
}) {
  const history = useHistory();
  const htmlSanitizer = (data) => {
		const config = {
		  ADD_ATTR: [ 'target'],
		  };
		return dompurify.sanitize(data, config);
	  };
  const { crowdSourcingAppState, setCrowdSourcingAppState } =
    useCrowdSourcing();
  const { track } = useAppInsights();
  const { 
    activityId, 
    projectTypeId,
    solutionMethodId,
    phaseId 
  } = useParams();
  const [modalShow, setModalShow] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [previewActivityDescription, setPreviewActivityDescription] =
    useState(1);
  const [previewActivityDeliverables, setPreviewActivityDeliverables] =
    useState(1);
  const [
    previewActivitySupportingActivities,
    setPreviewActivitySupportingActivities,
  ] = useState(1);
  const [
    previewActivityTipsAndTechniques,
    setpreviewActivityTipsAndTechniques,
  ] = useState(1);
  const [sampleTemplates, setSampleTemplates] = useState({
    enable: false,
    data: null,
    parentData: null,
  });
  // template Preview Data
  const [toggleActivityDetails, setToggleActivityDetails] = useState(false);

  const [templatePreviewData, setTemplatePreviewData] = useState(null);
  const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);
  const [enableFileDropModal, setEnableFileDropModal] = useState(false);
  const handleTemplatePreview = (data) => {
    setTemplatePreviewData(data);
    setEnableTemplatePreview(true);
  };
  const { axiosPostService, axiosGetService } = useAxios();

  const closeModal = () => {
    setModalShow(false);
    setShowPreviewAndSubmitPopUp(false);
  };

  const getSuggestionsInfo = (
    emCollection,
    suggestionsData,
    suggestionsMap
  ) => {
    for (var i = 0; i < emCollection.length; i++) {
      if ($(emCollection[i]).attr("data-suggestion-end-after")) {
        const suggestionId =
          $(emCollection[i]).attr("data-suggestion-end-after").split(":")
            .length > 3
            ? $(emCollection[i]).attr("data-suggestion-end-after").split(":")[2]
            : $(emCollection[i])
              .attr("data-suggestion-end-after")
              .split(":")[1];
        if (!suggestionsMap.has(suggestionId)) {
          suggestionsData.push(suggestionId);
          suggestionsMap.set(suggestionId, 1);
        }
      } else if ($(emCollection[i]).attr("data-suggestion-start-before")) {
        const suggestionId =
          $(emCollection[i]).attr("data-suggestion-start-before").split(":")
            .length > 3
            ? $(emCollection[i])
              .attr("data-suggestion-start-before")
              .split(":")[2]
            : $(emCollection[i])
              .attr("data-suggestion-start-before")
              .split(":")[1];
        if (!suggestionsMap.has(suggestionId)) {
          suggestionsData.push(suggestionId);
          suggestionsMap.set(suggestionId, 1);
        }
      }
    }
  };

  const findSuggestionsInHtmData = (htmlData) => {
    const domParser = new DOMParser();
    const docElement = domParser.parseFromString(
      htmlData,
      "text/html"
    ).documentElement;
    let emCollection = docElement.getElementsByTagName("suggestion");
    const suggestionsData = [];
    const suggestionsMap = new Map();
    for (let i = 0; i < emCollection.length; i++) {
      const suggestionId = $(emCollection[i]).attr("id").split(":")[0];
      if (!suggestionsMap.has(suggestionId)) {
        suggestionsData.push(suggestionId);
        suggestionsMap.set(suggestionId, 1);
      }
    }
    emCollection = docElement.getElementsByTagName("suggestion-start");
    for (let i = 0; i < emCollection.length; i++) {
      const suggestionId =
        $(emCollection[i]).attr("name").split(":").length > 3
          ? $(emCollection[i]).attr("name").split(":")[2]
          : $(emCollection[i]).attr("name").split(":")[1];
      if (!suggestionsMap.has(suggestionId)) {
        suggestionsData.push(suggestionId);
        suggestionsMap.set(suggestionId, 1);
      }
    }
    emCollection = docElement.getElementsByTagName("figure");
    getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
    emCollection = docElement.getElementsByTagName("th");
    getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
    emCollection = docElement.getElementsByTagName("td");
    getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
    emCollection = docElement.getElementsByTagName("tr");
    getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
    emCollection = docElement.getElementsByTagName("table");
    getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
    emCollection = docElement.getElementsByTagName("tbody");
    getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
    emCollection = docElement.getElementsByTagName("p");
    getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
    return suggestionsData;
  };

  const updateSuggestionsArray = (allSuggestions, htmlData) => {
    if (!htmlData || htmlData.length === 0) {
      return null;
    }
    const suggestionFromHtmlData = findSuggestionsInHtmData(htmlData);
    const verifiedSuggestions = [];
    for (const id in suggestionFromHtmlData) {
      for (const allSuggestionId in allSuggestions) {
        if (
          suggestionFromHtmlData[id] ===
          JSON.parse(allSuggestions[allSuggestionId]).id
        ) {
          verifiedSuggestions.push(allSuggestions[allSuggestionId]);
          break;
        }
      }
    }
    if (verifiedSuggestions.length > 0) {
      return verifiedSuggestions;
    } else {
      return null;
    }
  };

  const getToolsCount = () => {
    let toolsCount = 0;
    for (const tool of selectedToolsAndGuidanceSuggestions) {
      if (JSON.parse(tool).suggestionType !== "deletion") {
        toolsCount += 1;
      }
    }
    return toolsCount;
  };

  const verifySuggestions = (previousSuggestions, latestSuggestions) => {
    if (!previousSuggestions && !latestSuggestions) {
      return true;
    }
    if (previousSuggestions && !latestSuggestions) {
      return false;
    }
    for (const data in latestSuggestions) {
      let currentIdFound = false;
      for (const revData in previousSuggestions) {
        if (
          JSON.parse(latestSuggestions[data]).id ===
          JSON.parse(previousSuggestions[revData]).id
        ) {
          currentIdFound = true;
          break;
        }
      }
      if (!currentIdFound) {
        return false;
      }
    }
    return true;
  };

  const getToolsAndGuidanceSuggestion = () => {
    const updatedSuggestion = selectedToolsAndGuidanceSuggestions.filter(
      (suggestion) => JSON.parse(suggestion).suggestionType !== "none"
    );
    if (updatedSuggestion.length > 0) {
      return updatedSuggestion;
    }
    return null;
  };

  const getTemplateIds = () => {
    const templates = [
      ...templateData.map(({ id }) => {
        return id;
      }),
      ...templateSuggestions.map((item) => {
        return JSON.parse(item).id;
      }),
    ];
    return templates.toString();
  };
  const postData = {
    activityId: activityId,
    complexityId: complexityId,
    description: activityDescription,
    supportingActivities: activitySupportingActivities,
    toolsAndGuidance: activityToolsAndGuidance,
    tipsAndTechniques: activityTipsAndTechniques,
    deliverables: activityDeliverables,
    resultCalloutJson: {
      description: descriptionSuggestions,
      // updateSuggestionsArray(
      //   descriptionSuggestions,
      //   activityDescription
      // ),
      supportingActivities: supportingActivitiesSuggestions,
      // updateSuggestionsArray(
      //   supportingActivitiesSuggestions,
      //   activitySupportingActivities
      // ),
      toolsAndGuidance: getToolsAndGuidanceSuggestion(),
      tipsAndTechniques: tipsAndTechniquesSuggestions,
      // updateSuggestionsArray(
      //   tipsAndTechniquesSuggestions,
      //   activityTipsAndTechniques
      // ),
      templateSuggestions:
        templateSuggestions.length > 0 ? templateSuggestions : null,
      entryCriteriaSuggestions:
        entryCriteriaWithSuggestions.filter(
          (item) => JSON.parse(item).suggestionType !== "none"
        ).length > 0
          ? entryCriteriaWithSuggestions.filter(
            (item) => JSON.parse(item).suggestionType !== "none"
          )
          : null,
      exitCriteriaSuggestions:
        exitCriteriaWithSuggestions.filter(
          (item) => JSON.parse(item).suggestionType !== "none"
        ).length > 0
          ? exitCriteriaWithSuggestions.filter(
            (item) => JSON.parse(item).suggestionType !== "none"
          )
          : null,
      taskSuggestions:
        taskWithSuggestions.filter(
          (item) => JSON.parse(item).suggestionType !== "none"
        ).length > 0
          ? taskWithSuggestions.filter(
            (item) => JSON.parse(item).suggestionType !== "none"
          )
          : null,
      deliverables: deliverablesSuggestions,
      // updateSuggestionsArray(
      //   deliverablesSuggestions,
      //   activityDeliverables
      // ),
      originalAddedTask:addedTaskSequence ? addedTaskSequence.map(e=>JSON.stringify(e)):null,
      originalAddedEntry:addedEntrySequence?addedEntrySequence.map(e=>JSON.stringify(e)):null,
      originalAddedExit:addedExitSequence?addedExitSequence.map(e=>JSON.stringify(e)):null
    },
    resultReorderJson:{
			isTaskReorder: taskReorderArray,
			isEntryReorder:entryCriteriaReorderArray,                                                 
			isExitReorder:exitCriteriaReorderArray,
      isInputReorder:inputCriteriaReorderArray,
      isOutputReorder:outputCriteriaReorderArray,
		},
    tileDescription: contributorsData.tileDescription,
    isCritical: contributorsData.isCritical,
    isProcessStep: contributorsData.isProcessStep,
    isLite: contributorsData.isLite,
    isFull: contributorsData.isFull,
    sequence: contributorsData.sequence,
    //deliverables: contributorsData.deliverables,
    url: contributorsData.url,
    activityVersion: contributorsData.activityVersion,
    modifiedOn: contributorsData.modifiedOn,
    templateIds: getTemplateIds(),
    entryCriteriaIds: [
      ...entryCriteriaData.map((item) => {
        return item.id;
      }),
    ].toString(),
    entryCriteriaData: [...entryCriteriaData.map((item) =>
      JSON.stringify({ ...item })
    )].toString(),
    exitCriteriaIds: [
      ...exitCriteriaData.map((item) => {
        return item.id;
      }),
    ].toString(),
    exitCriteriaData: [...exitCriteriaData.map((item) =>
      JSON.stringify({ ...item })
    )].toString(),
    taskIds: [
      ...taskData.map((item) => {
        return item.id;
      }),
    ].toString(),
    taskData: [
      ...taskData.map((item) => {
        return JSON.stringify({ ...item })
      }),
    ].toString(),
  };

  useEffect(() => {
    track(2, 135, activityContent.activityId, activityContent.activityName);
  }, []);

  $(document).ready(function () {
    $("figure").each(function (index, element) {
      if (!element.getAttribute("contenteditable")) {
        const imgElement = element.children[0];
        $(imgElement).attr({ style: $(element).attr("style") });
      }
    });
  });

  useEffect(() => {
    $('#previewId').focus();
  }, []);

  const submitData = async () => {
    const editingtateId = await axiosGetService(
      `api/sdmactivity/getActivity2/${activityId}/${complexityId}`
    );
    if (editingtateId?.data?.stateId === 2) {
      setShowLoader(true);
      setLeavingWithoutSave(false);
      setMessage(messagesType["pendingApproval"]);
      setCrowdSourcingAppState({
        ...crowdSourcingAppState,
        successMessagePopUp: true,
        successMessageComplexityId: complexityId,
        successMessageTitle: activityContent.activityName,
        userLeaveWithSave: true,
      });
      setShowPreviewAndSubmitPopUp(false);
    } else {
      const response = editingtateId.data;
      let descVerified = true,
        supportVerified = true,
        tipsVerified = true,
        toolsVerified = true,
        templatesVerified = true,
        entryCriteriaVerified = true,
        exitCriteriaVerified = true,
        taskVerified = true,
        deliverablesVerified = true;
      if (response.resultCalloutJson) {
        descVerified = verifySuggestions(
          contributorDescSuggestions,
          response.resultCalloutJson.description
        );
        supportVerified = verifySuggestions(
          contributorSupportSuggestions,
          response.resultCalloutJson.supportingActivities
        );
        tipsVerified = verifySuggestions(
          contributorTipsSuggestions,
          response.resultCalloutJson.tipsAndTechniques
        );
        toolsVerified = verifySuggestions(
          contributorToolsSuggestions,
          response.resultCalloutJson.toolsAndGuidance
        );
        templatesVerified = verifySuggestions(
          contributorTemplateSuggestion,
          response.resultCalloutJson.templateSuggestions
        );
        entryCriteriaVerified = verifySuggestions(
          contributorEntryCriteriaSuggestion,
          response.resultCalloutJson.entryCriteriaSuggestions
        );
        exitCriteriaVerified = verifySuggestions(
          contributorExitCriteriaSuggestion,
          response.resultCalloutJson.exitCriteriaSuggestions
        );
        taskVerified = verifySuggestions(
          taskSuggestions,
          response.resultCalloutJson.taskSuggestions
        );
        deliverablesVerified = verifySuggestions(
          contributorDeliverablesSuggestions,
          response.resultCalloutJson.deliverables
        );

      }
      if (
        !(
          descVerified &&
          supportVerified &&
          tipsVerified &&
          toolsVerified &&
          templatesVerified &&
          entryCriteriaVerified &&
          exitCriteriaVerified &&
          taskVerified &&
          deliverablesVerified
        )
      ) {
        setShowLoader(true);
        setLeavingWithoutSave(false);
        setMessage(messagesType["contentUpdate"]);
        setCrowdSourcingAppState({
          ...crowdSourcingAppState,
          reviewerError: true,
          successMessagePopUp: true,
          successMessageComplexityId: complexityId,
          successMessageTitle: activityContent.activityName,
          userLeaveWithSave: true,
        });
        setShowPreviewAndSubmitPopUp(false);
      } else if (
        postData.resultCalloutJson.description ||
        postData.resultCalloutJson.supportingActivities ||
        postData.resultCalloutJson.toolsAndGuidance ||
        postData.resultCalloutJson.tipsAndTechniques ||
        postData.resultCalloutJson.templateSuggestions ||
        postData.resultCalloutJson.entryCriteriaSuggestions ||
        postData.resultCalloutJson.exitCriteriaSuggestions ||
        postData.resultCalloutJson.taskSuggestions ||
        postData.resultCalloutJson.deliverables||
        postData.resultReorderJson.isTaskReorder ||
        postData.resultReorderJson.isEntryReorder||
        postData.resultReorderJson.isExitReorder
      ) {
        setShowLoader(true);
        const imagesUrl = sessionStorage.getItem("crowdSourcing")
          ? JSON.parse(sessionStorage.getItem("crowdSourcing"))
          : [];
        postData.imagePaths = [...contributorsData.imagePaths, ...imagesUrl];

        await axiosPostService(
          `api/sdmactivity/addActivity2Contributor`,
          postData
        )
          .then((response) => {
            
            setLeavingWithoutSave(false);
            setMessage(messagesType["contributionSubmissionMessage"]);
            setCrowdSourcingAppState({
              ...crowdSourcingAppState,
              successMessagePopUp: true,
              successMessageComplexityId: complexityId,
              successMessageTitle: activityContent.activityName,
              userLeaveWithSave: true,
              // userLeaveWithoutSave:false
            });
            setShowPreviewAndSubmitPopUp(false);
            sessionStorage.setItem("crowdSourcing", JSON.stringify([]));
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        toast.error(messagesType["noContributionMessage"], {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  function removePTags(html) {
    // Replace all <p> tags with an empty string
    return html.replace(/<p>/g, '').replace(/<\/p>/g, '');
  }
  return (
    <>

      {!licenseKey || showLoader ? (
        <Spinner />
      ) : (
        <>
          <Modal
            className="PreviewAndSubmitPopUp activityPreviewAndSubmitModal"
            show={modalShow}
            onHide={() => {
              /* Not calling any function to close modal*/
            }}
            dialogClassName="modal-90w NewPreviewAndSubmitPopUp"
            role="dialog"
            aria-label="Preview dialog"
          >
            <Modal.Header >
              <Modal.Title>
              <span tabIndex={0} role="dialog" id="previewId">Preview</span>
                
                <div className="discardAndPreviewButton">
                  <Button
                  aria-label="Cancel"
                    title="Cancel"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        closeModal();
                        // track(1, 138, activityContent.activityId, activityContent.activityName);
                      }
                    }}
                    onClick={() => {
                      closeModal();
                      // track(1, 138, activityContent.activityId, activityContent.activityName);
                    }}
                    className="activityDiscardButton"
                    id="activityDiscardButtonId"
                  >
                    Cancel
                  </Button>
                  <Button
                    title="Submit"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        submitData();
                        track(
                          1,
                          200,
                          activityContent.activityId,
                          activityContent.activityName
                        );
                      }
                    }}
                    onClick={() => {
                      submitData();
                      track(
                        1,
                        200,
                        activityContent.activityId,
                        activityContent.activityName
                      );
                    }}
                    className="activityPreviewButton"
                  >
                    Submit
                  </Button>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="activityLandinPage">
                  <Activity
                      toolsUrlPreview={toolsUrl}
                      setComplexityNamePopUp={setComplexityName}
                      complexityNamePopUp={complexityName}
                      solutionMethodId={solutionMethodId}
                      isEnableComplexity={isEnableComplexity}
                      selectedActivity={activityContent}
                      pageType={'previewAndSubmitPopUp'}
                      selectedPhaseId={phaseId}
                      setToggleActivityDetails={setToggleActivityDetails}
                      toggleActivityDetails={toggleActivityDetails}

                      users={users}
                      currentUser={currentUser}
                      activityDescription={activityDescription}
                      setPreviewActivityDescription={setPreviewActivityDescription}
                      descriptionSuggestions={descriptionSuggestions}
                      licenseKey={licenseKey}

                      activitySupportingActivities={activitySupportingActivities}
                      setPreviewActivitySupportingActivities={setPreviewActivitySupportingActivities}
                      supportingActivitiesSuggestions={supportingActivitiesSuggestions}

                      entryCriteriaWithSuggestions={entryCriteriaWithSuggestions}
                      taskWithSuggestions={taskWithSuggestions}
                      exitCriteriaWithSuggestions={exitCriteriaWithSuggestions}

                      templateSuggestions={templateSuggestions}
                      activityDeliverables={activityDeliverables}
                      setPreviewActivityDeliverables={setPreviewActivityDeliverables}
                      deliverablesSuggestions={deliverablesSuggestions}
                      getToolsCount={getToolsCount}
                      selectedToolsAndGuidanceSuggestions={selectedToolsAndGuidanceSuggestions}
                      postDataCrowdSourcing={postData}
                      activityTipsAndTechniques={ activityTipsAndTechniques }
                      setpreviewActivityTipsAndTechniques={setpreviewActivityTipsAndTechniques}
                      tipsAndTechniquesSuggestions={tipsAndTechniquesSuggestions}
                      entryContributionReorder={entryContributionReorder}
                      inputContributionReorder={inputContributionReorder}
                      exitContributionReorder={exitContributionReorder}
                      outputContributionReorder={outputContributionReorder}
                      taskReorder={taskReorder}
                    />
                      {/* <div className="activityDescTitlePart">....Removed this part....</div> */}
                </div>
                {enableTemplatePreview ? (
                  <PreviewModal
                    enableTemplatePreview={enableTemplatePreview}
                    closeModal={() => setEnableTemplatePreview(false)}
                    data={templatePreviewData}
                  />
                ) : (
                  <></>
                )}
                {enableFileDropModal ? (
                  <FileDropModal
                    enableFileDropModal={enableFileDropModal}
                    closeModal={() => setEnableFileDropModal(false)}
                    data={sampleTemplates.parentTemplate}
                  />
                ) : (
                  <></>
                )}
                <Modal
                  show={sampleTemplates.enable}
                  onHide={() => {
                    setSampleTemplates({
                      id: null,
                      enable: false,
                      parentTemplate: null,
                    });
                  }}
                  centered
                  dialogClassName="templateModal"
                  >
                  {sampleTemplates.enable ? (
                    <SampleTemplateModal
                      id={sampleTemplates.id}
                      handleTemplatePreview={handleTemplatePreview}
                      setEnableFileDropModal={setEnableFileDropModal}
                      closeModal={() => {
                        setSampleTemplates({
                          id: null,
                          enable: false,
                          parentTemplate: null,
                        });
                      }}
                      enableFileDropModal={enableFileDropModal}
                      complexityId={complexityName.id}
                      />
                  ) : (
                    <></>
                  )}
                </Modal>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

export default PreviewAndSubmitPopUp;
