const ItemTypeMap = {
	1: 'Template',
	2: 'Activity',
	3: 'Home',
	4: 'Project',
	5: 'Authentication',
	6: 'Other Tools',
	7: 'My Profile',
	8: 'Change Project Type',
	9: 'Dropdown Item',
	10: 'Topic',
	11: 'JobAides',
	12: 'Readiness',
	15: 'SolutionMethod',
	17: 'WhatsNew',
	18: 'Topics Filter',
	25: 'Phase',
	29: 'DEPlaybook',
	60: 'SDMFavorites',
	61: 'SDMTutorials',
	105: 'Top Menu Item',
	106: 'MySettings',
	107: 'MyFavorites',
	108: 'Breadcrumb',
	110: 'Left Menu Collapse',
	111: 'Left Menu Expand',
	112: 'Template Download',
	113: 'Notification',
	130: 'Activity Suggest Edit',
	131: 'Activity Review And Edit',
	132: 'Activity Approve And Edit',
	133: 'Contribute', //
	134: 'Activity Suggestions Discard',
	135: 'Activity Preview And Submit',
	138: 'Discard Changes', //
	136: 'Data Submit', //
	137: 'Activity History View',
	114: 'Template Show More',
	115: 'Template Show More Sample',
	116: 'Template Show More Localized',
	117: 'Template Show More CuratedIP',
	118: 'Template Show More Complexity',
	119: 'Recently Downloaded Templates',
	120: 'Search',
	121: 'Search Tab',
	122: 'Sample Template Download',
	123: 'Localized Template Download',
	124: 'Notification Settings',
	125: 'Switch to 1.0',
	126: 'Template Preview',
	140: 'Topics Suggest Edit',
	141: 'Topics Review And Edit',
	142: 'Topics Approve And Edit',
	143: 'Contribute',
	144: 'Topics Suggestions Discard',
	145: 'Topics Preview And Submit',
	148: 'Topics Discard Changes',
	146: 'Topics Data Submit',
	147: 'Topics History View',
	150: 'Favorite & Feedback',
	151: 'Phase SVG Switch',
	152: 'Template Feedback',
	153: 'Template Review Page',
	154: 'Template Review Approve',
	155: 'Template Review Preview',
	156: 'Template Review Reject',
	157: 'Template Approve Page',
	158: 'Template Approve Publish',
	159: 'Template Approve Preview',
	160: 'Template Approve Reject',
	161: 'Template Contribution',
	162: 'Template Contribution Submit',
	163: 'Template Landing',
	164: 'My Contributions',
	165: 'Related Search Recommendations',
	200: 'Activity Contribution Data Submit',
	201: 'Activity Review Data Submit',
	202: 'Activity Approval Data Submit',
	203: 'Topic Contribution Data Submit',
	204: 'Topic Review Data Submit',
	205: 'Topic Approval Data Submit',
	400: 'Phase Search',
	401: 'Activity Search',
	402: 'Topic Search',
	403: 'Template Search',
	404: 'Solution Method Search',
	405: 'No Search Result Found',
	406: 'Search Page Number',
	407: 'Template Search Preview',
	408: 'JobAide Search',
	409: 'Tutorial Search',
	410: 'Readiness Search',
	501: 'Topics Filter JobAides',
	502: 'Topics Filter Process Group',
	503: 'Topics Filter Solution Methods',
	504: 'Topics Filter Phases',
	505: 'Topics Filter Activities',
	506: 'Topics Filter Readiness',
	601: 'Template Complexity Filter',
	602: 'Template Solution Methods Filter',
	603: 'Template Phases Filter',
	604: 'Template Activities Filter',
	10000: 'Guided Tour',
	10001: 'Help & Support',
	700: 'Topic Yammer',
	701: 'Activity Yammer',
	702: 'Tutorial Yammer',
	800: 'Phase Create',
	801: 'Phase Update',
	802: 'Parent Phase Create',
	803: 'Parent Phase Update',
	804: 'Phase Publish',
	805: 'Parent Phase Publish',
	900: 'Add A New Activity',
	901: 'Activity Update Page Visit',
	902: 'Activity Update Save',
	903: 'Clone Activity',
	904: 'Create Activity',
	905: 'Create Group',
	906: 'Update Group',
	907: 'Activity Sequence Update',
	908: 'Activity Edit Page Preview',
	909: 'Activity Edit Page Discard',
	910: 'Activity Refer',
	911: 'Phase Refer',
	1000: 'Activity Search Suggestion',
	1001: 'Phase Search Suggestion',
	1002: 'Solution Method Search Suggestion',
	1003: 'Topics Search Suggestion',
	1004: 'Template Search Suggestion',
	1005: 'Tutorial Search Suggestion',
	1006: 'JobAides Search Suggestion',
	1007: 'Readiness Search Suggestion',
	1100: 'Tutorial Upload',
	1101: 'Tutorial Update',
	1102: 'Tutorial Delete',
	1103: 'Tutorial Landing Page',
	1104: 'Tutorial Details Page',
	1105: 'Tutorial Rating',
	1106: 'Tutorial Details Page Search',
	1107: 'Tutorial Details Page Tutorial List',
	1108: 'Tutorial Sort',
	1109: 'Tutorial Landing Page Search',
	1110: 'Tutorial Landing Page Tutorial List',
	1111: 'Tutorial Delete',
	1112: 'Tutorial Instruction',
	1200: 'Topics Add Hashtag',
	1201: 'Activity Add Hashtag',
	1202: 'Template Add Hashtag',
	1203: 'Tutorials Add hashtag',
	1204: 'Hashtag Global Search AutoSuggestion',
	1205: 'Hashtag Global Search',
	1206: 'Topic Hashtag Search',
	1207: 'JobAides Hashtag Search',
	1208: 'Tutorials Hashtag Search',
	1209: 'Phase Landing Hashtag Search',
	1210: 'Readiness Hashtag Search',
	1401: 'Topics Related Content Add',
	1402: 'Topics Related Content Update',
	1403: 'Topics Related Content Delete',
	1404: 'JobAides Related Content Add',
	1405: 'JobAides Related Content Update',
	1406: 'JobAides Related Content Delete',
	1407: 'Tutorials Related Content Add',
	1408: 'Tutorials Related Content Update',
	1409: 'Tutorials Related Content Delete',
	1410: 'Readiness Related Content Add',
	1411: 'Readiness Related Content Update',
	1412: 'Readiness Related Content Delete',
	1600: 'Matrix View',
	1601: 'Matrix View Switch',
	1602: 'Blade View Switch',
	1603: 'Matrix View Activity',
	1700: 'Link Solution Method',
	1701: 'Link Group',
	1702: 'De-link Group',
	1703: 'De-link Solution Method',
	1704: 'De-link Activity',
	1705: 'Delete Activity',
	1706: 'Delete Phase',
	1707: 'De-link Phase',
	1800: 'Topics Update',
	1801: 'Topics Create',
	1802: 'Topics Sequence update',
	1803: 'Topics Collaborstors update',
	1804: 'Topics Delete',
	1805: 'Topics Publish',
	1806: 'Topics History View',
	15000: 'SDMPlus Support Team',
	15001: 'SDMPlus Support Ticket Raise',
	15002: 'SDMPlus Announcement Flyout',
	15003: 'SDMPlus Feature Flyout',
	15004: 'SDMPlus Flyout Close',
	15005: 'Privacy Policy',
	15006: 'Mark All As Read',
	1900: 'Template Link',
	1901: 'Activity Template Upload',
	1902: 'Activity Template Linking',
	1903: 'Phase Publish Schedule',
	1904: 'Parent Phase Publish Schedule',
	1905: 'Phase Publish Schedule Cancel',
	1906: 'Parent Phase Publish Schedule Cancel',
	1907: 'Activity Template Edit',
	2001: 'Admin Page',
	2002: 'Admin Page Item Click',
	2003: 'Filter Update',
	2004: 'Activity Grid File Download',
	2005: 'RACI Grid File Download',
	2006: 'Swimlane Approved',
	2007: 'Swimlane Rejected',
	2008: 'Tutorail Added',
	2009: 'Tutorail Updated',
	2010: 'Tutorail Removed',
	2011: 'Filter Sequence Update',
	2101: 'Raci Grid Page',
	2102: 'Raci Grid Method Expand/Collapse',
	2103: 'Raci Grid Phase Expand/Collapse',
	2104: 'Raci Grid Complexity Expand/Collapse',
	2105: 'Raci Grid RoleGroup Expand/Collapse',
	2106: 'Raci Grid Filters',
	2107: 'Admin Modal Loaded',
	2201: 'Toggle End User View',
	2108: 'Raci Grid Complexity Filter',
	2109: 'Phase Landing Complexity Filter',
	2110: 'Global Search Complexity Filter',
	2111: 'MCEM Dashboard',
	2112: 'Method Extract',
	2113: 'MCEM Phase Landing Apply Filter',
	2114: 'MCEM Phase Landing Set As Default Filter',
	2115: 'Copilot-Write a prompt',
	2116: 'Copilot-Auto Rewrite',
	2117: 'Copilot-Summarize',
	2118: 'Copilot-Adjust for Business tone',
	2119: 'Copilot-Adjust for Legal tone',
	2120: 'Additional Resources',
	2121: 'Tools on Tutorial Details Page',
	2122: "Like Icon selected for Activity",
	2123: "Favorite Icon selected for Activity",
	2124: "Dislike Icon selected for Activity",
	2125: "Like Icon deselected for Activity",
	2126: "Favorite Icon deselected for Activity",
	2127: "Dislike Icon deselected for Activity",
	2128: "Like Icon selected for Topic",
	2129: "Favorite Icon selected for Topic",
	2130: "Dislike Icon selected for Topic",
	2131: "Like Icon deselected for Topic",
	2132: "Favorite Icon deselected for Topic",
	2133: "Dislike Icon deselected for Topic",
	2134: "Like Icon selected for Tutorial",
	2135: "Favorite Icon selected for Tutorial",
	2136: "Dislike Icon selected for Tutorial",
	2137: "Like Icon deselected for Tutorial",
	2138: "Favorite Icon deselected for Tutorial",
	2139: "Dislike Icon deselected for Tutorial",	
	2140: "Like Icon selected for Job Aids",
	2141: "Favorite Icon selected for Job Aids",
	2142: "Dislike Icon selected for Job Aids",
	2143: "Like Icon deselected for Job Aids",
	2144: "Favorite Icon deselected for Job Aids",
	2145: "Dislike Icon deselected for Job Aids",
	2146: "Like Icon selected for Template",
	2147: "Favorite Icon selected for Template",
	2148: "Dislike Icon selected for Template",
	2149: "Like Icon deselected for Template",
	2150: "Favorite Icon deselected for Template",
	2151: "Dislike Icon deselected for Template",
	2152: "Like Icon selected for Readiness",
	2153: "Favorite Icon selected for Readiness",
	2154: "Dislike Icon selected for Readiness",
	2155: "Like Icon deselected for Readiness",
	2156: "Favorite Icon deselected for Readiness",
	2157: "Dislike Icon deselected for Readiness",
	2158: 'Icon clicked for next activity',
    2159: 'Icon clicked for previous activity',	  
};

const ActionType = {
	1: 'List View ',
	2: 'Single View',
	3: 'Add',
	4: 'Edit',
};

const EventTypeMap = {
	1: 'Event Click',
	2: 'Page Load',
};

export { ItemTypeMap, EventTypeMap, ActionType };
