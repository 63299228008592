import React, { useState, useCallback, useEffect, useRef } from 'react';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { ListenConsultIcon, InspireDesignIcon, EmpowerAchieveIcon, RealizeValueIcon, ManageOptimizeIcon, PinIcon, UnpinIcon } from './phasesTilesIcons';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import $ from 'jquery';
import { toast } from 'react-toastify';
import { Icon } from "@fluentui/react";
import './condenseStyles.css';
import ExtractMethodlogy from '../../components/LeftMenu/ExtractMethodology';
import { axiosPostService } from '../CkEditor/api/axiosBaseUrl';

const CondenseNavigation = ({
  setIsTopPhaseMenu,
  setSelected,
  selected,
  setDynamicPhaseCSS,
  dynamicPhaseCSS,
  isDefaultPage,
  setIsDefaultPage,
  getDefaultLandingPage,
  defaultPhase,
  defaultUrl
}) => {
  const { appState, endUserView } = useLeftMenuCollapse();
  const [activeId, setActiveId] = useState('listen');
  const [hoverId, setHoverId] = useState(null);
  const [phases, setPhases] = useState([]);
  const [mcemPhases, setMcemPhases] = useState([]);
  const [deliveryManagementId, setDeliveryManagementId] = useState('');
  const [realizeID, setRealizeID] = useState('');
  const { axiosGetService, axiosPostService, userAlias } = useAxios();
  const { track } = useAppInsights();

  const ref = useRef(null);
  const [showMethodAdminMenu, setShowMethodAdminMenu] = useState(false);
  const [projectDelivery, setIsProjectDelivery] = useState('');
  const [ismethodownerorcoowner, setIsmethodownerorcoowner] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [extractMethodlogyId, setExtractMethodologyId] = useState(0);
  const [showExtractMethodlogy, setShowExtractMethodlogy] = useState(false);
  const [solutionMethodName, setSolutionMethodName] = useState("MCEM for IS");
  const { defaultComplexity, projectTypeId } = appState;
  const [selectedComplexity, setSelectedComplexity] = useState(defaultComplexity);
  const [complexities, setComplexities] = useState([]);

  const location = useLocation();
  const [hoverText, setHoverText] = useState('');
  const [isShownHover, setIsShownHover] = useState(false);
  const handleItemClick = useCallback((phase) => {
    setIsTopPhaseMenu(true);
    setActiveId(phase.phaseId.toString());
  }, []);

  const getIconColor = (isActive, isHovered) => {
    if (isHovered) return '#4cb6ea';
    if (isActive) return '#253b62';
    return '#FFFFFF';
  };

  const getIconComponent = (phaseName) => {
    switch (phaseName.toLowerCase()) {
      case 'listen and consult': return ListenConsultIcon;
      case 'inspire and design': return InspireDesignIcon;
      case 'empower and achieve': return EmpowerAchieveIcon;
      case 'realize value': return RealizeValueIcon;
      case 'manage and optimize': return ManageOptimizeIcon;
      default: return null;
    }
  };

  const handleHover = (phase) => {
    setHoverId(phase.phaseId.toString());
    setHoverText(phase.phaseHoverDescription);
    setIsShownHover(true);
  };

  const handleHoverOut = () => {
    setHoverId(null);
    setHoverText('');
    setIsShownHover(false);
  };

  const getPhases = async () => {
    try {
      const res = await axiosGetService('api/sdmquicklink/getmcemphaseshovercontent');
      if (res.status === 200) {
        setPhases(res.data);
      }
    } catch (err) {
      toast.error(err.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getNonRealizeMCEMstagesWithRV = async () => {
    try {
      const response = await axiosGetService('api/sdmsettings?sdmKey=NonRealizeMCEMstagesWithRV');
      if (response.status === 200) {
        setMcemPhases(response.data[0].value.split(','));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getDeliveryManagementId = async () => {
    try {
      const response = await axiosGetService('api/sdmsettings?sdmKey=DeliveryManagementId');
      setDeliveryManagementId(response.data[0].value);
    } catch (err) {
      console.error(err);
    }
  };

  const getRealizeValue = async () => {
    try {
      const response = await axiosGetService('api/sdmsettings?sdmKey=RealizeValue');
      setRealizeID(response.data[0].value);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const path = location.pathname.split("/");
    const methodId = path[4];
    const phaseId = path[6];
  console.log('DTX',location.pathname.split("/").at(6),methodId);

    if (methodId === deliveryManagementId) {
      setActiveId(deliveryManagementId);
    } else if (phaseId) {
      setActiveId(phaseId);
    } else {
      setActiveId(methodId);
    }
  }, [location, deliveryManagementId]);


  const getProjectDeliveryId = async () => {
    await axiosGetService(`api/sdmsettings?sdmKey=SDMNotInProjectDeliveryId`).then(
      (response) => {
        setIsProjectDelivery(response.data[0].value.split(",").at(1))
      }
    );
  };


  const getCoOwnerGroupId = async () => {
    await axiosGetService(
      `api/sdmsolutionmethod/ismethodownerorcoowner/${projectDelivery}`
    ).then((response) => {
      setIsmethodownerorcoowner(response.data);
    });
  };

  const getIsAdmin = async () => {
    await axiosGetService('api/user/profile').then((res) => {
      setIsAdmin(res.data.isAdmin);
    });
  }

  const getComplexities = async () => {
    await axiosGetService("api/activities/projectcomplexity").then((res) => {
      const data = res.data.map((item) => {
        return {
          name: item.name,
          id: item.id,
          type: "option",
        };
      });
      setComplexities(data);
    });
  };

  const handleDefaultPage = async (phase) => {
    const defaultPinnedUrl = phase.phaseName.toLowerCase() === "realize value"
      ? `/project/1/method/${deliveryManagementId}`
      : `/project/1/method/${phase.solutionMethodId}/phase/${phase.phaseId}/parentPhase/${null}`;

    const payload = {
      "email": userAlias,
      "defaultLandingPageUrl": defaultUrl === defaultPinnedUrl ? "/" : defaultPinnedUrl,
      "isLandingPage": 1,
    }

    await axiosPostService(`api/sdmuser/insert`, payload).then((response) => {
      if (response.data === 1) {
        setIsDefaultPage(true);
        toast.info('The page is set as the default pinned page.', {
          position: 'top-right',
        });
      } else {
        setIsDefaultPage(false);
        toast.info('The page was unpinned successfully.', {
          position: 'top-right',
        });
      }
      getDefaultLandingPage();
    });

  }

  useEffect(() => {
    getDefaultLandingPage();
  }, [defaultUrl]);

  useEffect(() => {
    getIsAdmin();
    getProjectDeliveryId();
    getComplexities();
    getPhases();
    getNonRealizeMCEMstagesWithRV();
    getDeliveryManagementId();
    getRealizeValue();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowMethodAdminMenu(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref]);

  $('.businessIcon, .mandatoryIcon').css('top', '6.5px');
  $('.extractPopupMCEMContainer').attr('style', function (i, style) {
    return (style || '') + '; top: 185px; left: 30px !important;';
  });

  return (
    <nav className="_phasesTilesContainer _bgPrimary">
      <div className="_tilesWrapper">
        <button title='MCEM' className="_MCEMStages d-flex align-items-center justify-content-center border-0 rounded _bgPrimaryDark text-white" >
          <span className="_phaseName fw-semibold">MCEM</span>
          <div className="d-flex align-items-center pr-2">
            <div id="divMainLeftMenu" className="editMCEMSolutionMethod">
              <Icon
                style={{ top: '0px' }}
                id='divMainLeftMenuId'
                className="extractMCEMMoreIcon"
                iconName="MoreVertical"
                title="Extract Methodology"
                aria-label="Click to view extract Methodology"
                tabIndex={0}
                onClick={async () => {
                  setShowMethodAdminMenu(true);
                  await getCoOwnerGroupId(projectDelivery);
                  track(
                    1,
                    2111,
                    projectDelivery,
                    'MCEM Stages'
                  );
                }}
                onKeyDown={(e) => {
                  if (e.which === 13) {
                    setShowMethodAdminMenu(true);
                    track(
                      1,
                      2111,
                      projectDelivery,
                      'MCEM Stages'
                    );
                  }
                }}
              />

              <div
                className={`showMCEMMoreMenu ${showMethodAdminMenu
                  ? ""
                  : "hideMethodEditMenu"
                  }`}
                ref={ref}
              >
                <span className="showMoreArrow"></span>
                <ul className="menuMCEMItemUL">
                  {(isAdmin || ismethodownerorcoowner) && !endUserView && (
                    <>
                      <li className="moreMCEMItemLi">
                        <div
                          className="ellipseMCEMMenuItem"
                          aria-label="Click to edit Solution Method"
                        >
                          <NavLink to={`/project/${1}/method/${projectDelivery}`}
                            onClick={() => {
                              setIsTopPhaseMenu(false);
                              setSelected(false);
                              setShowMethodAdminMenu(false);
                              track(
                                1,
                                2111,
                                projectDelivery,
                                'MCEM Stages'
                              );
                            }} >
                            <span>Edit Solution Method</span>
                          </NavLink>
                        </div>
                      </li>
                    </>
                  )}
                  <li className="moreMCEMItemLi" >
                    <div
                      className="ellipseMCEMMenuItem"
                      tabIndex={0}
                      onClick={(event) => {
                        event.stopPropagation();
                        setExtractMethodologyId(projectDelivery);
                        setShowExtractMethodlogy(true);
                        setShowMethodAdminMenu(false);
                        track(
                          1,
                          2111,
                          projectDelivery,
                          'MCEM Stages'
                        );
                      }}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          setExtractMethodologyId(projectDelivery);
                          setShowExtractMethodlogy(true);
                          setShowMethodAdminMenu(false);
                          track(
                            1,
                            2111,
                            projectDelivery,
                            'MCEM Stages'
                          );
                        }
                      }}
                      aria-label="Click to extract Solution Method"
                    >
                      <span>Extract Solution Method</span>
                    </div>
                  </li>
                </ul>
              </div>
              {showExtractMethodlogy &&
                extractMethodlogyId === projectDelivery ? (
                <ExtractMethodlogy
                  showExtractMethodlogy={showExtractMethodlogy}
                  extractMethodlogyId={extractMethodlogyId}
                  solutionMethodName={solutionMethodName}
                  selectedComplexity={selectedComplexity}
                  setSelectedComplexity={setSelectedComplexity}
                  setExtractMethodologyId={setExtractMethodologyId}
                  setShowExtractMethodlogy={setShowExtractMethodlogy}
                  complexities={complexities}
                />
              ) : null}
            </div>
          </div>
        </button>
        {phases.map((phase) => {
          const isActive = activeId === phase.phaseId.toString() ? true : (phase.phaseName.toLowerCase() === "realize value" && activeId === deliveryManagementId)?true:false;
          const isHovered = hoverId === phase.phaseId.toString();
          const IconComponent = getIconComponent(phase.phaseName);
          return (
            <div key={phase.phaseId} className={`_phaseTile d-flex align-items-center justify-content-between border-0 rounded
              ${isActive ? 'bg-white _activePhaseTile' : '_bgPrimaryDark'}
            `}>
              <NavLink

                to={phase.phaseName.toLowerCase() === "realize value"
                  ? `/project/1/method/${deliveryManagementId}`
                  : `/project/1/method/${phase.solutionMethodId}/phase/${phase.phaseId}/parentPhase/${null}`
                }
                title={phase.phaseName}
                className={`_phaseLink ${isActive ? '_textPrimary' : 'text-white'}`}
                onClick={() => {
                  handleItemClick(phase);
                  track(1, 2111, phase.phaseId, phase.phaseName);
                }}
                onMouseEnter={() => handleHover(phase)}
                onMouseLeave={handleHoverOut}
              >
                <div className="d-flex align-items-center pl-2">
                  {IconComponent && <IconComponent fillColor={getIconColor(isActive, isHovered)} />}
                </div>
                <span className="_phaseName fw-semibold text-center flex-grow-1">{phase.phaseName.replaceAll("and", "&")}</span>
                {isHovered && isShownHover && (
                  <div className="hoverPhaseText">
                    <p dangerouslySetInnerHTML={{ __html: hoverText }}></p>
                  </div>
                )}
              </NavLink>
              <div className="d-flex align-items-center" onClick={() => {
                handleDefaultPage(phase);
              }}
                title={defaultPhase?.toString() === phase.phaseId.toString() ? 'Unpin' : 'Pin'}
              >
                {(phase.phaseName.toLowerCase() === 'realize value' && defaultPhase?.toString() === deliveryManagementId?.toString()) || (defaultPhase?.toString() === phase.phaseId.toString()) ?
                  <UnpinIcon fillColor={isActive ? '#253b62' : '#0078D4'} />
                  :
                  <PinIcon fillColor={isActive ? '#253b62' : '#0078D4'} />
                }

              </div>
            </div>
          );
        })}
      </div>
    </nav>
  );
};

export default CondenseNavigation;