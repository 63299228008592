/**
 * The list of supported Copilot options:
 *
 * * `'Write a prompt'`,
 * * `'Auto Rewrite'`,
 * * `'Summarize'`,
 * * `'Adjust for a Business Tone'`
 * *  `'Adjust for a leagal Tone'`
 */

export function getLocalizedOptions( editor ) {
	const t = editor.t;
	const localizedTitles = {
		'writeAPrompt': t( 'Write a Prompt' ),
		'autoRewrite': t( 'Auto Rewrite' ),
		'summarize': t( 'Summarize' ),
		'businessTone': t( 'Adjust for Business Tone' ),
		'legalTone': t( 'Adjust for Legal Tone' )
	};

	return editor.config.get( 'copilot.options' ).map( option => {
		const title = localizedTitles[ option.title ];

		if ( title && title != option.title ) {
			option.title = title;
		}

		return option;
	} );
}