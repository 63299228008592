import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './FeedbackIcons.css';
import { useAxios } from "../../context/AxiosContext";
import { useAppInsights } from "../../context/TelemetryContext";

const FeedbackIcons = ({ artifactId, artifacttypeid, showCounts, isliked, isdisliked, title, pageType,templateurl }) => {
  const [likeCount, setLikeCount] = useState(0);
  const [dislikeCount, setDislikeCount] = useState(0);
  const [isFavorited, setIsFavorited] = useState(false);
  const [isLiked, setIsLiked] = useState(isliked);
  const [isDisliked, setIsDisliked] = useState(isdisliked);
  const [viewCount, setViewCount] = useState(0);
  const { axiosGetService, axiosPostService } = useAxios();
  const [favorites, setFavorites] = useState([]);
  const location = window.location.pathname + window.location.search;
  const { track } = useAppInsights();


  useEffect(() => {
    setIsLiked(isliked);
    setIsDisliked(isdisliked);
  }, [isliked, isdisliked, artifactId, artifacttypeid]); 


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosGetService(`/api/sdmfavorite/getCountofArtifactFeedbackbyArtifact/?artifactId=${artifactId}&artifactTypeId=${artifacttypeid}`);
        const responseview = await axiosGetService(`/api/sdmfavorite/getartifactviewscount/?artifactId=${artifactId}&artifactTypeId=${artifacttypeid}`); 
        const responsefavorite = await axiosGetService(`/api/sdmfavorite/getbyuser`);
        setLikeCount(response.data.likeCount);
        setDislikeCount(response.data.dislikeCount);
        setFavorites(responsefavorite.data);
        setViewCount(responseview?.data?.views ? responseview.data.views : 0);
      } catch (error) {
        console.error('Error fetching feedback data:', error);
      }
    };

    fetchData();
  }, [artifactId, artifacttypeid]);

 
  useEffect(() => {
    const onLoad = () => {
      const artifact = favorites.filter((item) => item.url === (pageType === 'template' ? templateurl : location));
      setIsFavorited(artifact.length > 0);
    };

    if (favorites !== null) {
      onLoad();
    }
  }, [favorites, location, pageType, templateurl]);

  const handleLike = async () => {
    setLikeCount((prevLikeCount) => {
      const updatedLikeCount = isLiked ?  Math.max(prevLikeCount - 1, 0) : prevLikeCount + 1;
      return updatedLikeCount;
    });

    setIsLiked((prevIsLiked) => {
      const newIsLiked = !prevIsLiked;
      return newIsLiked;
    });

    if (isDisliked) {
      setDislikeCount((prevDislikeCount) => Math.max(prevDislikeCount - 1, 0));
      setIsDisliked(false);
    }

    try {
      const response = await axiosPostService(`/api/sdmfavorite/insertArtifactFeedback`, {
        artifactid: artifactId,
        artifacttypeid: artifacttypeid,
        isliked: !isLiked,
        isdisliked: false,
      });

      // Send tracking info based on pageType
      if (pageType === 'activity') {
        track(1, 2122, artifactId, title);
      } else if (pageType === 'tutorialDetails') {
        track(1, 2134, artifactId, title);
      } else if (pageType === 'topics') {
        track(1, 2128, artifactId, title);
      } else if (pageType === 'template') {
        track(1, 2146, artifactId, title);
      } else if (pageType === 'job Aides') {
        track(1, 2140, artifactId, title);
      } else if (pageType === 'readiness') {
        track(1, 2152, artifactId, title);
      }
    } catch (error) {
      console.error('Error liking the item:', error);
    }
  };

  const handleDislike = async () => {
    setDislikeCount((prevDislikeCount) => {
      const updatedDislikeCount = isDisliked ? Math.max(prevDislikeCount - 1, 0) : prevDislikeCount + 1;
      return updatedDislikeCount;
    });

    setIsDisliked((prevIsDisliked) => {
      const newIsDisliked = !prevIsDisliked;
      return newIsDisliked;
    });

    if (isLiked) {
      setLikeCount((prevLikeCount) => Math.max(prevLikeCount - 1, 0));
      setIsLiked(false);
    }

    try {
      const response = await axiosPostService(`/api/sdmfavorite/insertArtifactFeedback`, {
        artifactid: artifactId,
        artifacttypeid: artifacttypeid,
        isliked: false,
        isdisliked: !isDisliked,
      });

      // Send tracking info based on pageType
      if (pageType === 'activity') {
        track(1, 2124, artifactId, title);
      } else if (pageType === 'tutorialDetails') {
        track(1, 2136, artifactId, title);
      } else if (pageType === 'topics') {
        track(1, 2130, artifactId, title);
      } else if (pageType === 'template') {
        track(1, 2148, artifactId, title);
      } else if (pageType === 'job Aides') {
        track(1, 2142, artifactId, title);
      } else if (pageType === 'readiness') {
        track(1, 2154, artifactId, title);
      }
    } catch (error) {
      console.error('Error disliking the item:', error);
    }
  };

  const handleFavorite = async () => {
    const body = {
      url: templateurl ? templateurl :location,
      title: title,
    };
    try {
      await axiosPostService(`/api/sdmfavorite/insert`, body);
      setIsFavorited(!isFavorited);

      // Send tracking info based on pageType
      if (pageType === 'activity') {
        track(1, 2123, artifactId, title);
      } else if (pageType === 'tutorialDetails') {
        track(1, 2135, artifactId, title);
      } else if (pageType === 'topics') {
        track(1, 2129, artifactId, title);
      } else if (pageType === 'template') {
        track(1, 2147, artifactId, title);
      } else if (pageType === 'job Aides') {
        track(1, 2141, artifactId, title);
      } else if (pageType === 'readiness') {
        track(1, 2153, artifactId, title);
      }
    } catch (error) {
      console.error('Error favoriting the item:', error);
    }
  };

  const handleFavoriteDelete = async () => {
    const body = {
      url: templateurl ? templateurl :location,
    };
    try {
      await axiosPostService(`/api/sdmfavorite/delete`, body);
      setIsFavorited(!isFavorited);

      // Send tracking info based on pageType
      if (pageType === 'activity') {
        track(1, 2126, artifactId, title);
      } else if (pageType === 'tutorialDetails') {
        track(1, 2138, artifactId, title);
      } else if (pageType === 'topics') {
        track(1, 2132, artifactId, title);
      } else if (pageType === 'template') {
        track(1, 2150, artifactId, title);
      } else if (pageType === 'job Aides') {
        track(1, 2144, artifactId, title);
      } else if (pageType === 'readiness') {
        track(1, 2156, artifactId, title);
      }
    } catch (error) {
      console.error('Error favoriting the item:', error);
    }
  };

  return (
    <div className={pageType==='template'?'feedback-icons viewDivresuableTemplate':'feedback-icons'} style={{ display: 'flex', alignItems: 'center' }}>
      <div
        className="viewDivresuable"
        tabIndex={0}
        role="button"
        aria-label="View"
        title="View"
      >
        <span className="viewWrapper">
          <span className={pageType==='template'?'viewIconTemplate':'viewIcon'} />
          {showCounts && <span className="viewCount">{viewCount}</span>}
        </span>
      </div>

      <div
        className="favoriteDivResuable"
        tabIndex={0}
        onClick={isFavorited ? handleFavoriteDelete : handleFavorite}
        onKeyPress={(e) => {
          if (e.which === 13) isFavorited ? handleFavoriteDelete() : handleFavorite();
        }}
        role="button"
        aria-label="Favorite"
        title="Favorite"
      >
        <span className="favoriteWrapper">
          {!isFavorited ? (
            <span className="favoriteOutline" />
          ) : (
            <span className="favoriteFilled" />
          )}
        </span>
      </div>
      
      <div
        className="likeDivReusable"
        tabIndex={0}
        onClick={handleLike}
        onKeyPress={(e) => {
          if (e.which === 13) handleLike();
        }}
        role="button"
        aria-label="Like"
        title="Like"
      >
        <span className="likeWrapper">
          {!isLiked ? (
            <span className="likeIcon"></span>
          ) : (
            <span className="likedIcon"></span>
          )}
          {showCounts && <span className="likeCount">{likeCount}</span>}
        </span>
      </div>

      <div
        className="dislikeDivReusable"
        tabIndex={0}
        onClick={handleDislike}
        onKeyPress={(e) => {
          if (e.which === 13) handleDislike();
        }}
        role="button"
        aria-label="Dislike"
        title="Dislike"
      >
        <span className="dislikeWrapper">
          {!isDisliked ? (
            <span className="dislikeIcon" />
          ) : (
            <span className="dislikedIcon" />
          )}
          {showCounts && <span className="dislikeCount">{dislikeCount}</span>}
        </span>
      </div>
    </div>
  );
};

FeedbackIcons.propTypes = {
  artifactId: PropTypes.string.isRequired,
  artifacttypeid: PropTypes.string.isRequired,
  showCounts: PropTypes.bool,
  isliked: PropTypes.bool.isRequired,
  isdisliked: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
};

FeedbackIcons.defaultProps = {
  showCounts: true,
};

export default FeedbackIcons;
