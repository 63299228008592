import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import queryString from 'query-string';

import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import { useAxios } from '../../context/AxiosContext';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import '../ActivityContributorCrowdSourcing/DiscardPopUp.css';

function DiscardPopUp({
	contributorContent,
	isActivityLock,
	lockedByUsername,
	activityLockedBy,
}) {
	const [modalShow, setModalShow] = useState(true);
	const { crowdSourcingAppState, setCrowdSourcingAppState } =
		useCrowdSourcing();
	const complexityId = queryString.parse(window.location.search).complexity;
	const activityIdentifier = queryString.parse(
		window.location.search
	).identifier;
	const { projectTypeId, solutionMethodId, phaseId, parentPhaseId,activityId } =
		useParams();
	const [messagesType, setMessageType] = useState({});
	const { axiosGetService } = useAxios();

	const getMessage = async () => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=pendingReviewWithHistory&title=0`
		).then((response) => {
			message['pendingReviewWithHistory'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=pendingReviewWithoutHistory&title=0`
		).then((response) => {
			message['pendingReviewWithoutHistory'] = response.data;
		});
		setMessageType(message);
	};

	useEffect(() => {
		getMessage();
	}, []);
	const closeModal = () => {
		setModalShow(false);
		setCrowdSourcingAppState({
			...crowdSourcingAppState,
			showHistory: true,
		});
	};
	return (
		<Modal
			className="activityDiscardPopUp reviewedPopUp"
			show={modalShow}
			onHide={() => {
				/* We should not close modal when user click outside of modal */
			}}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				{contributorContent ? (
					<>
						{isActivityLock ? (
							<p>
								You can not edit this content now. Content is being updated by{' '}
								<a
									target="_blank"
									href={`https://teams.microsoft.com/l/chat/0/0?users=${activityLockedBy}`}
								>
									{lockedByUsername}
								</a>
								{' .'} Please check later for editing.
							</p>
						) : (
							messagesType['pendingReviewWithHistory']
						)}
						<div className="discardPopUpActivityButtons">
							<Button
								title="Show me historical preview"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
								className="activityDiscardPopUpAccept"
								variant="primary"
								onClick={() => closeModal()}
							>
								Show me historical preview
							</Button>
						</div>
					</>
				) : (
					<>
						{isActivityLock ? (
							<p>
								You can not edit this content now. Content is being updated by{' '}
								<a
									target="_blank"
									href={`https://teams.microsoft.com/l/chat/0/0?users=${activityLockedBy}`}
								>
									{lockedByUsername}
								</a>
								{' .'} Please check later for editing.
							</p>
						) : (
							messagesType['pendingReviewWithoutHistory']
						)}
						<div className="discardPopUpActivityButtons">
							<Link
								to={`/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}/activity/${activityIdentifier ? activityIdentifier : activityId}?complexity=${complexityId}`}
							>
								<Button
									title="OK"
									className="activityDiscardPopUpAccept"
									variant="primary"
									// onClick={() => closeModal()}
								>
									OK
								</Button>
							</Link>
						</div>
					</>
				)}
			</Modal.Body>
		</Modal>
	);
}

export default DiscardPopUp;
