import React, { useEffect, useState } from 'react';
import './ArrowNavigation.css'; // Import your CSS for styling
import { useAppInsights } from '../../context/TelemetryContext';

const ArrowNavigation = ({ items, selectedActivityId, onChange }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isUserTriggered, setIsUserTriggered] = useState(false);
  const { track } = useAppInsights();

  useEffect(() => {
    console.log(items, "lineotesm123");
    const newIndex = items.findIndex(item => item.activityIdentifier === selectedActivityId);
    if (newIndex !== currentIndex && !isUserTriggered) {
      setCurrentIndex(newIndex);
      onChange(items[newIndex], false); // False indicates this is not user-triggered
    } else {
      console.log('currentIndex remains the same:', currentIndex); // Debugging log
    }
    setIsUserTriggered(false); // Reset the flag after useEffect runs
  }, [selectedActivityId, items, currentIndex, onChange, isUserTriggered]);

  const handleNext = () => {
    if (currentIndex < items.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      setIsUserTriggered(true); // Set the flag to true for user-triggered change
      onChange(items[newIndex], true); // True indicates this is user-triggered
      track(1, 2158, items[newIndex]?.activityId, items[newIndex]?.title);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      setIsUserTriggered(true); // Set the flag to true for user-triggered change
      onChange(items[newIndex], true); // True indicates this is user-triggered
      track(1, 2159, items[newIndex]?.activityId, items[newIndex]?.title);
    }
  };

  return (
    <div className="NavigationDivresuable">
      <div className="Navigationpagecount">
        <span>{currentIndex + 1} of {items.length}</span>
      </div>
      <button
         className={`arrowButton`}
        tabIndex={0}
        onClick={currentIndex === 0 ? null : handlePrevious}
        onKeyPress={(e) => {
          if (e.which === 13 && currentIndex !== 0) handlePrevious();
        }}
        disabled={currentIndex === 0}
        aria-label="Previous"
        title="Previous"
      >
        <span className="activityTopArrowIcon"></span>
      </button>
      <button
        className={`arrowButton`}
        tabIndex={0}
        onClick={currentIndex === items.length - 1 ? null : handleNext}
        onKeyPress={(e) => {
          if (e.which === 13 && currentIndex !== items.length - 1) handleNext();
        }}
        disabled={currentIndex === items.length - 1}
        aria-label="Next"
        title="Next"
      >
        <span className="activityBottomArrowIcon"></span>
      </button>
    </div>
  );
};

export default ArrowNavigation;
