import React, { useState, useEffect, useRef, useCallback } from "react";
import ComplexityFilter from "./ComplexityFilter";
import RolesFilter from "./RolesFilter";
import { useAxios } from "../../context/AxiosContext";
import { useAppInsights } from "../../context/TelemetryContext";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import { Icon } from "@fluentui/react/lib/Icon";
import DynamicFilter from "./DynamicFilter";
import { toast } from "react-toastify";

function Complexity({
  setSelectedComplexity,
  solutionMethodId,
  setSelectedRoles,
  setShowFilters,
  selectedRoles,
  showFilters,
  searchInput,
  setShowSpinner,
  setSelectedComplexityId,
  setSelectedRoleId,
  showMatrixView,
  phaseCreated,
  updateComplexityFilter,
  parentId,
  complexities,
  setComplexities,
  isEnableComplexity = false,
  userActivityFilter = null,
  realizeValueToggler = () => {},
  setUserActivityFilter,
  pagetype,
  onButtonClick,
  nonrealizedvalue,
  setNonrealizedvalue,
  filterCleared,
  setFilterCleared,
  userRoleTodefault
}) {
  const {
    appState,
    setComplexityValidActivities,
    phase,
    parentPhase,
    complexity,
    setComplexity,
    role,
    setRole,
    phaseHashtags,
    phaseDynamicFilterTag: dynamicFilterTag,
    setPhaseDynamicFilterTag: setDynamicFilterTag,
    setPhaseDynamicFilterTagMapping: setDynamicFilterTagMapping,
    applyfilterTrigger,
    setApplyFilterTrigger,
    roleTag,
    setRoleTag,
  } = useLeftMenuCollapse();
  const { track } = useAppInsights();
  const { axiosGetService,axiosPostService } = useAxios();
  const { defaultRole,defaultComplexity } = appState;
  const searchInputRef = useRef(null);
  // list of all complexities and roles
  //const [complexities, setComplexities] = useState([]);
  const [roleSearchValue, setRoleSearchValue] = useState("");
  const [roles, setRoles] = useState([]);
  const [deliveryRoles, setDeliveryRoles] = useState([]);
  const [totalRoles, setTotalRoles] = useState([]);
  const [enableSearch, setEnableSearch] = useState({
    Tools: false,
    Roles: false,
  });
  const [totalFilteredRoles, setTotalFilteredRoles] = useState([]);
  // list of all complexity valid activities
  const [validActivities, setValidActivities] = useState([]);
  const [validDeliveryActivities, setValidDeliveryActivities] = useState([]);
  const [dynamicFilterData, setDynamicFilterData] = useState([]);
  const [dynamicSearchText, setDynamicSearchText] = useState({});
  const [dynamicFilterSearchResult, setDynamicFilterSearchResult] = useState(
    {}
  );
  const [dynamicFilterSearchEnable, setDynamicFilterSearchEnable] = useState(
    {}
  );
  const [roleId, setRoleId] = useState(0);

  // const updateComplexity = (complexityData, type) => {
  //   const complexityDetails = complexityData.filter(
  //     ({ name }) => name === type
  //   );
  //   setSelectedComplexity(type);
  //   setComplexity(complexityDetails[0]);
  //   setSelectedComplexityId(complexityDetails[0].id);
  // };
  // get complexities for dropDown
  // const getComplexities = useCallback(async () => {
  //   await axiosGetService(
  //     `api/sdmphase/getcomplexity/${solutionMethodId}/${
  //       phase.id && !showMatrixView ? phase.id : 0
  //     }/${parentPhase.id && !showMatrixView ? parentPhase.id : 0}`
  //   ).then((res) => {
  //     const data = res.data.map((item) => {
  //       return {
  //         name: item.name,
  //         id: item.id,
  //         type: "option",
  //       };
  //     });

  //     const availableComplexities = data.map(({ name }) => {
  //       return name;
  //     });
  //     if (!data.some((value) => value.id === complexity.id)) {
  //       if (availableComplexities.includes("Medium")) {
  //         updateComplexity(data, "Medium");
  //       } else if (availableComplexities.includes("Critical")) {
  //         updateComplexity(data, "Critical");
  //       } else if (availableComplexities.includes("High")) {
  //         updateComplexity(data, "High");
  //       } else if (availableComplexities.includes("Low")) {
  //         updateComplexity(data, "Low");
  //       }
  //     } else {
  //       updateComplexity(
  //         data,
  //         data.find((value) => value.id === complexity.id).name
  //       );
  //     }
  //     setComplexities(data);
  //   });
  // }, [
  //   parentPhase.id,
  //   phase.id,
  //   showMatrixView,
  //   phaseCreated,
  //   updateComplexityFilter,
  // ]);

  const searchRoles = (searchText) => {
    if (searchText.trim().length !== 0) {
      const filteredRoles = totalRoles.filter((role) =>
        role.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setTotalFilteredRoles(filteredRoles);
    }
  };

  // get Roles for respective PhaseId, activities for Dropdown
  const getRoles = (id, type) => {
    axiosGetService(
      `api/sdmphase/getphaseroles/${Number(id)}/${solutionMethodId}`
    ).then((res) => {
      const roleData =
        res.data === null
          ? []
          : res.data.map((item) => {
            const roleDataStringified = JSON.stringify({
              name: item.roleName,
              id: item.roleId,
              type: "option",
            });

            if (type === "phase") {
              return deliveryRoles.includes(roleDataStringified)
                ? null
                : roleDataStringified;
            } else {
              return roles.includes(roleDataStringified)
                ? null
                : roleDataStringified;
            }
          });

      const filteredRoles = roleData.filter((item) => item !== null);

      if (type === "parentPhase") {
        setDeliveryRoles(filteredRoles);
      } else {
        setRoles(filteredRoles);
      }
    });
  };

  const getAllRoles = async () => {
    await axiosGetService(
      `api/sdmphase/getphaseroles/${0}/${solutionMethodId}`
    ).then((response) => {
      const roleIds = response.data.map((item) => {
        return {
          name: item.roleName,
          id: item.roleId,
          type: "option",
        };
      });
      setTotalRoles(roleIds);
      setRoleTag(roleIds);

    });
  };

  useEffect(() => {
    if (pagetype === "non-realized") {
      getAllPhaseRoles();
    }
  }, [pagetype,phase.id]);

  const getAllPhaseRoles = async () => {
    await axiosGetService(
      `api/sdmphase/getphaseroles/${Number(phase.id)}/${solutionMethodId}`
    ).then((response) => {
      const roleIds = response.data.map((item) => {
        return {
          name: item.roleName,
          id: item.roleId,
          type: "option",
        };
      });
       const None = { name: "None", id: defaultRole.id, type: "option" };
      setTotalRoles([None,...roleIds]);
      setRoleTag(roleIds);

    });
  };

  // get activity Complexities and RACI info  
  const getActivityAndRaciData = async (type) => {
    if(pagetype !== "non-realized"){
    const id = type === "parentPhase" ? parentPhase.id : phase.id;
    const encodedHashtags = phaseHashtags.map((item) => {
      return encodeURIComponent(item);
    });
    if (searchInput.trim().length > 0) {
      setShowSpinner(true);
    }
    const selectedFiltersIds = [];
    const filterIds = Object?.keys(dynamicFilterTag);
    for (const id of filterIds) {
      if (dynamicFilterTag[Number(id)].selectedTagId.length > 0) {
        selectedFiltersIds.push(
          `${Number(id)}` +
          "-" +
          dynamicFilterTag[Number(id)].selectedTagId.toString()
        );
      }
    }
    if (role?.id !== 0) {
      selectedFiltersIds?.push(`16-${role?.id}`);
    }
    await axiosGetService(
      `api/sdmactivity/getactivity/${solutionMethodId}/${id}/${complexity?.id ? complexity?.id : defaultComplexity?.id }/${selectedFiltersIds?.length > 0 ? `${selectedFiltersIds.join("|")}` : ((userActivityFilter?.length > 0 ? userActivityFilter[0].filters : 0))
      }/${searchInput.trim().length > 0
        ? encodeURIComponent(searchInput.trim())
        : 0
      }/${phaseHashtags.length > 0 ? encodedHashtags.toString() : 0}`
    ).then((res) => {
      const dataFilter =
        res?.data === null
          ? []
          : res?.data?.map((item) => ({
            id: item.activityIdentifier,
            responsible: item.responsible,
            accountable: item.accountable,
            consulted: item.consulted,
            informed: item.informed,
            activityId: item.activityId,
          }));
      if (type === "parentPhase") {
        setValidDeliveryActivities(dataFilter);
      } else {
        setValidActivities(dataFilter);
      }
      setShowSpinner(false);
      if (phaseHashtags.length > 0 || searchInput.trim().length > 0) {
        track(1, 1207, "Job Aides Landing Page", "Job Aides", {
          hashtag: phaseHashtags.length > 0 ? phaseHashtags.toString() : null,
          title: searchInput.trim().length > 0 ? searchInput : null,
        });
      }
    });}
  };


  const getActivityAndRaciDataForNonRealized = async (type,dynamicFilterTag) => {
    if(pagetype === "non-realized"){
    const id = type === "parentPhase" ? parentPhase.id : phase.id;
    const encodedHashtags = phaseHashtags.map((item) => {
      return encodeURIComponent(item);
    });
    if (searchInput.trim().length > 0) {
      setShowSpinner(true);
    }
    const selectedFiltersIds = [];
    const filterIds = Object?.keys(dynamicFilterTag);
    for (const id of filterIds) {
      if (dynamicFilterTag[Number(id)].selectedTagId.length > 0) {
        selectedFiltersIds.push(
          `${Number(id)}` +
          "-" +
          dynamicFilterTag[Number(id)].selectedTagId.toString()
        );
      }
    }
    const roleFilterPrefix = '16-';
      const updatedFilters = selectedFiltersIds?.filter(
        (filter) => !filter?.startsWith(roleFilterPrefix)
      );
  
      // Add the current role.id if it's set and not zero
      if (role?.id !== 0) {
        selectedFiltersIds?.filter(
          (filter) => !filter?.startsWith(roleFilterPrefix)
        )?.push(`${roleFilterPrefix}${role?.id}`);
      }
    // if (role.id !== 0) {
    //   if (!filterMap.has(16)) {
    //     filterMap.set(16, new Set([role.id]));
    //   } else {
    //     filterMap.get(16).add(role.id);
    //   }
    // }
    await axiosGetService(
      `api/sdmactivity/getactivity/${solutionMethodId}/${id}/${complexity?.id ? complexity?.id : defaultComplexity?.id }/${selectedFiltersIds?.length > 0 ? `${selectedFiltersIds.join("|")}` : ((userActivityFilter?.length > 0 ? userActivityFilter[0].filters : 0))
      }/${searchInput.trim().length > 0
        ? encodeURIComponent(searchInput.trim())
        : 0
      }/${phaseHashtags.length > 0 ? encodedHashtags.toString() : 0}`
    ).then((res) => {
      const dataFilter =
        res?.data === null
          ? []
          : res?.data?.map((item) => ({
            id: item.activityIdentifier,
            responsible: item.responsible,
            accountable: item.accountable,
            consulted: item.consulted,
            informed: item.informed,
            activityId: item.activityId,
          }));
      if (type === "parentPhase") {
        setValidDeliveryActivities(dataFilter);
      } else {
        setValidActivities(dataFilter);
      }
      setShowSpinner(false);
      if (phaseHashtags.length > 0 || searchInput.trim().length > 0) {
        track(1, 1207, "Job Aides Landing Page", "Job Aides", {
          hashtag: phaseHashtags.length > 0 ? phaseHashtags.toString() : null,
          title: searchInput.trim().length > 0 ? searchInput : null,
        });
      }
    });}
  };

  const getDynamicFilterData = async (dynamicFilter = dynamicFilterTag) => {
    const filterMap = new Map();
    const filterIds = Object.keys(dynamicFilter);
    for (const id of filterIds) {
      const artifactTypeId = Number(id);
    const selectedTagIds = dynamicFilter[artifactTypeId]?.selectedTagId || [];

    if (selectedTagIds.length > 0) {
      if (!filterMap.has(artifactTypeId)) {
        filterMap.set(artifactTypeId, new Set(selectedTagIds));
      } else {
        const existingTags = filterMap.get(artifactTypeId);
        selectedTagIds.forEach((tagId) => existingTags.add(tagId));
      }
    }
    }
    if (role?.id !== 0) {
      if (!filterMap?.has(16)) {
        filterMap?.set(16, new Set([role?.id]));
      } else {
        filterMap?.get(16).add(role?.id);
      }
    }
  const selectedFiltersIds = Array.from(filterMap.entries()).map(
    ([artifactTypeId, tagSet]) => `${artifactTypeId}-${Array.from(tagSet).join(",")}`
  );
    await axiosGetService(
      `api/sdmartifactmaster/getdynamicfiltersbypagetype?pageTypeId=${showMatrixView ? 17 : 1
      }&isFilter=true&artifactId=${showMatrixView
        ? `${solutionMethodId}`
        : parentId && parentPhase.id
          ? `${[solutionMethodId, parentId].filter((val) => val).toString()}-${[
            phase.id,
            parentPhase.id,
          ]
            .filter((val) => val)
            .toString()}`
          : `${solutionMethodId}-${phase?.id ? phase?.id : 0}`
      }&filterIds=${selectedFiltersIds?.length > 0 ? selectedFiltersIds.join("|") : (userActivityFilter?.length > 0 ? userActivityFilter[0].filters : 0)
      }&complexityIds=${complexity?.id ? complexity?.id : defaultComplexity?.id}&searchValue=0`
    ).then((response) => {
      let data = Array.isArray(response?.data) ? response.data : []; 
      const nameMapping = {};
      const filterTag = {};
      const filterTagMapping = {};
      const serachFilterData = {};
      let searchText = {};
       data = response?.data?.map((item) => {
        return {
          artifactTypeId: item.artifactTypeId,
          artifactTypeName: item.artifactTypeName,
          artifactData: item.artifactData,
        };
      });
      for (const id in data) {
        nameMapping[data[id].artifactTypeId] =
          dynamicFilterSearchEnable[data[id].artifactTypeId];
        filterTag[data[id].artifactTypeId] = {
          artifactTypeId: data[id].artifactTypeId,
          artifactTypeName: data[id].artifactTypeName,
          selectedTagId: [],
        };
        const tag = data[id].artifactData;
        let artifactTag = {};
        for (const tagId in tag) {
          artifactTag = {
            ...artifactTag,
            [tag[tagId].id]: tag[tagId].name,
          };
        }
        filterTagMapping[data[id].artifactTypeId] = artifactTag;
        searchText = {
          ...searchText,
          [data[id].artifactTypeId]:
            dynamicSearchText[data[id].artifactTypeId] ?? "",
        };
        serachFilterData[data[id].artifactTypeId] = dynamicSearchText[
          data[id].artifactTypeId
        ]
          ? tag.filter((item) =>
            item.name
              .toLowerCase()
              .includes(
                dynamicSearchText[data[id].artifactTypeId].toLowerCase()
              )
          )
          : [];
      }
      setDynamicFilterSearchResult({ ...serachFilterData });
      setDynamicSearchText({ ...searchText });
      setDynamicFilterTagMapping({ ...filterTagMapping });
      setDynamicFilterSearchEnable({ ...nameMapping });
      setDynamicFilterData([...data]);
  if(showMatrixView || pagetype === "non-realized"){
    // Step 4: Handle userActivityFilter mapping
const newDynamicFilterTag = { ...dynamicFilterTag };


if (userActivityFilter?.length > 0 && userActivityFilter[0]?.filters) {
  setRoleId(0);

  // Parse filters string, e.g., "2-1400,1400,1338|15-7|25-8|75-2"
  const userFilterMappings = userActivityFilter[0]?.filters.split('|').map((filter) => {
    const [artifactTypeId, selectedTagIds] = filter.split('-');
    return {
      artifactTypeId: Number(artifactTypeId),
      selectedTagIds: selectedTagIds ? selectedTagIds?.split(',').map(Number) : [],
    };
  });
 
  
  const tagNameMapping = {};
  roleTag?.forEach((role) => {
    tagNameMapping[role.id] = role.name;
  });

  // Extract the role only once if artifactTypeId is 16
  const roleMapping = userFilterMappings?.find(({ artifactTypeId }) => artifactTypeId === 16);

  if (roleMapping) {
    const selectedId = roleMapping?.selectedTagIds[0];
    setRoleId(selectedId);
    setSelectedRoleId(selectedId);
   
    const selectedRole = totalRoles?.filter((item) => item.id === selectedId);
    setSelectedRoles(selectedRole[0]?.name);
  }
  // Update newDynamicFilterTag based on userFilterMappings
  userFilterMappings?.forEach(({ artifactTypeId, selectedTagIds }) => {
    // Skip processing for artifactTypeId 16
    if (artifactTypeId === 16) {
      return;
    }
  
    const artifactTypeData = data?.find((d) => d.artifactTypeId === artifactTypeId);
  
    // Ensure artifactTypeId exists in newDynamicFilterTag
    if (artifactTypeData) {
      newDynamicFilterTag[artifactTypeId] = {
        artifactTypeId,
        artifactTypeName: artifactTypeData.artifactTypeName,
        selectedTagId: selectedTagIds,
      };
    }
  });
  
}
else {
  if (userRoleTodefault === 1) {
    setRoleId(0);
   
  }}


// Step 5: Update dynamicFilterTag if there are changes
if (JSON.stringify(dynamicFilterTag) !== JSON.stringify(newDynamicFilterTag)) {
  setDynamicFilterTag(newDynamicFilterTag);
}
  }
  else{
 
    setRoleId(0);
    
    const newDynamicFilter = { ...dynamicFilter };
    for (const artifactTypeId in newDynamicFilter) {
      const { artifactData } =
        data.find(({ artifactTypeId: id }) => id == artifactTypeId) ?? [];
      newDynamicFilter[artifactTypeId].selectedTagId = newDynamicFilter[
        artifactTypeId
      ].selectedTagId.filter((tagId) =>
        artifactData.map(({ id: itemId }) => itemId).includes(tagId)
      );
    }
    if (
      JSON.stringify(dynamicFilterTag) !== JSON.stringify(newDynamicFilter)
    ) {
      setDynamicFilterTag(newDynamicFilter);
    }
  }
     
    });
  };

  const searchForArtifact = (searchText, artifactTypeId, artifactData) => {
    if (searchText.trim().length > 0) {
      const filteredRoles = artifactData.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setDynamicFilterSearchResult((prevState) => ({
        ...prevState,
        [artifactTypeId]: filteredRoles,
      }));
    }
    setDynamicSearchText((prevState) => ({
      ...prevState,
      [artifactTypeId]: searchText.trim().length > 0 ? searchText : "",
    }));
  };

  useEffect(() => {
    if(complexity.id !== '' && pagetype !== "non-realized"){
      getDynamicFilterData();
    }
  }, [solutionMethodId, phase.id, parentPhase.id, complexity.id]);

  useEffect(() => {
if(complexity.id !== '' && pagetype === "non-realized" && phase?.id){
  getDynamicFilterData();

}
  }, [phase.id, parentPhase.id,solutionMethodId,userActivityFilter]);
  useEffect(() => {
    if(complexity.id !== '' && pagetype !== "non-realized"){
      getDynamicFilterData();
    }
  }, [solutionMethodId, phase.id, parentPhase.id, complexity.id]);

  const handleApplyFilters = async () => {
  if(onButtonClick){
    onButtonClick();}
    setUserActivityFilter([]);
    setApplyFilterTrigger(prevState => !prevState);
    if(pagetype === "non-realized"){
      track(
        1,
        2113,
        phase?.id,
        phase?.title,
      ); }
    };

    const setDefaultFilter = async (e) => {
      // setUserActivityFilter([]);
if(onButtonClick){
      onButtonClick();
}
if(pagetype === "non-realized"){
      setNonrealizedvalue(false);}
      // setShowFilters(false);
      e.preventDefault();
      
      try {
        const selectedFiltersIds = [];
      const filterIds = Object?.keys(dynamicFilterTag);
      for (const id of filterIds) {
        if (dynamicFilterTag[Number(id)]?.selectedTagId?.length > 0) {
          selectedFiltersIds?.push(
            `${Number(id)}` +
              '-' +
              dynamicFilterTag[Number(id)]?.selectedTagId?.toString()
          );
        }
      }
      // Remove any existing `16-<id>` entries from selectedFiltersIds
      const roleFilterPrefix = '16-';
      const updatedFilters = selectedFiltersIds?.filter(
        (filter) => !filter?.startsWith(roleFilterPrefix)
      );
  
      // Add the current role.id if it's set and not zero
      if (role?.id !== 0) {
        updatedFilters?.push(`${roleFilterPrefix}${role?.id}`);
      }
    
        const postData = {
          "pageTypeId":showMatrixView ? 17 : 1,
          "filters": updatedFilters?.length > 0 ? updatedFilters.join('|') : 0,
          "complexities": complexity?.id,
          // "userSettingType": "1",
          // "IsProjectDelivery":realizeValueToggler === 2 ? true : false,
          "SolutionMethodId": solutionMethodId,
          "PhaseId": showMatrixView ? 0 : phase?.id,
        };
        await axiosPostService(`/api/sdmusersettings/insertusersettings`, postData).then((response) => {
        if (response.data) {
        //   setShowFilters(!showFilters);
        if(pagetype === "non-realized"){
        setNonrealizedvalue(false);}
        //   setDynamicFilterTag(response.data);
          toast.info('You have successfully saved filters.', {
          position: 'top-right',
          });
        } else {
          toast.error(response.data.errorMessage, {
          position: 'top-right',
          });
        }
        })
      } catch (e) {
      };
      if(complexity.id !== ''){

        if(userActivityFilter?.length == 0){
      await getDynamicFilterData(dynamicFilterTag); 
      if(pagetype === "non-realized"){

        if (phase.id !== null) {
         await   getActivityAndRaciDataForNonRealized("phase",dynamicFilterTag);
        }
        if (parentPhase.id !== null) {
         await   getActivityAndRaciDataForNonRealized("parentPhase",dynamicFilterTag);
        }}
      }  
      }
      setApplyFilterTrigger(prevState => !prevState);
      if(pagetype === "non-realized"){
        track(
          1,
          2114,
          phase?.id,
          phase?.title,
        ); }
      
      }
  
  useEffect(() => {
    const dynamicRefValue = Object.values(dynamicFilterSearchEnable);
    if (dynamicRefValue.includes(true) && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [dynamicFilterSearchEnable]);

  useEffect(() => {
    if (showMatrixView || pagetype === "non-realized") {
      setRole(defaultRole);

      const selectedRole = totalRoles?.filter((item) => item.id === roleId);
      if (selectedRole?.length > 0) {
        setRole(selectedRole[0]);
        
      }
    }
  }, [roleId,totalRoles]);

  useEffect(() => {
    if(complexity.id !== '' && pagetype !== "non-realized"){
    getDynamicFilterData();
    }
  }, [applyfilterTrigger]);
  // onload getComplexities, Roles and complexityActivities
  // useEffect(() => {
  //   getComplexities();
  // }, [
  //   parentPhase.id,
  //   phase.id,
  //   showMatrixView,
  //   phaseCreated,
  //   updateComplexityFilter,
  // ]);

  useEffect(() => {
    if (!showMatrixView && pagetype !== "non-realized") {
      const None = { name: "None", id: defaultRole.id, type: "option" };
      const parsedRoles = roles.map((role) => JSON.parse(role));
      const parsedDeliveryRoles = deliveryRoles.map((role) => JSON.parse(role));
      if (parsedDeliveryRoles.length !== 0 || parsedRoles.length !== 0) {
        setTotalRoles([None, ...parsedRoles, ...parsedDeliveryRoles]);
      }
    }
  }, [roles, deliveryRoles]);

  useEffect(() => {
    if (!showMatrixView && pagetype !== "non-realized") {
      const selectedRole = totalRoles.filter((item) => item.name === role.name);
      if (
        (roles.length !== 0 || phase.id === null) &&
        (deliveryRoles.length !== 0 || parentPhase.id === null) &&
        selectedRole.length === 0 &&
        totalRoles.length !== 0
      ) {
        setRole(defaultRole);
        setSelectedRoles(defaultRole.name);
      }
    }
  }, [totalRoles]);

  useEffect(() => {
setRole(defaultRole);

  }, []);

  useEffect(() => {
    const totalValidActivities = [
      ...validDeliveryActivities,
      ...validActivities,
    ];
    setComplexityValidActivities(totalValidActivities);
  }, [validActivities, validDeliveryActivities]);

  const [loadgetActivityAndRaciData, setgetActivityAndRaciData] = useState(0);
  
  useEffect(() => {
    let phaseTimeOutId;
    let parentPhaseTimeOutId;
    if(complexity.id !==''){
      if (!showMatrixView || pagetype !== "non-realized") {
        if (phase.id !== null) {
          phaseTimeOutId = setTimeout(() => {
            getActivityAndRaciData("phase");
          }, 1000);
  
          if (phase.id !== loadgetActivityAndRaciData &&
            dynamicFilterData.some(({ artifactTypeId }) => artifactTypeId === 16)) {
            getRoles(phase.id, "phase");
            // getActivityAndRaciData("phase");
            setgetActivityAndRaciData(phase.id);
          }
        }
        if (parentPhase.id !== null) {
          parentPhaseTimeOutId = setTimeout(() => {
            getActivityAndRaciData("parentPhase");
          }, 1000);
  
          if (parentPhase.id !== loadgetActivityAndRaciData &&
            dynamicFilterData.some(({ artifactTypeId }) => artifactTypeId === 16)) {
            getRoles(parentPhase.id, "parentPhase");
            // getActivityAndRaciData("parentPhase");
            setgetActivityAndRaciData(parentPhase.id);
          }
        }
      }
      else {
        if (phase.id !== null) {
          phaseTimeOutId = setTimeout(() => {
            getActivityAndRaciData("phase");
          }, 1000);
        }
        if (parentPhase.id !== null) {
          parentPhaseTimeOutId = setTimeout(() => {
            getActivityAndRaciData("parentPhase");
          }, 1000);
        }
      }
    }
     return () => {
      clearTimeout(phaseTimeOutId);
      clearTimeout(parentPhaseTimeOutId);
    };
  }, [complexity.id, parentPhase.id, phase.id, role, dynamicFilterData, dynamicFilterTag, phaseHashtags, searchInput,applyfilterTrigger]);


  useEffect(() => {
    let phaseTimeOutId;
    let parentPhaseTimeOutId;
    if(complexity.id !==''){
     
     
        if (phase.id !== null) {
          phaseTimeOutId = setTimeout(() => {
            getActivityAndRaciDataForNonRealized("phase");
          }, 1000);
        }
        if (parentPhase.id !== null) {
          parentPhaseTimeOutId = setTimeout(() => {
            getActivityAndRaciDataForNonRealized("parentPhase");
          }, 1000);
        }
      }
    
     return () => {
      clearTimeout(phaseTimeOutId);
      clearTimeout(parentPhaseTimeOutId);
    };
  }, [ parentPhase.id, phase.id, phaseHashtags, searchInput,userActivityFilter,solutionMethodId]);

  useEffect(() => {
    let phaseTimeOutId;
    let parentPhaseTimeOutId;
    if(filterCleared){
     
     
        if (phase.id !== null) {
          phaseTimeOutId = setTimeout(() => {
            getActivityAndRaciDataForNonRealized("phase");
          }, 1000);
        }
        if (parentPhase.id !== null) {
          parentPhaseTimeOutId = setTimeout(() => {
            getActivityAndRaciDataForNonRealized("parentPhase");
          }, 1000);
        }
      }
    
     return () => {
      clearTimeout(phaseTimeOutId);
      clearTimeout(parentPhaseTimeOutId);
    };
  }, [ filterCleared]);



  useEffect(() => {
    if (showMatrixView) {
      getAllRoles();
    }
  }, [showMatrixView]);

  useEffect(() => {
    const staticRefValue = Object.values(enableSearch);

    if (staticRefValue.includes(true) && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [enableSearch]);
  return (
    <div
      id="filtersRightPanel"
      className={`filtersRightPanel 
        ${pagetype === "non-realized" ? "filtersRightPanelNonRealized" : ""} 
        ${pagetype === "non-realized" && !nonrealizedvalue ? "hideFiltersRightPanel" : ""} 
        ${pagetype !== "non-realized" && !showFilters ? "hideFiltersRightPanel" : ""}`
      }
      // className={(pagetype === "non-realized" ? !nonrealizedvalue : !showFilters) ? "hideFiltersRightPanel" : ""}
    >
      <div className="filterPanelHeader">
        <h5>Filters</h5>
        <Icon
          className="searchIcon"
          iconName="cancel"
          onClick={() => {
            setShowFilters(false);
            if(pagetype === "non-realized"){
            setNonrealizedvalue(false);
            setFilterCleared(false);
          }
          }}
          onKeyPress={(e) => {
            if (e.which === 13) {
              setShowFilters(false);
              if(pagetype === "non-realized"){
              setNonrealizedvalue(false);
              setFilterCleared(false);
            }
            }
          }}
          tabIndex={0}
          role="button"
          aria-label="Close Filters"
        />
      </div>
      <div className={ showMatrixView  ?  "filtersPanelBodyLatest":  pagetype === "non-realized" ? "filtersPanelBodyLatestNonRealized" : "filtersPanelBody" } >
        {isEnableComplexity && <div className="filtersCategoryPanel">
          <h6>Complexity</h6>
          <ComplexityFilter
            className="complexityFilter"
            data={complexities}
            setValue={setComplexity}
            value={complexity}
            enableDropdown={
              phase.enableComplexity ||
              (phase.id === null && parentPhase.enableComplexity)
            }
            setSelectedComplexity={setSelectedComplexity}
            setSelectedComplexityId={setSelectedComplexityId}
          />
        </div>}
        {dynamicFilterData.map((item) => {
          if (item.artifactTypeId === 16)
            return (
              <div className="filtersCategoryPanel" key={item.artifactTypeId}>
                {enableSearch["Roles"] ? (
                  <div className="rolesSearch">
                    <input
                      type="text"
                      placeholder="Search for a role"
                      value={roleSearchValue}
                      onChange={(e) => {
                        setRoleSearchValue(e.target.value);
                        searchRoles(e.target.value);
                      }}
                      ref={searchInputRef}
                    />
                    <Icon
                      className="searchIcon"
                      iconName="cancel"
                      onClick={() => {
                        setEnableSearch((prev) => ({ ...prev, Roles: false }));
                        setRoleSearchValue("");
                      }}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          setEnableSearch((prev) => ({
                            ...prev,
                            Roles: false,
                          }));
                          setRoleSearchValue("");
                        }
                      }}
                      tabIndex={0}
                      role="button"
                      aria-label="Cancel Search Filter"
                    />
                  </div>
                ) : (
                  <div className="filtersearch">
                    <h6>Roles</h6>
                    <span
                      className="searchIcon"
                      onClick={() =>
                        setEnableSearch((prev) => ({ ...prev, Roles: true }))
                      }
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          setEnableSearch((prev) => ({ ...prev, Roles: true }));
                        }
                      }}
                      tabIndex={0}
                      role="button"
                      aria-label="Click to enable Roles search"
                    >
                      <Icon iconName="search" className="searchIcon" />
                    </span>
                  </div>
                )}

                <RolesFilter
                  className="roleFilter"
                  data={roleSearchValue ? totalFilteredRoles : totalRoles}
                  setValue={setRole}
                  value={role}
                  enableDropdown={
                    phase.enableComplexity ||
                    (phase.id === null && parentPhase.enableComplexity)
                  }
                  setSelectedRoles={setSelectedRoles}
                  selectedRoles={selectedRoles}
                  setSelectedRoleId={setSelectedRoleId}
                />
              </div>
            );
          else {
            return (
              <div className="filtersCategoryPanel" key={item.artifactTypeId}>
                {item.artifactData.length > 5 ? (
                  <>
                    {dynamicFilterSearchEnable[item.artifactTypeId] ? (
                      <div className="rolesSearch">
                        <input
                          type="text"
                          placeholder={`Search for  ${item.artifactTypeName}`}
                          value={dynamicSearchText[item.artifactTypeId]}
                          onChange={(e) => {
                            e.persist();
                            searchForArtifact(
                              e.target.value,
                              item.artifactTypeId,
                              item.artifactData
                            );
                          }}
                          ref={searchInputRef}
                        />
                        <Icon
                          className="searchIcon"
                          iconName="cancel"
                          onClick={() => {
                            setDynamicFilterSearchEnable({
                              ...dynamicFilterSearchEnable,
                              [item.artifactTypeId]: false,
                            });
                            setDynamicSearchText({
                              ...dynamicSearchText,
                              [item.artifactTypeId]: "",
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              setDynamicFilterSearchEnable({
                                ...dynamicFilterSearchEnable,
                                [item.artifactTypeId]: false,
                              });
                              setDynamicSearchText({
                                ...dynamicSearchText,
                                [item.artifactTypeId]: "",
                              });
                            }
                          }}
                          tabIndex={0}
                          role="button"
                          aria-label="Cancel Search Filter"
                        />
                      </div>
                    ) : (
                      <div className="filtersearch">
                        <h6>{item.artifactTypeName}</h6>
                        <span
                          className="searchIcon"
                          onClick={() =>
                            setDynamicFilterSearchEnable({
                              ...dynamicFilterSearchEnable,
                              [item.artifactTypeId]: true,
                            })
                          }
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              setDynamicFilterSearchEnable({
                                ...dynamicFilterSearchEnable,
                                [item.artifactTypeId]: true,
                              });
                            }
                          }}
                          tabIndex={0}
                          role="button"
                          aria-label={`click to search ${item.artifactTypeName} `}
                        >
                          <Icon iconName="search" className="searchIcon" />
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <h6>{item.artifactTypeName}</h6>
                )}
                <DynamicFilter
                  data={
                    dynamicSearchText[item.artifactTypeId]
                      ? dynamicFilterSearchResult[item.artifactTypeId]
                      : item.artifactData
                  }
                  value={dynamicFilterTag}
                  artifactTypeName={item.artifactTypeName}
                  setValue={setDynamicFilterTag}
                  artifactTypeId={item.artifactTypeId}
                  getDynamicFilterData={getDynamicFilterData}
                />
              </div>
            );
          }
        })}
      </div>
     {showMatrixView || pagetype === "non-realized" ? <div className="filterPanelFooter">
          <button
            type="button"
            className="btn btn-primary"
            tabIndex={0}
            onClick={handleApplyFilters}
          >
            Apply
          </button>
          <button
            type="button"
            className="btn btn-default"
            tabIndex={0}
            onClick={setDefaultFilter}
          >
            Set as Default
          </button>
        </div> : null}
    </div>
  );
}

export default Complexity;
