import React, { useState } from 'react';
import { Icon } from '@fluentui/react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';
import { getDate } from '../../helpers/Validation';

const PreviewModal = ({
	setShowPreviewModal,
	hashTags,
	topicVersion,
	title,
	content,
	setShowPublishModal,
	relatedContent,
	topicOwnerInfo,
	businessRelatedContent,
	sdmContentErrorMessage
}) => {

	const [activeContent, setActiveContent] = useState(1)
	const hasValidContent = businessRelatedContent.some(
		(item) => item.name?.trim() && item.url?.trim()
	);
	const hasValidRelatedContent = relatedContent.some(
		(item) => item.title?.trim() && item.url?.trim()
	);
	return (
		<Modal
			className="PreviewAndSubmitPopUp"
			show={true}
			onHide={() => {
				/* Not calling any function to close modal*/
			}}
			dialogClassName="NewPreviewAndSubmitPopUp"
		>
			<Modal.Header>
				<Modal.Title>
					<div className="topicOwnerPreviewPopupTitle">
						<span>Preview</span>

					</div>

				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='topicLandingMainContainer mt-0'>
					<div className='topicsCrowdSourcingContainer mt-0'>
						<div className='topicsLeftAndMainContainer NewPreviewContainer'>
							<div className="topicsRightMainContainer ml-0">
								<div class="topicsDescriptionDetails" id="topicTextDescription">
									<div className="topicDescTitlePart">

										<>
											<span className="topicVersion">
												Document Version: {topicVersion}
												,&nbsp;&nbsp;
												{`${getDate()} `}
											</span>
											<div className="discardAndPreviewButton mr-0">
												<Button
													title="Cancel"
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															setShowPreviewModal(false);
														}
													}}
													onClick={() => {
														setShowPreviewModal(false);
													}}
													className="activityDiscardButton"
												>
													Cancel
												</Button>
												<Button
													title="Publish"
													tabIndex={0}
													onClick={() => {
														setShowPublishModal(true);
														setShowPreviewModal(false);
													}}
													className="activityPreviewButton"
												>
													Publish
												</Button>
											</div>
										</>

									</div>

									<div className="topicDescriptionDivContainer">
										<div className={"topicPolygonDescriptionDiv topicPolygonDescriptionPreviewDiv _PolygonBorder"}>
											<div className="topicsOwner">
												<h1 className={"topicTitle topicTitleNewView polygonTopicTitle"}>
													{title.replace(/_.*/, '')}
												</h1>
											</div>
											{/* <div className="existingHashTagsOfTopic _existingHashTagsOfActivity">
												{hashTags &&
													hashTags.map((name) => (
														<span
															className="mr-1 hashTagsColor"
															key={name}


															tabIndex={0}
														>
															{name}
														</span>
													))}

											</div> */}
											<div
												className="desc ck-content polygonPreviewDesc"
												id="previewDescription"
												dangerouslySetInnerHTML={{
													__html: HtmlSanitizer(content),
												}}
											/>

										</div>

										<div className="topicRightDescriptionDiv _topicRightPreviewDescriptionDiv ">
											<div className="">

												{topicOwnerInfo.mail ? (
													<div
														className={
															"topicsOwnerDetails topicsOwnerDetailsNewView polygonLatestPreview"

														}
													>
														<img
															src={topicOwnerInfo.photo}
															alt="topicOwnerImage"
														/>
														<div className="topicsOwnerPersonalDetails" tabIndex={0} >
															<p>Owner</p>
															<a href={`mailto:${topicOwnerInfo.mail}`} role="link" aria-label={`Opens in new tab ${topicOwnerInfo.firstName + ' ' + topicOwnerInfo.surname}`}>
																<span className="alternativeOfh6">
																	{topicOwnerInfo.firstName +
																		" " +
																		topicOwnerInfo.surname}
																</span>
															</a>
														</div>
													</div>
												) : (
													<></>
												)}
												<div className=''>
													<div className={"topicAssociateContentPanel mt-3"}>
														<div className='topicsContentDiv'>
															<div className='topicsAssociateContentDiv'>
																<h6>Associated Content</h6>
																<div className='topicAssociatedContent'>
																	{relatedContent.length > 0 && hasValidRelatedContent? (
																		<>
																			{relatedContent?.map((item) => 
																			item.title?.trim() && item.url?.trim() ? 
																			(
																				<a href={item.url} className='topicAssociateContentAnchor' key={item.id} target="_blank">
																					<span>
																						{item.title}
																					</span>
																				</a> 
																			): null)}
																		</>
																	) : (
																		<p className="noRelatedContentMessage PreviewContentLatest">
																			{sdmContentErrorMessage}
																		</p>
																	)}
																</div>
															</div>
															<div className='topicsAssociateContentDiv'>
																<h6>Business Rule</h6>

																<div className='topicAssociatedContent'>
																	{businessRelatedContent.length > 0 && hasValidContent ? (
																		<>
																			{businessRelatedContent.map((item) =>
																				item.name?.trim() && item.url?.trim() ? (
																					<a href={item.url} className='topicAssociateContentAnchor' key={item.id} target="_blank">
																						<span className='topicAssociateContentSpan'>{item.name}</span>
																					</a>
																				) : null
																			)}
																		</>
																	) : (
																		<p className="noRelatedContentMessage PreviewContentLatest">{sdmContentErrorMessage}</p>
																	)}
																</div>

															</div>

														</div>

													</div>
												</div>

											</div>

										</div>
									</div>



								</div>




							</div></div></div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default PreviewModal;
