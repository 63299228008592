import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import queryString from 'query-string';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useAxios } from '../../context/AxiosContext';
import './DiscardPopUp.css';

function DiscardPopUp(props) {
	const [modalShow, setModalShow] = useState(true);
	const { projectTypeId, solutionMethodId, phaseId, parentPhaseId } = useParams();
	const activityIdentifier = queryString.parse(window.location.search).identifier;
	const [message, setMessage] = useState(null);
	const { axiosGetService } = useAxios();

	const getMessage = async () => {
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=discardPopUpMessage&title=0`
		).then((response) => {
			setMessage(response.data);
		});
	};
	const closeModal = () => {
		setModalShow(false);
		props.setShowDiscardPopUp(false);
	};
	useEffect(() => {
		getMessage();
	}, []);

	return (
		<Modal
			aria-label='Discard Pop Up'
			className="activityDiscardPopUp"
			show={modalShow}
			onHide={() => {
				/* Not calling any function to close modal*/
			}}
			size="md"
			// aria-labelledby="discard"
			centered
		>
			<Modal.Body aria-hidden="true">
				<p>{message}</p>
				<div className="discardPopUpActivityButtons">
					<Button
						title="No"
						aria-label="No"
						className="activityDiscardPopUpCancel"
						variant="primary"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								closeModal();
							}
						}}
						onClick={() => closeModal()}
					>
						No
					</Button>
					<Link role="presentation"
					
						to={`/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}/activity/${activityIdentifier?activityIdentifier:props.activityId}?complexity=${props.complexityId}`}
					>
						<Button aria-label="Yes" title="Yes" tabIndex={-1} className="activityDiscardPopUpAccept" variant="info">
							Yes
						</Button>
					</Link>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default DiscardPopUp;
