import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link, useRouteMatch } from "react-router-dom";
import loadable from "@loadable/component";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { Prompt } from "react-router";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { useAuth } from "../../context/UserAuthContext";
import $ from "jquery";
import { useAxios } from "../../context/AxiosContext";
import { useCrowdSourcing } from "../../context/CrowdSourcingContext";
import DiscardPopUp from "../TopicsContributorCrowdSourcing/DiscardPopUp";
import PreviewAndSubmitPopUp from "./PreviewAndSubmitPopUp";
import SuccessMessagePopUp from "../TopicsContributorCrowdSourcing/SuccessPopUp";
import { getOtherUserProfile } from "../../helpers/GraphService";
import { axiosTokenService } from "../CkEditor/api/axiosBaseUrl";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import { useAppInsights } from "../../context/TelemetryContext";
import UserNotVerified from "./UserNotVerifiedPopUp";
import Breadcrumbs from "../Breadcrumbs";
import Spinner from "../../helpers/Spinner";
import ReviewedPopUp from "./ReviewedPopUp";
import HistoryPreview from "./HistoricalPreviewPopUp";
import { TabTitle } from "../../utils/GeneralFunctions";
import decryptAES256 from "../CkEditor/DecryptionComponent";
import { useConfig } from "../../context/ConfigContext";

import "../TopicsDetail/TopicsDetailPage.css";
import TemporaryCkAlert from "../CkEditor/TemporaryCkAlert";

const DescriptionCkEditor = loadable(
  () => import("../CkEditor/TopicReviewerTrackChange/Description"),
  {
    fallback: (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    ),
  }
);

const TopicsContributorCrowdSourcing = (props) => {
  const { ckEdKEY } = useConfig();
  const history = useHistory();
  const { axiosPostService, axiosGetService } = useAxios();
  const { breadcrumbs, setBreadcrumbs, setSkipNavigationId, getImageBase64 } =
    useLeftMenuCollapse();
  const { crowdSourcingAppState, setCrowdSourcingAppState } =
    useCrowdSourcing();
  const { id } = useParams();
  const { user } = useAuth();

  const CkEditorCurrentUser = user.profile.mail;
  const { track } = useAppInsights();
  const [reviewerVerified, setReviewerVerified] = useState(true);
  const [messagesType, setMessagesType] = useState({});
  const [checkNotificationClick, setCheckNotificationClick] = useState(false);
  const { showHistory, userLeaveWithoutSave } = crowdSourcingAppState;
  const [ckEditorUsers, setCkEditorUsers] = useState([]);
  const [leavingWithoutSave, setLeavingWithoutSave] = useState(true);
  const [message, setSubmitMessage] = useState(null);
  const [showDiscardPopUp, setShowDiscardPopUp] = useState(false);
  const [showPreviewAndSubmitPopUp, setShowPreviewAndSubmitPopUp] =
    useState(false);
  const [description, setDescription] = useState(null);
  const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);
  const [topicDescriptionData, setTopicDescriptionData] = useState(null);
  const [reviewedDescriptionSuggestions, setReviewedDescriptionSuggestions] =
    useState([]);
  const [licenseKey, setLicenseKey] = useState(null);
  const [contributorSuggestionsInfo, setContributorSuggestionsInfo] = useState(
    {}
  );
  const [existingHashtags, setExistingHashtags] = useState([]);
  const [ratingPoints, setRatingPoints] = useState(null);
  const [enableGamification, setEnableGamification] = useState(false);
  const [topicLockedBy, setTopicLockedBy] = useState("");
  const [lockedByUsername, setLockedByUsername] = useState("");
  const contributorInfo = [];
  const graphUserData = [];
  const userDataMap = new Map();

  const { url } = useRouteMatch();
	const [parentTopic, setParentTopic] = useState();
	const [count, setCount] = useState(4);
	const [selectedPath, setSelectedPath] = useState(0);
	const [polygonTopicsData, setPolygonTopicsData] = useState();
	const [showLinking, setLinking] = useState(true);
	const pathSegments = url.split("/");
	const selectedTopicsListId = pathSegments[pathSegments.length - 1];
	const saveNextTopic = (category) => {setSelectedPath(category)}
	const getPolygonTopic = async () => {
		try {
			if (parentTopic !== null && parentTopic !== undefined && parentTopic !== 0) {
				await axiosGetService(`api/sdmtopics/getpolygontopicbyidwithtitle/${parentTopic}`,).then((response) => {
					setPolygonTopicsData(response.data);
			})
		  }
		} catch (error) {
		  console.log("Error", error);
		}
	  }
	  const getTopicDescriptionData = async () => {
		const response = await axiosGetService(
		  `api/sdmtopics/get/${selectedTopicsListId}`
		);
		if (response !== null) {
		  if (response.data !== null) {
			setParentTopic(response.data.polygonParent);
			setCount(response.data.polygonSides);
			setSelectedPath(response.data.polygonSequence);
		  } 
		}
	  };
	  useEffect(() => {
		getTopicDescriptionData();
		getPolygonTopic();
	  }, [showPreviewAndSubmitPopUp])
	 

  const getRatingPoints = async () => {
    await axiosGetService(`api/sdmuser/gamification/getRatingPoints`).then(
      (response) => {
        setRatingPoints(response.data);
      }
    );
  };

  const checkGamificationStatus = async () => {
    await axiosGetService(`api/sdmsettings?sdmKey=EnableGamification`).then(
      (response) => {
        if (response.data[0].value === "true") {
          setEnableGamification(true);
        }
      }
    );
  };

  const updateSuggestionInfo = (suggestionInfo, suggestionsData) => {
    const id =
      suggestionInfo.split(":").length > 3
        ? suggestionInfo.split(":")[2]
        : suggestionInfo.split(":")[1];
    const type =
      suggestionInfo.split(":").length > 3
        ? suggestionInfo.split(":").slice(0, 2).join(":")
        : suggestionInfo.split(":")[0];
    const authorId =
      suggestionInfo.split(":").length > 3
        ? suggestionInfo.split(":")[3]
        : suggestionInfo.split(":")[2];
    suggestionsData[id] = {
      id: id,
      type: type,
      authorId: authorId,
      data: null,
    };
  };

  const getSuggestionsInfo = (emCollection, suggestionsData) => {
    for (let i = 0; i < emCollection?.length; i++) {
      if ($(emCollection[i]).attr("data-suggestion-end-after")) {
        const suggestionInfo = $(emCollection[i]).attr(
          "data-suggestion-end-after"
        );
        updateSuggestionInfo(suggestionInfo, suggestionsData);
      } else if ($(emCollection[i]).attr("data-suggestion-start-before")) {
        const suggestionInfo = $(emCollection[i]).attr(
          "data-suggestion-start-before"
        );
        updateSuggestionInfo(suggestionInfo, suggestionsData);
      }
    }
  };

  const getTopicData = async () => {
    const response = await axiosGetService(`api/sdmtopics/getTopic2/${id}`);
    setTopicDescriptionData(response.data);
    if (response.data.isDrafted) {
      const res = await axiosGetService(`api/sdmtopics/get/${id}`);
      setTopicLockedBy(res.data.lastDraftChangesMadeBy);
      const accessToken = await axiosTokenService();
      const userData = await getOtherUserProfile(
        accessToken,
        res.data.lastDraftChangesMadeBy
      );
      if (userData)
        setLockedByUsername(userData.firstName + " " + userData.surname);
      else setLockedByUsername(res.data.lastDraftChangesMadeBy);
    }
    setReviewerVerified(response.data.isReviewer);
    const hashtags = response.data.hashTagsList.map(({ name }) => {
      return name;
    });
    setExistingHashtags(hashtags);
    if(response?.data?.resultCalloutJson?.length > 0){
      setContributorSuggestionsInfo(response.data.resultCalloutJson);
    }
    

    // var domParser = new DOMParser();
    // var docElement = domParser.parseFromString(
    //   response.data.description,
    //   "text/html"
    // ).documentElement;
    // var emCollection = docElement.getElementsByTagName("suggestion");
    // const suggestionsData = {};
    // for (let i = 0; i < emCollection.length; i++) {
    //   const suggestionId = emCollection[i].attributes.id.value;
    //   suggestionsData[suggestionId.split(":")[0]] = {
    //     id: suggestionId.split(":")[0],
    //     type: emCollection[i].attributes["suggestion-type"].value,
    //     authorId: suggestionId.split(":")[1],
    //     data: null,
    //   };
    // }
    // emCollection = docElement.getElementsByTagName("suggestion-start");
    // for (let i = 0; i < emCollection.length; i++) {
    //   const suggestionInfo = emCollection[i].attributes.name.value;
    //   const id =
    //     suggestionInfo.split(":").length > 3
    //       ? suggestionInfo.split(":")[2]
    //       : suggestionInfo.split(":")[1];
    //   const type =
    //     suggestionInfo.split(":").length > 3
    //       ? suggestionInfo.split(":").slice(0, 2).join(":")
    //       : suggestionInfo.split(":")[0];
    //   const authorId =
    //     suggestionInfo.split(":").length > 3
    //       ? suggestionInfo.split(":")[3]
    //       : suggestionInfo.split(":")[2];
    //   suggestionsData[id] = {
    //     id: id,
    //     type: type,
    //     authorId: authorId,
    //     data: null,
    //   };
    // }
    // emCollection = docElement.getElementsByTagName("figure");
    // getSuggestionsInfo(emCollection, suggestionsData);
    // emCollection = docElement.getElementsByTagName("th");
    // getSuggestionsInfo(emCollection, suggestionsData);
    // emCollection = docElement.getElementsByTagName("td");
    // getSuggestionsInfo(emCollection, suggestionsData);
    // emCollection = docElement.getElementsByTagName("tr");
    // getSuggestionsInfo(emCollection, suggestionsData);
    // emCollection = docElement.getElementsByTagName("table");
    // getSuggestionsInfo(emCollection, suggestionsData);
    // emCollection = docElement.getElementsByTagName("tbody");
    // getSuggestionsInfo(emCollection, suggestionsData);
    // emCollection = docElement.getElementsByTagName("p");
    // getSuggestionsInfo(emCollection, suggestionsData);
    // setContributorSuggestionsInfo(suggestionsData);
  };

  if (leavingWithoutSave && !showDiscardPopUp && !userLeaveWithoutSave) {
    setCrowdSourcingAppState({
      ...crowdSourcingAppState,
      userLeaveWithoutSave: true,
    });
  }

  useEffect(() => {
    return () => {
      setCrowdSourcingAppState({
        ...crowdSourcingAppState,
        userLeaveWithoutSave: false,
      });
    };
  }, []);

  // load and set the breadcrumb
  const loadBreadcrumb = async () => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=${id}&itemTypeId=10
			&isEdit=true&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=false&documentStatus=0
			&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split(">")
        .map((item) => item.trim());
      const topicsBreadcrumb = {
        title: breadcrumbTitle[1],
        path:
          breadcrumbTitle[1] === "Job Aides"
            ? "/topics?topicType=job&selectedJobAides=2"
            : breadcrumbTitle[1] === "Readiness"
            ? "/topics?topicType=read&selectedReadiness=2"
            : "/topics",
        parent: ["home", "myContributions"],
        type: "topics",
      };
      const topicsCategoryBreadcrumb = {
        title: breadcrumbTitle[2],
        path:breadcrumbTitle[1] === "Job Aides"
        ? `/topics/${id}?topicType=job&selectedJobAides=2`
        : breadcrumbTitle[1] === "Readiness"
        ? `/topics/${id}?topicType=read&selectedReadiness=2`  
        : `/topics/${id}`,
        parent: "topics",
        type: "topicType",
      };
      // const pageTitle = {
      //   title: breadcrumbTitle[3],
      //   path:breadcrumbTitle[1] === "Job Aides"
      //   ? `/topics/${breadcrumbTitle[2].charAt(0).toLowerCase() +
      //       breadcrumbTitle[2].slice(1)
      //     }/${id}?topicType=job&selectedJobAides=${
      //       breadcrumbTitle[2].toLowerCase() === "support" ? 3 : 2
      //     }`
      //   : breadcrumbTitle[1] === "Readiness"
      //   ? `/topics/${breadcrumbTitle[2].charAt(0).toLowerCase() +
      //     breadcrumbTitle[2].slice(1)
      //   }/${id}?topicType=read&selectedReadiness=${
      //     breadcrumbTitle[2].toLowerCase() === "support" ? 3 : 2
      //   }`  
      //   : `/topics/${breadcrumbTitle[2].charAt(0).toLowerCase() +
      //       breadcrumbTitle[2].slice(1)
      //     }/${id}`,
      //   type: "pageTitle",
      //   parent: "topicType",
      // };
      const currentTopicsContentBreadcrumb = {
        title: breadcrumbTitle[3],
        parent: ["pageTitle"],
      };

      const newBreadcrumbs = breadcrumbs.slice(
        0,
        breadcrumbs.length > 1 && breadcrumbs[1].type === "myContributions"
          ? 2
          : 1
      );
      newBreadcrumbs.push(topicsBreadcrumb);
      newBreadcrumbs.push(topicsCategoryBreadcrumb);
      // newBreadcrumbs.push(pageTitle);
      newBreadcrumbs.push(currentTopicsContentBreadcrumb);
      setBreadcrumbs(newBreadcrumbs);
      TabTitle(breadcrumbTitle[3] + " - Edit Topic");
    });
  };

  const getContributor = (suggestions) => {
    for (const id in suggestions) {
      const userEmail = JSON.parse(suggestions[id]).authorId;
      if (userEmail && !userDataMap.has(userEmail)) {
        graphUserData.push(userEmail);
        userDataMap.set(userEmail, 1);
      }
    }
  };

  const getUsersInfo = async () => {
    if (topicDescriptionData.reviewerSuggestionsJson) {
      getContributor(JSON.parse(topicDescriptionData.reviewerSuggestionsJson));
    }
    if (topicDescriptionData.resultCalloutJson) {
      getContributor(topicDescriptionData.resultCalloutJson);
    }
    if (!userDataMap.has(CkEditorCurrentUser)) {
      graphUserData.push(CkEditorCurrentUser);
      userDataMap.set(CkEditorCurrentUser, 1);
    }
    const accessToken = await axiosTokenService();
    for (const id in graphUserData) {
      const userEmail = graphUserData[id];
      const userData = await getOtherUserProfile(accessToken, userEmail);
      contributorInfo.push({
        id: userData.mail,
        name: userData.firstName + " " + userData.surname,
        avatar: userData.photo,
      });
    }
    setCkEditorUsers(contributorInfo);
  };

  const getLicense = async () => {
    const response = await axiosGetService(
      `api/sdmsettings?sdmKey=CKEditorLicenseKey`
    );
    const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
  };

  const getMessage = async (title) => {
    const message = {};
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=reviewPrivilegeMessage&title=0`
    ).then((response) => {
      message["reviewPrivilegeMessage"] = response.data;
    });
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=pendingApprovalForReviewer&title=0`
    ).then((response) => {
      message["pendingApprovalForReviewer"] = response.data;
    });
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=reviewSubmissionMessage&title=${encodeURIComponent(
        title
      )}`
    ).then((response) => {
      message["reviewSubmissionMessage"] = response.data;
    });
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=noContributionMessage&title=${encodeURIComponent(
        title
      )}`
    ).then((response) => {
      message["noContributionMessage"] = response.data;
    });
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=contentUpdate&title=0`
    ).then((response) => {
      message["contentUpdate"] = response.data;
    });
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=reviewError&title=0`
    ).then((response) => {
      message["reviewError"] = response.data;
    });
    setMessagesType(message);
  };

	const [topicOwnerInfo, setTopicOwnerInfo] = useState({});
	const [topicRelatedContent, setTopicRelatedContent] = useState([]);
	const [topicBusinessRelatedContent, setTopicBusinessRelatedContent] = useState([]);
	const [sdmContentErrorMessage, setSDMContentErrorMessage] = useState('');

	const getSDMContentErrorMessage = async () => {
		try {
			const response = await axiosGetService(`api/sdmsettings?sdmKey=Topic_EmptyContentMessage`);
			setSDMContentErrorMessage(response?.data[0]?.value || '');
		} catch (error) {
			console.error('Error fetching SDM Content Message:', error);
		}
	};
	
	const getBusinessRelatedContent = async () => {
		let api = `api/sdmbusinessrules/getassociatedbusinessRulesbyArtifactId/${id}/10/0/${false}`
        await axiosGetService(api).then(
            (response) => {
				setTopicBusinessRelatedContent(response?.data);
            })
    };
	
	const getRelatedContent = async () => {
		await axiosGetService(`api/sdmtopics/gettopicbyid/${id}`).then((response) => {
			const existingRelatedContent = response.data.topicRelatedContentList.map((item) => {
				return {
					title: item.title,
					url: item.url,
				};
			});
			setTopicRelatedContent([...existingRelatedContent, { title: "", url: "" }]);
		});
	};

	useEffect(() => {
		getBusinessRelatedContent();
		getRelatedContent();
		getSDMContentErrorMessage();
	}, [])

  useEffect(() => {
    getLicense();
    getTopicData();
    getRatingPoints();
    setSkipNavigationId("topicsInnerContent");
  }, []);

  useEffect(() => {
    if (topicDescriptionData) {
      getUsersInfo();
    }
  }, [topicDescriptionData]);

  useEffect(() => {
    if (checkNotificationClick) {
      window.location.reload();
    }
    setCheckNotificationClick(true);
  }, [id]);

  useEffect(() => {
    if (topicDescriptionData) {
      track(2, 141, topicDescriptionData.topicId, topicDescriptionData.title);
      getMessage(topicDescriptionData.title);
    }
  }, [topicDescriptionData]);

  useEffect(() => {
    loadBreadcrumb();
  }, []);

  useEffect(() => {
    if (topicDescriptionData) {
      setDescription(topicDescriptionData.description);
    }
    if (topicDescriptionData && topicDescriptionData.resultCalloutJson) {
      setDescriptionSuggestions(topicDescriptionData.resultCalloutJson);
    }
  }, [topicDescriptionData]);

  useEffect(() => {
    checkGamificationStatus();
  }, []);

  useEffect(() => {
    const suggestionItems = document.getElementsByClassName(
      "ck-suggestion-marker"
    );
    for (let i = 0; i < suggestionItems?.length; i++) {
      if (suggestionItems[i].attributes["data-suggestion"]) {
        const suggestionId =
          suggestionItems[i].attributes["data-suggestion"].value;
        const suggestionText = suggestionItems[i].innerHTML;
        if (
          suggestionId in contributorSuggestionsInfo &&
          !contributorSuggestionsInfo[suggestionId].data
        ) {
          setContributorSuggestionsInfo((prevState) => ({
            ...prevState,
            [suggestionId]: {
              ...contributorSuggestionsInfo[suggestionId],
              data: suggestionText,
            },
          }));
        }
      }
    }
  });

  if (!topicDescriptionData || ckEditorUsers?.length < 1 || !licenseKey) {
    return (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    );
  }

  if (!reviewerVerified) {
    return <UserNotVerified message={messagesType["reviewPrivilegeMessage"]} />;
  }

  if (!topicDescriptionData.isEditable && topicDescriptionData.stateId === 0) {
    return (
      <Modal
        className="activityDiscardPopUp contributorPopUp"
        show={true}
        onHide={() => {
          /* Not calling any function to close modal*/
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <p>
            This topic is not enabled for crowd sourcing based on the author's
            request. Please reach out to the author if you want to suggest
            changes to the topic
          </p>
          <div className="discardPopUpActivityButtons">
            <Link to={`/`}>
              <Button
                tabIndex={0}
                className="activityDiscardPopUpAccept"
                variant="info"
              >
                ok
              </Button>
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  if (topicDescriptionData.isDrafted) {
    return (
      <Modal
        className="activityDiscardPopUp contributorPopUp"
        show={true}
        onHide={() => {
          /* Not calling any function to close modal*/
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <p>
            You can not edit this content now. Content is being updated by{" "}
            <a
              target="_blank"
              href={`https://teams.microsoft.com/l/chat/0/0?users=${topicLockedBy}`}
            >
              {lockedByUsername}
            </a>
            {" ."} Please check later for editing.
          </p>
          <div className="discardPopUpActivityButtons">
            <Link to={`/`}>
              <Button
                tabIndex={0}
                className="activityDiscardPopUpAccept"
                variant="info"
              >
                ok
              </Button>
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <div className="topicLandingMainContainer">
      <div className="topicsLandingPage">
        <div className="topicFixedPart">
          <Breadcrumbs />
          <div className="topicDescTitlePart">
            <div className="topicTitlePartDiv">
              <span className="topicVersion">
                Document Version: {topicDescriptionData.topicVersion}
                ,&nbsp;&nbsp;
                {`${topicDescriptionData.modifiedDate} `}
              </span>
              <div className="discardAndPreviewButton">
                <Button
                  title="Discard"
                  className="activityDiscardButton"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      setShowDiscardPopUp(true);
                      track(
                        1,
                        144,
                        topicDescriptionData.topicId,
                        topicDescriptionData.title
                      );
                    }
                  }}
                  onClick={() => {
                    setShowDiscardPopUp(true);
                    track(
                      1,
                      144,
                      topicDescriptionData.topicId,
                      topicDescriptionData.title
                    );
                  }}
                >
                  Discard
                </Button>
                <Button
                  className="activityPreviewButton"
                  title="Preview &amp; Submit"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      setShowPreviewAndSubmitPopUp(true);
                      track(
                        1,
                        145,
                        topicDescriptionData.topicId,
                        topicDescriptionData.title
                      );
                    }
                  }}
                  onClick={() => {
                    setShowPreviewAndSubmitPopUp(true);
                    track(
                      1,
                      145,
                      topicDescriptionData.topicId,
                      topicDescriptionData.title
                    );
                  }}
                >
                  Preview &amp; Submit
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="topicsCrowdSourcingContainer topicsCrowdSourcingContent">
          <div class="topicsDescriptionDetails topicEditPage">
            <div className="topicDescriptionDiv" id="topicsInnerContent">
              <h4 className="topicTitle">{topicDescriptionData.title}</h4>
              <div class="existingHashTagsOfTopic">
                {topicDescriptionData.hashTagsList.map(({ name }) => (
                  <span
                    key={name}
                    onClick={() => {
                      history.push(
                        `/search?searchParam=${encodeURIComponent(name)}`
                      );
                    }}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        history.push(
                          `/search?searchParam=${encodeURIComponent(name)}`
                        );
                      }
                    }}
                  >
                    {name}
                  </span>
                ))}
              </div>

              {Number(topicDescriptionData.stateId) === 0 ? (
                <div className="WarningMessageActivityPage">
                  <MessageBar
                    messageBarType={MessageBarType.warning}
                    isMultiline={false}
                  >
                    You are a reviewer for this topic, any contributions that
                    you suggest will go directly to the approver for approvals.
                  </MessageBar>
                </div>
              ) : null}
              <TemporaryCkAlert />
              <DescriptionCkEditor
                users={ckEditorUsers}
                currentUser={CkEditorCurrentUser}
                initialData={
                  topicDescriptionData.description
                    ? topicDescriptionData.description
                    : ""
                }
                setContent={setDescription}
                initalSuggestions={
                  topicDescriptionData.resultCalloutJson
                    ? topicDescriptionData.resultCalloutJson
                    : null
                }
                setSuggestions={setDescriptionSuggestions}
                setReviewedSuggestion={setReviewedDescriptionSuggestions}
                licenseKey={licenseKey}
              />
            </div>
            {showDiscardPopUp ? (
              <DiscardPopUp setShowDiscardPopUp={setShowDiscardPopUp} />
            ) : null}
            {showPreviewAndSubmitPopUp ? (
              <PreviewAndSubmitPopUp
                countPolygon={count}
                selectedPathPolygon={selectedPath}
                topicsDataPolygon={polygonTopicsData}
                saveNextTopicPolygon={saveNextTopic}
                showLinkingPolygon={showLinking}
                setLinkinPolygon={setLinking}      
                setShowPreviewAndSubmitPopUp={setShowPreviewAndSubmitPopUp}
                users={ckEditorUsers}
                currentUser={CkEditorCurrentUser}
                description={description}
                descriptionSuggestions={descriptionSuggestions}
                setLeavingWithoutSave={setLeavingWithoutSave}
                setSubmitMessage={setSubmitMessage}
                reviewedDescriptionSuggestions={reviewedDescriptionSuggestions}
                contributorDescSuggestions={
                  topicDescriptionData.resultCalloutJson
                    ? topicDescriptionData.resultCalloutJson
                    : null
                }
                topicDescriptionData={topicDescriptionData}
                licenseKey={licenseKey}
                messagesType={messagesType}
                contributorSuggestionsInfo={contributorSuggestionsInfo}
                ratingPoints={ratingPoints}
                enableGamification={enableGamification}
                topicOwnerInfo={topicOwnerInfo}
                setTopicOwnerInfo={setTopicOwnerInfo}
								topicRelatedContent={topicRelatedContent}
								topicBusinessRelatedContent={topicBusinessRelatedContent}
								sdmContentErrorMessage={sdmContentErrorMessage}
								hasValidContent ={topicBusinessRelatedContent?.some((item) => item?.name?.trim() && item?.url?.trim()) ?? false}
								hasValidRelatedContent ={topicRelatedContent?.some((item) => item?.title?.trim() && item?.url?.trim()) ?? false}
              />
            ) : null}
            <SuccessMessagePopUp message={message} />
            {topicDescriptionData.stateId === 2 && !showHistory ? (
              <ReviewedPopUp
                contributorContent={
                  topicDescriptionData.lastContributorDescription
                }
              />
            ) : null}
            {topicDescriptionData.stateId === 2 && showHistory ? (
              <HistoryPreview
                contriutorLastSuggestions={
                  topicDescriptionData.resultCalloutJson
                }
                reviewedSuggestions={topicDescriptionData.resultReviewedJson}
                contributorContent={
                  topicDescriptionData.lastContributorDescription
                }
                topicDescriptionData={topicDescriptionData}
                users={ckEditorUsers}
                currentUser={CkEditorCurrentUser}
                existingHashtags={existingHashtags}
              />
            ) : null}
            <Prompt
              when={
                leavingWithoutSave &&
                !showDiscardPopUp &&
                topicDescriptionData.stateId !== 2
              }
              message="You have unsaved changes, are you sure you want to leave?"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicsContributorCrowdSourcing;
