import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';
import { getDate } from '../../helpers/Validation';
import Shapes from './Shapes';
import { useAxios } from '../../context/AxiosContext';


const PolygonPreviewModal = ({
	topicId,
	setShowPreviewModal,
	// hashTags,
	// setUserAddedHashtags,
	topicVersion,
	topicTitle,
	content,
	setShowPublishModal,
	relatedContent,
	topicOwnerInfo,
	businessRelatedContent,
	topicsData,
	setTopicsData,
	count,
	setBusinessRelatedContent,
	setRelatedContent,
	sdmContentErrorMessage
}) => {
	const [activeContent, setActiveContent] = useState(1)
	const [openRelatedContent, setOpenRelatedContent] = useState(false)
	const [selectedPath, setSelectedPath] = useState(0)
	const [showLinking, setLinking] = useState(false);
	const [title, setTitle] = useState(topicTitle);
	const [topicContent, setTopicContent] = useState(content);
	const { axiosGetService } = useAxios();
	const [hashTags, setHashTags] = useState();


	const getPolygonTopic = async () => {
		try {
			if (topicId !== null && topicId !== undefined) {
				await axiosGetService(`api/sdmtopics/getpolygontopicbyid/${topicId}`,).then((response) => {
					setTopicsData(response?.data);
					const existingRelatedContent = response?.data?.filter(e => e.polygonSequence === selectedPath)[0].topicRelatedContentList
						.map((item) => {
							return {
								title: item.title,
								url: item.url,
							};
						});
					setRelatedContent(existingRelatedContent);
					const existingHastag = response?.data?.filter(e => e.polygonSequence === selectedPath)[0]?.hashTagsList;
					setHashTags(existingHastag);

				})
			}
		} catch (error) {
			console.log("Error", error);
		}
	}
	useEffect(() => {
		getPolygonTopic();
	}, [topicId, selectedPath])

	useEffect(() => {
		getBusinessRelatedContent()
	}, [selectedPath])

	

	const getBusinessRelatedContent = async () => {
		const filteredTopicId = topicsData?.filter(e => e.polygonSequence === selectedPath)[0]?.topicId
		let api = `api/sdmbusinessrules/getassociatedbusinessRulesbyArtifactId/${filteredTopicId}/10/0/${false}`
		await axiosGetService(api).then(
			(response) => {
				setBusinessRelatedContent(response.data)
			})
	}

	const saveNextTopic = (category) => {
		setSelectedPath(category);
		const topicpolygon = topicsData.find((item) => item.polygonSequence === category);
		setTopicContent(topicpolygon.diagramName);
		setTitle(topicpolygon.topicName.replace(/_.*/, ''));
		// setHashTags(topicpolygon.hashTagsList);
	}
	const hasValidContent = businessRelatedContent.some(
		(item) => item.name?.trim() && item.url?.trim()
	);
	const hasValidRelatedContent = relatedContent.some(
		(item) => item.title?.trim() && item.url?.trim()
	);
	return (
		<Modal
			className="PreviewAndSubmitPopUp pr-0"
			show={true}
			onHide={() => {
				/* Not calling any function to close modal*/
			}}
			dialogClassName="NewPreviewAndSubmitPopUp"

		>
			<Modal.Header>
				<Modal.Title>
					<div className="topicOwnerPreviewPopupTitle">
						<span>Preview</span>

					</div>

				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='topicLandingMainContainer mt-0 '>
					<div className='topicsCrowdSourcingContainer _topicsCrowdSourcingContainerPolygon  mt-0'>
						<div className='topicsLeftAndMainContainer'>
							<div className="topicsRightMainContainer  polygonPreviewMain ml-0">
								<div class="topicsDescriptionDetails" id="topicTextDescription">
									<div className="topicDescTitlePart">

										<>
											<span className="topicVersion">
												Document Version: {topicVersion}
												,&nbsp;&nbsp;
												{`${getDate()} `}
											</span>
											<div className="discardAndPreviewButton mr-0">
												<Button
													title="Cancel"
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															setShowPreviewModal(false);
														}
													}}
													onClick={() => {
														setShowPreviewModal(false);
													}}
													className="activityDiscardButton"
												>
													Cancel
												</Button>
												<Button
													title="Publish"
													tabIndex={0}
													onClick={() => {
														setShowPublishModal(true);
														setShowPreviewModal(false);
													}}
													className="activityPreviewButton"
												>
													Publish
												</Button>

											</div>
										</>

									</div>

									<div className="topicDescriptionDivContainer">
										<div className={"topicPolygonDescriptionDiv _PolygonBorder"}>
											<div className="topicsOwner">
												<h1 className={"topicTitle topicTitleNewView polygonTopicTitle"}>
													{title.replace(/_.*/, '')}
												</h1>

											</div>

											{/* <div className="existingHashTagsOfTopic _existingHashTagsOfActivity">
												{Array.isArray(hashTags) && hashTags.map((Item) => (
													<span className="mr-1 hashTagsColor" key={`${Item.id}`} tabIndex={0}>
														{Item.name}
													</span>
												))}


											</div> */}
											<div
												className="desc ck-content polygonPreviewDesc"
												id="previewDescription"
												dangerouslySetInnerHTML={{
													__html: HtmlSanitizer(topicContent),
												}}
											/>


										</div>
										<div className="topicRightDescriptionDiv _topicRightPreviewDescriptionDiv polygonPreviewRightContainer ">
											<div className="topicsOwner polygontopicOnwer">

												{topicOwnerInfo?.mail ? (
													<div
														className={
															"topicsOwnerDetails topicsOwnerDetailsNewView polygonLatestPreview"

														}
													>
														<img
															src={topicOwnerInfo.photo}
															alt="topicOwnerImage"
														/>
														<div className="topicsOwnerPersonalDetails" tabIndex={0} >
															<p>Owner</p>
															<a href={`mailto:${topicOwnerInfo?.mail}`} role="link" aria-label={`Opens in new tab ${topicOwnerInfo.firstName + ' ' + topicOwnerInfo.surname}`}>
																<span className="alternativeOfh6">
																	{topicOwnerInfo?.firstName +
																		" " +
																		topicOwnerInfo?.surname}
																</span>
															</a>
														</div>
													</div>
												) : (
													<></>
												)}


											</div>
											{topicsData?.length > 0 ?
												<div className="polygonPreviewArea polygonPreviewImageContainer">
													<Shapes
														count={count}
														selectedPath={selectedPath}
														topicsData={topicsData}
														saveNextTopic={saveNextTopic}
														showLinking={showLinking}
														setLinking={setLinking}
													/>
												</div>
												:
												<></>
											}
											<div className=''>
												<div className={count === 5
													? "topicAssociateContentPanel previewAssociateSquare"
													: count === 7
														? "topicAssociateContentPanel previewAssociateHexa"
														: count === 4 ? "topicAssociateContentPanel previewAssociateTraingle" : "topicAssociateContentPanel previewAssociateContent"}>
													<div className='topicsContentDiv'>
														<div className='topicsAssociateContentDiv'>
															<h6>Associated Content</h6>
															<div className='topicAssociatedContent'>
																{relatedContent?.length > 0 && hasValidRelatedContent ? (
																	<>
																		{relatedContent?.map((item) =>
																		item.title?.trim() && item.url?.trim() ? 
																		(
																			<a href={item.url} className='topicAssociateContentAnchor' key={item.id} target="_blank">
																				<span>
																					{item.title}
																				</span>
																			</a>
																		): null)}
																	</>
																) : (
																	<p className="noRelatedContentMessage PreviewContentLatest">
																		{sdmContentErrorMessage}
																	</p>
																)}
															</div>
														</div>
														<div className='topicsAssociateContentDiv'>
															<h6>Business Rule</h6>

															<div className='topicAssociatedContent'>
																{businessRelatedContent?.length > 0 && hasValidContent ? (
																	<>
																		{businessRelatedContent.map((item) =>
																			item.name?.trim() && item.url?.trim() ? (
																				<a href={item.url} className='topicAssociateContentAnchor' key={item.id} target="_blank">
																					<span className='topicAssociateContentSpan'>{item.name}</span>
																				</a>
																			) : null
																		)}
																	</>
																) : (
																	<p className="noRelatedContentMessage PreviewContentLatest">{sdmContentErrorMessage}</p>
																)}
															</div>

														</div>

													</div>

												</div>
											</div>

										</div>

									</div>


								</div>



							</div></div></div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default PolygonPreviewModal;



