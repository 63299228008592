import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import queryString from 'query-string';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import { useAxios } from '../../context/AxiosContext';
import './DiscardPopUp.css';

function DiscardPopUp({ isActivityLock, activityLockedBy, lockedByUsername }) {
	const [modalShow, setModalShow] = useState(true);
	const searchComplexityParam = Number(
		queryString.parse(window.location.search).complexity
	);
	const activityIdentifier = queryString.parse(
		window.location.search
	).identifier;
	const { projectTypeId, solutionMethodId, phaseId, parentPhaseId,activityId } =
		useParams();
	const [message, setMessage] = useState(null);
	const { axiosGetService } = useAxios();

	const getMessage = async () => {
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=pendingApproval&title=0`
		).then((response) => {
			setMessage(response.data);
		});
	};
	const closeModal = () => {
		setModalShow(false);
	};

	useEffect(() => {
		getMessage();
	}, []);

	return (
		<Modal
			className="activityDiscardPopUp contributorPopUp"
			show={modalShow}
			onHide={() => {
				/* Not calling any function to close modal*/
			}}
			size="md"
			// aria-labelledby="Contributed"
			centered
		>
			<Modal.Body>
				{isActivityLock ? (
					<p>
						You can not edit this content now. Content is being updated by{' '}
						<a
							target="_blank"
							href={`https://teams.microsoft.com/l/chat/0/0?users=${activityLockedBy}`}
						>
							{lockedByUsername}
						</a>
						{' .'} Please check later for editing.
					</p>
				) : (
					<p>{message}</p>
				)}
				<div className="discardPopUpActivityButtons">
					<Link
						aria-label="Document in Review State"
						to={`/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}/activity/${activityIdentifier ? activityIdentifier : activityId}?complexity=${searchComplexityParam}`}
					>
						<Button
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									closeModal();
								}
							}}
							onClick={closeModal}
							className="activityDiscardPopUpAccept"
							variant="info"
						>
							OK
						</Button>
					</Link>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default DiscardPopUp;
