import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { v4 as uniqueIdGenerator } from 'uuid';
import { Prompt } from 'react-router';
import queryString from 'query-string';
import dompurify from 'dompurify';
import { Button } from 'react-bootstrap';
import { MessageBar, MessageBarType } from '@fluentui/react';
import loadable from '@loadable/component';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import Breadcrumbs from '../Breadcrumbs';
import AddBreadCrumb from '../../helpers/BreadcrumbService';
import Dropdown from '../Dropdown';
import MultiSelectDropdown from './MultiSelectDropdown';
import MultiSelectDropdownFluentApprover from './MultiSelectDropdownFluentApprover';
import DiscardPopUp from '../ActivityContributorCrowdSourcing/DiscardPopUp';
import PreviewAndSubmitPopUp from './PreviewAndSubmitPopUp';
import { useAuth } from '../../context/UserAuthContext';
import SuccessMessagePopUp from '../ActivityContributorCrowdSourcing/SuccessPopUp';
import Spinner from '../../helpers/Spinner';
import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import HistoryPreview from './HistoricalPreviewPopUp';
import { getOtherUserProfile, getGroupInfo } from '../../helpers/GraphService';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import UserNotVerified from '../ActivityReviewerCrowdSourcing/UserNotVerifiedPopUp';
import FileDropModal from '../TemplateCard/FileDropModal';
import PreviewModal from '../TemplateCard/PreviewModal';
import SampleTemplateModal from '../TemplateCard/SampleTemplateModal';
import { Icon } from '@fluentui/react/lib/Icon';
import TemplateCrowdSourcing from '../ActivityContributorCrowdSourcing/TemplateContribution';
import TemplateBuilder from '../ActivityTemplateBuilder';
import ActivityRaciMatrix from '../ActivityRaciMatrix';
import { TabTitle } from '../../utils/GeneralFunctions';
import EntryCriteriaContribution from '../ActivityContributorCrowdSourcing/EntryCriteriaContribution';
import ExitCriteriaContribution from '../ActivityContributorCrowdSourcing/ExitCriteriaContribution';
import TaskContribution from '../ActivityContributorCrowdSourcing/TaskContribution';

import '../Activity/Activity.css';
import '../ActivityContributorCrowdSourcing/ContributorActivityCrowdSourcing.css';
import TemporaryCkAlert from '../CkEditor/TemporaryCkAlert';
import decryptAES256 from '../CkEditor/DecryptionComponent';
import { useConfig } from '../../context/ConfigContext';

const DescriptionCkEditor = loadable(
  () =>
    import('../CkEditor/ActivityApproverTrackChanges/DescriptionTrackChanges'),
  {
    fallback: (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    ),
  }
);
const SupportAndActivitiesCkEditor = loadable(
  () =>
    import(
      '../CkEditor/ActivityApproverTrackChanges/SupportingActivitieTrackChanges'
    ),
  {
    fallback: (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    ),
  }
);
const TipsAndTechniquesCkEditor = loadable(
  () =>
    import(
      '../CkEditor/ActivityApproverTrackChanges/TipsAndTechniquesTrackChanges'
    ),
  {
    fallback: (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    ),
  }
);
const DeliverablesCkEditor = loadable(
  () =>
    import('../CkEditor/ActivityApproverTrackChanges/DeliverableTrackChanges'),
  {
    fallback: (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    ),
  }
);

function ContributorActivityCrowdSourcing({isEnableComplexity}) {
  const history = useHistory();
  const { ckEdKEY } = useConfig();
  const htmlSanitizer = dompurify.sanitize;
  const { track } = useAppInsights();
  const { breadcrumbs, setBreadcrumbs, setSkipNavigationId } =
    useLeftMenuCollapse();
  const [ckEditorUsers, setCkEditorUsers] = useState([]);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [reviewerInfo, setReviewerInfo] = useState(null);
  const [approverVerified, setApproverVerified] = useState(true);
  const [licenseKey, setLicenseKey] = useState(null);
  const { user } = useAuth();
  const [messagesType, setMessagesType] = useState({});
  const [toolsSidebarHeight, setToolsSidebarHeight] = useState('239px');
  const [existingHashtags, setExistingHashtags] = useState([]);
  const [templateSidebarHeight, setTemplateSidebarHeight] = useState('239px');

  const CkEditorCurrentUser = user.profile.mail;
  const [leavingWithoutSave, setLeavingWithoutSave] = useState(true);
  const { crowdSourcingAppState, setCrowdSourcingAppState } =
    useCrowdSourcing();
  const { showHistory, userLeaveWithoutSave } = crowdSourcingAppState;
  const {
    activityId,
    projectTypeId,
    solutionMethodId,
    phaseId,
    parentPhaseId,
  } = useParams();
  const { axiosGetService } = useAxios();
  const searchComplexityParam = Number(
    queryString.parse(window.location.search).complexity
  );
  const activityIdentifier = queryString.parse(
    window.location.search
  ).identifier;
  const [activityContent, setactivityContent] = useState(null);
  const [templateData, setTemplateData] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [procedureData, setProcedureData] = useState([]);
  const [complexities, setComplexities] = useState([]);
  const complexityId = searchComplexityParam;
  const [complexityName, setComplexityName] = useState({
    name: '',
    type: 'option',
    id: '',
  });
  const [showDiscardPopUp, setShowDiscardPopUp] = useState(false);
  const [showPreviewAndSubmitPopUp, setShowPreviewAndSubmitPopUp] =
    useState(false);
  const [description, setDescription] = useState(null);
  const [supportingActivities, setSupportingActivities] = useState(null);
  const [toolsAndGuidance, setToolsAndGuidance] = useState(null);
  const [tipsAndTechniques, setTipsAndTechniques] = useState(null);
  const [contributorsData, setContributorsData] = useState(null);
  const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);
  const [checkNotificationClick, setCheckNotificationClick] = useState(false);
  const [supportingActivitiesSuggestions, setSupportingActivitiesSuggestions] =
    useState([]);
  const [toolsAndGuidanceSuggestions, setToolsAndGuidanceSuggestions] =
    useState([]);
  const [tipsAndTechniquesSuggestions, setTipsAndTechniquesSuggestions] =
    useState([]);
  const [templateSuggestions, setTemplateSuggestions] = useState([]);

  const [approverDescSuggestions, setApproverDescSuggestions] = useState([]);
  const [approverSupportSuggestions, setApproverSupportSuggestions] = useState(
    []
  );
  const [approverTipssSuggestions, setaApproverTipssSuggestions] = useState([]);

  const [toolsUrl, setToolsUrl] = useState({});
  const [approvedDescSuggestions, setApprovedDescSuggestions] = useState([]);
  const [approvedSupportSuggestions, setApprovedSupportSuggestions] = useState(
    []
  );
  const [approvedTipsSuggestions, setApprovedTipsSuggestions] = useState([]);
  const [approvedTemplateSuggestions, setApprovedTemplateSuggestions] =
    useState([]);
  const [historyToolsId, setHistoryToolsId] = useState([]);
  const [historicalPreviewTools, setHistoricalPreviewTools] = useState([]);
  const [historyToolsSuggestion, setHistoryToolsSuggestion] = useState([]);
  const [toolsReviewedIds, setToolsReviewedIds] = useState({});
  const [ckEditorUserMap, setCkEditorUserMap] = useState({});
  const [sidebarCard, setSidebarCard] = useState([]);
  const [
    selectedToolsAndGuidanceSuggestions,
    setSelectedToolsAndGuidanceSuggestions,
  ] = useState([]);
  const [
    contributiorToolsAndGuidanceSuggestion,
    setContributiorToolsAndGuidanceSuggestion,
  ] = useState([]);
  const [adminToolsAndGuidance, setAdminToolsAndGuidance] = useState([]);
  const [reviewedTools, setReviewedTools] = useState([]);
  const [checkForAdminToolsUpdate, setCheckForAdminToolsUpdate] = useState([]);
  const [contributiorToolsId, setContributiorToolsId] = useState([]);
  const [
    reviewedToolsAndGuidanceSuggestions,
    setReviewedToolsAndGuidanceSuggestions,
  ] = useState([]);
  const [reviewedTemplateSuggestions, setReviewedTemplateSuggestions] =
    useState([]);
  const [reviewerTemplateSuggestions, setReviewerTemplateSuggestions] = useState([]);
  const [reviewerSuggestionsInfo, setReviewerSuggestionsInfo] = useState({});
  const [sampleTemplates, setSampleTemplates] = useState({
    enable: false,
    data: null,
    parentData: null,
  });
  // template Preview Data
  const [templatePreviewData, setTemplatePreviewData] = useState(null);
  const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);
  const [enableFileDropModal, setEnableFileDropModal] = useState(false);
  const handleTemplatePreview = (data) => {
    setTemplatePreviewData(data);
    setEnableTemplatePreview(true);
  };
  const [ratingPoints, setRatingPoints] = useState(null);
  const [enableGamification, setEnableGamification] = useState(false);
  const [showTemplateBuilder, setShowTemplateBuilder] = useState(false);

  const [activityComplexityData, setActivityComplexityData] = useState({});
  const [selectedComplexityTab, setSelectedComplexityTab] = useState('');
  const [complexitySectionModal, setComplexitySectionModal] = useState('');
  const [publishComplexity, setPublishComplexity] = useState([]);
  const [publishComplexityEntity, setPublishComplexityEntity] = useState({});

  const [entryCriteriaButtonClicked, setEntryCriteriaButtonClicked] =
    useState(false);
  const [inputButtonClicked, setInputButtonClicked] = useState(false);
  const [entryCriteriaSuggestions, setEntryCriteriaSuggestions] = useState([]);
  const [inputSuggestions, setInputSuggestions] = useState([]);
  const [entryCriteriaWithSuggestions, setEntryCriteriaWithSuggestions] =
    useState([]);

  const [exitCriteriaButtonClicked, setExitCriteriaButtonClicked] =
    useState(false);

  const [exitCriteriaSuggestions, setExitCriteriaSuggestions] = useState([]);

  const [exitCriteriaWithSuggestions, setExitCriteriaWithSuggestions] =
    useState([]);
  const [entryCriteriaData, setEntryCriteriaData] = useState([]);
  const [exitCriteriaData, setExitCriteriaData] = useState([]);

  const [reviewedEntryCriteriaSuggestions, setReviewedEntryCriteriaSuggestions,] = useState([]);
  const [reviewedExitCriteriaSuggestions, setReviewedExitCriteriaSuggestions] = useState([]);
  const [currentEntryTab, setCurrentEntryTab] = useState('tab-entry');
  const [currentExitTab, setCurrentExitTab] = useState('tab-exit');

  const [taskButtonClicked, setTaskButtonClicked] = useState(false);
  const [taskSuggestions, setTaskSuggestions] = useState([]);
  const [taskWithSuggestions, setTaskWithSuggestions] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [reviewedTaskSuggestions, setReviewedTaskSuggestions,] = useState([]);

  const [reviewerEntryCriteriaSuggestions, setReviewerEntryCriteriaSuggestions] = useState([]);
  const [reviewerExitCriteriaSuggestions, setReviewerExitCriteriaSuggestions] = useState([]);
  const [reviewerTaskSuggestions, setReviewerTaskSuggestions] = useState([]);

  const [deliverables, setDeliverables] = useState(null);
  const [deliverablesSuggestions, setDeliverablesSuggestions] = useState([]);
  const [approverDeliverablesSuggestions, setApproverDeliverablesSuggestions] = useState([]);
  const [approvedDeliverablesSuggestions, setApprovedDeliverablesSuggestions] = useState([]);
  const [taskReorder,setTaskReorder] = useState(false);
	const [entryContributionReorder,setEntryContributionReorder] = useState(false);
	const [inputContributionReorder,setInputContributionReorder] = useState(false);
	const [exitContributionReorder,setExitContributionReorder] = useState(false);
	const [outputContributionReorder,setOutputContributionReorder] = useState(false);
	const [taskReorderArray, setTaskReorderArray] = useState([]);
	const [entryCriteriaReorderArray, setEntryCriteriaReorderArray] = useState([]);
	const [inputCriteriaReorderArray, setInputCriteriaReorderArray] = useState([]);
	const [exitCriteriaReorderArray, setExitCriteriaReorderArray]= useState([]);
	const [outputCriteriaReorderArray, setOutputCriteriaReorderArray]= useState([]);
  const [initialTaskIdBeforeReorder, setInitialTaskIdBeforeReorder] = useState([])
  const [initialEntryIdBeforeReorder,setInitialEntryIdBeforeReorder] = useState([])
  const [initialExitIdBeforeReorder,setInitialExitIdBeforeReorder] = useState([])
  const [disableDrag, setDisableDrag] = useState(false)
  const [ckEditorCurrentReorderUsername, setCkEditorCurrentReorderUsername] = useState(user.profile.firstName+''+user.profile.surname)
  const [ckEditorCurrentReorderUserImage,setCkEditorCurrentReorderUserImage]=useState(user.profile.photo)
  const [ckEditorCurrentEntryReorderUsername, setCkEditorCurrentEntryReorderUsername] = useState(user.profile.firstName+''+user.profile.surname)
  const [ckEditorCurrentEntryReorderUserImage,setCkEditorCurrentEntryReorderUserImage]=useState(user.profile.photo)
  const [ckEditorCurrentExitReorderUserImage,setCkEditorCurrentExitReorderUserImage]=useState(user.profile.photo)
  const [ckEditorCurrentExitReorderUsername, setCkEditorCurrentExitReorderUsername] = useState(user.profile.firstName+''+user.profile.surname)
  const [ckEditorCurrentInputReorderUserImage,setCkEditorCurrentInputReorderUserImage]=useState(user.profile.photo)
  const [ckEditorCurrentInputReorderUsername, setCkEditorCurrentInputReorderUsername] = useState(user.profile.firstName+''+user.profile.surname)
  const [ckEditorCurrentOutputReorderUserImage,setCkEditorCurrentOutputReorderUserImage]=useState(user.profile.photo)
  const [ckEditorCurrentOutputReorderUsername,setCkEditorCurrentOutputReorderUsername]=useState(user.profile.firstName+''+user.profile.surname)
  const [reviewedReorderedTaskSuggestions,setReviewedReorderedTaskSuggestions] = useState([])
  const [reviewedReorderedEntrySuggestions,setReviewedReorderedEntrySuggestions] = useState([])
	const [reviewedReorderedExitSuggestions,setReviewedReorderedExitSuggestions] = useState([])
	const [reviewedReorderedInputSuggestions,setReviewedReorderedInputSuggestions] = useState([])
	const [reviewedReorderedOutputSuggestions,setReviewedReorderedOutputSuggestions] = useState([])
	const [disableEntryDrag, setDisableEntryDrag] = useState(false)
	const [disableInputDrag, setDisableInputDrag] = useState(false)
	const [disableExitDrag, setDisableExitDrag] = useState(false)
	const [disableOutputDrag, setDisableOutputDrag] = useState(false)
  const [reorderHistory,setReorderHistory] = useState([])
  const [isApproverTaskReordered, setIsApproverTaskReordered] = useState(false)
	const [isApproverEntryReordered, setIsApproverEntryReordered] = useState(false)
	const [isApproverInputReordered, setIsApproverInputReordered] = useState(false)
	const [isApproverExitReordered, setIsApproverExitReordered] = useState(false)
	const [isApproverOutputReordered, setIsApproverOutputReordered] = useState(false)
  const [reorderedTaskRejectionCardInfo, setReorderedTaskRejectionCardInfo] = useState([])
  const [reorderedEntryRejectionCardInfo, setReorderedEntryRejectionCardInfo] = useState([])
  const [reorderedInputRejectionCardInfo, setReorderedInputRejectionCardInfo] = useState([])
  const [reorderedExitRejectionCardInfo, setReorderedExitRejectionCardInfo] = useState([])
  const [reorderedOutputRejectionCardInfo, setReorderedOutputRejectionCardInfo] = useState([])
  const [reorderContributionCount, setReorderContributionCount] = useState(0)
  const [addedTaskSequence, setAddedTaskSequence] = useState([])
  const [addedEntrySequence, setAddedEntrySequence] = useState([])
  const [addedExitSequence, setAddedExitSequence] = useState([])
  const [isOrderChanged, setIsOrderChanged] = useState({
    task:false,
    entry:false,
    exit:false,
    input:false,
    output:false
  })

  const contributorInfo = [];
  const graphUserData = [];
  const userDataMap = new Map();

  const getTemplatesInfo = (data) => {
    let templates = [];
    const activityTemplateDetails = data.activityTemplateDetails
      ? data.activityTemplateDetails
      : [];
    if (data.resultCalloutJson && data.resultCalloutJson.templateSuggestions) {
      const reviewedSuggestions = JSON.parse(
        data.reviewedSuggestionsJson
      ).templateReviewedSuggestions;
      templates = data.resultCalloutJson.templateSuggestions
        .filter(
          (item) =>
            reviewedSuggestions.some(
              (suggestion) =>
                JSON.parse(suggestion).id === JSON.parse(item).id &&
                JSON.parse(suggestion).state === 'accepted'
            ) &&
            !activityTemplateDetails.some(
              (existingTemplate) => existingTemplate.id === JSON.parse(item).id
            )
        )
        .map((template) => {
          return JSON.parse(template);
        });

    }
    setTemplateData([...activityTemplateDetails, ...templates]);
  };

  const getExistingTemplates = () => {
    if (templateSuggestions) {
      const existingTemplates = templateSuggestions.map((template) =>
        JSON.parse(template)
      );
      return [...templateData, ...existingTemplates];
    }
    return [...templateData];
  };

  const addTemplateToSuggestion = (suggestions) => {
    const updatedSuggestions = suggestions
      .filter(
        (suggestedTemplate) =>
          !templateData.some(({ id }) => id === suggestedTemplate.id)
      )
      .map((template) =>
        JSON.stringify({
          ...template,
          suggestionType: 'insertion',
          authorId: CkEditorCurrentUser,
          title: template.name,
          templateId: template.id.toString(),
          addedBy: 'approver',
        })
      );
    setTemplateSuggestions((prevState) => [
      ...prevState,
      ...updatedSuggestions,
    ]);
  };

  const updateSuggestionInfo = (suggestionInfo, suggestionsData) => {
    const id =
      suggestionInfo.split(':').length > 3
        ? suggestionInfo.split(':')[2]
        : suggestionInfo.split(':')[1];
    const type =
      suggestionInfo.split(':').length > 3
        ? suggestionInfo.split(':').slice(0, 2).join(':')
        : suggestionInfo.split(':')[0];
    const authorId =
      suggestionInfo.split(':').length > 3
        ? suggestionInfo.split(':')[3]
        : suggestionInfo.split(':')[2];
    suggestionsData[id] = {
      id: id,
      type: type,
      authorId: authorId,
      data: null,
    };
  };
 
  const getSuggestionsInfo = (emCollection, suggestionsData) => {
    for (let i = 0; i < emCollection.length; i++) {
      if ($(emCollection[i]).attr('data-suggestion-end-after')) {
        const suggestionInfo = $(emCollection[i]).attr(
          'data-suggestion-end-after'
        );
        updateSuggestionInfo(suggestionInfo, suggestionsData);
      } else if ($(emCollection[i]).attr('data-suggestion-start-before')) {
        const suggestionInfo = $(emCollection[i]).attr(
          'data-suggestion-start-before'
        );
        updateSuggestionInfo(suggestionInfo, suggestionsData);
      }
    }
  };

  const checkSuggestion = (htmlData, type) => {
    const domParser = new DOMParser();
    const docElement = domParser.parseFromString(
      htmlData,
      'text/html'
    ).documentElement;
    let emCollection = docElement.getElementsByTagName('suggestion');

    const suggestionsData = {};
    for (let i = 0; i < emCollection.length; i++) {
      const suggestionId = emCollection[i].attributes.id.value;
      suggestionsData[suggestionId.split(':')[0]] = {
        id: suggestionId.split(':')[0],
        type: emCollection[i].attributes['suggestion-type'].value,
        authorId: suggestionId.split(':')[1],
        data: null,
      };
    }
    emCollection = docElement.getElementsByTagName('suggestion-start');
    for (let i = 0; i < emCollection.length; i++) {
      const suggestionInfo = emCollection[i].attributes.name.value;
      const id =
        suggestionInfo.split(':').length > 3
          ? suggestionInfo.split(':')[2]
          : suggestionInfo.split(':')[1];
      const type =
        suggestionInfo.split(':').length > 3
          ? suggestionInfo.split(':').slice(0, 2).join(':')
          : suggestionInfo.split(':')[0];
      const authorId =
        suggestionInfo.split(':').length > 3
          ? suggestionInfo.split(':')[3]
          : suggestionInfo.split(':')[2];
      suggestionsData[id] = {
        id: id,
        type: type,
        authorId: authorId,
        data: null,
      };
    }
    emCollection = docElement.getElementsByTagName('figure');
    getSuggestionsInfo(emCollection, suggestionsData);
    emCollection = docElement.getElementsByTagName('th');
    getSuggestionsInfo(emCollection, suggestionsData);
    emCollection = docElement.getElementsByTagName('td');
    getSuggestionsInfo(emCollection, suggestionsData);
    emCollection = docElement.getElementsByTagName('tr');
    getSuggestionsInfo(emCollection, suggestionsData);
    emCollection = docElement.getElementsByTagName('table');
    getSuggestionsInfo(emCollection, suggestionsData);
    emCollection = docElement.getElementsByTagName('tbody');
    getSuggestionsInfo(emCollection, suggestionsData);
    emCollection = docElement.getElementsByTagName('p');
    getSuggestionsInfo(emCollection, suggestionsData);
    setReviewerSuggestionsInfo((prevState) => ({
      ...prevState,
      [type]: suggestionsData,
    }));
  };

  const getRatingPoints = async () => {
    await axiosGetService(`api/sdmuser/gamification/getRatingPoints`).then(
      (response) => {
        setRatingPoints(response.data);
      }
    );
  };

  const checkGamificationStatus = async () => {
    await axiosGetService(`api/sdmsettings?sdmKey=EnableGamification`).then(
      (response) => {
        if (response.data[0].value === 'true') {
          setEnableGamification(true);
        }
      }
    );
  };

  const getActivityComplexityData = async (mappedComplexities) => {
    const availableComplexities = mappedComplexities
      .filter(({ id }) => id != complexityId)
      .map(({ id }) => {
        return id;
      })
      .toString();
    if (availableComplexities) {
      await axiosGetService(
        `api/activities/activitydetailsforavailablecomplexities?activityIdentifier=${activityIdentifier}&complexityIds=${availableComplexities}&url=0&urlPathName=0`
      ).then((response) => {
        const data = {};
        for (const activity of response.data
          .activityDetailsForAvailableComplexities) {
          data[activity.complexityName] = activity;
        }
        setActivityComplexityData(data);
      });
    }
  };

  const getActivityContents = async () => {
    await axiosGetService(
      `api/activities/activity?activityIdentifier=${activityIdentifier}&complexityId=${complexityId}&url=activity&urlPathName=activity`
    )
      .then((response) => {
        const mappedComplexities = response.data.mappedCompelxityList.map(
          (item) => ({
            name: item.name,
            id: item.id,
            option: 'type',
          })
        );
        getActivityComplexityData(mappedComplexities);
        setComplexityName({
          name: response.data.complexityName,
          id: response.data.complexityId,
          type: 'option',
        });
        const hashtags = response.data.hashTagsList.map(({ name }) => {
          return name;
        });
        setExistingHashtags(hashtags);
        setTopicData(response.data.activityTopics);
        setProcedureData(response.data.activityProcedureType);
        setactivityContent(response.data);
        setComplexities(mappedComplexities);
        setApproverVerified(response.data.isApprover);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getReviewerContent = async () => {
    await axiosGetService(
      `api/sdmactivity/getActivity2/${activityId}/${complexityId}`
    )
      .then((response) => {
        setContributorsData(response.data);
        setReviewerSuggestionsInfo((prevState) => ({
          ...prevState,
          ['description']: response.data.reviewerCalloutJsontoReact?  response.data.reviewerCalloutJsontoReact.description : null,
        }));
        setReviewerSuggestionsInfo((prevState) => ({
          ...prevState,
          ['supportingActivities']: response.data.reviewerCalloutJsontoReact?response.data.reviewerCalloutJsontoReact.supportingActivities : null,
        }));
        setReviewerSuggestionsInfo((prevState) => ({
          ...prevState,
          ['tipsAndTechniques']: response.data.reviewerCalloutJsontoReact?response.data.reviewerCalloutJsontoReact.tipsAndTechniques : null,
        }));
        setReviewerSuggestionsInfo((prevState) => ({
          ...prevState,
          ['deliverables']: response.data.reviewerCalloutJsontoReact?response.data.reviewerCalloutJsontoReact.deliverables:null,
        }));
        // checkSuggestion(response.data.description, 'description');
        // checkSuggestion(
        //   response.data.supportingActivities,
        //   'supportingActivities'
        // );
        // checkSuggestion(response.data.tipsAndTechniques, 'tipsAndTechniques');
        // checkSuggestion(response.data.deliverables, 'deliverables');
        getTemplatesInfo(response.data);
        if (
          response.data.resultReviewedJson &&
          response.data.resultReviewedJson.templateSuggestions
        ) {
          const templateSuggestions =
            response.data.resultReviewedJson.templateSuggestions;
          const suggestions = {};
          for (const template of templateSuggestions) {
            suggestions[JSON.parse(template).id] = {
              ...JSON.parse(template),
              type: JSON.parse(template).suggestionType,
            };
          }
          setReviewerSuggestionsInfo((prevState) => ({
            ...prevState,
            templates: suggestions,
          }));
          setReviewerTemplateSuggestions([...response.data.resultReviewedJson.templateSuggestions]);
        } else {
          setReviewerSuggestionsInfo((prevState) => ({
            ...prevState,
            templates: {},
          }));
        }
        const adminTools = [];
        const contributionsToolsId = [];
        const updateIsSelected = {};
        const contributorToolsSuggestions = [];
        if (
          response.data.resultReviewedJson &&
          response.data.resultReviewedJson.toolsAndGuidance
        ) {
          const toolsSuggestions =
            response.data.resultReviewedJson.toolsAndGuidance;
          for (const id in toolsSuggestions) {
            const tools = {
              toolsId: JSON.parse(toolsSuggestions[id]).toolsId,
              id: JSON.parse(toolsSuggestions[id]).id,
              authorId: JSON.parse(toolsSuggestions[id]).authorId,
              suggestionType: JSON.parse(toolsSuggestions[id]).suggestionType,
              name: JSON.parse(toolsSuggestions[id]).name,
              createdAt: JSON.parse(toolsSuggestions[id]).createdAt,
              type: 'option',
              isSelected: JSON.parse(toolsSuggestions[id]).isSelected,
              createdBy: JSON.parse(toolsSuggestions[id]).createdBy,
            };
            contributionsToolsId.push(JSON.parse(toolsSuggestions[id]).toolsId);
            if (
              JSON.parse(toolsSuggestions[id]).suggestionType === 'insertion'
            ) {
              updateIsSelected[JSON.parse(toolsSuggestions[id]).toolsId] = true;
            } else {
              updateIsSelected[
                JSON.parse(toolsSuggestions[id]).toolsId
              ] = false;
            }
            contributorToolsSuggestions.push(JSON.stringify(tools));
          }
          const suggestion = {};
          for (const tool of toolsSuggestions) {
            suggestion[JSON.parse(tool).id] = JSON.parse(tool);
          }
          setReviewerSuggestionsInfo((prevState) => ({
            ...prevState,
            toolsAndGuidance: suggestion,
          }));
        } else {
          setReviewerSuggestionsInfo((prevState) => ({
            ...prevState,
            toolsAndGuidance: {},
          }));
        }

        setContributiorToolsId(contributionsToolsId);
        const updateToolsAndGuideSuggestion = [];
        const toolData = [...response.data.activityToolsList];
        const reviewedToolsId = response.data.toolsAndGuidance?.split(',');
        const url = {};
        for (const id in toolData) {
          const tools = {
            activities: toolData[id].activities,
            createdBy: toolData[id].createdBy,
            createdOn: toolData[id].createdOn,
            email: toolData[id].email,
            toolsId: toolData[id].id,
            isSelected: toolData[id].isSelected,
            modifiedBy: toolData[id].modifiedBy,
            modifiedOn: toolData[id].modifiedOn,
            modifiedUserEmail: toolData[id].modifiedUserEmail,
            name: toolData[id].name,
            url: toolData[id].url,
          };
          if (toolData[id].id in updateIsSelected) {
            tools.isSelected = updateIsSelected[toolData[id].id];
            updateToolsAndGuideSuggestion.push(tools);
          } else if (reviewedToolsId.includes(toolData[id].id.toString())) {
            tools.isSelected = true;
            updateToolsAndGuideSuggestion.push(tools);
          } else {
            tools.isSelected = false;
            updateToolsAndGuideSuggestion.push(tools);
          }
          url[toolData[id].id] = toolData[id].url;
        }
        setToolsUrl(url);
        setToolsAndGuidanceSuggestions(updateToolsAndGuideSuggestion);
        const nonContributedTools = [];
        for (const id in response.data.activityToolsList) {
          if (
            (response.data.activityToolsList[id].isSelected &&
              !contributionsToolsId.includes(
                response.data.activityToolsList[id].id
              ) &&
              reviewedToolsId.includes(
                response.data.activityToolsList[id].id.toString()
              )) ||
            (reviewedToolsId.includes(
              response.data.activityToolsList[id].id.toString()
            ) &&
              !contributionsToolsId.includes(
                response.data.activityToolsList[id].id
              ))
          ) {
            const tools = {
              toolsId: response.data.activityToolsList[id].id,
              authorId: null,
              suggestionType: 'none',
              createdAt: new Date(),
              name: response.data.activityToolsList[id].name,
              type: 'option',
              isSelected: true,
            };
            nonContributedTools.push(JSON.stringify(tools));
            adminTools.push(response.data.activityToolsList[id].id);
          }
        }
        setAdminToolsAndGuidance([...adminTools]);
        for (const id in contributorToolsSuggestions) {
          nonContributedTools.push(contributorToolsSuggestions[id]);
        }
        setSelectedToolsAndGuidanceSuggestions(nonContributedTools);

        const historyContributionsToolsId = [];
        if (
          response.data.resultCalloutJson &&
          response.data.resultCalloutJson.toolsAndGuidance
        ) {
          setHistoricalPreviewTools(
            response.data.resultCalloutJson.toolsAndGuidance
          );
          const toolsSuggestions =
            response.data.resultCalloutJson.toolsAndGuidance;
          for (const id in toolsSuggestions) {
            historyContributionsToolsId.push(
              JSON.parse(toolsSuggestions[id]).toolsId
            );
          }
          setHistoryToolsId(historyContributionsToolsId);
        }
        const updatedTools = [];
        for (const id in response.data.activityToolsList) {
          const tools = {
            activities: response.data.activityToolsList.activities,
            createdBy: response.data.activityToolsList.createdBy,
            createdOn: response.data.activityToolsList.createdOn,
            email: response.data.activityToolsList.email,
            toolsId: response.data.activityToolsList.id,
            isSelected: historyContributionsToolsId.includes(
              response.data.activityToolsList[id].toolsId
            )
              ? true
              : false,
            modifiedBy: response.data.activityToolsList.modifiedBy,
            modifiedOn: response.data.activityToolsList.modifiedOn,
            modifiedUserEmail:
              response.data.activityToolsList.modifiedUserEmail,
            name: response.data.activityToolsList.name,
            url: response.data.activityToolsList.url,
          };
          updatedTools.push(tools);
        }
        setHistoryToolsSuggestion([...updatedTools]);
        const reviewedIds = {};
        if (response.data.reviewedSuggestionsJson) {
          const toolsAndGuidancesReviewedSuggestions = JSON.parse(
            response.data.reviewedSuggestionsJson
          ).toolsAndGuidancesReviewedSuggestions;
          for (const id in toolsAndGuidancesReviewedSuggestions) {
            reviewedIds[
              JSON.parse(toolsAndGuidancesReviewedSuggestions[id]).id
            ] = JSON.parse(toolsAndGuidancesReviewedSuggestions[id]).state;
          }
          setToolsReviewedIds(reviewedIds);
        }
        setEntryCriteriaData(response.data.entryCriteriaList)
        getEntryCriteriaSuggestions(response);
        setExitCriteriaData(response.data.exitCriteriaList);
        getExitCriteriaSuggestions(response);
        getTaskSuggestions(response);
        setTaskData(response.data.taskList);
        getTaskSuggestionsReorder(response);
				getEntrySuggestionsReorder(response);
				getExitSuggestionsReorder(response);
				getInputSuggestionsReorder(response);
				getOutputSuggestionsReorder(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showHistoryPreview = () => {
    setCrowdSourcingAppState({
      ...crowdSourcingAppState,
      showHistory: true,
      showPreviewPopUp: true,
    });
  };

  if (leavingWithoutSave && !showDiscardPopUp && !userLeaveWithoutSave) {

    setCrowdSourcingAppState({
      ...crowdSourcingAppState,
      userLeaveWithoutSave: true,
    });
  }
 
  const getContributor = (suggestions) => {
    for (const id in suggestions) {
      const userEmail = JSON.parse(suggestions[id]).authorId;
      if (userEmail && !userDataMap.has(userEmail)) {
        graphUserData.push(userEmail);
        userDataMap.set(userEmail, 1);
      }
    }
  };
  
  const removeToolsAndGuidance = (cardType, item) => {
    let data = [];
    let count = 0;
    for (const key in selectedToolsAndGuidanceSuggestions) {
      if (
        JSON.parse(selectedToolsAndGuidanceSuggestions[key]).toolsId !==
        item.toolsId
      ) {
        data.push(selectedToolsAndGuidanceSuggestions[key]);
        count = count + 1;
      } else if (
        adminToolsAndGuidance.includes(item.toolsId) &&
        cardType === 'admin'
      ) {
        count = count + 1;
        const suggestionCardData = {
          toolsId: item.toolsId,
          id: uniqueIdGenerator(),
          authorId: CkEditorCurrentUser,
          suggestionType: 'deletion',
          name: item.name,
          type: 'option',
          createdAt: new Date(),
          isSelected: false,
          createdBy: 'approver',
        };
        data.push(JSON.stringify(suggestionCardData));
        if (!checkForAdminToolsUpdate.includes(item.toolsId)) {
          setCheckForAdminToolsUpdate([
            ...checkForAdminToolsUpdate,
            item.toolsId,
          ]);
        }
      } else if (
        adminToolsAndGuidance.includes(item.toolsId) &&
        cardType === 'adminToolsDeletion'
      ) {
        count = count + 1;
        const suggestionCardData = {
          toolsId: item.toolsId,
          id: uniqueIdGenerator(),
          authorId: CkEditorCurrentUser,
          suggestionType: 'none',
          name: item.name,
          type: 'option',
          createdAt: new Date(),
          isSelected: true,
          createdBy: 'approver',
        };
        const removeCheckedSuggestion = checkForAdminToolsUpdate.filter(
          (id) => Number(id) !== Number(item.toolsId)
        );
        setCheckForAdminToolsUpdate(removeCheckedSuggestion);
        data.push(JSON.stringify(suggestionCardData));
      }
    }
    setSelectedToolsAndGuidanceSuggestions(count > 0 ? data : []);
    data = [];
    for (const key in toolsAndGuidanceSuggestions) {
      if (toolsAndGuidanceSuggestions[key].toolsId === item.toolsId) {
        toolsAndGuidanceSuggestions[key].isSelected =
          cardType === 'adminToolsDeletion' ? true : false;
        data.push(toolsAndGuidanceSuggestions[key]);
      } else {
        data.push(toolsAndGuidanceSuggestions[key]);
      }
    }
    setToolsAndGuidanceSuggestions(data);
   };
   const originalTaskOrder = []
   const originalExitOrder = []
   const originalEntryOrder = []
   const originalInputOrder = []
   const originalOutputOrder = []
   taskWithSuggestions.forEach(e=>originalTaskOrder.push({id:JSON.parse(e).id, name:JSON.parse(e).name}))
 
   exitCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName ==='Exit Criteria')
  .forEach(e=>originalExitOrder.push({id:JSON.parse(e).id, name:JSON.parse(e).name}))
 
   entryCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName ==='Entry Criteria')
  .forEach(e=>originalEntryOrder.push({id:JSON.parse(e).id, name:JSON.parse(e).name}))
  
  entryCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName ==='Input')
  .forEach(e=>originalInputOrder.push({id:JSON.parse(e).id, name:JSON.parse(e).name}))

   exitCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName ==='Output')
  .forEach(e=>originalOutputOrder.push({id:JSON.parse(e).id, name:JSON.parse(e).name}))

  
  const updateContributorSuggestion = (reviewType, item, suggestionType) => {
    if (reviewType === 'accept') {
      setReviewedToolsAndGuidanceSuggestions([
        ...reviewedToolsAndGuidanceSuggestions,
        JSON.stringify({ id: item.id, state: 'accepted' }),
      ]);
      setReviewedTools([...reviewedTools, item.toolsId]);
      if (suggestionType === 'insertion') {
        setAdminToolsAndGuidance([...adminToolsAndGuidance, item.toolsId]);
      }
    } else {
      setReviewedTools([...reviewedTools, item.toolsId]);
      setReviewedToolsAndGuidanceSuggestions([
        ...reviewedToolsAndGuidanceSuggestions,
        JSON.stringify({ id: item.id, state: 'rejected' }),
      ]);
      if (suggestionType === 'deletion') {
        setAdminToolsAndGuidance([...adminToolsAndGuidance, item.toolsId]);
      }
    }
    let data = [];
    for (const key in selectedToolsAndGuidanceSuggestions) {
      if (
        JSON.parse(selectedToolsAndGuidanceSuggestions[key]).toolsId !==
        item.toolsId
      ) {
        data.push(selectedToolsAndGuidanceSuggestions[key]);
      } else if (
        (reviewType === 'accept' && suggestionType === 'insertion') ||
        (reviewType === 'reject' && suggestionType === 'deletion')
      ) {
        const suggestionCardData = {
          toolsId: JSON.parse(selectedToolsAndGuidanceSuggestions[key]).toolsId,
          id: null,
          authorId: null,
          suggestionType: 'none',
          name: JSON.parse(selectedToolsAndGuidanceSuggestions[key]).name,
          type: 'option',
          createdAt: new Date(),
          isSelected: JSON.parse(selectedToolsAndGuidanceSuggestions[key])
            .isSelected,
        };
        data.push(JSON.stringify(suggestionCardData));
      }
    }

    setSelectedToolsAndGuidanceSuggestions(data);
    data = [];
    for (const key in toolsAndGuidanceSuggestions) {
      if (toolsAndGuidanceSuggestions[key].toolsId === item.toolsId) {
        toolsAndGuidanceSuggestions[key].isSelected =
          (reviewType === 'accept' && item.suggestionType === 'insertion') ||
            (reviewType === 'reject' && item.suggestionType === 'deletion')
            ? true
            : false;
        data.push(toolsAndGuidanceSuggestions[key]);
      } else {
        data.push(toolsAndGuidanceSuggestions[key]);
      }
    }
    setToolsAndGuidanceSuggestions(data);
  };

  const getEntryCriteriaSuggestions = async (response) => {
    let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
    if (
      response.data.entryCriteriaList &&
      !(
        response.data.resultReviewedJson &&
        response.data.resultReviewedJson.entryCriteriaSuggestions
      ) 
    ) {
      if(reorderResponse && reorderResponse?.isEntryReorder
      || reorderResponse?.isInputReorder){
      const updatedList = response.data.entryCriteriaList
      .map((item) =>
      JSON.stringify({
        ...item,
        suggestionType: 'none',
        authorId: CkEditorCurrentUser,
        suggestionId: null,
        createdAt: new Date(),
      })
    );
      const parsedUpdatedList = updatedList.map(e=>JSON.parse(e))
      const parsedFilteredEntry = parsedUpdatedList.filter(e=>e.criteriaTypeName === 'Entry Criteria')
			const parsedFilteredInput = parsedUpdatedList.filter(e=>e.criteriaTypeName === 'Input')
      const parsedReorderEntryArray = reorderResponse.isEntryReorder?.map(e=> JSON.parse(e))
			const parsedReorderInputArray = reorderResponse.isInputReorder?.map(e=> JSON.parse(e))
			if(reorderResponse?.isEntryReorder && !reorderResponse?.isInputReorder){
        setDisableEntryDrag(true)
			const idToObjectMap = new Map(parsedFilteredEntry.map(obj => [obj.id, obj]));
			const entryWithReorder = parsedReorderEntryArray.map(item => idToObjectMap.get(item.id))
			const allEntryWithReorder = [...entryWithReorder,...parsedFilteredInput]
			const allEntryWithReorderString = allEntryWithReorder.map(e=>JSON.stringify(e))
			setEntryCriteriaWithSuggestions(allEntryWithReorderString)
      if(response.data.resultReviewedJson.isReviewerEntryReordered[0]==='true'){
        setInitialEntryIdBeforeReorder(updatedList);
      } else{
        setInitialEntryIdBeforeReorder(allEntryWithReorderString);
      }
			} else if(reorderResponse?.isInputReorder && !reorderResponse?.isEntryReorder){
        setDisableInputDrag(true)
			 const idToObjectMapInput = new Map(parsedFilteredInput.map(obj => [obj.id, obj]));
			const inputWithReorder = parsedReorderInputArray.map(item => idToObjectMapInput.get(item.id))
			const allInputWithReorder = [...parsedFilteredEntry,...inputWithReorder]
			const allEntryWithReorderString = allInputWithReorder.map(e=>JSON.stringify(e))
			setEntryCriteriaWithSuggestions(allEntryWithReorderString)
      if(response.data.resultReviewedJson.isReviewerInputReordered[0]==='true'){
        setInitialEntryIdBeforeReorder(updatedList);
      } else{
        setInitialEntryIdBeforeReorder(allEntryWithReorderString);
      }
			} else if(reorderResponse?.isEntryReorder &&reorderResponse?.isInputReorder){
        setDisableEntryDrag(true)
        setDisableInputDrag(true)
        const idToObjectMap = new Map(parsedFilteredEntry.map(obj => [obj.id, obj]));
        const idToObjectMapInput = new Map(parsedFilteredInput.map(obj => [obj.id, obj]));
				const entryWithReorder = parsedReorderEntryArray.map(item => idToObjectMap.get(item.id))
				const inputWithReorder = parsedReorderInputArray.map(item => idToObjectMapInput.get(item.id))
				const allInputWithReorder = [...entryWithReorder,...inputWithReorder]
				const allEntryWithReorderString = allInputWithReorder.map(e=>JSON.stringify(e))
				setEntryCriteriaWithSuggestions(allEntryWithReorderString)
        if(response.data.resultReviewedJson.isReviewerEntryReordered[0]==='true'){
          setInitialEntryIdBeforeReorder(updatedList);
        } else{
          setInitialEntryIdBeforeReorder(allEntryWithReorderString);
        }
        if(response.data.resultReviewedJson.isReviewerInputReordered[0]==='true'){
          setInitialEntryIdBeforeReorder(updatedList);
        } else{
          setInitialEntryIdBeforeReorder(allEntryWithReorderString);
        }
			}
      return;
			}
      const updatedList = response.data.entryCriteriaList
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: 'none',
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );

      setEntryCriteriaWithSuggestions((prevState) => [
        ...prevState,
        ...updatedList,
      ]);
      setInitialEntryIdBeforeReorder((prevState)=>[...prevState,...updatedList])
    }

    if (
      response.data.resultReviewedJson &&
      response.data.resultReviewedJson.entryCriteriaSuggestions
    ) {
      

      const criteriaSuggestions =
        response.data.resultReviewedJson.entryCriteriaSuggestions;
      const suggestions = {};
      for (const criteria of criteriaSuggestions) {
        suggestions[JSON.parse(criteria).id] = {
          ...JSON.parse(criteria),
          type: JSON.parse(criteria).suggestionType,
        };
      }
      setReviewerSuggestionsInfo((prevState) => ({
        ...prevState,
        entryCriteria: suggestions,
      }));
    

      setEntryCriteriaSuggestions([
        ...response.data.resultReviewedJson.entryCriteriaSuggestions,
      ]);
      setReviewerEntryCriteriaSuggestions([
        ...response.data.resultReviewedJson.entryCriteriaSuggestions,
      ]);
      

      const adminEntryCriteria = response.data.entryCriteriaList
        .filter(
          (item) =>
            !response.data.resultReviewedJson.entryCriteriaSuggestions.some(
              (value) => item.id === JSON.parse(value).id
            )
        )
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: 'none',
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );
        const allEntry = [
          ...adminEntryCriteria,
          ...response.data.resultReviewedJson.entryCriteriaSuggestions,      ]
        const parsedAllEntry = allEntry.map(e=>JSON.parse(e))
        function customSort(a, b) {
          if (a.sequence !== b.sequence) {
            return a.sequence - b.sequence;
          }
          
           
          return a.criteriaType === 'Exit' ? -1 : 1;
          }
          const sortedArray = parsedAllEntry.sort(customSort);
          const sortedArrayString = sortedArray.map(e=>JSON.stringify(e))
        if(reorderResponse && reorderResponse?.isEntryReorder || reorderResponse?.isInputReorder){
          const allEntryWithoutReorder = [...adminEntryCriteria, ...response.data.resultReviewedJson.entryCriteriaSuggestions]
		    	const parsedallEntryWithoutReorder = allEntryWithoutReorder.map(e=>JSON.parse(e))
          const entryWithoutInsert = parsedallEntryWithoutReorder.filter(e=>e.id.length < 20)
			    const entryWithInsert = parsedallEntryWithoutReorder.filter(e=>e.id.length > 20)
          const sortedArray = entryWithoutInsert?.map((item)=>{
          
            return JSON.stringify({
              ...item,
               sequence: response.data.entryCriteriaList.filter((v) => v.id === item.id)[0]?.sequence,
               authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
              createdAt: new Date(),
              })
    
          })
          const parsedOriginalAddedEntry = entryWithInsert.length ? response.data.resultReviewedJson.originalAddedEntry
			    .map(e=>JSON.parse(e)) : []
			    const sortedAddedEntry = entryWithInsert.length ? entryWithInsert?.map((item)=>{
			   	return JSON.stringify({
					...item,
					 sequence: parsedOriginalAddedEntry.filter((v) => v.id === item.id)[0]?.sequence,
					 authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
					createdAt: new Date(),
				  })
				}) : []
          const parsedSortedArray = sortedArray.map(e=>JSON.parse(e))
          const parsedSortedAddedEntry = sortedAddedEntry.map(e=>JSON.parse(e))
       
          parsedSortedArray.sort((a,b)=>{
            return a.sequence - b.sequence
          })
          parsedSortedAddedEntry.sort((a,b)=>{
            return a.sequence - b.sequence
          })
          const combinedData = [...parsedSortedArray, ...parsedSortedAddedEntry]
        
		    	let i = 1;
		    	let j = 1;
          const initialSequence = [...combinedData]
		    	initialSequence.forEach(e=>{
		   		if(e.criteriaTypeID === 1){
					e.sequence = i;
					i++
			  	 }
				  else if(e.criteriaTypeID === 3){
					 e.sequence = j;
				  j++
				   }
		    	 })
           const parsedInitialSequence = initialSequence.map(e=>JSON.stringify(e))
		    	const parsedFilteredEntry = initialSequence.filter(e=>e.criteriaTypeName === 'Entry Criteria')
		    	const parsedFilteredInput = initialSequence.filter(e=>e.criteriaTypeName === 'Input')
          const parsedReorderEntryArray = reorderResponse.isEntryReorder?.map(e=> JSON.parse(e))
			    const parsedReorderInputArray = reorderResponse.isInputReorder?.map(e=> JSON.parse(e))
		    	const idToObjectMap = new Map(parsedallEntryWithoutReorder.map(obj => [obj.id, obj]));
		    	if(reorderResponse?.isEntryReorder && !reorderResponse?.isInputReorder){
          setDisableEntryDrag(true)
		    	const idToObjectMap = new Map(parsedFilteredEntry.map(obj => [obj.id, obj]));
		    	const entryWithReorder = parsedReorderEntryArray.map(item => idToObjectMap.get(item.id))
		    	const allEntryWithReorder = [...entryWithReorder,...parsedFilteredInput]
		    	const allEntryWithReorderString = allEntryWithReorder.map(e=>JSON.stringify(e))
		    	setEntryCriteriaWithSuggestions(allEntryWithReorderString)
          if(response.data.resultReviewedJson.isReviewerEntryReordered[0]==='true'){
            setReorderContributionCount((prev)=>prev + 1)
		    	setInitialEntryIdBeforeReorder(parsedInitialSequence)
          } else{
            setInitialEntryIdBeforeReorder(allEntryWithReorderString)
          }

		    	} else if(!reorderResponse?.isEntryReorder && reorderResponse?.isInputReorder){
          setDisableInputDrag(true)
		    	const idToObjectMapInput = new Map(parsedFilteredInput.map(obj => [obj.id, obj]));
		    	const inputWithReorder = parsedReorderInputArray.map(item => idToObjectMapInput.get(item.id))
		    	const allInputWithReorder = [...parsedFilteredEntry,...inputWithReorder]
		    	const allEntryWithReorderString = allInputWithReorder.map(e=>JSON.stringify(e))
		    	setEntryCriteriaWithSuggestions(allEntryWithReorderString)
          if(response.data.resultReviewedJson.isReviewerInputReordered[0]==='true'){
            setReorderContributionCount((prev)=>prev + 1)
            setInitialEntryIdBeforeReorder(parsedInitialSequence)
            } else{
              setInitialEntryIdBeforeReorder(allEntryWithReorderString)
            }

		    	}
		    	else if(reorderResponse.isEntryReorder && reorderResponse.isInputReorder){
            setDisableEntryDrag(true)
            setDisableInputDrag(true)
            const idToObjectMap = new Map(parsedFilteredEntry.map(obj => [obj.id, obj]));
            const idToObjectMapInput = new Map(parsedFilteredInput.map(obj => [obj.id, obj]));
            const entryWithReorder = parsedReorderEntryArray.map(item => idToObjectMap.get(item.id))
            const inputWithReorder = parsedReorderInputArray.map(item => idToObjectMapInput.get(item.id))
            const allEntryWithReorder = [...entryWithReorder,...inputWithReorder]
		    	   const allEntryWithReorderString = allEntryWithReorder.map(e=>JSON.stringify(e))
		    	   setEntryCriteriaWithSuggestions(allEntryWithReorderString)
		    	    
		    	   if(response.data.resultReviewedJson.isReviewerEntryReordered[0]==='true'){
              setReorderContributionCount((prev)=>prev + 1)
              setInitialEntryIdBeforeReorder(parsedInitialSequence)
              } else{
                setInitialEntryIdBeforeReorder(allEntryWithReorderString)
              }
              if(response.data.resultReviewedJson.isReviewerInputReordered[0]==='true'){
                setReorderContributionCount((prev)=>prev + 1)
                setInitialEntryIdBeforeReorder(parsedInitialSequence)
                } else{
                  setInitialEntryIdBeforeReorder(allEntryWithReorderString)
                }
		    	} 
               return;
           }


      setEntryCriteriaWithSuggestions([
        ...sortedArrayString
      ]);
      setInitialEntryIdBeforeReorder([
        ...sortedArrayString
      ]);
    }
    else {
      setReviewerSuggestionsInfo((prevState) => ({
        ...prevState,
        entryCriteria: {},
      }));
    }
  };
  const getExitCriteriaSuggestions = async (response) => {
    let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
    if (
      response.data.exitCriteriaList &&
      !(
        response.data.resultReviewedJson &&
        response.data.resultReviewedJson.exitCriteriaSuggestions
      )
    ) {
      if(reorderResponse && reorderResponse?.isExitReorder
      || reorderResponse?.isOutputReorder){
        const updatedList = response.data.exitCriteriaList
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: 'none',
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );
        const parsedUpdatedList = updatedList.map(e=>JSON.parse(e))
        const parsedFilteredExit = parsedUpdatedList.filter(e=>e.criteriaTypeName === 'Exit Criteria')
        const parsedFilteredOutput = parsedUpdatedList.filter(e=>e.criteriaTypeName === 'Output')
        const parsedReorderExitArray = reorderResponse.isExitReorder?.map(e=> JSON.parse(e))
        const parsedReorderOutputArray = reorderResponse.isOutputReorder?.map(e=> JSON.parse(e))
        if(reorderResponse?.isExitReorder && !reorderResponse?.isOutputReorder){
          setDisableExitDrag(true)
          const idToObjectMap = new Map(parsedFilteredExit.map(obj => [obj.id, obj]));
          const exitWithReorder = parsedReorderExitArray.map(item => idToObjectMap.get(item.id))
          const allExitWithReorder = [...exitWithReorder,...parsedFilteredOutput]
          const allExitWithReorderString = allExitWithReorder.map(e=>JSON.stringify(e))
          setExitCriteriaWithSuggestions(allExitWithReorderString)
          if(response.data.resultReviewedJson.isReviewerExitReordered[0]==='true'){
            setInitialExitIdBeforeReorder(updatedList);
          } else{
            setInitialExitIdBeforeReorder(allExitWithReorderString);
          }
          } else if(reorderResponse?.isOutputReorder && !reorderResponse?.isExitReorder){
            setDisableOutputDrag(true)
            const idToObjectMapInput = new Map(parsedFilteredOutput.map(obj => [obj.id, obj]));
           const outputWithReorder = parsedReorderOutputArray.map(item => idToObjectMapInput.get(item.id))
           const allOutputWithReorder = [...parsedFilteredExit,...outputWithReorder]
           const allExitWithReorderString = allOutputWithReorder.map(e=>JSON.stringify(e))
           setExitCriteriaWithSuggestions(allExitWithReorderString)
           if(response.data.resultReviewedJson.isReviewerOutputReordered[0]==='true'){
            setInitialExitIdBeforeReorder(updatedList);
          } else{
            setInitialExitIdBeforeReorder(allExitWithReorderString);
          }
           } else if(reorderResponse?.isExitReorder && reorderResponse?.isOutputReorder){
            setDisableExitDrag(true)
            setDisableOutputDrag(true)
            const idToObjectMap = new Map(parsedFilteredExit.map(obj => [obj.id, obj]));
            const idToObjectMapInput = new Map(parsedFilteredOutput.map(obj => [obj.id, obj]));
            const exitWithReorder = parsedReorderExitArray.map(item => idToObjectMap.get(item.id))
            const outputWithReorder = parsedReorderOutputArray.map(item => idToObjectMapInput.get(item.id))
            const allExitWithReorder = [...exitWithReorder,...outputWithReorder]
            const allExitWithReorderString = allExitWithReorder.map(e=>JSON.stringify(e))
            setExitCriteriaWithSuggestions(allExitWithReorderString)
            if(response.data.resultReviewedJson.isReviewerExitReordered[0]==='true'){
              setInitialExitIdBeforeReorder(updatedList);
            } else{
              setInitialExitIdBeforeReorder(allExitWithReorderString);
            }
            if(response.data.resultReviewedJson.isReviewerOutputReordered[0]==='true'){
              setInitialExitIdBeforeReorder(updatedList);
            } else{
              setInitialExitIdBeforeReorder(allExitWithReorderString);
            }
          }
          return
      }
      const updatedList = response.data.exitCriteriaList
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: 'none',
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );

      setExitCriteriaWithSuggestions((prevState) => [
        ...prevState,
        ...updatedList,
      ]);
      setInitialExitIdBeforeReorder((prevState)=>[...prevState,...updatedList])
    }

    if (
      response.data.resultReviewedJson &&
      response.data.resultReviewedJson.exitCriteriaSuggestions
    ) {

      const criteriaSuggestions =
        response.data.resultReviewedJson.exitCriteriaSuggestions;
      const suggestions = {};
      for (const criteria of criteriaSuggestions) {
        suggestions[JSON.parse(criteria).id] = {
          ...JSON.parse(criteria),
          type: JSON.parse(criteria).suggestionType,
        };
      }
      setReviewerSuggestionsInfo((prevState) => ({
        ...prevState,
        exitCriteria: suggestions,
      }));

      setExitCriteriaSuggestions([
        ...response.data.resultReviewedJson.exitCriteriaSuggestions,
      ]);
      setReviewerExitCriteriaSuggestions([
        ...response.data.resultReviewedJson.exitCriteriaSuggestions,
      ]);

      const adminExitCriteria = response.data.exitCriteriaList
        .filter(
          (item) =>
            !response.data.resultReviewedJson.exitCriteriaSuggestions.some(
              (value) => item.id === JSON.parse(value).id
            )
        )
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: 'none',
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );
        const allEntry = [
          ...adminExitCriteria,
          ...response.data.resultReviewedJson.exitCriteriaSuggestions,    ]
        const parsedAllEntry = allEntry.map(e=>JSON.parse(e))
        function customSort(a, b) {
          if (a.sequence !== b.sequence) {
            return a.sequence - b.sequence;
          }
          
           
          return a.criteriaType === 'Exit' ? -1 : 1;
          }
          const sortedArray = parsedAllEntry.sort(customSort);
          const sortedArrayString = sortedArray.map(e=>JSON.stringify(e))
       if(reorderResponse && reorderResponse?.isExitReorder || reorderResponse?.isOuputReorder){
        const allExitWithoutReorder = [...adminExitCriteria, ...response.data.resultReviewedJson.exitCriteriaSuggestions]
        const parsedallExitWithoutReorder = allExitWithoutReorder.map(e=>JSON.parse(e))
        const exitWithoutInsert = parsedallExitWithoutReorder.filter(e=>e.id.length < 20)
				const exitWithInsert = parsedallExitWithoutReorder.filter(e=>e.id.length > 20)
        const sortedArray = exitWithoutInsert?.map((item)=>{
          
          return JSON.stringify({
            ...item,
             sequence: response.data.exitCriteriaList.filter((v) => v.id === item.id)[0]?.sequence,
             authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
            createdAt: new Date(),
            })
  
        })
        const parsedOriginalAddedExit = exitWithInsert.length ?response.data.resultReviewedJson.originalAddedExit
				.map(e=>JSON.parse(e)):[]
				const sortedAddedArray =  exitWithInsert.length ? exitWithInsert.map((item)=>{
					return JSON.stringify({
						...item,
						 sequence: parsedOriginalAddedExit.filter((v) => v.id === item.id)[0]?.sequence,
						 authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
						createdAt: new Date(),
					  }) 
				}): []
        const parsedSortedArray = sortedArray.map(e=>JSON.parse(e))
        const parsedSortedAddedArray = sortedAddedArray.map(e=>JSON.parse(e))
        parsedSortedArray.sort((a,b)=>{
          return a.sequence - b.sequence
        })
        parsedSortedAddedArray.sort((a,b)=>{
          return a.sequence - b.sequence
        })
        const combinedData = [...parsedSortedArray, ...parsedSortedAddedArray]
        let i = 1;
        let j = 1;
        const initialSequence = [...combinedData]
        initialSequence.forEach(e=>{
         if(e.criteriaTypeID === 2){
        e.sequence = i;
        i++
         }
        else if(e.criteriaTypeID === 4){
         e.sequence = j;
        j++
         }
         })

         const parsedInitialSequence = initialSequence.map(e=>JSON.stringify(e))
   
        const reorderResponse=JSON.parse(response.data.reorderJson)
        const parsedFilteredExit = parsedallExitWithoutReorder.filter(e=>e.criteriaTypeName === 'Exit Criteria')
        const parsedFilteredOutput = parsedallExitWithoutReorder.filter(e=>e.criteriaTypeName === 'Output')
        const parsedReorderExitArray = reorderResponse.isExitReorder?.map(e=> JSON.parse(e))
        const parsedReorderOutputArray = reorderResponse.isOutputReorder?.map(e=> JSON.parse(e))
        if(reorderResponse?.isExitReorder && !reorderResponse?.isOutputReorder){
          setDisableExitDrag(true)
          const idToObjectMap = new Map(parsedFilteredExit.map(obj => [obj.id, obj]));
          const exitWithReorder = parsedReorderExitArray.map(item => idToObjectMap.get(item.id))
          const allExitWithReorder = [...exitWithReorder,...parsedFilteredOutput]
          const allExitWithReorderString = allExitWithReorder.map(e=>JSON.stringify(e))
          setExitCriteriaWithSuggestions(allExitWithReorderString)
          if(response.data.resultReviewedJson.isReviewerExitReordered[0]==='true'){
            setReorderContributionCount((prev)=>prev + 1)
            setInitialExitIdBeforeReorder(parsedInitialSequence);
          } else{
            setInitialExitIdBeforeReorder(allExitWithReorderString);
          }
          } else if(reorderResponse?.isOutputReorder && !reorderResponse?.isExitReorder){
            setDisableOutputDrag(true)
            const idToObjectMapInput = new Map(parsedFilteredOutput.map(obj => [obj.id, obj]));
           const outputWithReorder = parsedReorderOutputArray.map(item => idToObjectMapInput.get(item.id))
           const allOutputWithReorder = [...parsedFilteredExit,...outputWithReorder]
           const allExitWithReorderString = allOutputWithReorder.map(e=>JSON.stringify(e))
           setExitCriteriaWithSuggestions(allExitWithReorderString)
           if(response.data.resultReviewedJson.isReviewerOutputReordered[0]==='true'){
            setReorderContributionCount((prev)=>prev + 1)
            setInitialExitIdBeforeReorder(parsedInitialSequence);
          } else{
            setInitialExitIdBeforeReorder(allExitWithReorderString);
          }
           } else if(reorderResponse?.isExitReorder && reorderResponse?.isOutputReorder){
            setDisableExitDrag(true)
            setDisableOutputDrag(true)
            const idToObjectMap = new Map(parsedFilteredExit.map(obj => [obj.id, obj]));
            const idToObjectMapInput = new Map(parsedFilteredOutput.map(obj => [obj.id, obj]));
            const exitWithReorder = parsedReorderExitArray.map(item => idToObjectMap.get(item.id))
            const outputWithReorder = parsedReorderOutputArray.map(item => idToObjectMapInput.get(item.id))
            const allExitWithReorder = [...exitWithReorder,...outputWithReorder]
            const allExitWithReorderString = allExitWithReorder.map(e=>JSON.stringify(e))
            setExitCriteriaWithSuggestions(allExitWithReorderString)
            if(response.data.resultReviewedJson.isReviewerExitReordered[0]==='true'){
              setReorderContributionCount((prev)=>prev + 1)
              setInitialExitIdBeforeReorder(parsedInitialSequence);
            } else{
              setInitialExitIdBeforeReorder(allExitWithReorderString);
            }
            if(response.data.resultReviewedJson.isReviewerOutputReordered[0]==='true'){
              setReorderContributionCount((prev)=>prev + 1)
              setInitialExitIdBeforeReorder(parsedInitialSequence);
            } else{
              setInitialExitIdBeforeReorder(allExitWithReorderString);
            }
          }
          return

       }




      setExitCriteriaWithSuggestions([
       ...sortedArrayString
      ]);
      setInitialExitIdBeforeReorder([
        ...sortedArrayString
      ]);
    }
    else {
      setReviewerSuggestionsInfo((prevState) => ({
        ...prevState,
        exitCriteria: {},
      }));
    }
  };
  const getTaskSuggestions = async (response) => {
    let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
    if (
      response.data.taskList &&
      !(
        response.data.resultReviewedJson &&
        response.data.resultReviewedJson.taskSuggestions
      )
    ) { 
   
        if(reorderResponse && reorderResponse?.isTaskReorder){
          const updatedList = response.data.taskList
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: 'none',
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        ); 
          setDisableDrag(true)
          const parsedUpdatedList = updatedList.map(e=>JSON.parse(e))
          const parsedReorderTaskArray = reorderResponse.isTaskReorder.map(e=> JSON.parse(e))
          const idToObjectMap = new Map(parsedUpdatedList.map(obj => [obj.id, obj]));
          const allTasksWithReorder = parsedReorderTaskArray.map(item => idToObjectMap.get(item.id))
          const allTasksWithReorderString = allTasksWithReorder.map(e=>JSON.stringify(e))
          setTaskWithSuggestions((prevState) => [
            ...prevState,
            ...allTasksWithReorderString,
          ]);
          if(response.data.resultReviewedJson.isReviewerTaskReordered[0]==='true'){
            setInitialTaskIdBeforeReorder(updatedList);
          } else{
            setInitialTaskIdBeforeReorder((prevState) => [
              ...prevState,
              ...allTasksWithReorderString,
            ]);
          }
          return;
        } else {
      const updatedList = response.data.taskList
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: 'none',
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );

      setTaskWithSuggestions((prevState) => [
        ...prevState,
        ...updatedList,
      ]);
      setInitialTaskIdBeforeReorder((prevState)=>[...prevState,...updatedList])

    }
  }
    if (
      response.data.resultReviewedJson &&
      response.data.resultReviewedJson.taskSuggestions&&
			!(reorderResponse && reorderResponse?.isTaskReorder)
    ) { 
      const taskSuggestions =
        response.data.resultReviewedJson.taskSuggestions;
      const suggestions = {};
      for (const task of taskSuggestions) {
        suggestions[JSON.parse(task).id] = {
          ...JSON.parse(task),
          type: JSON.parse(task).suggestionType,
        };
      }
      setReviewerSuggestionsInfo((prevState) => ({
        ...prevState,
        task: suggestions,
      }));

      setTaskSuggestions([
        ...response.data.resultReviewedJson.taskSuggestions,
      ]);
      setReviewerTaskSuggestions([
        ...response.data.resultReviewedJson.taskSuggestions,
      ]);
 
      const adminTask = response.data.taskList
        .filter(
          (item) =>
            !response.data.resultReviewedJson.taskSuggestions.some(
              (value) => item.id === JSON.parse(value).id
            )
        )
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: 'none',
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );
        const allTask = [
					...adminTask,
        ...response.data.resultReviewedJson.taskSuggestions,
				]
				const parsedAllTask = allTask.map(e=>JSON.parse(e))
				parsedAllTask.sort((a,b)=>{
					return a.sequence - b.sequence
				})
			 const stringParsedAllTask = parsedAllTask.map(e=>JSON.stringify(e))
      setTaskWithSuggestions([
        ...stringParsedAllTask
      ]);
      setInitialTaskIdBeforeReorder([
       ...stringParsedAllTask
      ]);
      return
    }
		if(reorderResponse && reorderResponse?.isTaskReorder){
      
      const parsedReorderTaskArray = reorderResponse.isTaskReorder.map(e=> JSON.parse(e))
			setDisableDrag(true)
			if(response.data.resultReviewedJson && response.data.resultReviewedJson.taskSuggestions){
					const taskSuggestions =
        response.data.resultReviewedJson.taskSuggestions;
      const suggestions = {};
      for (const task of taskSuggestions) {
        suggestions[JSON.parse(task).id] = {
          ...JSON.parse(task),
          type: JSON.parse(task).suggestionType,
        };
      }
      setReviewerSuggestionsInfo((prevState) => ({
        ...prevState,
        task: suggestions,
      }));

      setTaskSuggestions([
        ...response.data.resultReviewedJson.taskSuggestions,
      ]);
      setReviewerTaskSuggestions([
        ...response.data.resultReviewedJson.taskSuggestions,
      ]);

      const adminTask = response.data.taskList
        .filter(
          (item) =>
            !response.data.resultReviewedJson.taskSuggestions.some(
              (value) => item.id === JSON.parse(value).id
            )
        )
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: 'none',
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );
        const allTask = [  ...adminTask,
          ...response.data.resultReviewedJson.taskSuggestions,]

        const parsedAllTask = allTask.map(e=>JSON.parse(e))
        const instertedTask = parsedAllTask.filter(e=>e.id.length > 20)
				const notInsertedTask = parsedAllTask.filter(e=>e.id.length < 20)
        const sortedArray = notInsertedTask.map((item)=>{
          return JSON.stringify({
            ...item,
             sequence: response.data.taskList.filter((v) => v.id === item.id)[0]?.sequence,
             authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
            createdAt: new Date(),
            })
        })
        const parsedOriginalAddedTask = instertedTask.length ?response.data.resultReviewedJson.originalAddedTask
							.map(e=>JSON.parse(e)):[]
        const sortedAddedArray = instertedTask.length?instertedTask.map((item)=>{
          return JSON.stringify({
            ...item,
            sequence: parsedOriginalAddedTask.filter((v) => v.id === item.id)[0]?.sequence,
            authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
             createdAt: new Date(),
         })
         }):[];
         
         const parsedSortedArray = sortedArray.map(e=>JSON.parse(e))
         const parsedSortedAddedArray = sortedAddedArray.map(e=>JSON.parse(e))
        parsedSortedArray.sort((a,b)=>{
        return a.sequence - b.sequence
        })
        parsedSortedAddedArray.sort((a,b)=>{
        return a.sequence - b.sequence
         })

        const combinedData = [...parsedSortedArray,...parsedSortedAddedArray]
        
      setTaskWithSuggestions([
        ...adminTask,
        ...response.data.resultReviewedJson.taskSuggestions,
      ]);
      setInitialTaskIdBeforeReorder([
        ...adminTask,
        ...response.data.resultReviewedJson.taskSuggestions,
      ]);
							const allTaskWithoutReorder = [...adminTask, ...response.data.resultReviewedJson.taskSuggestions]
							const parsedallTaskWithoutReorder = allTaskWithoutReorder.map(e=>JSON.parse(e))
							const idToObjectMap = new Map(parsedallTaskWithoutReorder.map(obj => [obj.id, obj]));
							const allTasksWithReorder = parsedReorderTaskArray.map(item => idToObjectMap.get(item.id))
							const allTasksWithReorderString = allTasksWithReorder.map(e=>JSON.stringify(e))
							setTaskWithSuggestions(allTasksWithReorderString)
              const initialSequence = [...combinedData]
							initialSequence.forEach((item, index) => {
								item.sequence = index + 1;
							  });
		          const allTasksUpdatedSequence = initialSequence.map(e=>JSON.stringify(e))
              if(response.data.resultReviewedJson.isReviewerTaskReordered[0]==='true'){
                setReorderContributionCount((prev)=>prev + 1)
                setInitialTaskIdBeforeReorder([...allTasksUpdatedSequence])
              } else{
                setInitialTaskIdBeforeReorder((prevState)=>[
                  ...prevState,...allTasksWithReorderString
                ])
               
              }
						}else{
							const adminTask = response.data.taskList.map((item) =>
					  JSON.stringify({
						...item,
						suggestionType: 'none',
						authorId: CkEditorCurrentUser,
						suggestionId: null,
						createdAt: new Date(),
					})   
				); 
        const parsedAdminTask = adminTask.map(e=>JSON.parse(e))
				const idToObjectMap = new Map(parsedAdminTask.map(obj => [obj.id, obj]));
        const allTasksWithReorder = parsedReorderTaskArray.map(item => idToObjectMap.get(item.id))
				const allTasksWithReorderString = allTasksWithReorder.map(e=>JSON.stringify(e))
        setTaskWithSuggestions(allTasksWithReorderString)
				setInitialTaskIdBeforeReorder(adminTask)
				}
  } else {
      setReviewerSuggestionsInfo((prevState) => ({
        ...prevState,
        task: {},
      }));
    }   //JSON.parse(taskReorderArray?.[0] || '{}')?.authorId?.[0]
  };
  const getTaskSuggestionsReorder = async (response)=>{
		let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
		if(reorderResponse && reorderResponse.isTaskReorder){
      setReorderHistory(reorderResponse)
      setTaskReorderArray(reorderResponse.isTaskReorder)
      setCkEditorCurrentReorderUserImage(JSON.parse(reorderResponse.isTaskReorder[0]).authorId[1])
			setCkEditorCurrentReorderUsername(JSON.parse(reorderResponse.isTaskReorder[0]).authorId[0])
      setReorderedTaskRejectionCardInfo([JSON.parse(reorderResponse.isTaskReorder[0]).authorId[1],JSON.parse(reorderResponse.isTaskReorder[0]).authorId[0]])
      
			setTaskReorder(true)
		}
	
	}
  const getEntrySuggestionsReorder = async (response)=>{
    let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
		if(reorderResponse && reorderResponse.isEntryReorder){
      setReorderHistory(reorderResponse)
      setEntryCriteriaReorderArray(reorderResponse.isEntryReorder)
      setCkEditorCurrentEntryReorderUserImage(JSON.parse(reorderResponse.isEntryReorder[0]).authorId[1])
			setCkEditorCurrentEntryReorderUsername(JSON.parse(reorderResponse.isEntryReorder[0]).authorId[0])
      setReorderedEntryRejectionCardInfo([JSON.parse(reorderResponse.isEntryReorder[0]).authorId[1],JSON.parse(reorderResponse.isEntryReorder[0]).authorId[0]])
			setEntryContributionReorder(true)
		}	
	}
  const getExitSuggestionsReorder = async (response)=>{
    let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
		if(reorderResponse && reorderResponse.isExitReorder){
      setReorderHistory(reorderResponse)
      setExitCriteriaReorderArray(reorderResponse.isExitReorder)
      setCkEditorCurrentExitReorderUserImage(JSON.parse(reorderResponse.isExitReorder[0]).authorId[1])
			setCkEditorCurrentExitReorderUsername(JSON.parse(reorderResponse.isExitReorder[0]).authorId[0])
      setReorderedExitRejectionCardInfo([JSON.parse(reorderResponse.isExitReorder[0]).authorId[1],JSON.parse(reorderResponse.isExitReorder[0]).authorId[0]])
			setExitContributionReorder(true)
		}
	
	}

  const getInputSuggestionsReorder = async (response)=>{
    let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
		if(response.data.reorderJson && reorderResponse.isInputReorder){
      setReorderHistory(reorderResponse)
			setInputCriteriaReorderArray(reorderResponse.isInputReorder)
      setCkEditorCurrentInputReorderUserImage(JSON.parse(reorderResponse.isInputReorder[0]).authorId[1])
			setCkEditorCurrentInputReorderUsername(JSON.parse(reorderResponse.isInputReorder[0]).authorId[0])
      setReorderedInputRejectionCardInfo([JSON.parse(reorderResponse.isInputReorder[0]).authorId[1],JSON.parse(reorderResponse.isInputReorder[0]).authorId[0]])
			setInputContributionReorder(true)
		}
	
	}
  const getOutputSuggestionsReorder = async (response)=>{
    let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
		if(response.data.reorderJson && reorderResponse.isOutputReorder){
      setReorderHistory(reorderResponse)
			setOutputCriteriaReorderArray(reorderResponse.isOutputReorder)
      setCkEditorCurrentOutputReorderUserImage(JSON.parse(reorderResponse.isOutputReorder[0]).authorId[1])
			setCkEditorCurrentOutputReorderUsername(JSON.parse(reorderResponse.isOutputReorder[0]).authorId[0])
      setReorderedOutputRejectionCardInfo([JSON.parse(reorderResponse.isOutputReorder[0]).authorId[1],JSON.parse(reorderResponse.isOutputReorder[0]).authorId[0]])
			setOutputContributionReorder(true)
		}
	}

  const getUsersInfo = async () => {
    if (contributorsData.resultReviewedJson) {
      if (contributorsData.resultReviewedJson.description) {
        getContributor(contributorsData.resultReviewedJson.description);
      }
      if (contributorsData.resultReviewedJson.supportingActivities) {
        getContributor(
          contributorsData.resultReviewedJson.supportingActivities
        );
      }
      if (contributorsData.resultReviewedJson.toolsAndGuidance) {
        getContributor(contributorsData.resultReviewedJson.toolsAndGuidance);
      }
      if (contributorsData.resultReviewedJson.tipsAndTechniques) {
        getContributor(contributorsData.resultReviewedJson.tipsAndTechniques);
      }
      if (contributorsData.resultReviewedJson.templateSuggestions) {
        getContributor(contributorsData.resultReviewedJson.templateSuggestions);
      }
      if (contributorsData.resultReviewedJson.entryCriteriaSuggestions) {
        getContributor(contributorsData.resultReviewedJson.entryCriteriaSuggestions);
      }
      if (contributorsData.resultReviewedJson.exitCriteriaSuggestions) {
        getContributor(contributorsData.resultReviewedJson.exitCriteriaSuggestions);
      }
      if (contributorsData.resultReviewedJson.taskSuggestions) {
        getContributor(contributorsData.resultReviewedJson.taskSuggestions);
      }
      if (contributorsData.resultReviewedJson.deliverables) {
        getContributor(contributorsData.resultReviewedJson.deliverables);
      }
    }
    if (contributorsData.resultCalloutJson) {
      if (contributorsData.resultCalloutJson.description) {
        getContributor(contributorsData.resultCalloutJson.description);
      }
      if (contributorsData.resultCalloutJson.supportingActivities) {
        getContributor(contributorsData.resultCalloutJson.supportingActivities);
      }
      if (contributorsData.resultCalloutJson.toolsAndGuidance) {
        getContributor(contributorsData.resultCalloutJson.toolsAndGuidance);
      }
      if (contributorsData.resultCalloutJson.tipsAndTechniques) {
        getContributor(contributorsData.resultCalloutJson.tipsAndTechniques);
      }
      if (contributorsData.resultCalloutJson.templateSuggestions) {
        getContributor(contributorsData.resultCalloutJson.templateSuggestions);
      }
      if (contributorsData.resultCalloutJson.entryCriteriaSuggestions) {
        getContributor(contributorsData.resultCalloutJson.entryCriteriaSuggestions);
      }
      if (contributorsData.resultCalloutJson.exitCriteriaSuggestions) {
        getContributor(contributorsData.resultCalloutJson.exitCriteriaSuggestions);
      }
      if (contributorsData.resultCalloutJson.taskSuggestions) {
        getContributor(contributorsData.resultCalloutJson.taskSuggestions);
      }
      if (contributorsData.resultCalloutJson.deliverables) {
        getContributor(contributorsData.resultCalloutJson.deliverables);
      }
    }
    if (!userDataMap.has(CkEditorCurrentUser)) {
      graphUserData.push(CkEditorCurrentUser);
      userDataMap.set(CkEditorCurrentUser, 1);
    }
    const userProfileMapping = {};
    const accessToken = await axiosTokenService();
    for (const id in graphUserData) {
      const userEmail = graphUserData[id];
      const userData = await getOtherUserProfile(accessToken, userEmail);
      contributorInfo.push({
        id: userData.mail,
        name: userData.firstName + ' ' + userData.surname,
        avatar: userData.photo,
      });
      userProfileMapping[userData.mail] = [
        userData.firstName + ' ' + userData.surname,
        userData.photo,
      ];
    }
    setCkEditorUserMap(userProfileMapping);
    setCkEditorUsers(contributorInfo);
    if (contributorsData.reviewerEmail) {
      await getOtherUserProfile(
        accessToken,
        contributorsData.reviewerEmail
      ).then((response) => {
        setReviewerInfo(response.firstName + ' ' + response.surname);
      });
    }
  };

  const getLicense = async () => {
    const response = await axiosGetService(
      `api/sdmsettings?sdmKey=CKEditorLicenseKey`
    );
    const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
  };

  const getMessage = async (title) => {
    const message = {};
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=approvePrivilegeMessage&title=0`
    ).then((response) => {
      message['approvePrivilegeMessage'] = response.data;
    });
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=approvalPageWithoutHistory&title=0`
    ).then((response) => {
      message['approvalPageWithoutHistory'] = response.data;
    });
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=publishedDocumentMessage&title=0`
    ).then((response) => {
      message['publishedDocumentMessage'] = response.data;
    });
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=approvalSubmissionMessage&title=${encodeURIComponent(
        title
      )}`
    ).then((response) => {
      message['approvalSubmissionMessage'] = response.data;
    });
    setMessagesType(message);
  };

  const updateToolsSuggestions = () => {
    const updatedCard = [];
    for (const id in selectedToolsAndGuidanceSuggestions) {
      if (
        !adminToolsAndGuidance.includes(
          JSON.parse(selectedToolsAndGuidanceSuggestions[id]).toolsId &&
          ((JSON.parse(selectedToolsAndGuidanceSuggestions[id]).authorId !==
            CkEditorCurrentUser &&
            !reviewedTools.includes(
              JSON.parse(selectedToolsAndGuidanceSuggestions[id]).toolsId
            )) ||
            JSON.parse(selectedToolsAndGuidanceSuggestions[id]).authorId ===
            CkEditorCurrentUser)
        ) ||
        (JSON.parse(selectedToolsAndGuidanceSuggestions[id]).suggestionType ===
          'deletion' &&
          JSON.parse(selectedToolsAndGuidanceSuggestions[id]).authorId ===
          CkEditorCurrentUser)
      ) {
        updatedCard.push(selectedToolsAndGuidanceSuggestions[id]);
      }
    }
    setSidebarCard([...updatedCard]);
  };

  const getToolsConatinerHeight = () => {
    const height = $('.ToolsContentContainer').height();
    setToolsSidebarHeight(height + 63);
  };

  const getTemplateSidebarHeight = () => {
    const height = $('.templateContentContainer').height();
    setTemplateSidebarHeight(height + 63);
  };

  const updateSuggestions = (id, text, type) => {
    if(reviewerSuggestionsInfo !== null && reviewerSuggestionsInfo[type] !== undefined && reviewerSuggestionsInfo[type] !== null){
      const activitySuggestions = reviewerSuggestionsInfo[type];
      if (id in activitySuggestions && !activitySuggestions[id].data) {
        setReviewerSuggestionsInfo((prevState) => ({
          ...prevState,
          [type]: {
            ...reviewerSuggestionsInfo[type],
            [id]: { ...reviewerSuggestionsInfo[type][id], data: text },
          },
        }));
      }
    }
  };

  useEffect(() => {
    getToolsConatinerHeight();
  });

  useEffect(() => {
    getTemplateSidebarHeight();
  });

  useEffect(() => {
    updateToolsSuggestions();
  }, [selectedToolsAndGuidanceSuggestions]);

  useEffect(() => {
    getLicense();
  }, []);

  useEffect(() => {
    if (contributorsData) {
      getUsersInfo();
    }
  }, [contributorsData]);

  useEffect(() => {
    if (checkNotificationClick) {
      window.location.reload();
    }
    setCheckNotificationClick(true);
  }, [activityId, complexityId]);

  useEffect(() => {
    const suggestionItems = document.getElementsByClassName(
      'ck-suggestion-marker'
    );
    for (let i = 0; i < suggestionItems.length; i++) {
      if (suggestionItems[i].attributes['data-suggestion']) {
        const suggestionId =
          suggestionItems[i].attributes['data-suggestion'].value;
        const suggestionText = suggestionItems[i].innerHTML;
        updateSuggestions(suggestionId, suggestionText, 'description');
        updateSuggestions(suggestionId, suggestionText, 'supportingActivities');
        updateSuggestions(suggestionId, suggestionText, 'tipsAndTechniques');
        updateSuggestions(suggestionId, suggestionText, 'deliverables');
      }
    }
  });

  const loadBreadcrumb = async () => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=${activityId}&
			itemTypeId=2&isEdit=true&isAutoComplete=false&
			isSearchResultsHome=false&isSearchResults=false&
			isMyContributions=false&documentStatus=0&
			isNotifications=false&solutionMethodId=${solutionMethodId}&phaseId=${phaseId !== 'null'
        ? phaseId
        : parentPhaseId !== 'null'
          ? parentPhaseId
          : 0
      }`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split('>')
        .map((item) => item.trim());
      const projectBreadcrumb = {
        title: breadcrumbTitle[1],
        type: 'projectType',
        path: `/project/${projectTypeId}`,
        parent: ['home', 'myContributions'],
      };
      const solutionMethodBreadcrumb = {
        title: breadcrumbTitle[2],
        type: 'solutionMethod',
        path: `/project/${projectTypeId}/method/${solutionMethodId}`,
        parent: 'projectType',
      };
      const phaseBreadcrumb = {
        title: breadcrumbTitle[3],
        type: 'Phase',
        path: `/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}`,
        parent: 'solutionMethod',
      };
      const titleBreadcrumb = {
        title: breadcrumbTitle[4],
        type: 'pageTitle',
        path: `/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}/activity/${activityIdentifier}?complexity=${complexityId}`,
        parent: ['phase'],
      };
      const pageBreadcrumb = {
        title: breadcrumbTitle[5],
        type: 'activity',
        path: window.location.pathname + `?complexity=${complexityId}`,
        parent: ['pageTitle'],
      };
      const newBreadcrumbs = breadcrumbs.slice(
        0,
        breadcrumbs.length > 1 && breadcrumbs[1].type === 'myContributions'
          ? 2
          : 1
      );
      if (
        phaseId === 'null' &&
        parentPhaseId === 'null' &&
        solutionMethodId === '0'
      ) {
        newBreadcrumbs.push(titleBreadcrumb);
        newBreadcrumbs.push(pageBreadcrumb);
      } else {
        newBreadcrumbs.push(projectBreadcrumb);
        newBreadcrumbs.push(solutionMethodBreadcrumb);
        newBreadcrumbs.push(phaseBreadcrumb);
        newBreadcrumbs.push(titleBreadcrumb);
        newBreadcrumbs.push(pageBreadcrumb);
      }
      setBreadcrumbs(newBreadcrumbs);
      TabTitle(breadcrumbTitle[5]);
    });
  };

  useEffect(() => {
    getActivityContents();
    getReviewerContent();
    setSkipNavigationId('innerContent');
  }, []);

  useEffect(() => {
    if (activityContent) {
      track(2, 132, activityContent.activityId, activityContent.activityName);
      getMessage(activityContent.activityName);
    }
  }, [activityContent]);

  useEffect(() => {
    loadBreadcrumb();
    getRatingPoints();
  }, []);

  useEffect(() => {
    checkGamificationStatus();
  }, []);

  if (leavingWithoutSave && !showDiscardPopUp && !userLeaveWithoutSave) {
    setCrowdSourcingAppState({
      ...crowdSourcingAppState,
      userLeaveWithoutSave: true,
    });
  }
   

  useEffect(() => {
    return () => {
      setCrowdSourcingAppState({
        ...crowdSourcingAppState,
        userLeaveWithoutSave: false,
      });
    };
  }, []);

  useEffect(() => {
    if (contributorsData) {
      setDescription(contributorsData.description);
      setSupportingActivities(contributorsData.supportingActivities);
      setToolsAndGuidance(contributorsData.toolsAndGuidance);
      setTipsAndTechniques(contributorsData.tipsAndTechniques);
      setDeliverables(contributorsData.deliverables);
    }
    if (contributorsData && contributorsData.resultReviewedJson) {
      setDescriptionSuggestions(
        contributorsData.resultReviewedJson.description
      );
      setSupportingActivitiesSuggestions(
        contributorsData.resultReviewedJson.supportingActivities
      );
      setContributiorToolsAndGuidanceSuggestion(
        contributorsData.resultReviewedJson.toolsAndGuidance
      );
      setTipsAndTechniquesSuggestions(
        contributorsData.resultReviewedJson.tipsAndTechniques
      );
      setDeliverablesSuggestions(
        contributorsData.resultReviewedJson.deliverables
      );
      setTemplateSuggestions(
        contributorsData.resultReviewedJson.templateSuggestions
          ? contributorsData.resultReviewedJson.templateSuggestions
          : []
      );
    }
  }, [contributorsData]);
  if (
    !activityContent ||
    ckEditorUsers.length < 1 ||
    !contributorsData ||
    !licenseKey
  ) {
    return (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    );
  }

  if (!approverVerified) {
    return (
      <UserNotVerified message={messagesType['approvePrivilegeMessage']} />
    );
  }
  return (
    <div>
      <div className="activityLandinPage activityCrowdSourcing">
        <Breadcrumbs />
        <SuccessMessagePopUp message={submitMessage} />

        {showHistory ? (
          <HistoryPreview
            contriutorLastSuggestions={contributorsData.resultCalloutJson}
            reviewedSuggestions={contributorsData.reviewedSuggestionsJson}
            contributorContent={contributorsData.lastContributorActivityContent}
            activityContent={activityContent}
            templateData={templateData}
            topicData={topicData}
            procedureData={procedureData}
            complexities={complexities}
            setComplexityName={setComplexityName}
            complexityName={complexityName}
            complexityId={complexityId}
            users={ckEditorUsers}
            currentUser={CkEditorCurrentUser}
            reviewerInfo={reviewerInfo}
            historyToolsId={historyToolsId}
            historicalPreviewTools={historicalPreviewTools}
            historyToolsSuggestion={historyToolsSuggestion}
            toolsReviewedIds={toolsReviewedIds}
            ckEditorUserMap={ckEditorUserMap}
            activityToolsList={contributorsData.activityToolsList}
            toolsUrl={toolsUrl}
            existingHashtags={existingHashtags}
            entryCriteriaData={entryCriteriaData}
            exitCriteriaData={exitCriteriaData}
            taskData={taskData}
            reorderedTaskReviewedSuggestions={JSON.parse(contributorsData.reviewedSuggestionsJson).reorderedTaskReviewedSuggestions}
            reorderedEntryReviewedSuggestions={JSON.parse(contributorsData.reviewedSuggestionsJson).reorderedEntryReviewedSuggestions}
            reorderedExitReviewedSuggestions={JSON.parse(contributorsData.reviewedSuggestionsJson).reorderedExitReviewedSuggestions}
            reorderedInputReviewedSuggestions={JSON.parse(contributorsData.reviewedSuggestionsJson).reorderedInputReviewedSuggestions}
            reorderedOutputReviewedSuggestions={JSON.parse(contributorsData.reviewedSuggestionsJson).reorderedOutputReviewedSuggestions}
            reorderHistory={reorderHistory}
          />
        ) : null}
        <div className="activityMainContainer">
          <div className="activityDescriptionDetails">
            <div className="activityDescTitlePart">
              <div className="activityTitleRightPart">
                <span className="activityVersion">
                  {`Document Version: ${activityContent.activityVersion},`}
                  &nbsp;&nbsp;{`${activityContent.date} `}
                </span>
                {isEnableComplexity && <div className="activityComplexity">
                  <span>Complexity:</span>
                  <Dropdown
                    className="complexityDisabled"
                    data={complexities}
                    setValue={setComplexityName}
                    value={complexityName}
                    enableDropdown={false}
                  />
                </div>}
                {showDiscardPopUp ? (
                  <DiscardPopUp
                    setShowDiscardPopUp={setShowDiscardPopUp}
                    complexityId={complexityId}
                    activityId={activityId}
                  />
                ) : null}
                {showPreviewAndSubmitPopUp ? (
                  <PreviewAndSubmitPopUp
                    toolsUrl={toolsUrl}
                    isEnableComplexity={isEnableComplexity}
                    setShowPreviewAndSubmitPopUp={setShowPreviewAndSubmitPopUp}
                    activityContent={activityContent}
                    contributorContent={contributorsData}
                    activityDescription={description}
                    activitySupportingActivities={supportingActivities}
                    templateData={templateData}
                    topicData={topicData}
                    procedureData={procedureData}
                    activityToolsAndGuidance={toolsAndGuidance}
                    activityTipsAndTechniques={tipsAndTechniques}
                    complexities={complexities}
                    setComplexityName={setComplexityName}
                    complexityName={complexityName}
                    complexityId={complexityId}
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    descriptionSuggestions={descriptionSuggestions}
                    supportingActivitiesSuggestions={
                      supportingActivitiesSuggestions
                    }
                    toolsAndGuidanceSuggestions={toolsAndGuidanceSuggestions}
                    tipsAndTechniquesSuggestions={tipsAndTechniquesSuggestions}
                    templateSuggestions={templateSuggestions}
                    setLeavingWithoutSave={setLeavingWithoutSave}
                    setSubmitMessage={setSubmitMessage}
                    approverDescSuggestions={approverDescSuggestions}
                    approvedDescSuggestions={approvedDescSuggestions}
                    approverSupportSuggestions={approverSupportSuggestions}
                    approvedSupportSuggestions={approvedSupportSuggestions}
                    approverToolsSuggestions={
                      selectedToolsAndGuidanceSuggestions
                    }
                    approvedToolsSuggestions={
                      reviewedToolsAndGuidanceSuggestions
                    }
                    approverTipssSuggestions={approverTipssSuggestions}
                    approvedTipsSuggestions={approvedTipsSuggestions}
                    approvedTemplateSuggestions={approvedTemplateSuggestions}
                    licenseKey={licenseKey}
                    selectedToolsAndGuidanceSuggestions={
                      selectedToolsAndGuidanceSuggestions
                    }
                    messagesType={messagesType}
                    reviewerSuggestionsInfo={reviewerSuggestionsInfo}
                    existingHashtags={existingHashtags}
                    ratingPoints={ratingPoints}
                    enableGamification={enableGamification}
                    activityComplexityData={activityComplexityData}
                    selectedComplexityTab={selectedComplexityTab}
                    setSelectedComplexityTab={setSelectedComplexityTab}
                    complexitySectionModal={complexitySectionModal}
                    setComplexitySectionModal={setComplexitySectionModal}
                    publishComplexity={publishComplexity}
                    setPublishComplexity={setPublishComplexity}
                    publishComplexityEntity={publishComplexityEntity}
                    setPublishComplexityEntity={setPublishComplexityEntity}
                    //approverEntryCriteriaSuggestions={approverEntryCriteriaSuggestions}
                    approvedEntryCriteriaSuggestions={
                      reviewedEntryCriteriaSuggestions
                    }
                    //	approverExitCriteriaSuggestions={approverExitCriteriaSuggestions}
                    approvedExitCriteriaSuggestions={
                      reviewedExitCriteriaSuggestions}
                    entryCriteriaData={entryCriteriaData}
                    exitCriteriaData={exitCriteriaData}
                    entryCriteriaSuggestions={entryCriteriaSuggestions}
                    entryCriteriaWithSuggestions={entryCriteriaWithSuggestions}
                    exitCriteriaSuggestions={exitCriteriaSuggestions}
                    exitCriteriaWithSuggestions={exitCriteriaWithSuggestions}
                    approvedTaskSuggestions={reviewedTaskSuggestions}
                    taskData={taskData}
                    taskSuggestions={taskSuggestions}
                    taskWithSuggestions={taskWithSuggestions}
                    activityDeliverables={deliverables}
                    deliverablesSuggestions={deliverablesSuggestions}
                    approverDeliverablesSuggestions={approverDeliverablesSuggestions}
                    approvedDeliverablesSuggestions={approvedDeliverablesSuggestions}
                    isApproverTaskReordered ={isApproverTaskReordered}
                    isApproverEntryReordered={isApproverEntryReordered}               
                    isApproverInputReordered={isApproverInputReordered}
                    isApproverExitReordered={isApproverExitReordered}
                    isApproverOutputReordered={isApproverOutputReordered}
                    taskReorderArray={taskReorderArray.length ? taskReorderArray:originalTaskOrder.length? originalTaskOrder.map(e=>JSON.stringify(e)) : null}
                    exitCriteriaReorderArray={exitCriteriaReorderArray.length ? exitCriteriaReorderArray: originalExitOrder.length? originalExitOrder.map(e=>JSON.stringify(e)):null}
                    entryCriteriaReorderArray={entryCriteriaReorderArray.length ? entryCriteriaReorderArray : originalEntryOrder.length?originalEntryOrder.map(e=>JSON.stringify(e)):null}
                    inputCriteriaReorderArray={inputCriteriaReorderArray.length ? inputCriteriaReorderArray: originalInputOrder.length?originalInputOrder.map(e=>JSON.stringify(e)):null}
                    outputCriteriaReorderArray={outputCriteriaReorderArray.length ? outputCriteriaReorderArray:originalOutputOrder.length?originalOutputOrder.map(e=>JSON.stringify(e)):null}
                    reviewedReorderedTaskSuggestions={reviewedReorderedTaskSuggestions.length ? reviewedReorderedTaskSuggestions: null}
										reviewedReorderedEntrySuggestions={reviewedReorderedEntrySuggestions.length ? reviewedReorderedEntrySuggestions:null}
										reviewedReorderedExitSuggestions={reviewedReorderedExitSuggestions.length ? reviewedReorderedExitSuggestions:null}
										reviewedReorderedInputSuggestions={reviewedReorderedInputSuggestions.length ?reviewedReorderedInputSuggestions:null}
										reviewedReorderedOutputSuggestions={reviewedReorderedOutputSuggestions.length ?reviewedReorderedOutputSuggestions:null}
                    ckEditorCurrentReorderUsername={ckEditorCurrentReorderUsername}
                    ckEditorCurrentReorderUserImage={ckEditorCurrentReorderUserImage}
                    reorderedTaskRejectionCardInfo={reorderedTaskRejectionCardInfo}
                    reorderedEntryRejectionCardInfo={reorderedEntryRejectionCardInfo}
                    reorderedInputRejectionCardInfo={reorderedInputRejectionCardInfo}
                    reorderedExitRejectionCardInfo={reorderedExitRejectionCardInfo}
                    reorderedOutputRejectionCardInfo={reorderedOutputRejectionCardInfo}
                    reorderContributionCount={reorderContributionCount}
                  />
                ) : null}
                <div className="discardAndPreviewButton">
                  <Button
                    title="Discard"
                    className="activityDiscardButton"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        setShowDiscardPopUp(true);
                        // track(1, 138, activityContent.activityId, activityContent.activityName);
                      }
                    }}
                    onClick={() => {
                      setShowDiscardPopUp(true);
                      // track(1, 138, activityContent.activityId, activityContent.activityName);
                    }}
                  >
                    Discard
                  </Button>
                  <Button
                    title="Preview &amp; Publish"
                    className="activityPreviewButton"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        setShowPreviewAndSubmitPopUp(true);
                        track(
                          1,
                          136,
                          activityContent.activityId,
                          activityContent.activityName
                        );
                      }
                    }}
                    onClick={() => {
                      setShowPreviewAndSubmitPopUp(true);
                      track(
                        1,
                        136,
                        activityContent.activityId,
                        activityContent.activityName
                      );
                    }}
                  >
                    Preview &amp; Publish
                  </Button>
                </div>
              </div>
            </div>
            <div className="descOfActivity">
              <h1
                title={activityContent.activityName}
                className="activityLandingPageTitle"
              >
                {activityContent.activityName}
              </h1>
              <div class="existingHashTagsOfActivity _existingHashTagsOfActivity">
                {existingHashtags.map((name) => (
                  <span
                    key={name}
                    onClick={() => {
                      history.push(
                        `/search?searchParam=${encodeURIComponent(name)}`
                      );
                    }}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        history.push(
                          `/search?searchParam=${encodeURIComponent(name)}`
                        );
                      }
                    }}
                  >
                    {name}
                  </span>
                ))}
              </div>
              {contributorsData.stateId === 2 ? (
                <div className="WarningMessageActivityPage">
                  {contributorsData.lastContributorActivityContent ? (
                    <MessageBar
                      messageBarType={MessageBarType.warning}
                      isMultiline={true}
                    >
                      You are viewing final draft of what has already been
                      reviewed and was submitted to you for approval. You can
                      still make your own changes to it before making it live.
                      You can also view a&nbsp;
                      <Link
                        tabIndex={0}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            e.preventDefault();
                            showHistoryPreview();
                          }
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          showHistoryPreview();
                        }}
                        to={'/'}
                      >
                        Historical Preview here.
                      </Link>
                    </MessageBar>
                  ) : (
                    <MessageBar
                      messageBarType={MessageBarType.warning}
                      isMultiline={true}
                    >
                      {messagesType['approvalPageWithoutHistory']}
                    </MessageBar>
                  )}
                </div>
              ) : null}

              <ActivityRaciMatrix activityData={activityContent} />
              <div className="innerContent" id="innerContent">
                <h2
                  className="sub-heading"
                  id="desc"
                  aria-label="Description"
                  tabIndex={0}
                >
                  Description
                </h2>
                 <TemporaryCkAlert width={'77'}/> 
                {contributorsData.description ? (
                  <DescriptionCkEditor
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    initialData={contributorsData.description}
                    setContent={setDescription}
                    initalSuggestions={
                      contributorsData.resultReviewedJson
                        ? contributorsData.resultReviewedJson.description
                        : null
                    }
                    setSuggestions={setDescriptionSuggestions}
                    licenseKey={licenseKey}
                    setApproverDescSuggestions={setApproverDescSuggestions}
                    setApprovedDescSuggestions={setApprovedDescSuggestions}
                  />
                ) : (
                  <DescriptionCkEditor
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    initialData=" "
                    setContent={setDescription}
                    initalSuggestions={
                      contributorsData.resultReviewedJson
                        ? contributorsData.resultReviewedJson.description
                        : null
                    }
                    setSuggestions={setDescriptionSuggestions}
                    licenseKey={licenseKey}
                    setApproverDescSuggestions={setApproverDescSuggestions}
                    setApprovedDescSuggestions={setApprovedDescSuggestions}
                  />
                )}
               

                <h2 className="sub-heading" id="supportAct">
                  Supporting Activities
                </h2>
                <TemporaryCkAlert width={'77'} />
                {contributorsData.supportingActivities ? (
                  <SupportAndActivitiesCkEditor
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    initialData={contributorsData.supportingActivities}
                    setContent={setSupportingActivities}
                    initalSuggestions={
                      contributorsData.resultReviewedJson
                        ? contributorsData.resultReviewedJson
                          .supportingActivities
                        : null
                    }
                    setSuggestions={setSupportingActivitiesSuggestions}
                    licenseKey={licenseKey}
                    setApproverSupportSuggestions={
                      setApproverSupportSuggestions
                    }
                    setApprovedSupportSuggestions={
                      setApprovedSupportSuggestions
                    }
                  />
                ) : (
                  <SupportAndActivitiesCkEditor
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    initialData=" "
                    setContent={setSupportingActivities}
                    initalSuggestions={
                      contributorsData.resultReviewedJson
                        ? contributorsData.resultReviewedJson
                          .supportingActivities
                        : null
                    }
                    setSuggestions={setSupportingActivitiesSuggestions}
                    licenseKey={licenseKey}
                    setApproverSupportSuggestions={
                      setApproverSupportSuggestions
                    }
                    setApprovedSupportSuggestions={
                      setApprovedSupportSuggestions
                    }
                  />
                )}

                <div className="innerContent" id="innerContent">
                  <h2 className="sub-heading entryCriteria-heading">
                    <span aria-label="entry criteria" tabIndex={0}>
                      Entry Criteria/Input
                    </span>

                    <div className="contributorAddTemplates">
                      <span
                        className={
                          entryCriteriaButtonClicked
                            ? 'contributorAddTemplatesButton hiddenEntryCriteria'
                            : 'contributorAddTemplatesButton '
                        }
                        tabIndex={0}
                        title="Add entry criteria"
                        role="button"
                        aria-label="Click to Add criteria"
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            setEntryCriteriaButtonClicked(true);
                          }
                        }}
                        onClick={() => {
                          setEntryCriteriaButtonClicked(true);
                        }}
                      >
                        Add{' '}
                        {currentEntryTab === 'tab-entry'
                          ? 'Entry Criteria'
                          : 'Input'}
                        <span className="phaseAddIcon"></span>
                      </span>
                    </div>
                  </h2>
                  <EntryCriteriaContribution
                    contributorsData={contributorsData}
                    entryCriteriaButtonClicked={entryCriteriaButtonClicked}
                    setEntryCriteriaButtonClicked={
                      setEntryCriteriaButtonClicked
                    }
                    ckEditorUserMap={ckEditorUserMap}
                    CkEditorCurrentUser={CkEditorCurrentUser}
                    entryCriteriaSuggestions={entryCriteriaSuggestions}
                    setEntryCriteriaSuggestions={setEntryCriteriaSuggestions}
                    entryCriteriaWithSuggestions={entryCriteriaWithSuggestions}
                    setEntryCriteriaWithSuggestions={
                      setEntryCriteriaWithSuggestions
                    }
                    reviewedEntryCriteriaSuggestions={
                      reviewedEntryCriteriaSuggestions
                    }
                    setReviewedEntryCriteriaSuggestions={
                      setReviewedEntryCriteriaSuggestions
                    }
                    source="approve"
                    entryCriteriaData={entryCriteriaData}
                    setEntryCriteriaData={setEntryCriteriaData}
                    reviewerEntryCriteriaSuggestions={reviewerEntryCriteriaSuggestions}
                    currentTab={currentEntryTab}
                    setCurrentTab={setCurrentEntryTab}
                    entryCriteriaReorderArray = {entryCriteriaReorderArray}  
                    setEntryCriteriaReorderArray={setEntryCriteriaReorderArray}
                    inputCriteriaReorderArray ={inputCriteriaReorderArray} 
                    setInputCriteriaReorderArray = {setInputCriteriaReorderArray}
                    entryContributionReorder={entryContributionReorder}
                    setEntryContributionReorder={setEntryContributionReorder}
                    inputContributionReorder={inputContributionReorder}
                    setInputContributionReorder={setInputContributionReorder}
                    initialEntryIdBeforeReorder={initialEntryIdBeforeReorder}
                    setInitialEntryIdBeforeReorder={setInitialEntryIdBeforeReorder}
                    ckEditorCurrentEntryReorderUsername={ckEditorCurrentEntryReorderUsername}
                    setCkEditorCurrentEntryReorderUsername={setCkEditorCurrentEntryReorderUsername}
                    ckEditorCurrentEntryReorderUserImage={ckEditorCurrentEntryReorderUserImage}
                    setCkEditorCurrentEntryReorderUserImage={setCkEditorCurrentEntryReorderUserImage}
									  ckEditorCurrentInputReorderUserImage={ckEditorCurrentInputReorderUserImage}
										setCkEditorCurrentInputReorderUserImage={setCkEditorCurrentInputReorderUserImage}
									  ckEditorCurrentInputReorderUsername={ckEditorCurrentInputReorderUsername}
										setCkEditorCurrentInputReorderUsername={setCkEditorCurrentInputReorderUsername}
                    reviewedReorderedEntrySuggestions={reviewedReorderedEntrySuggestions}
                    setReviewedReorderedEntrySuggestions={setReviewedReorderedEntrySuggestions}
                    reviewedReorderedInputSuggestions={reviewedReorderedInputSuggestions}
                    setReviewedReorderedInputSuggestions={setReviewedReorderedInputSuggestions}
                    disableEntryDrag={disableEntryDrag}
                    setDisableEntryDrag={setDisableEntryDrag}
                    disableInputDrag={disableInputDrag}
                    setDisableInputDrag={setDisableInputDrag}
                    user ={user }
                    setIsApproverEntryReordered={setIsApproverEntryReordered}
                    setIsApproverInputReordered={setIsApproverInputReordered}
                    setReorderContributionCount={setReorderContributionCount}
                    setAddedEntrySequence={setAddedEntrySequence}
                    setIsOrderChanged={setIsOrderChanged}
                  />
                </div>
                <div className="innerContent" id="innerContent">
                  <h2 className="sub-heading entryCriteria-heading">
                    <span aria-label="task" tabIndex={0}>
                      Task
                    </span>

                    <div className="contributorAddTemplates">
                      <span
                        className={
                          taskButtonClicked
                            ? 'contributorAddTemplatesButton contributorAddTemplatesButtonTask hiddenEntryCriteria'
                            : 'contributorAddTemplatesButton contributorAddTemplatesButtonTask '
                        }
                        tabIndex={0}
                        title="Add task"
                        role="button"
                        aria-label="Click to Add Task"
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            setTaskButtonClicked(true);
                          }
                        }}
                        onClick={() => {
                          setTaskButtonClicked(true);
                        }}
                      >
                        Add Task
                        <span className="phaseAddIcon"></span>
                      </span>
                    </div>
                  </h2>
                  <TaskContribution
                    contributorsData={contributorsData}
                    taskButtonClicked={taskButtonClicked}
                    setTaskButtonClicked={
                      setTaskButtonClicked
                    }
                    ckEditorUserMap={ckEditorUserMap}
                    CkEditorCurrentUser={CkEditorCurrentUser}
                    taskSuggestions={taskSuggestions}
                    setTaskSuggestions={setTaskSuggestions}
                    taskWithSuggestions={taskWithSuggestions}
                    setTaskWithSuggestions={
                      setTaskWithSuggestions
                    }
                    reviewedTaskSuggestions={
                      reviewedTaskSuggestions
                    }
                    setReviewedTaskSuggestions={
                      setReviewedTaskSuggestions
                    }
                    source="approve"
                    taskData={taskData}
                    setTaskData={setTaskData}
                    reviewerTaskSuggestions={reviewerTaskSuggestions}
                    taskReorder={taskReorder}
										setTaskReorder={setTaskReorder}
                    taskReorderArray = {taskReorderArray} 
                    setTaskReorderArray = {setTaskReorderArray}
                    initialTaskIdBeforeReorder={initialTaskIdBeforeReorder}
                    setInitialTaskIdBeforeReorder={setInitialTaskIdBeforeReorder}
                    disableDrag={disableDrag} 
										setDisableDrag={setDisableDrag}
                    ckEditorCurrentReorderUsername={ckEditorCurrentReorderUsername}
                    setCkEditorCurrentReorderUsername={setCkEditorCurrentReorderUsername}
                    ckEditorCurrentReorderUserImage={ckEditorCurrentReorderUserImage}
                    setCkEditorCurrentReorderUserImage={setCkEditorCurrentReorderUserImage}
                    reviewedReorderedTaskSuggestions={
                      reviewedReorderedTaskSuggestions
                     }
                     setReviewedReorderedTaskSuggestions={
                      setReviewedReorderedTaskSuggestions
                   }
                   user={user}
                   setIsApproverTaskReordered={setIsApproverTaskReordered}
                   setReorderContributionCount={setReorderContributionCount}
                   setAddedTaskSequence={setAddedTaskSequence}
                   setIsOrderChanged={setIsOrderChanged}
                   />
                </div>


                <div className="innerContent" id="innerContent">
                  <h2 className="sub-heading exitCriteria-heading">
                    <span aria-label="exit criteria" tabIndex={0}>
                      Exit Criteria/Output
                    </span>
                    <div className="contributorAddTemplates">
                      <span
                        className={
                          exitCriteriaButtonClicked
                            ? 'contributorAddTemplatesButton hiddenExitCriteria'
                            : 'contributorAddTemplatesButton '
                        }
                        tabIndex={0}
                        title="Add exit criteria"
                        role="button"
                        aria-label="Click to Add a new Template"
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            setExitCriteriaButtonClicked(true);
                          }
                        }}
                        onClick={() => {
                          setExitCriteriaButtonClicked(true);
                        }}
                      >
                        Add{' '}
                        {currentExitTab === 'tab-exit'
                          ? 'Exit Criteria'
                          : 'Output'}
                        <span className="phaseAddIcon"></span>
                      </span>
                    </div>

                  </h2>
                  <ExitCriteriaContribution
                    contributorsData={contributorsData}
                    exitCriteriaButtonClicked={exitCriteriaButtonClicked}
                    setExitCriteriaButtonClicked={setExitCriteriaButtonClicked}
                    ckEditorUserMap={ckEditorUserMap}
                    CkEditorCurrentUser={CkEditorCurrentUser}
                    exitCriteriaSuggestions={exitCriteriaSuggestions}
                    setExitCriteriaSuggestions={setExitCriteriaSuggestions}
                    exitCriteriaWithSuggestions={exitCriteriaWithSuggestions}
                    setExitCriteriaWithSuggestions={
                      setExitCriteriaWithSuggestions
                    }
                    reviewedExitCriteriaSuggestions={
                      reviewedExitCriteriaSuggestions
                    }
                    setReviewedExitCriteriaSuggestions={
                      setReviewedExitCriteriaSuggestions
                    }
                    source="approve"
                    exitCriteriaData={exitCriteriaData}
                    setExitCriteriaData={setExitCriteriaData}
                    reviewerExitCriteriaSuggestions={reviewerExitCriteriaSuggestions}
                    currentTab={currentExitTab}
                    setCurrentTab={setCurrentExitTab}
                    exitCriteriaReorderArray = {exitCriteriaReorderArray}
                    setExitCriteriaReorderArray ={setExitCriteriaReorderArray}
	                  outputCriteriaReorderArray= {outputCriteriaReorderArray}
                    setOutputCriteriaReorderArray = {setOutputCriteriaReorderArray}
                    exitContributionReorder={exitContributionReorder}
                    setExitContributionReorder={setExitContributionReorder}
                    outputContributionReorder={outputContributionReorder}
                    setOutputContributionReorder={setOutputContributionReorder}
                    ckEditorCurrentExitReorderUserImage={ckEditorCurrentExitReorderUserImage}
                    setCkEditorCurrentExitReorderUserImage={setCkEditorCurrentExitReorderUserImage}
                    ckEditorCurrentExitReorderUsername={ckEditorCurrentExitReorderUsername} 
                    setCkEditorCurrentExitReorderUsername={setCkEditorCurrentExitReorderUsername} 
                    ckEditorCurrentOutputReorderUserImage={ckEditorCurrentOutputReorderUserImage}
                    setCkEditorCurrentOutputReorderUserImage={setCkEditorCurrentOutputReorderUserImage}
                    ckEditorCurrentOutputReorderUsername={ckEditorCurrentOutputReorderUsername}
                    setCkEditorCurrentOutputReorderUsername={setCkEditorCurrentOutputReorderUsername}    
                    initialExitIdBeforeReorder={initialExitIdBeforeReorder}
                    setInitialExitIdBeforeReorder={setInitialExitIdBeforeReorder}
                    reviewedReorderedExitSuggestions={reviewedReorderedExitSuggestions}
                    setReviewedReorderedExitSuggestions={setReviewedReorderedExitSuggestions}
                    reviewedReorderedOutputSuggestions={reviewedReorderedOutputSuggestions}
                    setReviewedReorderedOutputSuggestions={setReviewedReorderedOutputSuggestions}
                    disableExitDrag={disableExitDrag}
                    setDisableExitDrag={setDisableExitDrag}
                    disableOutputDrag={disableOutputDrag}
                    setDisableOutputDrag={setDisableOutputDrag}
                    user ={user }
                    setIsApproverExitReordered={setIsApproverExitReordered}
                    setIsApproverOutputReordered={setIsApproverOutputReordered}
                    setReorderContributionCount={setReorderContributionCount}
                    addedExitSequence = {addedExitSequence}
                    setAddedExitSequence={setAddedExitSequence}
                    setIsOrderChanged={setIsOrderChanged}
                  />
                </div>


                {procedureData.length > 0 ? (
                  <div className="nonEditable">
                    <h2 className="sub-heading" id="deliver">
                      Procedures
                      <div className="nonEditableSection" tabIndex={0}>
                        <span className="nonEditableIcon"></span>
                        <div class="nonEditableIconToolTip">
                          <span class="nonEditablaToolTipArrow"></span>
                          <span>
                            You can not edit the contents of this text
                          </span>
                        </div>
                      </div>
                    </h2>
                    {procedureData.length > 0 ? (
                      <div id="procedureList">
                        <ul>
                          {procedureData.map((procedure) => (
                            <li>
                              <a href={procedure.url} target="_blank">
                                {procedure.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <></>
                )}
                {topicData.length > 0 ? (
                  <div className="nonEditable">
                    <h2 className="sub-heading" id="deliver">
                      Related Topics
                      <div className="nonEditableSection" tabIndex={0}>
                        <span className="nonEditableIcon"></span>
                        <div class="nonEditableIconToolTip">
                          <span class="nonEditablaToolTipArrow"></span>
                          <span>
                            You can not edit the contents of this text
                          </span>
                        </div>
                      </div>
                    </h2>
                    {topicData.length > 0 ? (
                      <div id="topicList">
                        <ul>
                          {topicData.map((topic) => (
                            <li>
                              <a href={topic.url} target="_blank">
                                {topic.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <></>
                )}

                <div className="addingTemplatesEditAcivity">
                  <h2 className="sub-heading" id="deliver">
                    <span aria-label="Deliverables" tabIndex={0}>
                      Deliverables
                    </span>

                    <div class="contributorAddTemplates">
                      <div>
                        <span className="delTemplateAccessabilityIcon"></span>
                        <span>
                          Can't find the template you are looking for?
                        </span>
                      </div>
                      <span
                        class="contributorAddTemplatesButton"
                        onClick={() => {
                          setShowTemplateBuilder(true);
                        }}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            setShowTemplateBuilder(true);
                          }
                        }}
                        tabIndex={0}
                        role="button"
                        aria-label="Add New Template"
                      >
                        <span>Add a new Template</span>
                        <span class="phaseAddIcon"></span>
                      </span>
                    </div>
                  </h2>
                  <TemplateCrowdSourcing
                    templateData={templateData}
                    toolsSidebarHeight={templateSidebarHeight}
                    CkEditorCurrentUser={CkEditorCurrentUser}
                    templateSuggestions={templateSuggestions}
                    setTemplateSuggestions={setTemplateSuggestions}
                    setTemplateData={setTemplateData}
                    ckEditorUserMap={ckEditorUserMap}
                    source="approve"
                    reviewedTemplateSuggestions={approvedTemplateSuggestions}
                    setReviewedTemplateSuggestions={
                      setApprovedTemplateSuggestions
                    }
                    reviewerTemplateSuggestions={reviewerTemplateSuggestions}
                  />
                  {/* {activityContent.activityDeliverables ? (
                    <div
                      id="activityDeliverables"
                      dangerouslySetInnerHTML={{
                        __html: htmlSanitizer(
                          activityContent.activityDeliverables
                        ),
                      }}
                    />
                  ) : null} */}
                  <div className='deliverables-container'>
                    <TemporaryCkAlert width={'77'}/>
                    <h2
                      className="sub-heading"
                      id="desc"
                      aria-label="Description"
                    >
                      Description
                    </h2>
                    {contributorsData.deliverables ? (
                      <DeliverablesCkEditor
                        users={ckEditorUsers}
                        currentUser={CkEditorCurrentUser}
                        initialData={contributorsData.deliverables}
                        setContent={setDeliverables}
                        initalSuggestions={
                          contributorsData.resultReviewedJson
                            ? contributorsData.resultReviewedJson.deliverables
                            : null
                        }
                        setSuggestions={setDeliverablesSuggestions}
                        licenseKey={licenseKey}
                        setApproverDeliverablesSuggestions={setApproverDeliverablesSuggestions}
                        setApprovedDeliverablesSuggestions={setApprovedDeliverablesSuggestions}
                      />
                    ) : (
                      <DeliverablesCkEditor
                        users={ckEditorUsers}
                        currentUser={CkEditorCurrentUser}
                        initialData=" "
                        setContent={setDeliverables}
                        initalSuggestions={
                          contributorsData.resultReviewedJson
                            ? contributorsData.resultReviewedJson.deliverables
                            : null
                        }
                        setSuggestions={setDeliverablesSuggestions}
                        licenseKey={licenseKey}
                        setApproverDeliverablesSuggestions={setApproverDeliverablesSuggestions}
                        setApprovedDeliverablesSuggestions={setApprovedDeliverablesSuggestions}
                      />
                    )}
                  </div>
                </div>
                <h2
                  className="sub-heading"
                  id="tool"
                  aria-label="Tools"
                  tabIndex={0}
                >
                  Tools
                </h2>
                <div className="customToolsEditor">
                  <div className="toolsEditorMainContainer">
                    <div className="toolsHeaderEditor selectTools">
                      <h6>Tools</h6>
                      <MultiSelectDropdownFluentApprover
                        className="toolsAndGuidanceMultiSelectFilter"
                        value={selectedToolsAndGuidanceSuggestions}
                        setValue={setSelectedToolsAndGuidanceSuggestions}
                        setToolsAndGuidanceSuggestions={
                          setToolsAndGuidanceSuggestions
                        }
                        enableDropdown={true}
                        toolsAndGuidanceSuggestions={
                          toolsAndGuidanceSuggestions
                        }
                        adminToolsAndGuidance={adminToolsAndGuidance}
                        currentUser={CkEditorCurrentUser}
                        checkForAdminToolsUpdate={checkForAdminToolsUpdate}
                        setCheckForAdminToolsUpdate={
                          setCheckForAdminToolsUpdate
                        }
                        setContributiorToolsAndGuidanceSuggestion={
                          setContributiorToolsAndGuidanceSuggestion
                        }
                        contributiorToolsAndGuidanceSuggestion={
                          contributiorToolsAndGuidanceSuggestion
                        }
                        pageType="review"
                        contributiorToolsId={contributiorToolsId}
                        reviewedTools={reviewedTools}
                        setReviewedTools={setReviewedTools}
                        updateContributorSuggestion={
                          updateContributorSuggestion
                        }
                      />                      
                    </div>
                    <div className="ToolsContentContainer">
                      <ul>
                        {selectedToolsAndGuidanceSuggestions.map((item) => (
                          <>
                            {!adminToolsAndGuidance.includes(
                              JSON.parse(item).toolsId
                            ) ? (
                              <li
                                className={
                                  JSON.parse(item).suggestionType ===
                                    'deletion' &&
                                    !reviewedTools.includes(
                                      JSON.parse(item).toolsId
                                    )
                                    ? 'userDeletedToolsAndGuidance'
                                    : 'userSelectedToolsAndGuidance'
                                }
                              >
                                <div className={'userAddedItem'}>
                                  <a href={toolsUrl[JSON.parse(item).toolsId]}>
                                    {JSON.parse(item).name}
                                  </a>
                                </div>
                              </li>
                            ) : (
                              <li
                                className={`adminToolsAndGuidance ${JSON.parse(item).suggestionType ===
                                  'deletion' &&
                                  JSON.parse(item).authorId ===
                                  CkEditorCurrentUser
                                  ? 'userDeletedToolsAndGuidance'
                                  : 'none'
                                  }`}
                              >
                                <a
                                  href={toolsUrl[JSON.parse(item).toolsId]}
                                  target="_blank"
                                >
                                  {JSON.parse(item).name}
                                </a>

                                <Icon
                                  ariaLabel={`${JSON.parse(item).name} Remove`}
                                  iconName="Cancel"
                                  onClick={() =>
                                    removeToolsAndGuidance(
                                      'admin',
                                      JSON.parse(item)
                                    )
                                  }
                                  onKeyPress={(e) => {
                                    if (e.which === 13) {
                                      removeToolsAndGuidance(
                                        'admin',
                                        JSON.parse(item)
                                      );
                                    }
                                  }}
                                  role="button"
                                  tabIndex={0}
                                />
                              </li>
                            )}
                          </>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="toolsSidebar"
                    style={{ height: toolsSidebarHeight }}
                  >
                    {sidebarCard.map((card) => (
                      <>
                        {(JSON.parse(card).authorId !== CkEditorCurrentUser &&
                          !reviewedTools.includes(JSON.parse(card).toolsId) &&
                          !adminToolsAndGuidance.includes(
                            JSON.parse(card).toolsId
                          )) ||
                          JSON.parse(card).createdBy === 'reviewer' ? (
                          <>
                            <div
                              className={`sideBarCard ${JSON.parse(card).suggestionType === 'insertion'
                                ? 'userAddedItem'
                                : 'userDeletedToolsAndGuidance'
                                }`}
                            >
                              <div className="sideBarCardHeader">
                                <img
                                  className="userImg"
                                  src={
                                    ckEditorUserMap[
                                    JSON.parse(card).authorId
                                    ][1]
                                  }
                                  alt=""
                                />
                                <span>
                                  {
                                    ckEditorUserMap[
                                    JSON.parse(card).authorId
                                    ][0]
                                  }
                                </span>

                                <Icon
                                  title="Cancel"
                                  ariaLabel={`Reject $${JSON.parse(card).name
                                    } as suggestion`}
                                  role="button"
                                  iconName="Cancel"
                                  onClick={() =>
                                    updateContributorSuggestion(
                                      'reject',
                                      JSON.parse(card),
                                      JSON.parse(card).suggestionType
                                    )
                                  }
                                  onKeyPress={(e) => {
                                    if (e.which === 13) {
                                      updateContributorSuggestion(
                                        'reject',
                                        JSON.parse(card),
                                        JSON.parse(card).suggestionType                                      
                                      );
                                    }
                                  }}
                                  tabIndex={0}
                                />
                                {JSON.parse(card).authorId !==
                                  CkEditorCurrentUser ? (
                                  <Icon
                                    title="Accept Suggestion"
                                    ariaLabel={`Accept ${JSON.parse(card).name
                                      } as suggestion`}
                                    role="button"
                                    iconName="Accept"
                                    onClick={() =>
                                      updateContributorSuggestion(
                                        'accept',
                                        JSON.parse(card),
                                        JSON.parse(card).suggestionType
                                      )
                                    }
                                    tabIndex={0}
                                  />
                                ) : (
                                  <></>
                                )}

                                <div className="itemStatus">
                                  <span>
                                    {JSON.parse(card).suggestionType ===
                                      'insertion'
                                      ? 'Add'
                                      : 'Remove'}
                                  </span>
                                  &nbsp;"{JSON.parse(card).name}"
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {JSON.parse(card).authorId ===
                              CkEditorCurrentUser &&
                              JSON.parse(card).suggestionType === 'insertion' ? (
                              <>
                                <div
                                  className={`sideBarCard ${JSON.parse(card).suggestionType ===
                                    'insertion'
                                    ? 'userAddedItem'
                                    : 'userDeletedToolsAndGuidance'
                                    }`}
                                >
                                  <div className="sideBarCardHeader">
                                    <img
                                      className="userImg"
                                      src={
                                        ckEditorUserMap[
                                        JSON.parse(card).authorId
                                        ][1]
                                      }
                                      alt=""
                                    />
                                    <span>
                                      {
                                        ckEditorUserMap[
                                        JSON.parse(card).authorId
                                        ][0]
                                      }
                                    </span>
                                    <Icon
                                      title="Cancel Suggestion"
                                      ariaLabel={`Cancel ${JSON.parse(card).name
                                        } as suggestion`}
                                      role="button"
                                      iconName="Cancel"
                                      onClick={() =>
                                        removeToolsAndGuidance(
                                          'insertion',
                                          JSON.parse(card)
                                        )
                                      }
                                      onKeyPress={(e) => {
                                        if (e.which === 13) {
                                          removeToolsAndGuidance(
                                            'insertion',
                                            JSON.parse(card)
                                          );
                                        }
                                      }}
                                      tabIndex={0}
                                    />
                                    <div className="itemStatus">
                                      <span>Add</span>&nbsp;"
                                      {JSON.parse(card).name}"
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {JSON.parse(card).suggestionType ===
                                  'deletion' &&
                                  JSON.parse(card).authorId ===
                                  CkEditorCurrentUser ? (
                                  <div
                                    className={`sideBarCard ${JSON.parse(card).suggestionType ===
                                      'insertion'
                                      ? 'userAddedItem'
                                      : 'userDeletedToolsAndGuidance'
                                      }`}
                                  >
                                    <div className="sideBarCardHeader">
                                      <img
                                        className="userImg"
                                        src={
                                          ckEditorUserMap[
                                          JSON.parse(card).authorId
                                          ][1]
                                        }
                                        alt=""
                                      />
                                      <span>
                                        {
                                          ckEditorUserMap[
                                          JSON.parse(card).authorId
                                          ][0]
                                        }
                                      </span>
                                      <Icon
                                        title="Reject Suggestion"
                                        ariaLabel={`Cancel $${JSON.parse(card).name
                                          } as suggestion`}
                                        role="button"
                                        iconName="Cancel"
                                        onClick={() =>
                                          removeToolsAndGuidance(
                                            'adminToolsDeletion',
                                            JSON.parse(card)
                                          )
                                        }
                                        onKeyPress={(e) => {
                                          if (e.which === 13) {
                                            removeToolsAndGuidance(
                                              'adminToolsDeletion',
                                              JSON.parse(card)
                                            )
                                          }
                                        }}
                                        tabIndex={0}
                                      />
                                    </div>
                                    <div className="itemStatus">
                                      <span>Remove</span>&nbsp;"
                                      {JSON.parse(card).name}"
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ))}
                  </div>
                </div>

                <h2 className="sub-heading" id="tipstech">
                  Guidance, Tips and Techniques
                </h2>
                <TemporaryCkAlert width={'77'}/>

                {contributorsData.tipsAndTechniques ? (
                  <TipsAndTechniquesCkEditor
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    initialData={contributorsData.tipsAndTechniques}
                    setContent={setTipsAndTechniques}
                    initalSuggestions={
                      contributorsData.resultReviewedJson
                        ? contributorsData.resultReviewedJson.tipsAndTechniques
                        : null
                    }
                    setSuggestions={setTipsAndTechniquesSuggestions}
                    licenseKey={licenseKey}
                    setaApproverTipssSuggestions={setaApproverTipssSuggestions}
                    setApprovedTipsSuggestions={setApprovedTipsSuggestions}
                  />
                ) : (
                  <TipsAndTechniquesCkEditor
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    initialData=" "
                    setContent={setTipsAndTechniques}
                    initalSuggestions={
                      contributorsData.resultReviewedJson
                        ? contributorsData.resultReviewedJson.tipsAndTechniques
                        : null
                    }
                    setSuggestions={setTipsAndTechniquesSuggestions}
                    licenseKey={licenseKey}
                    setaApproverTipssSuggestions={setaApproverTipssSuggestions}
                    setApprovedTipsSuggestions={setApprovedTipsSuggestions}
                  />
                )}
              </div>
              {/* <Prompt
                when={
                  leavingWithoutSave &&
                  !showDiscardPopUp &&
                  contributorsData.stateId === 2
                }
                message="You have unsaved changes, are you sure you want to leave?"
              /> */}
              {contributorsData.stateId <= 1 ? (
                <UserNotVerified message="The content has already been approved and published successfully by another approver. You can view the published content" />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {showTemplateBuilder ? (
        <TemplateBuilder
          showActivityTemplateBuilder={showTemplateBuilder}
          setShowActivityTemplateBuilder={setShowTemplateBuilder}
          activityId={activityContent.activityId}
          complexityId={complexityId}
          pageType="crowdSource"
          addTemplateSuggestion={addTemplateToSuggestion}
          existingTemplates={getExistingTemplates()}
          stateUpdate={false}
        />
      ) : (
        <></>
      )}
      {enableTemplatePreview ? (
        <PreviewModal
          enableTemplatePreview={enableTemplatePreview}
          closeModal={() => setEnableTemplatePreview(false)}
          data={templatePreviewData}
        />
      ) : (
        <></>
      )}
      {enableFileDropModal ? (
        <FileDropModal
          enableFileDropModal={enableFileDropModal}
          closeModal={() => setEnableFileDropModal(false)}
          data={sampleTemplates.parentTemplate}
        />
      ) : (
        <></>
      )}
      <Modal
        show={sampleTemplates.enable}
        onHide={() => {
          setSampleTemplates({
            id: null,
            enable: false,
            parentTemplate: null,
          });
        }}
        centered
        dialogClassName="templateModal"
      >
        {sampleTemplates.enable ? (
          <SampleTemplateModal
            id={sampleTemplates.id}
            handleTemplatePreview={handleTemplatePreview}
            setEnableFileDropModal={setEnableFileDropModal}
            closeModal={() => {
              setSampleTemplates({
                id: null,
                enable: false,
                parentTemplate: null,
              });
            }}
            enableFileDropModal={enableFileDropModal}
            complexityId={complexityName.id}
          />
        ) : (
          <></>
        )}
      </Modal>
    </div>
  );
}

export default ContributorActivityCrowdSourcing;
