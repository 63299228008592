import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
	useParams,
	Switch,
	Route,
	useRouteMatch,
	useHistory,
	Link
} from 'react-router-dom';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { Row, Col } from "react-bootstrap";
import { Icon } from '@fluentui/react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import $ from 'jquery';
import queryString from 'query-string';
import { useAxios } from '../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../context/TelemetryContext';
import Phase from '../Phase';
import Activity from '../Activity';
import Complexity from '../Complexity';
import Breadcrumbs from '../Breadcrumbs';
import PhaseLeftMenu from '../PhaseLeftMenu';
import PhaseShowMore from '../PhaseShowMore';
import DeliveryPhase from '../DeliveryPhase';
import DeliveryManagement from '../DeliveryManagement';
import SolutionMethod from '../../views/SolutionMethod';
import DeliveryManagementDesc from '../../views/DeliveryManagement';
import ContributorActivityCrowdSourcing from '../ActivityContributorCrowdSourcing';
import ReviewerActivityCrowdSourcing from '../ActivityReviewerCrowdSourcing';
import ApproverActivityCrowdSourcing from '../ActivityApproverCrowdSourcing';
import FavoriteAndFeedback from '../FavoriteAndFeedback';
import ActivityUpdatePage from '../NewActivityDetailsPage';
import Spinner from '../../helpers/Spinner';
import DeletedEntityModel from '../DeletedEntity';
import HashtagSuggestions from './HashtagSuggestions';
import PhaseSelectionModal from './PhaseSelectionModal';
import MatrixView from './MatrixView';
import ExtractRolesModal from './ExtractRoles';
import { TabTitle } from '../../utils/GeneralFunctions';
import './LandingPage.css';
import NewPhase from '../Phase/NewPhase'
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import { getOtherUserProfile } from '../../helpers/GraphService';


const NewLandingPage = () => {
	const urlParams = useParams();
	const { activityId } = urlParams;
	const showMatrixViewParam = window.location.search
		? queryString.parse(window.location.search).showMatrixView
		: false;
	const history = useHistory();
	const { projectTypeId, solutionMethodId, phaseId } = useParams();
	const { url } = useRouteMatch();
	const location = window.location.pathname;
	const {
		appState,
		setAppState,
		setCollapsedLeftMenu,
		phase,
		parentPhase,
		setExpandedActivityGroups,
		setComplexity,
		setRole,
		setPhase,
		setParentPhase,
		breadcrumbs,
		setBreadcrumbs,
		svg,
		setSvg,
		phaseHashtags,
		setPhaseHashtags,
		phaseDynamicFilterTag,
		setPhaseDynamicFilterTag,
		phaseDynamicFilterTagMapping,
		setPhaseDynamicFilterTagMapping,
		endUserView,
		showRaciViewBanner,
		showSupportBanner,
		complexity,
		applyfilterTrigger,
		nonrealizedvalue,
		setNonrealizedvalue,
		filterCleared,
		setFilterCleared,
		ToggleExpandedState,
		setRoleFlag,
		roleFlag
	} = useLeftMenuCollapse();
	const { track } = useAppInsights();
	const [userActivityFilter, setUserActivityFilter] = useState([]);
	const [apiCallCompleted, setApiCallCompleted] = useState(false);
	const [activityClicked, setActivityClicked] = useState(false);
	const { axiosGetService, axiosPostService, userAlias } = useAxios();
	const userActivityFilterRef = useRef([]);
	const { defaultComplexity, defaultRole, defaultPhaseObject } = appState;
	const [solutionMethod, setSolutionMethod] = useState({
		phasesList: [],
	});
	const [parentSolutionMethod, setParentSolutionMethod] = useState({
		phasesList: [],
	});
	const [visible, setVisible] = useState({
		leftPanel: true,
		rightPanel: true,
	});
	const [loading, setLoading] = useState(true);
	const [hasParentMethod, setHasParentMethod] = useState(false);
	const [userDataAvailable, setUserDataAvailable] = useState(false);
	const [showMoreVisible, setShowMoreVisible] = useState(false);
	const [showMoreDescription, setShowMoreDescription] = useState(null);
	const [svgExpanded, setSvgExpanded] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [selectedComplexity, setSelectedComplexity] = useState(defaultComplexity.name);
	const [selectedRoles, setSelectedRoles] = useState(null);
	const [showGroup, setShowGroup] = useState(false);
	const [tagFilterHeight, setTagFilterHeight] = useState(false);
	const [phaseCreated, setPhaseCreated] = useState(false);
	const [showPublishButton, setShowPublishButton] = useState(false);
	const [isParentPhaseDrafted, setIsParentPhaseDrafted] = useState(false);
	const [showPublishModal, setShowPublishModal] = useState(false);
	const [showParentPhasePublishModal, setShowParentPhasePublishModal] = useState(false);
	const [showPhaseSelectionModal, setShowPhaseSelectionModal] = useState(false);
	const [visioNotFound, setVisioNotFound] = useState(false);
	const [coOwners, setCoOwners] = useState([]);
	const [parentPhaseCoOwners, setParentPhaseCoOwners] = useState([]);
	const [isUserValidated, setIsUserValidated] = useState(false);
	const [deletePhase, setDeletePhase] = useState(false);
	const [showUpdatePhasePopUp, setShowPhaseUpdatePopUp] = useState(false);
	const [deletePhaseId, setDeletePhaseId] = useState(0);
	const [showDeletePhaseModal, setShowDeletePhaseModal] = useState(false);
	const [showParentDeletePhaseModal, setShowParentDeletePhaseModal] = useState(false);
	const [isActivityCountZero, setIsActivityCountZero] = useState(false);
	const [isParentActivityCountZero, setIsParentActivityCountZero] = useState(false);
	const [apiCallMade, setApiCallMade] = useState(false);
	const [deletePhaseName, setDeletePhaseName] = useState('');
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [searchInput, setSearchInput] = useState('');
	const [hashtagSearchInput, setHashtagSearchInput] = useState('');
	const [showPhaseDeletionMessage, setShowPhaseDeletionMessage] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [parentPhaseCreated, setParentPhaseCreated] = useState(false);
	const [phasePublishTitle, setPhasePublishTitle] = useState('');
	const [parentPhasePublishTitle, setParentPhasePublishTitle] = useState('');
	const [showPhaseUpdateModal, setShowPhaseUpdateModal] = useState(false);
	const [updatePhaseId, setUpdatePhaseId] = useState(0);
	const [selectedPhaseTab, setSelectedPhaseTab] = useState(2);
	const [showParentPhaseUpdateModal, setShowParentPhaseUpdateModal] = useState(false);
	const [updateParentPhaseId, setUpdateParentPhaseId] = useState(0);
	const [selectedParentPhaseTab, setSelectedParentPhaseTab] = useState(1);
	const [selectedPhase, setSelectedPhase] = useState(null);
	const [showMatrixView, setShowMatrixView] = useState(false);
	const [selectedComplexityId, setSelectedComplexityId] = useState(defaultComplexity.id);
	const [selectedRoleId, setSelectedRoleId] = useState(defaultRole.id);
	const [showDefaultViewModal, setShowDefaultViewModal] = useState(false);
	const [updateComplexityFilter, setUpdateComplexityFilter] = useState(false);
	const [handleExtractRoles, setHandleExtractRoles] = useState(false);
	const [supportBannerMessage, setSupportBannerMessage] = useState('');
	const [raciViewBanner, setRaciViewBanner] = useState('');
	const [complexities, setComplexities] = useState([]);
	const [isEnableComplexity, setIsEnableComplexity] = useState();
	const [solutionMethodOwner, setSolutionMethodOwner] = useState({})
	const [existingCoOwners, setExistingCoOwners] = useState([]);
	const [triggerApi, setTriggerApi] = useState(false);
	const [userRoleTodefault, setUserRoleTodefault] = useState(0);
	const [userActivityFilterTrigger, setUserActivityFilterTrigger] = useState(false);

	const moveLeftPanel = () =>
		setVisible({ ...visible, rightPanel: !visible.rightPanel });
	const moveRightPanel = () =>
		setVisible({ ...visible, leftPanel: !visible.leftPanel });

	const setDefaultView = (newDefaultViewId) => {
		setShowDefaultViewModal(false);
		if (solutionMethod.defaultView !== newDefaultViewId) {
			setApiCallMade(true);
			axiosPostService('api/sdmsolutionmethod/updatedefaultview', {
				...solutionMethod,
				defaultView: newDefaultViewId,
			}).then((response) => {
				setSolutionMethod((prevState) => {
					return { ...prevState, defaultView: response.data.defaultView };
				});
				setApiCallMade(false);
				if (response.data.defaultView === 1) {
					setShowMatrixView(false);
				} else if (response.data.defaultView === 2) {
					setShowMatrixView(false);
				}
			});
		}
	};
	useEffect(() => {
		setSelectedComplexity(defaultComplexity.name)
		setSelectedComplexityId(defaultComplexity.id)
	}, [defaultComplexity])
	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSearchInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	const getParentPhaseCoOwnerGroupId = async (id) => {
		await axiosGetService(
			`api/sdmsolutionmethod/ismethodownerorcoowner/${id}`
		).then((response) => {
			if (response.data) {
				const users = [userAlias];
				getParentSolutionMethodData(id, users);
				setParentPhaseCoOwners([...users]);
			} else {
				setParentPhaseCoOwners([]);
				getParentSolutionMethodData(id, []);
			}
		});
	};

	const processFilters = (filters) => {
		const filterGroups = filters.split('|');

		const filteredGroups = filterGroups.map(group => {
			const filterValues = group.split(',');

			const filteredValues = filterValues.filter(value => !value.startsWith('15-') && !value.startsWith('25-'));

			return filteredValues.join(',');
		});

		const nonEmptyGroups = filteredGroups.filter(group => group.trim() !== "");

		return nonEmptyGroups.join('|');
	};
	useEffect(() => {
		setUserRoleTodefault(0);
		setNonrealizedvalue(false);
		setFilterCleared(false);
		setUserActivityFilter([]);
		setActivityClicked(false);

		setApiCallCompleted(false);
		const fetchData = async () => {
			try {
				if (phaseId) {
					setRole({ name: 'None', id: 0, type: 'option' });
					setSelectedRoles(null);
					setSelectedRoleId(null);
					const userSettingsResponse = await axiosGetService(`api/sdmusersettings/getusersettings/${1}/${solutionMethodId}/${phaseId}`);
					setPhaseDynamicFilterTag({});
					setUserDataAvailable(true);
					const userSettingsData = userSettingsResponse?.data || [];

					const processedUserSettings = userSettingsData.filter(item => {
						const processedFilters = processFilters(item.filters);
						return processedFilters.trim() !== ""; // Keep the item if the filters are not empty after processing
					}).map(item => {
						// Apply the filter processing function
						return {
							...item,
							filters: processFilters(item.filters), // Clean the filters
						};
					});
					if (processedUserSettings?.length === 0) {
						setUserRoleTodefault(1);
						setRoleFlag(1);
						setSelectedRoles(null);

					}
					userActivityFilterRef.current = processedUserSettings;
					setUserActivityFilter(processedUserSettings);

					const userComplexityName = userSettingsResponse?.data[0]?.complexities;
					let complexitiesArray = [];
					if (userSettingsResponse?.data) {
						try {
							complexitiesArray = JSON.parse(userSettingsResponse.data[0]?.complexityData);
						} catch (error) {
						}
					}
					const selectedComplexity = complexitiesArray?.find(complexity => String(complexity?.Id) === userComplexityName);
					const mappedComplexity = selectedComplexity
						? { name: selectedComplexity?.Name, id: selectedComplexity?.Id, type: 'option' }
						: defaultComplexity; // Fall back to default if no match is found
					setComplexity(mappedComplexity);
					if(!activityClicked){
						setApiCallCompleted(true);
					}
					setLoading(false);
					setUserActivityFilterTrigger((prev) => {
						if (userSettingsResponse?.data?.length > 0) {
							return false;
						}
						return true;
					});
				}

			} catch (error) {
				setLoading(true);
			}
		};
		// if(!apiCallCompleted){
			fetchData();
		// }
	}, [phaseId, history?.location?.pathname]);

	const getParentSolutionMethodData = async (id, coOwnersList) => {
		await axiosGetService(
			`/api/sdmsolutionmethod/getmergedphases/${id}/${coOwnersList.includes(userAlias) && !endUserView
			}/0/0/0/0/true/0`
		).then((response) => {
			setParentSolutionMethod(response.data);
			setIsUserValidated(true);
		});
	};

	const getSolutionMethodData = async (coOwnersList, type) => {
		await axiosGetService(
			`/api/sdmsolutionmethod/getmergedphases/${solutionMethodId}/${coOwnersList.includes(userAlias) && !endUserView
			}/0/0/0/0/false/0`
		).then((response) => {
			if (response.data && response.data.isDeleted) {
				setSolutionMethod({
					phasesList: [],
					errorMsg: response.data.errorMsg,
					isDeleted: true,
				});
				setIsUserValidated(true);
				setIsEnableComplexity(response.data.isComplexity)
			} else {
				if (response.data) {
					setIsEnableComplexity(response.data.isComplexity)
					setSolutionMethod(response.data);
					if (
						Number(response.data.parentId) === 0 ||
						response.data.parentId === null ||
						response.data.isMerged
					) {
						setHasParentMethod(false);
						setIsUserValidated(true);
					} else {
						TabTitle(response.data.solutionMethodName);
						getParentPhaseCoOwnerGroupId(response.data.parentId);
						setHasParentMethod(true);
					}
					if (type !== 'update') {
						if (showMatrixViewParam) {
							setShowMatrixView(false);
						} else if (response.data.defaultView === 1) {
							setShowMatrixView(false);
							setSvg({ enable: false, url: null, type: null });
						} else if (response.data.defaultView === 3) {
							setSvg({
								enable: true,
								url: null,
								type: 'phase',
								source: 'matrixView',
							});
						} else {
							setShowMatrixView(false);
							setSvg({ enable: false, url: null, type: null });
						}
					}
				} else {
					setIsEnableComplexity(response.data.isComplexity)
					setIsUserValidated(true);
					setSolutionMethod({
						phasesList: [],
					});
					setParentSolutionMethod({
						phasesList: [],
					});
				}
			}
		});
	};

	const getCoOwnerGroupId = async () => {
		await axiosGetService(
			`api/sdmsolutionmethod/ismethodownerorcoowner/${solutionMethodId}`
		).then((response) => {
			setIsUserValidated(false);
			if (response.data) {
				const users = [userAlias];
				getSolutionMethodData(users, 'source');
				setCoOwners([...users]);
			} else {
				setCoOwners([]);
				getSolutionMethodData([], 'source');
			}
		});
	};

	const getSupportBannerMessage = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=SupportBanner`).then(
			(response) => {
				setSupportBannerMessage(response.data[0].value);
			}
		);
	};
	const getRaciViewBanner = () =>
		axiosGetService(`api/sdmsettings?sdmKey=RACIViewBanner`).then(({ data }) =>
			setRaciViewBanner(data[0].value)
		);

	const onSolutionMethodChangeReset = () => {
		setComplexity(defaultComplexity);
		setRole(defaultRole);
		// setExpandedActivityGroups([]);
		setPhase(defaultPhaseObject);
		setParentPhase(defaultPhaseObject);
		if (svg.source !== 'matrixView') {
			setSvg({ enable: false, url: null, type: null });
		}
	};

	const openFullScreen = (elem) => {
		if (elem.msRequestFullscreen) {
			/* IE/Edge */
			elem.msRequestFullscreen();
		} else if (elem.webkitRequestFullscreen) {
			/* Chrome, Safari & Opera */
			elem.webkitRequestFullscreen();
		} else if (elem.mozRequestFullScreen) {
			/* Firefox */
			elem.mozRequestFullScreen();
		} else if (elem.requestFullscreen) {
			elem.requestFullscreen();
		}
	};

	const closeFullScreen = () => {
		if (document.msExitFullscreen) {
			document.msExitFullscreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
		}
	};

	// on enter & exit fullscreen, setSvgExpanded
	const handleSvgExpand = () => {
		const elem = document.getElementById('svgPhase');
		if (svgExpanded) {
			closeFullScreen();
		} else {
			openFullScreen(elem);
		}
	};

	function handleFullScreen() {
		if (document.webkitFullscreenElement != null) {
			setSvgExpanded(true);
		} else {
			setSvgExpanded(false);
		}
	}

	const handleDynamicFilterTag = (artifactTypeId, tagId) => {
		const updatedProcessGroupFilterTag = phaseDynamicFilterTag[
			artifactTypeId
		].selectedTagId.filter((id) => id !== tagId);
		const updatedValue = {
			artifactTypeId: artifactTypeId,
			artifactTypeName: phaseDynamicFilterTag[artifactTypeId].artifactTypeName,
			selectedTagId: [...updatedProcessGroupFilterTag],
		};
		setPhaseDynamicFilterTag({
			...phaseDynamicFilterTag,
			[artifactTypeId]: updatedValue,
		});
	};

	const clearFilters = () => {
		setUserActivityFilter([]);
		setPhaseHashtags([]);

		setComplexity(defaultComplexity);
		setSelectedComplexity(defaultComplexity.name);
		const newDynamicFilterTag = { ...phaseDynamicFilterTag };
		const filterIds = Object.keys(newDynamicFilterTag);
		for (const id of filterIds) {
			newDynamicFilterTag[Number(id)].selectedTagId = [];
		}
		setPhaseDynamicFilterTag(newDynamicFilterTag);
		setHashtagSearchInput('');
		setRole(appState.defaultRole);
		setSelectedRoles(null);
		setSelectedRoleId(defaultRole.id);
	};

	const removeHashtag = (item) => {
		const updatehashtag = phaseHashtags.filter((hashtag) => hashtag !== item);
		setPhaseHashtags(updatehashtag);
	};

	const collapseFilter = (e) => {
		const filterArea = document.getElementById('filtersRightPanel');
		if (
			filterArea &&
			!filterArea.contains(e.target) &&
			e.target.className !== 'filtersButton' &&
			!(
				typeof e.target.className !== 'object' &&
				e.target.className &&
				e.target.className.includes('searchIcon')
			) &&
			e.target.className !== 'root-50' &&
			e.target.className !== 'root-56' &&
			e.target.className !== 'root-60' &&
			e.target.className !== 'showMoreFilter'
		) {
			setShowFilters(false);
		}
		const tagContainerHeight = $('.filterTagsContainer').height();
		if (Number(tagContainerHeight) > 36) {
			setTagFilterHeight(true);
		} else {
			setTagFilterHeight(false);
		}
	};

	const collapseDefaultViewModal = (e) => {
		const checkContainer = document.getElementById('defaultViewModalContainer');
		if (checkContainer && !checkContainer.contains(e.target)) {
			setShowDefaultViewModal(false);
		}
	};

	const updateComplexity = (complexityData, type) => {

		const complexityDetails = complexityData?.filter(
			({ name }) => name === type
		);
		setSelectedComplexity(type);
		setComplexity(complexityDetails[0] ? complexityDetails[0] : defaultComplexity);
		setSelectedComplexityId(complexityDetails[0]?.id);
	};

	const getComplexities = async () => {
		await axiosGetService("api/activities/projectcomplexity").then((res) => {
			const data = res.data.map((item) => {
				return {
					name: item.name,
					id: item.id,
					type: "option",
				};
			});
			setComplexities(data);
		});
	};
	useEffect(() => { getComplexities() }, []);
	const getUser = async (userEmail, setValue) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setValue((prevState) => [...prevState, response]);
		});
	};
	const getCollaboratorsInfo = async (membersList, setValue) => {
		for (const userEmail of membersList) {
			getUser(userEmail, setValue);
		}
	}

	const getSolutionMethodOwnerDetails = async (email) => {
		const accessToken = await axiosTokenService();
		const userData = await getOtherUserProfile(accessToken, email);
		setSolutionMethodOwner(userData);
	};

	const getCollaborators = async () => {
		await axiosGetService(
			`api/sdmactivity/getactivityorphaseormethodcollaborators/0/0/${solutionMethodId}`
		).then((response) => {
			getCollaboratorsInfo(response.data.coOwnerMembersList, setExistingCoOwners)
			getSolutionMethodOwnerDetails(response.data.owner)
		}).catch((error) => {
			console.log(error)
		})
	}

	useEffect(() => {
		getCollaborators()
	}, [])

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSearchInput.trim() && hashtagSearchInput[0] === '#') {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSearchInput]);

	useEffect(() => {
		getCoOwnerGroupId();
		setSearchInput('');
		setHashtagSearchInput('');
		setSolutionMethod({
			phasesList: [],
		});
	}, [solutionMethodId, projectTypeId, endUserView]);

	// on phase change, reset panels and close showMore
	useEffect(() => {
		if (phase.id !== null) {
			setVisible({
				...visible,
				leftPanel: true,
			});
		}
		if (showMoreVisible) {
			setShowMoreVisible(false);
		}
		if (!phase.id) {
			setShowPublishButton(false);
		}
		setVisioNotFound(false);
	}, [phase.id]);

	// on parentPhase change, reset panels and close showMore
	useEffect(() => {
		if (parentPhase.id !== null) {
			setVisible({
				...visible,
				rightPanel: true,
			});
		}
		if (showMoreVisible) {
			setShowMoreVisible(false);
		}
	}, [parentPhase.id]);

	// on solutionMethod landing, close showMore and set defaultView
	useEffect(() => {
		if (location === url) {
			setShowMoreVisible(false);
			setVisible({
				leftPanel: true,
				rightPanel: true,
			});
			onSolutionMethodChangeReset();
		}
	}, [location]);

	useEffect(() => {
		setAppState({
			...appState,
			projectTypeId: Number(projectTypeId),
			solutionMethodId: null,
		});
		setAppState({
			...appState,
			projectTypeId: Number(projectTypeId),
			solutionMethodId: Number(solutionMethodId),
		});
		setShowPublishButton(false);
		setSelectedRoles(null);
		setSelectedComplexity(defaultComplexity.name);
		setDeletePhase(false);
		setIsActivityCountZero(false);
		setIsParentActivityCountZero(false);
		setIsParentPhaseDrafted(false);
		setPhasePublishTitle('');
		setParentPhasePublishTitle('');
		setShowMatrixView(false);
		setApiCallMade(false);
		setShowDefaultViewModal(false);
	}, [projectTypeId, solutionMethodId]);

	// onComponentLoad set ProjectTypeId, solutionMethodId from url params & get DeliveryManagement
	useEffect(() => {
		setAppState({
			...appState,
			projectTypeId: Number(projectTypeId),
			solutionMethodId: Number(solutionMethodId),
		});

		setCollapsedLeftMenu(true);

		document.addEventListener('fullscreenchange', handleFullScreen, false);
		document.addEventListener('mozfullscreenchange', handleFullScreen, false);
		document.addEventListener(
			'webkitfullscreenchange',
			handleFullScreen,
			false
		);
		document.addEventListener('msfullscreenchange', handleFullScreen, false);

		return () => {
			setBreadcrumbs(breadcrumbs.slice(0, 1));
			onSolutionMethodChangeReset();
			setPhaseDynamicFilterTag({});
			setPhaseDynamicFilterTagMapping({});
			setPhaseHashtags([]);
			document.removeEventListener('fullscreenchange', handleFullScreen, false);
			document.removeEventListener(
				'mozfullscreenchange',
				handleFullScreen,
				false
			);
			document.removeEventListener(
				'webkitfullscreenchange',
				handleFullScreen,
				false
			);
			document.removeEventListener(
				'msfullscreenchange',
				handleFullScreen,
				false
			);
		};
	}, []);

	useEffect(() => {
		if (showSupportBanner) {
			getSupportBannerMessage();
		}
	}, [showSupportBanner]);

	useEffect(() => {
		if (showRaciViewBanner) {
			getRaciViewBanner();
		}
	}, [showRaciViewBanner]);

	useEffect(() => {
		window.addEventListener('click', collapseFilter);
		return () => {
			window.removeEventListener('click', collapseFilter);
		};
	}, []);

	useEffect(() => {
		window.addEventListener('click', collapseDefaultViewModal);
		return () => {
			window.removeEventListener('click', collapseDefaultViewModal);
		};
	}, []);

	useEffect(() => {
		if (showMatrixView) {
			setShowMoreDescription(null);
			setShowMoreVisible(false);
		}
	}, [showMatrixView]);


	$(document).ready(function () {
		$('figure').each(function (index, element) {
			if (!element.getAttribute('contenteditable')) {
				const imgElement = element.children[0];
				$(imgElement).attr({ style: $(element).attr('style') });
			}
		});
	});
	const handleToggleFilters = useCallback(() => {
		setNonrealizedvalue(true);
		setShowFilters(true); // Set the state to true
	}, []);


	return (
		<Switch>
			<Route path="/project/:projectTypeId/method/:solutionMethodId/phase/:phaseId/parentPhase/:parentPhaseId/updateActivity/:activityId/draft/:draftId/complexity/:complexityIds/default/:defaultComplexity">
				<ActivityUpdatePage
					isUserValidated={isUserValidated}
					getSolutionMethodData={getSolutionMethodData}
					coOwners={coOwners}
					isEnableComplexity={isEnableComplexity}
					solutionMethodOwner={solutionMethodOwner}
					existingCoOwners={existingCoOwners}
				/>
			</Route>
			<Route path="/project/:projectTypeId/method/:solutionMethodId/phase/:phaseId/parentPhase/:parentPhaseId/activity/:activityId/approver/edit">
				<ApproverActivityCrowdSourcing isEnableComplexity={isEnableComplexity} />
			</Route>
			<Route path="/project/:projectTypeId/method/:solutionMethodId/phase/:phaseId/parentPhase/:parentPhaseId/activity/:activityId/reviewer/edit">
				<ReviewerActivityCrowdSourcing isEnableComplexity={isEnableComplexity} />
			</Route>
			<Route path="/project/:projectTypeId/method/:solutionMethodId/phase/:phaseId/parentPhase/:parentPhaseId/activity/:activityId/edit">
				<ContributorActivityCrowdSourcing isEnableComplexity={isEnableComplexity} />
			</Route>
			{/* <Route
				path={`/project/1/method/:solutionMethodId/phase/:phaseId/parentPhase/:parentPhaseId/activity/:activityId/`}
			>
				<Activity
					projectTypeId={projectTypeId}
					solutionMethodId={solutionMethodId}
					isEnableComplexity={isEnableComplexity}
					selectedActivity={selectedActivity}
					pageType={'nonRealizedItem'}
					selectedPhaseId={parseInt(window.location.href.split("/").at(6), 10)}
					setToggleActivityDetails={setToggleActivityDetails}
					toggleActivityDetails={toggleActivityDetails}
				/>
			</Route> */}

			<Route path={url}>
				{!isUserValidated || apiCallMade ? (
					<Spinner />
				) : (
					<>
						{solutionMethod.isDeleted ? (
							<DeletedEntityModel errorMsg={solutionMethod.errorMsg} />
						) : (
							<>
								{showPhaseDeletionMessage ? (
									<Modal
										className="activityDiscardPopUp"
										show={showPhaseDeletionMessage}
										onHide={() => {
											setShowPhaseDeletionMessage(false);
											setSelectedPhase(null);
										}}
										size="md"
										aria-labelledby="contained-modal-title-vcenter"
										centered
									>
										<Modal.Body>
											<p>
												You have successfully{' '}
												{selectedPhase.isRefered ? 'de-linked' : 'deleted'} the
												phase
											</p>
											<div className="discardPopUpActivityButtons">
												<Button
													title="OK"
													onClick={() => {
														setShowPhaseDeletionMessage(false);
														setSelectedPhase(null);
													}}
													tabIndex={-1}
													className="activityDiscardPopUpAccept"
													variant="info"
												>
													OK
												</Button>
											</div>
										</Modal.Body>
									</Modal>
								) : null}
								<div
									role="main"
									className={
										solutionMethod.isMerged
											? 'phaseLandPageMainContainer mergedPhaseLandPage'
											: 'phaseLandPageMainContainer'
									}
								>
									<div
										className={
											hasParentMethod && !showMatrixView
												? 'phaselandMiddleContainer deliveryMargin'
												: `phaselandMiddleContainer ${showMatrixView ? 'matrixView' : ''
												}`
										}
									>
										{svg.enable && svg.url !== null ? (
											<div className="phaseSwimlineView" id="svgPhase">
												{visioNotFound ? (
													<p className="visioNotFound">
														Visio approval in progress.
													</p>
												) : (
													<>
														<img
															alt="First Sprint"
															onError={() => setVisioNotFound(true)}
															src={svg.url}
														></img>
														<button
															title="swichSwimlineIcon"
															className="swichSwimlineIcon"
															onClick={handleSvgExpand}
															aria-label="expand swimline"
														>
															<span
																className={
																	svgExpanded
																		? 'swimlineCollapseIcon'
																		: 'swimlineExpandIcon'
																}
															></span>
														</button>
													</>
												)}
											</div>
										) : showMoreVisible ? (
											<PhaseShowMore
												phaseDescription={showMoreDescription}
												setShowMoreVisible={setShowMoreVisible}
												parentName={
													showMoreDescription.type === 'phase'
														? solutionMethod.solutionMethodName
														: parentSolutionMethod.solutionMethodName
												}
												isMerged={solutionMethod.isMerged}
											/>
										) : (
											<>
												{showMatrixView ? (
													<></>

												) : (
													<>
														<Row className='mx-0 _breadcrumbForNonRealized'>
															<Col className='pl-0'>
																<Breadcrumbs />


															</Col>
															{!ToggleExpandedState && <Col className='NewFilterButton'>
																<button
																	className="filtersButton mr-0"
																	onClick={handleToggleFilters}
																>
																	Filters
																	<Icon
																		iconName="FilterSolid"
																		className="searchIcon"
																		title="Filters"
																	/>
																</button>


																<Complexity
																	setFilterCleared={setFilterCleared}
																	filterCleared={filterCleared}
																	showFilters={showFilters}
																	setNonrealizedvalue={setNonrealizedvalue}
																	nonrealizedvalue={nonrealizedvalue}
																	setShowFilters={setShowFilters}
																	setSelectedComplexity={setSelectedComplexity}
																	setSelectedRoles={setSelectedRoles}
																	selectedRoles={selectedRoles}
																	isMerged={solutionMethod.isMerged}
																	solutionMethodId={solutionMethodId}
																	searchInput={searchInput}
																	setShowSpinner={setShowSpinner}
																	setSelectedComplexityId={setSelectedComplexityId}
																	setSelectedRoleId={setSelectedRoleId}
																	showMatrixView={showMatrixView}
																	phaseCreated={phaseCreated}
																	updateComplexityFilter={updateComplexityFilter}
																	setUpdateComplexityFilter={
																		setUpdateComplexityFilter
																	}
																	parentId={solutionMethod.parentId}
																	complexities={complexities}
																	setComplexities={setComplexities}
																	isEnableComplexity={isEnableComplexity}
																	pagetype={"non-realized"}
																	onButtonClick={() => {
																		setTriggerApi(prevState => !prevState); // Trigger API call in SecondComponent
																		setUserActivityFilter([]);

																	}}
																	userActivityFilter={userActivityFilter}
																	setUserActivityFilter={setUserActivityFilter}
																	userRoleTodefault={userRoleTodefault}
																/>
															</Col>}


														</Row>
														{!ToggleExpandedState && <Row className='mx-0 complexity_newFiltertags_NonRealized'>
															<Col className='_NonrealizedComplexity pl-0' xs="2" >
																{isEnableComplexity && <span
																	className={
																		'filtertagsStyle filtertagsStyle_Nonrealized Complexity_Non_Realized'
																	}
																>
																	Complexity:&nbsp;{complexity?.name}
																</span>}
															</Col>

															<Col className=''>



																<div className='newFiltertags_NonRealized'>

																	{
																		<span className='filtertagsStyle filtertagsStyle_Nonrealized ClearAllFilter_Nonrealized mr-0' tabIndex={0}
																			role="button"
																			aria-label="Clear all filters"
																			style={{ cursor: 'default' }}
																		>
																			Clear all filters&nbsp;
																			<Icon
																				iconName="cancel"
																				className="activity-remove-icon-non-realized"
																				onClick={() => {
																					clearFilters();
																					setFilterCleared(true);
																				}}
																				onKeyPress={(e) => {
																					if (e.which === 13) {
																						clearFilters();
																						setFilterCleared(true);
																					}
																				}}

																				style={{ cursor: 'pointer' }}
																			/>
																		</span>

																	}


																	{(selectedRoles && selectedRoles !== 'Select All' && selectedRoles !== 'None') ||
																		phaseHashtags?.length > 0 ||
																		Object?.keys(phaseDynamicFilterTag || {}).some(
																			(key) =>
																				phaseDynamicFilterTag[Number(key)]?.artifactTypeId !== 16 &&
																				phaseDynamicFilterTag[Number(key)]?.selectedTagId?.length > 0
																		) ? (
																		<></>
																	) : null}
																	{selectedRoles &&
																		selectedRoles !== 'Select All' &&
																		selectedRoles !== 'None' ? (
																		<span className='filtertagsStyle filtertagsStyle_Nonrealized'>
																			Roles:&nbsp;{selectedRoles}

																		</span>
																	) : (
																		<></>
																	)}
																	{phaseHashtags.map((item) => (
																		<span className='filtertagsStyle filtertagsStyle_Nonrealized'>
																			{item}
																			<Icon
																				iconName="cancel"
																				className="activity-remove-icon-non-realized"
																				onClick={() => removeHashtag(item)}
																			/>
																		</span>
																	))}
																	{Object.keys(phaseDynamicFilterTag || {}).map((item) => (
																		<>
																			{phaseDynamicFilterTag?.[Number(item)]?.artifactTypeId !== 16 &&
																				phaseDynamicFilterTag?.[Number(item)]?.selectedTagId?.length > 0 &&
																				phaseDynamicFilterTag[Number(item)].selectedTagId.map((tagId) => (
																					<>
																						{phaseDynamicFilterTagMapping?.[
																							phaseDynamicFilterTag[Number(item)]?.artifactTypeId
																						]?.[tagId] && (
																								<span key={tagId} className='filtertagsStyle filtertagsStyle_Nonrealized'>
																									{phaseDynamicFilterTagMapping[
																										phaseDynamicFilterTag[Number(item)]?.artifactTypeId
																									]?.[tagId]}

																								</span>
																							)}
																					</>
																				))}
																		</>
																	))}</div>

															</Col>
														</Row>}
														<div
															className={`descrpOfPhases ${phase.id !== null ||
																parentPhase.id !== null ||
																showMoreVisible
																? `filterPhasesView ${tagFilterHeight ? 'tagFilterHeight' : ''
																}`
																: ''
																}`}
														>
															<div
																className={
																	visible.leftPanel
																		? `solutionMethodDescription ${!visible.rightPanel
																			? 'methodActive'
																			: ''
																		}
                 														${!hasParentMethod ? 'noDelivery' : ''}`
																		: 'solutionMethodDescription collapsed'
																}
															>

																{visible.leftPanel ? (
																	<>
																		{hasParentMethod ? (
																			<span
																				className="collapsingIcon"
																				onClick={() => {
																					moveLeftPanel();
																				}}
																				role="button"
																				tabIndex={0}
																				onKeyPress={(e) => {
																					if (e.which === 13) {
																						moveLeftPanel();
																					}
																				}}
																				aria-label="collapsing Left Panel"
																			>
																				<span className="roundingIcon">
																					{visible.rightPanel ? (
																						<Icon
																							iconName="ChevronRight"
																							title="expand"
																						/>
																					) : (
																						<Icon
																							iconName="ChevronLeft"
																							title="collapse"
																						/>
																					)}
																				</span>
																			</span>
																		) : null}

																		<div className="solMethodDescrOnLandPage _nonRealizedPage">
																			<NewPhase
																				filterCleared={filterCleared}
																				urlParams={urlParams}
																				setShowMoreDescription={setShowMoreDescription}
																				setShowMoreVisible={setShowMoreVisible}
																				selectedComplexity={selectedComplexity}
																				setSelectedComplexity={setSelectedComplexity}
																				setSelectedComplexityId={setSelectedComplexityId}
																				showMatrixView={showMatrixView}
																				projectTypeId={projectTypeId}
																				solutionMethodId={solutionMethodId}
																				showGroup={showGroup}
																				showFilters={showFilters}
																				selectedRoles={selectedRoles}
																				isMerged={solutionMethod.isMerged}
																				solutionMethodName={solutionMethod.solutionMethodName}
																				parentMethodName={solutionMethod.parentMethodName}
																				isRetired={solutionMethod.isRetired}
																				phaseCreated={phaseCreated}
																				setShowPublishButton={setShowPublishButton}
																				showPublishModal={showPublishModal}
																				setShowPublishModal={setShowPublishModal}
																				setPhaseCreated={setPhaseCreated}
																				coOwners={coOwners}
																				deletePhase={deletePhase}
																				setDeletePhase={setDeletePhase}
																				setDeletePhaseId={setDeletePhaseId}
																				showDeletePhaseModal={showDeletePhaseModal}
																				setShowDeletePhaseModal={setShowDeletePhaseModal}
																				setIsActivityCountZero={setIsActivityCountZero}
																				isActivityCountZero={isActivityCountZero}
																				getSolutionMethodData={getSolutionMethodData}
																				setDeletePhaseName={setDeletePhaseName}
																				setShowPhaseDeletionMessage={setShowPhaseDeletionMessage}
																				searchInput={searchInput}
																				setPhasePublishTitle={setPhasePublishTitle}
																				showPhaseUpdateModal={showPhaseUpdateModal}
																				setShowPhaseUpdateModal={setShowPhaseUpdateModal}
																				updatePhaseId={updatePhaseId}
																				setUpdatePhaseId={setUpdatePhaseId}
																				selectedTab={selectedPhaseTab}
																				setSelectedTab={setSelectedPhaseTab}
																				selectedPhase={selectedPhase}
																				setSelectedPhase={setSelectedPhase}
																				updateComplexityFilter={updateComplexityFilter}
																				setUpdateComplexityFilter={setUpdateComplexityFilter}
																				complexities={complexities}
																				setComplexities={setComplexities}
																				isEnableComplexity={isEnableComplexity}
																				triggerApi={triggerApi}
																				userActivityFilter={userActivityFilter}
																				apiCallCompleted={apiCallCompleted}
																				pageType={'newPhasePage'}
																				userRoleTodefault={userRoleTodefault}
																				setActivityClicked={setActivityClicked}
																				activityClicked={activityClicked}
																			/>
																		</div>
																	</>
																) : null}
															</div>
															{hasParentMethod ? (
																<div
																	className={
																		visible.rightPanel
																			? `deliveryMangementDescription ${!visible.leftPanel
																				? 'methodActive'
																				: ''
																			}`
																			: 'deliveryMangementDescription collapsed'
																	}
																>
																	{visible.rightPanel ? (
																		<>
																			<span
																				className="collapsingIcon"
																				onClick={() => moveRightPanel()}
																				role="button"
																				tabIndex={0}
																				onKeyPress={(e) => {
																					if (e.which === 13) {
																						moveRightPanel();
																					}
																				}}
																				aria-label="collapsing Right Panel"
																			>
																				<span className="roundingIcon">
																					{visible.leftPanel ? (
																						<Icon
																							iconName="ChevronLeft"
																							title="expand"
																						/>
																					) : (
																						<Icon
																							iconName="ChevronRight"
																							title="collapse"
																						/>
																					)}
																				</span>
																			</span>
																			<div>
																				{!showMatrixView ? (
																					<div className="contentContOfDeliveryManagment">
																						<Switch>
																							<Route
																								exact
																								path={`${url}/phase/:phaseId/parentPhase/null`}
																							>
																								<DeliveryManagementDesc
																									parentSolutionMethod={
																										parentSolutionMethod
																									}
																									setVisible={setVisible}
																								/>
																							</Route>
																							<Route
																								exact
																								path={`${url}/phase/:phaseId/parentPhase/:parentPhaseId`}
																							>
																								<DeliveryPhase
																									setShowMoreDescription={
																										setShowMoreDescription
																									}
																									setShowMoreVisible={
																										setShowMoreVisible
																									}
																									projectTypeId={projectTypeId}
																									solutionMethodId={
																										solutionMethodId
																									}
																									showGroup={showGroup}
																									showFilters={showFilters}
																									selectedRoles={selectedRoles}
																									isMerged={
																										solutionMethod.isMerged
																									}
																									solutionMethodName={
																										solutionMethod.solutionMethodName
																									}
																									parentMethodName={
																										solutionMethod.parentMethodName
																									}
																									isRetired={
																										solutionMethod.isRetired
																									}
																									phaseCreated={phaseCreated}
																									isParentPhaseDrafted={
																										isParentPhaseDrafted
																									}
																									setIsParentPhaseDrafted={
																										setIsParentPhaseDrafted
																									}
																									setShowPublishButton={
																										setShowPublishButton
																									}
																									showPublishModal={
																										showParentPhasePublishModal
																									}
																									setShowPublishModal={
																										setShowParentPhasePublishModal
																									}
																									setPhaseCreated={
																										setPhaseCreated
																									}
																									coOwners={parentPhaseCoOwners}
																									deletePhase={deletePhase}
																									setDeletePhase={
																										setDeletePhase
																									}
																									setDeletePhaseId={
																										setDeletePhaseId
																									}
																									showParentDeletePhaseModal={
																										showParentDeletePhaseModal
																									}
																									setShowParentDeletePhaseModal={
																										setShowParentDeletePhaseModal
																									}
																									setIsActivityCountZero={
																										setIsParentActivityCountZero
																									}
																									isActivityCountZero={
																										isParentActivityCountZero
																									}
																									getSolutionMethodData={
																										getSolutionMethodData
																									}
																									setDeletePhaseName={
																										setDeletePhaseName
																									}
																									setShowPhaseDeletionMessage={
																										setShowPhaseDeletionMessage
																									}
																									searchInput={searchInput}
																									selectedComplexity={
																										selectedComplexity
																									}
																									parentId={
																										solutionMethod.parentId
																									}
																									getParentSolutionMethodData={
																										getParentSolutionMethodData
																									}
																									parentPhaseCreated={
																										parentPhaseCreated
																									}
																									setParentPhaseCreated={
																										setParentPhaseCreated
																									}
																									showDeletePhaseModal={
																										showDeletePhaseModal
																									}
																									setShowDeletePhaseModal={
																										setShowDeletePhaseModal
																									}
																									setParentPhasePublishTitle={
																										setParentPhasePublishTitle
																									}
																									parentSolutionMethod={
																										parentSolutionMethod
																									}
																									showPhaseUpdateModal={
																										showParentPhaseUpdateModal
																									}
																									setShowPhaseUpdateModal={
																										setShowParentPhaseUpdateModal
																									}
																									updatePhaseId={
																										updateParentPhaseId
																									}
																									setUpdatePhaseId={
																										setUpdateParentPhaseId
																									}
																									selectedTab={
																										selectedParentPhaseTab
																									}
																									setSelectedTab={
																										setSelectedParentPhaseTab
																									}
																									selectedPhase={selectedPhase}
																									setSelectedPhase={
																										setSelectedPhase
																									}
																									updateComplexityFilter={
																										updateComplexityFilter
																									}
																									setUpdateComplexityFilter={
																										setUpdateComplexityFilter
																									}
																								/>
																							</Route>
																							<Route exact path={url}>
																								<DeliveryManagementDesc
																									parentSolutionMethod={
																										parentSolutionMethod
																									}
																									setVisible={setVisible}
																									isMerged={
																										solutionMethod.isMerged
																									}
																								/>
																							</Route>
																							<Route path="*">
																								<ComingSoon />
																							</Route>
																						</Switch>
																					</div>
																				) : (
																					<></>
																				)}
																			</div>
																		</>
																	) : null}
																</div>
															) : null}
														</div>
													</>
												)}
											</>
										)}
									</div>
									{!showMatrixView ? (
										<>
											{hasParentMethod ? (
												<DeliveryManagement
													phasesList={parentSolutionMethod.phasesList}
													parentMethodName={
														parentSolutionMethod.solutionMethodName
													}
													solutionMethodName={solutionMethod.solutionMethodName}
													isMerged={solutionMethod.isMerged}
													projectTypeId={projectTypeId}
													solutionMethodId={solutionMethodId}
													methodOwner={solutionMethod.methodOwner}
													setPhaseCreated={setPhaseCreated}
													solutionMethod={solutionMethod}
													coOwners={parentPhaseCoOwners}
													showUpdatePhasePopUp={showUpdatePhasePopUp}
													setShowPhaseUpdatePopUp={setShowPhaseUpdatePopUp}
													setDeletePhase={setDeletePhase}
													setDeletePhaseId={setDeletePhaseId}
													isRetired={solutionMethod.isRetired}
													isActivityCountZero={isParentActivityCountZero}
													parentId={solutionMethod.parentId}
													getParentSolutionMethodData={
														getParentSolutionMethodData
													}
													parentPhaseCreated={parentPhaseCreated}
													setParentPhaseCreated={setParentPhaseCreated}
													showParentDeletePhaseModal={
														showParentDeletePhaseModal
													}
													setShowParentDeletePhaseModal={
														setShowParentDeletePhaseModal
													}
													showDeletePhaseModal={showDeletePhaseModal}
													setShowDeletePhaseModal={setShowDeletePhaseModal}
													showPhaseUpdateModal={showParentPhaseUpdateModal}
													setShowPhaseUpdateModal={
														setShowParentPhaseUpdateModal
													}
													updatePhaseId={updateParentPhaseId}
													setUpdatePhaseId={setUpdateParentPhaseId}
													selectedTab={selectedParentPhaseTab}
													setSelectedTab={setSelectedParentPhaseTab}
													selectedPhase={selectedPhase}
													setSelectedPhase={setSelectedPhase}
													getSolutionMethodData={getSolutionMethodData}
												/>
											) : null}
										</>
									) : (
										<></>
									)}
								</div>
							</>
						)}
					</>
				)}
			</Route>
		</Switch>
	);
}

const ComingSoon = () => {
	const history = useHistory();
	history.push('/pageNotFound');
	return <></>;
};


export default NewLandingPage;
