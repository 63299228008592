import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class CopilotEditing extends Plugin {

    static get pluginName() {
		return 'CopilotEditing';
	}

    constructor( editor ) {
		super( editor );

        editor.config.define( 'copilot', {
			options: [
				{ model: 'writeAPrompt', title: 'Write a Prompt', class: 'ck-copilot-writeAPrompt' },
				{ model: 'autoRewrite', title: 'Auto Rewrite', class: 'ck-copilot-autoRewrite' },
				{ model: 'summarize', title: 'Summarize', class: 'ck-copilot-summarize' },
				{ model: 'businessTone', title: 'Adjust for Business Tone', class: 'ck-copilot-businessTone' },
                { model: 'legalTone', title: 'Adjust for Legal Tone', class: 'ck-copilot-legalTone' }
			]
		} );
	}
}
