import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
 
import { useAxios } from '../../context/AxiosContext';
import Dropdown from '../Dropdown';
import Spinner from '../../helpers/Spinner';
import { toast } from 'react-toastify';
import { useAppInsights } from "../../context/TelemetryContext";

const ExtractMethodlogy = ({
    showExtractMethodlogy,
    extractMethodlogyId,
    solutionMethodName,
    setSelectedComplexity,
    selectedComplexity,
    setExtractMethodologyId,
    setShowExtractMethodlogy,
    complexities,
    popupPosition
  }) => {
    const { track } = useAppInsights();
    const { axiosGetService } = useAxios();
    const [selectedExtractType, setSelectedExtractType] = useState(1);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [defaultComplexity, setDefaultComplexity] = useState();
    const [solutionComplexities, setSolutionComplexities] = useState([]);
    const [showDropdown,setShowDropdown] = useState(false);
    const [projectDelivery, setIsProjectDelivery] = useState('');
    const [isProgramDelivery, setIsProgramDelivery] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);   
 
 
    useEffect(() => {
        const fetchData = async () => {
            if (extractMethodlogyId) {
                await Promise.all([
                    getComplexityList(),
                    getProjectDeliveryId(),
                    getProgramDeliveryId(),
                    getDefaultdynamiccomplexity()
                ]);
            }
            setIsLoaded(true);
        };
        fetchData();
    }, [extractMethodlogyId]);
 
    // useEffect(() => { getComplexityList() }, [extractMethodlogyId])
 
    const getComplexityList = async () => {
        await axiosGetService(`api/activities/projectdynamiccomplexity?solutionMethodId=${extractMethodlogyId}`).then(
            (response) => {
                const processedArr = response.data.map(obj => ({
                    id: obj.id,
                    name: obj.name,
                    type: "option"
                  }));
                setSolutionComplexities(processedArr);
                setShowDropdown(true)
            }
        );
    };
 
    const handleClickOutside = (e) => {
        const extractMethodology = document.getElementById('extractMethodologyId');
        if (extractMethodology && !extractMethodology.contains(e.target)) {
            // setSelectedComplexity(defaultComplexity);
            setExtractMethodologyId(0);
            setShowExtractMethodlogy(false);
        }
    };   
 
    const getExtractDetails = async (fileType) => {
        setShowMessage(true);
        await axiosGetService(
            `api/sdmsolutionmethod/extractmethodology?solutionMethodId=${extractMethodlogyId}
            &complexityId=${selectedComplexity.id}&documentModel=1&documentType=${fileType === 1 ? 'word' : 'ppt'
            }`
        ).then((response) => {
            if (response.data.errorMessage) {
                setMessage(response.data.errorMessage);
            } 
            // else {
            //     toast.info('Extract file has been sent to your mail successfully.', {
            //         position: 'top-right',
            //     });
            // }
        });
    };
 
    const getExtractSummary = async (fileType) => {
        setShowMessage(true);
        await axiosGetService(
            `api/sdmsolutionmethod/extractmethodology?solutionMethodId=${extractMethodlogyId}
            &complexityId=0&documentModel=0&documentType=${fileType === 1 ? 'word' : 'ppt'}
            `
        ).then((response) => {
            if (response.data.errorMessage) {
                setMessage(response.data.errorMessage);
            } 
            // else {
            //     toast.info('Extract file has been sent to your mail successfully.', {
            //         position: 'top-right',
            //     });
            // }
        });
    };
 
    const getProjectDeliveryId = async () => {
        await axiosGetService(`api/sdmsettings?sdmKey=SDMNotInProjectDeliveryId`).then(
            (response) => {
                setIsProjectDelivery(response.data[0].value.split(",").at(1))
            }
        );
    };
 
    const getProgramDeliveryId = async () => {
        await axiosGetService(`api/sdmsettings?sdmKey=SDMProgramDeliveryId`).then(
            (response) => {
                setIsProgramDelivery(response.data[0].value)
            }
        );
    };
 
    // useEffect(() => {
    //     getProjectDeliveryId();
    // }, []);
    // useEffect(() => {
    //  getProgramDeliveryId();
    // },[]);
 
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
 
    // useEffect(() => {
    //  getDefaultdynamiccomplexity()
    // }, [extractMethodlogyId]);
 
    const getDefaultdynamiccomplexity = async () => {
        await axiosGetService(`api/sdmsolutionmethod/defaultdynamiccomplexity?solutionMethodId=${extractMethodlogyId}`).then(
            (response) => {
                const data = JSON.parse(response.data)
                setSelectedComplexity(data)
                setDefaultComplexity(data)
            }
        );
    };
   
    if (!isLoaded) {
        return <div className="extractPopupMCEMContainer">
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',minHeight:'344px',width:'300px',fontWeight:'bold'}}>
                            <Spinner />
                    </div>
                </div>;
      }
 
 
      const position = popupPosition ? popupPosition : { left: 'defaultLeftValue', top: 'defaultTopValue' };

    return (
        <React.Fragment>
            {showExtractMethodlogy ? (
               
                <div
                    className={extractMethodlogyId === parseInt(isProgramDelivery) ? 'extractPopupProgramContainer' : (extractMethodlogyId === projectDelivery ? 'extractPopupMCEMContainer' : 'extractPopupMainContainer')}
                    id="extractMethodologyId"
                    style={{ left:( position.left<270?270:position.left), top: position.top, height:'344px' }}
                >
                    <div className="extractPopupHeader">
                        <h6 title={solutionMethodName}>
                            Extract Methodology for "{solutionMethodName}"
                        </h6>
                        <Icon
                            iconName="Cancel"
                            onClick={() => {
                                setSelectedComplexity(defaultComplexity);
                                setExtractMethodologyId(0);
                                setShowExtractMethodlogy(false);
                            }}
                            onKeyPress={(e) => {
                                if (e.which === 13) {
                                    setSelectedComplexity(defaultComplexity);
                                    setExtractMethodologyId(0);
                                    setShowExtractMethodlogy(false);
                                }
                            }}
                            tabIndex="0"
                            title="Close"
                            aria-label={`Click to Close Extract methodology`}
                            role="button"
                        />
                    </div>
                    <div className="extractPopupBody">
                        <div className="extractFileType">
                            <span>Extract Type</span>
                            <div className="extractFileContainer">
                                <label className="checkBoxContainer filterChkboxContainer">
                                    <input
                                        name="allComplexity"
                                        type="radio"
                                        checked={selectedExtractType === 1 ? true : false}
                                        onChange={() => setSelectedExtractType(1)}
                                    />
                                    <span className="checkmark"></span>
                                    <span className="filterLabel">Summary</span>
                                </label>
                                <label className="checkBoxContainer filterChkboxContainer">
                                    <input
                                        name="allComplexity"
                                        type="radio"
                                        checked={selectedExtractType === 2 ? true : false}
                                        onChange={() => setSelectedExtractType(2)}
                                    />
                                    <span className="checkmark"></span>
                                    <span className="filterLabel">Details</span>
                                </label>
                            </div>
                        </div>
                        {showDropdown && <div
                            className={`extractSelOfComplexity ${selectedExtractType === 1 ? 'disableExtractComplexity' : ''
                                }`}
                        >
                            <span>Select Complexity</span>
                            <Dropdown
                                className="extractMethodologyComplexityDropdown"
                                data={solutionComplexities}
                                value={selectedComplexity}
                                setValue={setSelectedComplexity}
                                enableDropdown={selectedExtractType === 1 ? false : true}
                            />
                        </div>}
                        <div className="extractMethodButtons">
                            <div
                                className="extractDetailsButton"
                                onClick={() => {
                                    setMessage(
                                        'File generation is in progress. Once it is completed, you will receive a URL for download in your email.'
                                    );
                                    if (selectedExtractType === 1) {
                                        getExtractSummary(1);
                                    } else {
                                        getExtractDetails(1);
                                    }
                                    track(
                                        1,
                                        2112,
                                        'Method Extract',
                                        'Extract Word File'
                                    );
                                }}
                                onKeyPress={(e) => {
                                    if (e.which === 13) {
                                        setMessage(
                                            'File generation is in progress. Once it is completed, you will receive a URL for download in your email.'
                                        );
                                        if (selectedExtractType === 1) {
                                            getExtractSummary(1);
                                        } else {
                                            getExtractDetails(1);
                                        }
                                    }
                                    track(
                                        1,
                                        2112,
                                        'Method Extract',
                                        'Extract Word File'
                                    );
                                }}
                                tabIndex="0"
                                title="Extract Word File"
                                aria-label={`Click here to Extract Word File`}
                                role="button"
                            >
                                Extract Word File
                            </div>
 
                            <div
                                className="extractSummeryButton"
                                onClick={() => {
                                    setMessage(
                                        'File generation is in progress. Once it is completed, you will receive a URL for download in your email.'
                                    );
                                    if (selectedExtractType === 1) {
                                        getExtractSummary(2);
                                    } else {
                                        getExtractDetails(2);
                                    }
                                    track(
                                        1,
                                        2112,
                                        'Method Extract',
                                        'Extract PPT File'
                                    );
                                }}
                                onKeyPress={(e) => {
                                    if (e.which === 13) {
                                        setMessage(
                                            'File generation is in progress. Once it is completed, you will receive a URL for download in your email.'
                                        );
                                        if (selectedExtractType === 1) {
                                            getExtractSummary(1);
                                        } else {
                                            getExtractDetails(1);
                                        }
                                    }
                                    track(
                                        1,
                                        2112,
                                        'Method Extract',
                                        'Extract PPT File'
                                    );
                                }}
                                tabIndex="0"
                                title="Extract PPT File"
                                aria-label={`Click here to Extract PPT File`}
                                role="button"
                            >
                                Extract PPT File
                            </div>
                        </div>
                    </div>
                    {showMessage ? (
                        <p className="extractMethodologyMessage">{message}</p>
                    ) : (
                        <></>
                    )}
                </div>
            ) : null}
        </React.Fragment>
    );
};
 
export default ExtractMethodlogy;
