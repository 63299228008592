import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import queryString from "query-string";
import dompurify from "dompurify";
import { Button } from "react-bootstrap";
import { Prompt } from "react-router";
import loadable from "@loadable/component";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";
import TemplateCrowdSourcing from "./TemplateContribution";
import EntryCriteriaContribution from "./EntryCriteriaContribution";
import ExitCriteriaContribution from "./ExitCriteriaContribution";
import TaskContribution from "./TaskContribution";

import { useAxios } from "../../context/AxiosContext";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import { useCrowdSourcing } from "../../context/CrowdSourcingContext";
import Breadcrumbs from "../Breadcrumbs";
import AddBreadCrumb from "../../helpers/BreadcrumbService";
import Dropdown from "../Dropdown";
import DiscardPopUp from "./DiscardPopUp";
import PreviewAndSubmitPopUp from "./PreviewAndSubmitPopUp";
import { useAuth } from "../../context/UserAuthContext";
import SuccessMessagePopUp from "./SuccessPopUp";
import Spinner from "../../helpers/Spinner";
import ContributorPopUp from "./ContributorPopUp";
import FileDropModal from "../TemplateCard/FileDropModal";
import PreviewModal from "../TemplateCard/PreviewModal";
import MultiSelectDropdown from "../MultiSelectDropdown";
import MultiSelectDropdownFluent from '../MultiSelectDropdownFluent';
import SampleTemplateModal from "../TemplateCard/SampleTemplateModal";
import { useAppInsights } from "../../context/TelemetryContext";
import { getOtherUserProfile } from "../../helpers/GraphService";
import { axiosTokenService } from "../CkEditor/api/axiosBaseUrl";
import { Icon } from "@fluentui/react/lib/Icon";
import { v4 as uniqueIdGenerator } from "uuid";
import TemplateBuilder from "../ActivityTemplateBuilder";
import { artifactMapData } from "../../static/ArtifactMapData";
import ActivityRaciMatrix from "../ActivityRaciMatrix";
import { TabTitle } from "../../utils/GeneralFunctions";
import "../Activity/Activity.css";
import "./ContributorActivityCrowdSourcing.css";
import TemporaryCkAlert from "../CkEditor/TemporaryCkAlert";
import decryptAES256 from "../CkEditor/DecryptionComponent";
import { useConfig } from "../../context/ConfigContext";


const DescriptionCkEditor = loadable(
  () =>
    import(
      "../CkEditor/ActivityContributorTrackChanges/DescriptionTrackChanges"
    ),
  {
    fallback: (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    ),
  }
);
const SupportCkEditor = loadable(
  () =>
    import(
      "../CkEditor/ActivityContributorTrackChanges/SupportingActivitieTrackChanges"
    ),
  {
    fallback: (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    ),
  }
);
const TipsCkEditor = loadable(
  () =>
    import(
      "../CkEditor/ActivityContributorTrackChanges/TipsAndTechniquesTrackChanges"
    ),
  {
    fallback: (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    ),
  }
);
const DeliverableCkEditor = loadable(
  () =>
    import(
      "../CkEditor/ActivityContributorTrackChanges/DeliverableTrackChanges"
    ),
  {
    fallback: (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    ),
  }
);

function ContributorActivityCrowdSourcing({isEnableComplexity}) {
  const { ckEdKEY } = useConfig();
  const graphUserData = [];
  const userDataMap = new Map();
  const history = useHistory();
  const htmlSanitizer = dompurify.sanitize;
  const { breadcrumbs, setBreadcrumbs, setSkipNavigationId } =
    useLeftMenuCollapse();
  const { track } = useAppInsights();
  const { crowdSourcingAppState, setCrowdSourcingAppState } =
    useCrowdSourcing();
  const { userLeaveWithoutSave } = crowdSourcingAppState;
  const [ckEditorUsers, setCkEditorUsers] = useState([]);
  const [leavingWithoutSave, setLeavingWithoutSave] = useState(true);
  const { user } = useAuth();
  const CkEditorCurrentUser = user.profile.mail;

  const {
    projectTypeId,
    solutionMethodId,
    phaseId,
    parentPhaseId,
    activityId,
  } = useParams();
  const { axiosGetService } = useAxios();
  const searchComplexityParam = Number(
    queryString.parse(window.location.search).complexity
  );
  const activityIdentifier = queryString.parse(
    window.location.search
  ).identifier;
  const [activityContent, setactivityContent] = useState(null);
  const [templateData, setTemplateData] = useState([]);
  const [entryCriteriaData, setEntryCriteriaData] = useState([]);
  const [exitCriteriaData, setExitCriteriaData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [procedureData, setProcedureData] = useState([]);
  const [complexities, setComplexities] = useState([]);
  const [messagesType, setMessageType] = useState({});
  const [sidebarCard, setSidebarCard] = useState([]);
  const complexityId = searchComplexityParam;
  const [complexityName, setComplexityName] = useState({
    name: "",
    type: "option",
    id: "",
  });
  const [message, setMessage] = useState(null);
  const [showDiscardPopUp, setShowDiscardPopUp] = useState(false);
  const [showPreviewAndSubmitPopUp, setShowPreviewAndSubmitPopUp] =
    useState(false);
  const [description, setDescription] = useState(null);
  const [supportingActivities, setSupportingActivities] = useState(null);
  const [toolsAndGuidance, setToolsAndGuidance] = useState(null);
  const [tipsAndTechniques, setTipsAndTechniques] = useState(null);
  const [contributorsData, setContributorsData] = useState(null);
  const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);
  const [supportingActivitiesSuggestions, setSupportingActivitiesSuggestions] =
    useState([]);
  const [ckEditorUserMap, setCkEditorUserMap] = useState({});
  const [toolsAndGuidanceSuggestions, setToolsAndGuidanceSuggestions] =
    useState([]);
  const [contributionToolsId, setContributionToolsId] = useState({});
  const [
    selectedToolsAndGuidanceSuggestions,
    setSelectedToolsAndGuidanceSuggestions,
  ] = useState([]);
  const [checkForAdminToolsUpdate, setCheckForAdminToolsUpdate] = useState([]);
  const [adminToolsAndGuidance, setAdminToolsAndGuidance] = useState([]);
  const [entryCriteriaSuggestions, setEntryCriteriaSuggestions] = useState([]);
  const [exitCriteriaSuggestions, setExitCriteriaSuggestions] = useState([]);
  const [outputSuggestions, setOutputSuggestions] = useState([]);
  const [entryCriteriaWithSuggestions, setEntryCriteriaWithSuggestions] =
    useState([]);
  const [exitCriteriaWithSuggestions, setExitCriteriaWithSuggestions] =
    useState([]);
  const [
    contributorEntryCriteriaSuggestion,
    setContributorEntryCriteriaSuggestion,
  ] = useState({});

  const [
    contributorExitCriteriaSuggestion,
    setContributorExitCriteriaSuggestion,
  ] = useState({});

  const [taskSuggestions, setTaskSuggestions] = useState([]);
  const [taskWithSuggestions, setTaskWithSuggestions] = useState([]);
  const [contributorTaskSuggestion, setContributorTaskSuggestion] = useState(
    {}
  );

  const [tipsAndTechniquesSuggestions, setTipsAndTechniquesSuggestions] =
    useState([]);
  const [toolsUrl, setToolsUrl] = useState({});
  const [rejectedDescSuggestions, setRejectedDescSuggestions] = useState([]);
  const [rejectedSupportSuggestioons, setRejectedSupportSuggestioons] =
    useState([]);
  const [rejectedToolsSuggestions, setRejectedToolsSuggestions] = useState([]);
  const [rejectedTipsSuggestions, setRejectedTipsSuggestions] = useState([]);
  const [toolsSidebarHeight, setToolsSidebarHeight] = useState("239px");
  const [licenseKey, setLicenseKey] = useState(null);
  const [sampleTemplates, setSampleTemplates] = useState({
    enable: false,
    data: null,
    parentData: null,
  });
  // template Preview Data
  const [templatePreviewData, setTemplatePreviewData] = useState(null);
  const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);
  const [enableFileDropModal, setEnableFileDropModal] = useState(false);
  const handleTemplatePreview = (data) => {
    setTemplatePreviewData(data);
    setEnableTemplatePreview(true);
  };
  const [isActivityLock, setIsActivityLock] = useState(false);
  const [existingHashtags, setExistingHashtags] = useState([]);
  const [activityLockedBy, setActivityLockedBy] = useState("");
  const [lockedByUsername, setLockedByUsername] = useState("");
  const [templateSuggestions, setTemplateSuggestions] = useState([]);
  const [showTemplateBuilder, setShowTemplateBuilder] = useState(false);
  const [contributorTemplateSuggestion, setContributorTemplateSuggestion] =
    useState([]);
  const [templateSidebarHeight, setTemplateSidebarHeight] = useState("239px");
  const [entryCriteriaButtonClicked, setEntryCriteriaButtonClicked] =
    useState(false);
  const [exitCriteriaButtonClicked, setExitCriteriaButtonClicked] =
    useState(false);
  const [taskButtonClicked, setTaskButtonClicked] = useState(false);
  const [outputButtonClicked, setOutputButtonClicked] = useState(false);
  const [currentEntryTab, setCurrentEntryTab] = useState("tab-entry");
  const [currentExitTab, setCurrentExitTab] = useState("tab-exit");
  const [reviewerEntryCriteriaSuggestions, setReviewerEntryCriteriaSuggestions] = useState([]);
  const [reviewerExitCriteriaSuggestions, setReviewerExitCriteriaSuggestions] = useState([]);
  const [reviewerTaskSuggestions, setReviewerTaskSuggestions] = useState([]);
  const [reviewerTemplateSuggestions, setReviewerTemplateSuggestions] =
    useState([]);
  const [deliverables, setDeliverables] = useState(null);
  const [deliverablesSuggestions, setDeliverablesSuggestions] = useState([]);
  const [rejectedDeliverablesSuggestions, setRejectedDeliverablesSuggestions] = useState([]);
  const [taskReorder,setTaskReorder] = useState(false);
  const [entryContributionReorder,setEntryContributionReorder] = useState(false);
  const [inputContributionReorder,setInputContributionReorder] = useState(false);
  const [exitContributionReorder,setExitContributionReorder] = useState(false);
  const [outputContributionReorder,setOutputContributionReorder] = useState(false);
  const [taskReorderArray, setTaskReorderArray] = useState([]);
  const [entryCriteriaReorderArray, setEntryCriteriaReorderArray] = useState([]);
  const [inputCriteriaReorderArray, setInputCriteriaReorderArray] = useState([]);
  const [exitCriteriaReorderArray, setExitCriteriaReorderArray]= useState([]);
  const [outputCriteriaReorderArray, setOutputCriteriaReorderArray]= useState([]);
  const [initialTaskIdBeforeReorder, setInitialTaskIdBeforeReorder] = useState([])
  const [initialEntryIdBeforeReorder,setInitialEntryIdBeforeReorder]= useState([])
  const [initialExitIdBeforeReorder,setInitialExitIdBeforeReorder]= useState([])
  const [ckEditorCurrentReorderUsername, setCkEditorCurrentReorderUsername] = useState(user.profile.firstName+' '+user.profile.surname)
  const [ckEditorCurrentReorderUserImage,setCkEditorCurrentReorderUserImage]=useState(user.profile.photo)
  const [disableDrag, setDisableDrag] = useState(false)
  const [ckEditorCurrentEntryReorderUsername, setCkEditorCurrentEntryReorderUsername] = useState(user.profile.firstName+' '+user.profile.surname)
  const [ckEditorCurrentEntryReorderUserImage,setCkEditorCurrentEntryReorderUserImage]=useState(user.profile.photo)
  const [ckEditorCurrentExitReorderUserImage,setCkEditorCurrentExitReorderUserImage]=useState(user.profile.photo)
	const [ckEditorCurrentExitReorderUsername, setCkEditorCurrentExitReorderUsername] = useState(user.profile.firstName+' '+user.profile.surname)
  const [ckEditorCurrentInputReorderUserImage,setCkEditorCurrentInputReorderUserImage]=useState(user.profile.photo)
	const [ckEditorCurrentInputReorderUsername, setCkEditorCurrentInputReorderUsername] = useState(user.profile.firstName+' '+user.profile.surname)
	const [ckEditorCurrentOutputReorderUserImage,setCkEditorCurrentOutputReorderUserImage]=useState(user.profile.photo)
	const [ckEditorCurrentOutputReorderUsername,setCkEditorCurrentOutputReorderUsername]=useState(user.profile.firstName+' '+user.profile.surname)
  const [addedTaskSequence, setAddedTaskSequence] = useState([])
  const [addedEntrySequence, setAddedEntrySequence] = useState([])
  const [addedExitSequence, setAddedExitSequence] = useState([])
  const [disableEntryDrag, setDisableEntryDrag] = useState(false)
	const [disableInputDrag, setDisableInputDrag] = useState(false)
	const [disableExitDrag, setDisableExitDrag] = useState(false)
	const [disableOutputDrag, setDisableOutputDrag] = useState(false)
  const [isOrderChanged, setIsOrderChanged] = useState({
    task:false,
    entry:false,
    exit:false,
    input:false,
    output:false
  })

  const getActivityLockInfo = async () => {
    await axiosGetService(
      `api/sdmartifactmaster/getLockedArtifactsForContribution?artifactTypeConstId=${artifactMapData["activities"]
      }&artifactId=${activityId}&artifactDraftId=${0}`
    )
      .then(async (response) => {
        if (response.data) {
          setIsActivityLock(true);
          setActivityLockedBy(response.data.email);
          const accessToken = await axiosTokenService();
          const userData = await getOtherUserProfile(
            accessToken,
            response.data.email
          );
          if (userData)
            setLockedByUsername(userData.firstName + " " + userData.surname);
          else setLockedByUsername(response.data.email);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getExistingTemplates = () => {
    const existingTemplates = templateSuggestions.map((template) =>
      JSON.parse(template)
    );
    return [...templateData, ...existingTemplates];
  };

  const addTemplateToSuggestion = (suggestions, source) => {
    const updatedSuggestions = suggestions
      .filter(
        (suggestedTemplate) =>
          !templateData.some(({ id }) => id === suggestedTemplate.id)
      )
      .map((template) =>
        JSON.stringify({
          ...template,
          suggestionType: "insertion",
          authorId: CkEditorCurrentUser,
          title: template.name,
          templateId: template.id.toString(),
          createdAt: new Date(),
        })
      );

    setTemplateSuggestions((prevState) => [
      ...prevState,
      ...updatedSuggestions,
    ]);
    if (source === "templateUpload") {
      setContributorTemplateSuggestion((prevState) => [
        ...prevState,
        ...updatedSuggestions,
      ]);
    }
  };
  const removeToolsAndGuidance = (cardType, item) => {
    let data = [];
    let count = 0;
    for (const key in selectedToolsAndGuidanceSuggestions) {
      if (
        JSON.parse(selectedToolsAndGuidanceSuggestions[key]).toolsId !==
        item.toolsId
      ) {
        data.push(selectedToolsAndGuidanceSuggestions[key]);
        count = count + 1;
      } else if (
        adminToolsAndGuidance.includes(item.toolsId) &&
        cardType === "admin"
      ) {
        count = count + 1;
        const suggestionCardData = {
          toolsId: item.toolsId,
          id: uniqueIdGenerator(),
          authorId: CkEditorCurrentUser,
          suggestionType: "deletion",
          name: item.name,
          type: "option",
          createdAt: new Date(),
          isSelected: false,
        };
        data.push(JSON.stringify(suggestionCardData));

        if (!checkForAdminToolsUpdate.includes(item.toolsId)) {
          setCheckForAdminToolsUpdate([
            ...checkForAdminToolsUpdate,
            item.toolsId,
          ]);
        }
      } else if (
        adminToolsAndGuidance.includes(item.toolsId) &&
        cardType === "adminToolsDeletion"
      ) {
        count = count + 1;
        const suggestionCardData = {
          toolsId: item.toolsId,
          id: uniqueIdGenerator(),
          authorId: CkEditorCurrentUser,
          suggestionType: "none",
          name: item.name,
          type: "option",
          createdAt: new Date(),
          isSelected: true,
        };
        const removeCheckedSuggestion = checkForAdminToolsUpdate.filter(
          (id) => Number(id) !== Number(item.toolsId)
        );
        setCheckForAdminToolsUpdate(removeCheckedSuggestion);
        data.push(JSON.stringify(suggestionCardData));
      }
    }
    setSelectedToolsAndGuidanceSuggestions(count > 0 ? data : []);
    data = [];
    for (const key in toolsAndGuidanceSuggestions) {
      if (toolsAndGuidanceSuggestions[key].toolsId === item.toolsId) {
        toolsAndGuidanceSuggestions[key].isSelected =
          cardType === "adminToolsDeletion" ? true : false;
        data.push(toolsAndGuidanceSuggestions[key]);
      } else {
        data.push(toolsAndGuidanceSuggestions[key]);
      }
    }
    setToolsAndGuidanceSuggestions(data);
  };




  const getActivityContents = async () => {
    await axiosGetService(
      `api/activities/activity?activityIdentifier=${activityIdentifier}&complexityId=${complexityId}&url=activity&urlPathName=activity`
    )
      .then(async (response) => {
        const mappedComplexities = response.data.mappedCompelxityList.map(
          (item) => ({
            name: item.name,
            id: item.id,
            option: "type",
          })
        );
        setComplexityName({
          name: response.data.complexityName,
          id: response.data.complexityId,
          type: "option",
        });
        const hashtags = response.data.hashTagsList.map(({ name }) => {
          return name;
        });
        setExistingHashtags(hashtags);
        setTemplateData((prevState) => [
          ...response.data.activityTemplateDetails,
          ...prevState,
        ]);
        setTopicData(response.data.activityTopics);
        setactivityContent(response.data);
        setComplexities(mappedComplexities);
        setProcedureData(response.data.activityProcedureType);
        setIsActivityLock(response.data.isDrafted);
        setActivityLockedBy(response.data.lastDraftChangesMadeBy);
        if (response.data.isDrafted) {
          const accessToken = await axiosTokenService();
          const userData = await getOtherUserProfile(
            accessToken,
            response.data.lastDraftChangesMadeBy
          );
          if (userData)
            setLockedByUsername(userData.firstName + " " + userData.surname);
          else setLockedByUsername(response.data.lastDraftChangesMadeBy);
        }
        if (!response.data.isDrafted) {
          getActivityLockInfo();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await axiosGetService(
      `api/sdmactivity/getActivity2/${activityId}/${complexityId}`
    )
      .then((response) => {
        setContributorsData(response.data);
        const adminTools = [];
        const contributedToolsId = [];
        const contributorToolsSuggestions = [];
        const updateIsSelected = {};
        const otherUserInfo = {};
        if (
          response.data.resultCalloutJson &&
          response.data.resultCalloutJson.toolsAndGuidance
        ) {
          const toolsSuggestionData =
            response.data.resultCalloutJson.toolsAndGuidance;
          for (const id in toolsSuggestionData) {
            contributedToolsId.push(
              JSON.parse(toolsSuggestionData[id]).toolsId
            );

            const tools = {
              toolsId: JSON.parse(toolsSuggestionData[id]).toolsId,
              id: JSON.parse(toolsSuggestionData[id]).id,
              authorId: JSON.parse(toolsSuggestionData[id]).authorId,
              suggestionType: JSON.parse(toolsSuggestionData[id])
                .suggestionType,
              name: JSON.parse(toolsSuggestionData[id]).name,
              createdAt: JSON.parse(toolsSuggestionData[id]).createdAt,
              type: "option",
              isSelected: JSON.parse(toolsSuggestionData[id]).isSelected,
            };
            otherUserInfo[tools.toolsId] = tools.authorId;
            if (tools.suggestionType === "insertion") {
              updateIsSelected[tools.toolsId] = true;
            } else {
              updateIsSelected[tools.toolsId] = false;
            }
            contributorToolsSuggestions.push(JSON.stringify(tools));
          }
        }
        setContributionToolsId(otherUserInfo);
        const updateToolsAndGuideSuggestion = [];
        const toolData = [...response.data.activityToolsList];
        const url = {};
        for (const id in toolData) {
          const tools = {
            activities: toolData[id].activities,
            createdBy: toolData[id].createdBy,
            createdOn: toolData[id].createdOn,
            email: toolData[id].email,
            toolsId: toolData[id].id,
            isSelected: toolData[id].isSelected,
            modifiedBy: toolData[id].modifiedBy,
            modifiedOn: toolData[id].modifiedOn,
            modifiedUserEmail: toolData[id].modifiedUserEmail,
            name: toolData[id].name,
            url: toolData[id].url,
          };
          if (toolData[id].id in updateIsSelected) {
            tools.isSelected = updateIsSelected[toolData[id].id];
            updateToolsAndGuideSuggestion.push(tools);
          } else {
            updateToolsAndGuideSuggestion.push(tools);
          }
          url[toolData[id].id] = toolData[id].url;
        }
        setToolsUrl(url);
        setToolsAndGuidanceSuggestions(updateToolsAndGuideSuggestion);
        for (const id in response.data.activityToolsList) {
          if (
            response.data.activityToolsList[id].isSelected &&
            !contributedToolsId.includes(response.data.activityToolsList[id].id)
          ) {
            const tools = {
              toolsId: response.data.activityToolsList[id].id,
              authorId: null,
              suggestionType: "none",
              createdAt: new Date(),
              name: response.data.activityToolsList[id].name,
              type: "option",
              isSelected: true,
            };
            contributorToolsSuggestions.push(JSON.stringify(tools));
            adminTools.push(response.data.activityToolsList[id].id);
          }
        }
        setAdminToolsAndGuidance([...adminTools]);
        setSelectedToolsAndGuidanceSuggestions(contributorToolsSuggestions);
        if (
          response.data.resultCalloutJson &&
          response.data.resultCalloutJson.templateSuggestions
        ) {
          setTemplateSuggestions(
            response.data.resultCalloutJson.templateSuggestions.map(
              (template) =>
                JSON.stringify({
                  ...JSON.parse(template),
                  suggestionType: "insertion",
                })
            )
          );
          setContributorTemplateSuggestion([
            ...response.data.resultCalloutJson.templateSuggestions,
          ]);
        }
        setEntryCriteriaData(response.data.entryCriteriaList);
        getEntryCriteriaSuggestions(response);
        setExitCriteriaData(response.data.exitCriteriaList);
        getExitCriteriaSuggestions(response);
        setTaskData(response.data.taskList);
        getTaskSuggestions(response);
        setOutputData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  if (leavingWithoutSave && !showDiscardPopUp && !userLeaveWithoutSave) {
    setCrowdSourcingAppState({
      ...crowdSourcingAppState,
      userLeaveWithoutSave: true,
    });
  }

  const updateToolsSuggestions = () => {
    const updatedCard = [];
    for (const id in selectedToolsAndGuidanceSuggestions) {
      if (
        !adminToolsAndGuidance.includes(
          JSON.parse(selectedToolsAndGuidanceSuggestions[id]).toolsId
        ) ||
        JSON.parse(selectedToolsAndGuidanceSuggestions[id]).suggestionType ===
        "deletion"
      ) {
        updatedCard.push(selectedToolsAndGuidanceSuggestions[id]);
      }
    }
    setSidebarCard([...updatedCard]);
  };

  const getEntryCriteriaSuggestions = async (response) => {
    if (
      response.data.entryCriteriaList &&
      !(
        response.data.resultCalloutJson &&
        response.data.resultCalloutJson.entryCriteriaSuggestions
      )
    ) {
      const updatedList = response.data.entryCriteriaList

        // .filter((item) => item.criteriaTypeName === 'Entry Criteria')
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: "none",
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );

      setEntryCriteriaWithSuggestions((prevState) => [
        ...prevState,
        ...updatedList,
      ]);
      setInitialEntryIdBeforeReorder((prevState) => [
        ...prevState,
        ...updatedList,
      ]);
    }

    if (
      response.data.resultCalloutJson &&
      response.data.resultCalloutJson.entryCriteriaSuggestions
    ) {
      setEntryCriteriaSuggestions([
        ...response.data.resultCalloutJson.entryCriteriaSuggestions,
      ]);
      setContributorEntryCriteriaSuggestion([
        ...response.data.resultCalloutJson.entryCriteriaSuggestions,
      ]);
      // if(response.data.resultCalloutJson.entryCriteriaSuggestions.some((item)=>JSON.parse(item).id===))
      const adminEntryCriteria = response.data.entryCriteriaList
        .filter(
          (item) =>
            !response.data.resultCalloutJson.entryCriteriaSuggestions.some(
              (value) => item.id === JSON.parse(value).id
            )
        )
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: "none",
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );

      setEntryCriteriaWithSuggestions([
        ...adminEntryCriteria,
        ...response.data.resultCalloutJson.entryCriteriaSuggestions,
      ]);
      setInitialEntryIdBeforeReorder([
        ...adminEntryCriteria,
        ...response.data.resultCalloutJson.entryCriteriaSuggestions,
      ]);
    }
  };

  const getExitCriteriaSuggestions = async (response) => {
    if (
      response.data.exitCriteriaList &&
      !(
        response.data.resultCalloutJson &&
        response.data.resultCalloutJson.exitCriteriaSuggestions
      )
    ) {
      const updatedList = response.data.exitCriteriaList
        //.filter((item) => item.criteriaTypeName === 'Exit Criteria')
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: "none",
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );

      setExitCriteriaWithSuggestions((prevState) => [
        ...prevState,
        ...updatedList,
      ]);
      setInitialExitIdBeforeReorder((prevState) => [
        ...prevState,
        ...updatedList,
      ]);
    }

    if (
      response.data.resultCalloutJson &&
      response.data.resultCalloutJson.exitCriteriaSuggestions
    ) {
      setExitCriteriaSuggestions([
        ...response.data.resultCalloutJson.exitCriteriaSuggestions,
      ]);
      setContributorExitCriteriaSuggestion([
        ...response.data.resultCalloutJson.exitCriteriaSuggestions,
      ]);
      // if(response.data.resultCalloutJson.exitCriteriaSuggestions.some((item)=>JSON.parse(item).id===))
      const adminExitCriteria = response.data.exitCriteriaList
        .filter(
          (item) =>
            !response.data.resultCalloutJson.exitCriteriaSuggestions.some(
              (value) => item.id === JSON.parse(value).id
            )
        )
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: "none",
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );

      setExitCriteriaWithSuggestions([
        ...adminExitCriteria,
        ...response.data.resultCalloutJson.exitCriteriaSuggestions,
      ]);
      setInitialExitIdBeforeReorder([
        ...adminExitCriteria,
        ...response.data.resultCalloutJson.exitCriteriaSuggestions,
      ]);
      
    }
  };

  const getTaskSuggestions = async (response) => {
    if (
      response.data.taskList &&
      !(
        response.data.resultCalloutJson &&
        response.data.resultCalloutJson.taskSuggestions
      )
    ) {
      const updatedList = response.data.taskList
        //.filter((item) => item.criteriaTypeName === 'Exit Criteria')
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: "none",
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );

      setTaskWithSuggestions((prevState) => [...prevState, ...updatedList]);
      setInitialTaskIdBeforeReorder((prevState)=>[...prevState,...updatedList])
    }

    if (
      response.data.resultCalloutJson &&
      response.data.resultCalloutJson.taskSuggestions
    ) {
      setTaskWithSuggestions([
        ...response.data.resultCalloutJson.taskSuggestions,
      ]);
      setContributorTaskSuggestion([
        ...response.data.resultCalloutJson.taskSuggestions,
      ]);
      // if(response.data.resultCalloutJson.exitCriteriaSuggestions.some((item)=>JSON.parse(item).id===))
      const adminTask = response.data.taskList
        .filter(
          (item) =>            !response.data.resultCalloutJson.taskSuggestions.some(
              (value) => item.id === JSON.parse(value).id
            )
        )
        .map((item) =>
          JSON.stringify({
            ...item,
            suggestionType: "none",
            authorId: CkEditorCurrentUser,
            suggestionId: null,
            createdAt: new Date(),
          })
        );


      setTaskWithSuggestions([
        ...adminTask,
        ...response.data.resultCalloutJson.taskSuggestions,
      ]);
      setInitialTaskIdBeforeReorder([
        ...adminTask,
        ...response.data.resultCalloutJson.taskSuggestions,
      ]);
    }
  };

  const setOutputData = async (response) => {
    if (
      response.data.resultCalloutJson &&
      response.data.resultCalloutJson.outputSuggestions
    ) {
      setOutputSuggestions(response.data.resultCalloutJson.outputSuggestions);
    }
  };

  const loadBreadcrumb = async () => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=${activityId}&itemTypeId=2
			&isEdit=true&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=false&documentStatus=0
			&isNotifications=false&solutionMethodId=${solutionMethodId}&phaseId=${phaseId !== "null"
        ? phaseId
        : parentPhaseId !== "null"
          ? parentPhaseId
          : 0
      }`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split(">")
        .map((item) => item.trim());

      const projectBreadcrumb = {
        title: breadcrumbTitle[1],
        type: "projectType",
        path: `/project/${projectTypeId}`,
        parent: ["home", "myContributions"],
      };
      const solutionMethodBreadcrumb = {
        title: breadcrumbTitle[2],
        type: "solutionMethod",
        path: `/project/${projectTypeId}/method/${solutionMethodId}`,
        parent: "projectType",
      };
      const phaseBreadcrumb = {
        title: breadcrumbTitle[3],
        type: "Phase",
        path: `/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}`,
        parent: "solutionMethod",
      };
      const titleBreadcrumb = {
        title: breadcrumbTitle[4],
        type: "pageTitle",
        path: `/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}/activity/${activityIdentifier}?complexity=${complexityId}`,
        parent: ["phase"],
      };
      const pageBreadcrumb = {
        title: breadcrumbTitle[5],
        type: "activity",
        path: window.location.pathname + `?complexity=${complexityId}`,
        parent: ["pageTitle"],
      };
      const newBreadcrumbs = breadcrumbs.slice(
        0,
        breadcrumbs.length > 1 && breadcrumbs[1].type === "myContributions"
          ? 2
          : 1
      );
      if (
        phaseId === "null" &&
        parentPhaseId === "null" &&
        solutionMethodId === "0"
      ) {
        newBreadcrumbs.push(titleBreadcrumb);
        newBreadcrumbs.push(pageBreadcrumb);
      } else {
        newBreadcrumbs.push(projectBreadcrumb);
        newBreadcrumbs.push(solutionMethodBreadcrumb);
        newBreadcrumbs.push(phaseBreadcrumb);
        newBreadcrumbs.push(titleBreadcrumb);
        newBreadcrumbs.push(pageBreadcrumb);
      }
      setBreadcrumbs(newBreadcrumbs);
      TabTitle(breadcrumbTitle[5] + " Activity");
    });
  };

  const getUsersEmail = (suggestions) => {
    for (const suggestion of suggestions) {
      const userEmail = JSON.parse(suggestion).authorId;
      if (userEmail && !userDataMap.has(userEmail)) {
        graphUserData.push(userEmail);
        userDataMap.set(userEmail, 1);
      }
    }
  };

  // $(document).ready(function () {
  // 	$('ul.criteria-tabs li').click(function () {
  // 		var tab_id = $(this).attr('data-tab');

  // 		$('ul.criteria-tabs li').removeClass('current');
  // 		$('.criteria-tab-content').removeClass('current');

  // 		$(this).addClass('current');
  // 		$('#' + tab_id).addClass('current');
  // 	});
  // });

  const getUsersInfo = async () => {
    const contributorInfo = [];
    if (contributorsData.resultCalloutJson) {
      if (contributorsData.resultCalloutJson.description) {
        getUsersEmail(contributorsData.resultCalloutJson.description);
      }
      if (contributorsData.resultCalloutJson.supportingActivities) {
        getUsersEmail(contributorsData.resultCalloutJson.supportingActivities);
      }
      if (contributorsData.resultCalloutJson.toolsAndGuidance) {
        getUsersEmail(contributorsData.resultCalloutJson.toolsAndGuidance);
      }
      if (contributorsData.resultCalloutJson.tipsAndTechniques) {
        getUsersEmail(contributorsData.resultCalloutJson.tipsAndTechniques);
      }
      if (contributorsData.resultCalloutJson.templateSuggestions) {
        getUsersEmail(contributorsData.resultCalloutJson.templateSuggestions);
      }
      if (contributorsData.resultCalloutJson.entryCriteriaSuggestions) {
        getUsersEmail(
          contributorsData.resultCalloutJson.entryCriteriaSuggestions
        );
      }
      if (contributorsData.resultCalloutJson.exitCriteriaSuggestions) {
        getUsersEmail(
          contributorsData.resultCalloutJson.exitCriteriaSuggestions
        );
      }
      if (contributorsData.resultCalloutJson.taskSuggestions) {
        getUsersEmail(contributorsData.resultCalloutJson.taskSuggestions);
      }
      if (contributorsData.resultCalloutJson.outputSuggestions) {
        getUsersEmail(contributorsData.resultCalloutJson.outputSuggestions);
      }
      if (contributorsData.resultCalloutJson.deliverables) {
        getUsersEmail(contributorsData.resultCalloutJson.deliverables);
      }
    }
    if (!userDataMap.has(CkEditorCurrentUser)) {
      graphUserData.push(CkEditorCurrentUser);
      userDataMap.set(CkEditorCurrentUser, 1);
    }
    const userProfileMapping = {};
    const accessToken = await axiosTokenService();
    for (const id in graphUserData) {
      const userEmail = graphUserData[id];
      const userData = await getOtherUserProfile(accessToken, userEmail);
      contributorInfo.push({
        id: userData.mail,
        name: userData.firstName + " " + userData.surname,
        avatar: userData.photo,
      });
      userProfileMapping[userData.mail] = [
        userData.firstName + " " + userData.surname,
        userData.photo,
      ];
    }
    setCkEditorUserMap(userProfileMapping);
    setCkEditorUsers(contributorInfo);
  };

  const getLicense = async () => {
    const response = await axiosGetService(
      `api/sdmsettings?sdmKey=CKEditorLicenseKey`
    );
    const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
  };

  const getTemplateSidebarHeight = () => {
    const height = $(".templateContentContainer").height();
    setTemplateSidebarHeight(height + 63);
  };

  const getMessage = async (title) => {
    const messages = {};
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=contentUpdate&title=0`
    ).then((response) => {
      messages["contentUpdate"] = response.data;
    });
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=noContributionMessage&title=0`
    ).then((response) => {
      messages["noContributionMessage"] = response.data;
    });
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=contributionSubmissionMessage&title=${encodeURIComponent(
        title
      )}`
    ).then((response) => {
      messages["contributionSubmissionMessage"] = response.data;
    });
    await axiosGetService(
      `/api/sdmuser/getmessagevalue?messageName=pendingApproval&title=0`
    ).then((response) => {
      messages["pendingApproval"] = response.data;
    });
    setMessageType(messages);
  };
  const getToolsConatinerHeight = () => {
    const height = $(".ToolsContentContainer").height();
    setToolsSidebarHeight(height + 63);
  };

  useEffect(() => {
    getLicense();
  }, []);

  useEffect(() => {
    return () => {
      setCrowdSourcingAppState({
        ...crowdSourcingAppState,
        userLeaveWithoutSave: false,
      });
    };
  }, []);

  useEffect(() => {
    getToolsConatinerHeight();
  });

  useEffect(() => {
    getTemplateSidebarHeight();
  });

  useEffect(() => {
    if (contributorsData) {
      getUsersInfo();
    }
  }, [contributorsData]);

  useEffect(() => {
    getActivityContents();
    setSkipNavigationId("innerContent");
  }, []);

  useEffect(() => {
    if (activityContent) {
      track(2, 130, activityContent.activityId, activityContent.activityName);
      getMessage(activityContent.activityName);
    }
  }, [activityContent]);

  useEffect(() => {
    loadBreadcrumb();
  }, []);

  useEffect(() => {
    updateToolsSuggestions();
  }, [selectedToolsAndGuidanceSuggestions]);

  useEffect(() => {
    if (contributorsData) {
      setDescription(contributorsData.description);
      setSupportingActivities(contributorsData.supportingActivities);
      setToolsAndGuidance(contributorsData.toolsAndGuidance);
      setTipsAndTechniques(contributorsData.tipsAndTechniques);
      setDeliverables(contributorsData.deliverables);
    }
    if (contributorsData && contributorsData.resultCalloutJson) {
      setDescriptionSuggestions(contributorsData.resultCalloutJson.description);
      setSupportingActivitiesSuggestions(
        contributorsData.resultCalloutJson.supportingActivities
      );
      setTipsAndTechniquesSuggestions(
        contributorsData.resultCalloutJson.tipsAndTechniques
      );
      setDeliverablesSuggestions(contributorsData.resultCalloutJson.deliverables);


    }
  }, [contributorsData]);

  if (
    !activityContent ||
    ckEditorUsers.length < 1 ||
    !contributorsData ||
    !licenseKey
  ) {
    return (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    );
  }
  if (Number(contributorsData.stateId) === 2 || isActivityLock) {
    return (
      <ContributorPopUp
        isActivityLock={isActivityLock}
        lockedByUsername={lockedByUsername}
        activityLockedBy={activityLockedBy}
      />
    );
  }


  return (
    <div>
      <div className="activityLandinPage activityCrowdSourcing">
        <Breadcrumbs />

        <SuccessMessagePopUp message={message} />
        <div className="activityMainContainer">
          <div className="activityDescriptionDetails">
            <div className="activityDescTitlePart">
              <div className="activityTitleRightPart">
                <span className="activityVersion">
                  {`Document Version: ${activityContent.activityVersion},`}
                  &nbsp;&nbsp;{`${activityContent.date} `}
                </span>
              {isEnableComplexity &&  <div className="activityComplexity">
                  <span>Complexity:</span>
                  <Dropdown
                    className="complexityDisabled"
                    data={complexities}
                    setValue={setComplexityName}
                    value={complexityName}
                    enableDropdown={false}
                  />
                </div>}
                {showDiscardPopUp ? (
                  <DiscardPopUp
                  setShowDiscardPopUp={setShowDiscardPopUp}
                  complexityId={complexityId}
                  activityId={activityId}
                  />
                ) : null}
                {showPreviewAndSubmitPopUp ? (
                  <PreviewAndSubmitPopUp
                    toolsUrl={toolsUrl}
                    isEnableComplexity={isEnableComplexity}
                    setShowPreviewAndSubmitPopUp={setShowPreviewAndSubmitPopUp}
                    activityContent={activityContent}
                    activityDescription={description}
                    activitySupportingActivities={supportingActivities}
                    templateData={templateData}
                    activityToolsAndGuidance={toolsAndGuidance}
                    activityTipsAndTechniques={tipsAndTechniques}
                    entryCriteriaData={entryCriteriaData}
                    exitCriteriaData={exitCriteriaData}
                    taskData={taskData}
                    complexities={complexities}
                    setComplexityName={setComplexityName}
                    complexityName={complexityName}
                    complexityId={complexityId}
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    descriptionSuggestions={descriptionSuggestions}
                    supportingActivitiesSuggestions={
                      supportingActivitiesSuggestions
                    }
                    toolsAndGuidanceSuggestions={toolsAndGuidanceSuggestions}
                    selectedToolsAndGuidanceSuggestions={
                      selectedToolsAndGuidanceSuggestions
                    }
                    tipsAndTechniquesSuggestions={tipsAndTechniquesSuggestions}
                    entryCriteriaSuggestions={entryCriteriaSuggestions}
                    entryCriteriaWithSuggestions={entryCriteriaWithSuggestions}
                    exitCriteriaSuggestions={exitCriteriaSuggestions}
                    exitCriteriaWithSuggestions={exitCriteriaWithSuggestions}
                    taskSuggestions={taskSuggestions}
                    taskWithSuggestions={taskWithSuggestions}
                    setLeavingWithoutSave={setLeavingWithoutSave}
                    setMessage={setMessage}
                    rejectedDescSuggestions={rejectedDescSuggestions}
                    rejectedSupportSuggestioons={rejectedSupportSuggestioons}
                    rejectedToolsSuggestions={rejectedToolsSuggestions}
                    rejectedTipsSuggestions={rejectedTipsSuggestions}
                    contributorDescSuggestions={
                      contributorsData.resultCalloutJson
                        ? contributorsData.resultCalloutJson.description
                        : null
                    }
                    contributorSupportSuggestions={
                      contributorsData.resultCalloutJson
                        ? contributorsData.resultCalloutJson
                          .supportingActivities
                        : null
                    }
                    contributorTipsSuggestions={
                      contributorsData.resultCalloutJson
                        ? contributorsData.resultCalloutJson.tipsAndTechniques
                        : null
                    }
                    contributorToolsSuggestions={
                      contributorsData.resultCalloutJson
                        ? contributorsData.resultCalloutJson.toolsAndGuidance
                        : null
                    }
                    contributorTemplateSuggestion={
                      contributorTemplateSuggestion.length > 0
                        ? contributorTemplateSuggestion
                        : null
                    }
                    contributorEntryCriteriaSuggestion={
                      contributorEntryCriteriaSuggestion.length > 0
                        ? contributorEntryCriteriaSuggestion
                        : null
                    }
                    contributorExitCriteriaSuggestion={
                      contributorExitCriteriaSuggestion.length > 0
                        ? contributorExitCriteriaSuggestion
                        : null
                    }
                    contributorTaskSuggestion={
                      contributorTaskSuggestion.length > 0
                        ? contributorTaskSuggestion
                        : null
                    }
                    contributorsData={contributorsData}
                    licenseKey={licenseKey}
                    messagesType={messagesType}
                    topicData={topicData}
                    procedureData={procedureData}
                    existingHashtags={existingHashtags}
                    templateSuggestions={templateSuggestions}
                    activityDeliverables={deliverables}
                    deliverablesSuggestions={deliverablesSuggestions}
                    contributorDeliverablesSuggestions={
                      contributorsData.resultCalloutJson
                        ? contributorsData.resultCalloutJson.deliverables
                        : null
                    }
                    rejectedDeliverablesSuggestions={rejectedDeliverablesSuggestions}
                    exitContributionReorder={exitContributionReorder}
                    entryContributionReorder={entryContributionReorder}
                    taskReorder={taskReorder}
                    inputContributionReorder={inputContributionReorder}
                    outputContributionReorder={outputContributionReorder}
                    taskReorderArray={isOrderChanged.task  ? taskReorderArray:null}
                    exitCriteriaReorderArray={isOrderChanged.exit ? exitCriteriaReorderArray: null}
                    entryCriteriaReorderArray={isOrderChanged.entry ? entryCriteriaReorderArray : null}
                    inputCriteriaReorderArray={isOrderChanged.input ? inputCriteriaReorderArray: null}
                    outputCriteriaReorderArray={isOrderChanged.output ? outputCriteriaReorderArray:null }
                    addedTaskSequence={addedTaskSequence.length? addedTaskSequence:null}
                    addedEntrySequence={addedEntrySequence.length? addedEntrySequence:null}
                    addedExitSequence={addedExitSequence.length?addedExitSequence:null}
                  />
                ) : null}
                <div className="discardAndPreviewButton">
                  <Button
                    title="Discard"
                    className="activityDiscardButton"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        setShowDiscardPopUp(true);
                        track(
                          1,
                          134,
                          activityContent.activityId,
                          activityContent.activityName
                        );
                      }
                    }}
                    onClick={() => {
                      setShowDiscardPopUp(true);
                      track(
                        1,
                        134,
                        activityContent.activityId,
                        activityContent.activityName
                      );
                    }}
                  >
                    Discard
                  </Button>
                  <Button
                    className="activityPreviewButton"
                    title="Preview &amp; Submit"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        setShowPreviewAndSubmitPopUp(true);
                        track(
                          1,
                          135,
                          activityContent.activityId,
                          activityContent.activityName
                        );
                      }
                    }}
                    onClick={() => {
                      setShowPreviewAndSubmitPopUp(true);
                      track(
                        1,
                        135,
                        activityContent.activityId,
                        activityContent.activityName
                      );
                    }}
                  >
                    Preview &amp; Submit
                  </Button>
                </div>
              </div>
            </div>
            <div className="descOfActivity">
              <h1
                title={activityContent.activityName}
                className="activityLandingPageTitle"
              >
                {activityContent.activityName}
              </h1>
              <div className="existingHashTagsOfActivity _existingHashTagsOfActivity">
                {existingHashtags.map((name) => (
                  <span
                    key={name}
                    onClick={() => {
                      history.push(
                        `/search?searchParam=${encodeURIComponent(name)}`
                      );
                    }}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        history.push(
                          `/search?searchParam=${encodeURIComponent(name)}`
                        );
                      }
                    }}
                  >
                    {name}
                  </span>
                ))}
              </div>
              <ActivityRaciMatrix
                activityData={activityContent}
              ></ActivityRaciMatrix>
              <div className="innerContent" id="innerContent">

                <h2
                  className="sub-heading"
                  id="desc"
                  aria-label="Description"
                >
                  Description
                </h2>
                <TemporaryCkAlert width={'77'}/>
                {contributorsData.description ? (
                  <DescriptionCkEditor
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    initialData={contributorsData.description}
                    setContent={setDescription}
                    initalSuggestions={
                      contributorsData.resultCalloutJson
                        ? contributorsData.resultCalloutJson.description
                        : null
                    }
                    setSuggestions={setDescriptionSuggestions}
                    setRejectedDescSuggestions={setRejectedDescSuggestions}
                    sectionName="description"
                    licenseKey={licenseKey}
                  />
                ) : (
                  <DescriptionCkEditor
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    initialData=" "
                    setContent={setDescription}
                    initalSuggestions={
                      contributorsData.resultCalloutJson
                        ? contributorsData.resultCalloutJson.description
                        : null
                    }
                    setSuggestions={setDescriptionSuggestions}
                    setRejectedDescSuggestions={setRejectedDescSuggestions}
                    sectionName="description"
                    licenseKey={licenseKey}
                  />
                )}

                <h2 className="sub-heading" id="supportAct">
                  Supporting Activities
                </h2>
                <TemporaryCkAlert width={'77'} />
                {contributorsData.supportingActivities ? (
                  
                  <SupportCkEditor
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    initialData={contributorsData.supportingActivities}
                    setContent={setSupportingActivities}
                    initalSuggestions={
                      contributorsData.resultCalloutJson
                        ? contributorsData.resultCalloutJson
                          .supportingActivities
                        : null
                    }
                    setSuggestions={setSupportingActivitiesSuggestions}
                    setRejectedSupportSuggestioons={
                      setRejectedSupportSuggestioons
                    }
                    sectionName="supportActivities"
                    licenseKey={licenseKey}
                  />
                ) : (
                  <SupportCkEditor
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    initialData=" "
                    setContent={setSupportingActivities}
                    initalSuggestions={
                      contributorsData.resultCalloutJson
                        ? contributorsData.resultCalloutJson
                          .supportingActivities
                        : null
                    }
                    setSuggestions={setSupportingActivitiesSuggestions}
                    setRejectedSupportSuggestioons={
                      setRejectedSupportSuggestioons
                    }
                    sectionName="supportActivities"
                    licenseKey={licenseKey}
                  />
                )}


                <div className="innerContent" id="innerContent">
                  <h2 className="sub-heading entryCriteria-heading">
                    <span aria-label="entry criteria">
                      Entry Criteria/Input
                    </span>

                    <div className="contributorAddTemplates">
                      <span
                        className={
                          entryCriteriaButtonClicked
                            ? "contributorAddTemplatesButton hiddenEntryCriteria"
                            : "contributorAddTemplatesButton "
                        }
                        tabIndex={0}
                        title="Add entry criteria"
                        role="button"
                        aria-label="Click to Add criteria"
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            setEntryCriteriaButtonClicked(true);
                          }
                        }}
                        onClick={() => {
                          setEntryCriteriaButtonClicked(true);
                        }}
                      >
                        Add{" "}
                        {currentEntryTab === "tab-entry"
                          ? "Entry Criteria"
                          : "Input"}
                        <span className="phaseAddIcon"></span>
                      </span>
                    </div>
                  </h2>
                  <EntryCriteriaContribution
                    contributorsData={contributorsData}
                    entryCriteriaButtonClicked={entryCriteriaButtonClicked}
                    setEntryCriteriaButtonClicked={
                      setEntryCriteriaButtonClicked
                    }
                    ckEditorUserMap={ckEditorUserMap}
                    CkEditorCurrentUser={CkEditorCurrentUser}
                    entryCriteriaSuggestions={entryCriteriaSuggestions}
                    setEntryCriteriaSuggestions={setEntryCriteriaSuggestions}
                    entryCriteriaWithSuggestions={entryCriteriaWithSuggestions}
                    setEntryCriteriaWithSuggestions={
                      setEntryCriteriaWithSuggestions
                    }
                    entryCriteriaData={entryCriteriaData}
                    setEntryCriteriaData={setEntryCriteriaData}
                    currentTab={currentEntryTab}
                    setCurrentTab={setCurrentEntryTab}
                    reviewerEntryCriteriaSuggestions={reviewerEntryCriteriaSuggestions}
                    setEntryCriteriaReorderArray={setEntryCriteriaReorderArray}
                    entryCriteriaReorderArray={entryCriteriaReorderArray}
                    setInputCriteriaReorderArray={setInputCriteriaReorderArray}
                    inputCriteriaReorderArray={inputCriteriaReorderArray}
                    entryContributionReorder ={entryContributionReorder}
                    setEntryContributionReorder={setEntryContributionReorder}
                    setInputContributionReorder={setInputContributionReorder}
                    inputContributionReorder={inputContributionReorder}
                    initialEntryIdBeforeReorder ={initialEntryIdBeforeReorder}
                    setInitialEntryIdBeforeReorder = {setInitialEntryIdBeforeReorder}
                    ckEditorCurrentEntryReorderUsername={ckEditorCurrentEntryReorderUsername}
                    setCkEditorCurrentEntryReorderUsername={setCkEditorCurrentEntryReorderUsername}   
                    ckEditorCurrentEntryReorderUserImage={ckEditorCurrentEntryReorderUserImage}
                    setCkEditorCurrentEntryReorderUserImage={setCkEditorCurrentEntryReorderUserImage}    
                    ckEditorCurrentInputReorderUsername={ckEditorCurrentInputReorderUsername}
                    setCkEditorCurrentInputReorderUsername={setCkEditorCurrentInputReorderUsername}
                    ckEditorCurrentInputReorderUserImage={ckEditorCurrentInputReorderUserImage}
                    setCkEditorCurrentInputReorderUserImage={setCkEditorCurrentInputReorderUserImage}
                    user={user}
                    disableEntryDrag={disableEntryDrag}
                    setDisableEntryDrag={setDisableEntryDrag}
                    disableInputDrag={disableInputDrag}
                    setDisableInputDrag={setDisableInputDrag}
                    setAddedEntrySequence={setAddedEntrySequence}
                    setIsOrderChanged={setIsOrderChanged}
                   />
                </div>

                <div className="innerContent" id="innerContentTask">
                  <h2 className="sub-heading entryCriteria-heading">
                    <span aria-label="task" >
                      Task  
                    </span>

                    <div className="contributorAddTemplates" id="task-section">
                      <span
                        className={
                          taskButtonClicked
                            ? "contributorAddTemplatesButton hiddenEntryCriteria"
                            : "contributorAddTemplatesButton "
                        }
                        tabIndex={0}
                        title="Add new task"
                        role="button"
                        aria-label="Click to Add a new task"
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            setTaskButtonClicked(true);
                          }
                        }}
                        onClick={() => {
                          setTaskButtonClicked(true);
                        }}
                      >
                        Add new task
                        <span className="phaseAddIcon"></span>
                      </span>
                    </div>
                  </h2>
                  <TaskContribution
                    contributorsData={contributorsData}
                    taskButtonClicked={taskButtonClicked}
                    setTaskButtonClicked={setTaskButtonClicked}
                    ckEditorUserMap={ckEditorUserMap}
                    CkEditorCurrentUser={CkEditorCurrentUser}
                    taskSuggestions={taskSuggestions}
                    setTaskSuggestions={setTaskSuggestions}
                    taskWithSuggestions={taskWithSuggestions}
                    setTaskWithSuggestions={setTaskWithSuggestions}
                    taskData={taskData}
                    setTaskData={setTaskData}
                    reviewerTaskSuggestions={reviewerTaskSuggestions}
                    taskReorder={taskReorder}
                    setTaskReorder={setTaskReorder}
                    setTaskReorderArray={setTaskReorderArray}
                    taskReorderArray={taskReorderArray}
                    initialTaskIdBeforeReorder={initialTaskIdBeforeReorder}
                    setInitialTaskIdBeforeReorder={setInitialTaskIdBeforeReorder}
                    disableDrag={disableDrag}
                    setDisableDrag={setDisableDrag}
                    ckEditorCurrentReorderUsername={ckEditorCurrentReorderUsername}
                    setCkEditorCurrentReorderUsername={setCkEditorCurrentReorderUsername}
                    ckEditorCurrentReorderUserImage={ckEditorCurrentReorderUserImage}
                    setCkEditorCurrentReorderUserImage={setCkEditorCurrentReorderUserImage}
                    user={user}
                    setAddedTaskSequence={setAddedTaskSequence}
                    setIsOrderChanged={setIsOrderChanged}
                 />
                </div>

                <div className="innerContent" id="innerContent">
                  <h2 className="sub-heading exitCriteria-heading">
                    <span aria-label="exit criteria">
                      Exit Criteria/Output
                    </span>
                    <div className="contributorAddTemplates">
                      <span
                        className={
                          exitCriteriaButtonClicked
                            ? 'contributorAddTemplatesButton hiddenExitCriteria'
                            : 'contributorAddTemplatesButton '
                        }
                        tabIndex={0}
                        title="Add exit criteria"
                        role="button"
                        aria-label="Click to Add a new Criteria"
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            setExitCriteriaButtonClicked(true);
                          }
                        }}
                        onClick={() => {
                          setExitCriteriaButtonClicked(true);
                        }}
                      >
                        Add{' '}
                        {currentExitTab === 'tab-exit'
                          ? 'Exit Criteria'
                          : 'Output'}
                        <span className="phaseAddIcon"></span>
                      </span>
                    </div>
                  </h2>
                  <ExitCriteriaContribution
                    contributorsData={contributorsData}
                    exitCriteriaButtonClicked={exitCriteriaButtonClicked}
                    setExitCriteriaButtonClicked={setExitCriteriaButtonClicked}
                    ckEditorUserMap={ckEditorUserMap}
                    CkEditorCurrentUser={CkEditorCurrentUser}
                    exitCriteriaSuggestions={exitCriteriaSuggestions}
                    setExitCriteriaSuggestions={setExitCriteriaSuggestions}
                    exitCriteriaWithSuggestions={exitCriteriaWithSuggestions}
                    setExitCriteriaWithSuggestions={
                      setExitCriteriaWithSuggestions
                    }
                    outputButtonClicked={outputButtonClicked}
                    setOutputButtonClicked={setOutputButtonClicked}
                    outputSuggestions={outputSuggestions}
                    setOutputSuggestions={setOutputSuggestions}
                    exitCriteriaData={exitCriteriaData}
                    setExitCriteriaData={setExitCriteriaData}
                    currentTab={currentExitTab}
                    setCurrentTab={setCurrentExitTab}
                    reviewerExitCriteriaSuggestions={reviewerExitCriteriaSuggestions}
                    setExitContributionReorder={setExitContributionReorder}
                    setOutputContributionReorder={setOutputContributionReorder}
                    exitContributionReorder={exitContributionReorder}
                    exitCriteriaReorderArray={exitCriteriaReorderArray}
                    setExitCriteriaReorderArray={setExitCriteriaReorderArray}
                    outputCriteriaReorderArray={outputCriteriaReorderArray}
                    setOutputCriteriaReorderArray={setOutputCriteriaReorderArray}
                    outputContributionReorder={outputContributionReorder}
                    setInputCriteriaReorderArray={setInputCriteriaReorderArray}
                    initialExitIdBeforeReorder = {initialExitIdBeforeReorder}
                    setInitialExitIdBeforeReorder = {setInitialExitIdBeforeReorder}
                    ckEditorCurrentExitReorderUserImage={ckEditorCurrentExitReorderUserImage}
                    setCkEditorCurrentExitReorderUserImage={setCkEditorCurrentExitReorderUserImage}
                    ckEditorCurrentExitReorderUsername={ckEditorCurrentExitReorderUsername} 
                    setCkEditorCurrentExitReorderUsername={setCkEditorCurrentExitReorderUsername} 
                    ckEditorCurrentOutputReorderUserImage={ckEditorCurrentOutputReorderUserImage}
                    setCkEditorCurrentOutputReorderUserImage={setCkEditorCurrentOutputReorderUserImage}
                    ckEditorCurrentOutputReorderUsername={ckEditorCurrentOutputReorderUsername}
                    setCkEditorCurrentOutputReorderUsername={setCkEditorCurrentOutputReorderUsername}     
                    user={user} 
                    setAddedExitSequence={setAddedExitSequence}  
                    disableExitDrag={disableExitDrag}
                    setDisableExitDrag={setDisableExitDrag}
	                  disableOutputDrag={disableOutputDrag}
                    setDisableOutputDrag ={setDisableOutputDrag}    
                    addedExitSequence = {addedExitSequence}
                    setIsOrderChanged={setIsOrderChanged}
                 />
                </div>


                {procedureData.length > 0 ? (
                  <div className="nonEditable">
                    <h2 className="sub-heading" id="deliver">
                      Procedures
                      <div className="nonEditableSection" tabIndex={0}>
                        <span className="nonEditableIcon"></span>
                        <div className="nonEditableIconToolTip">
                          <span className="nonEditablaToolTipArrow"></span>
                          <span>
                            You can not edit the contents of this text
                          </span>
                        </div>
                      </div>
                    </h2>
                    {procedureData.length > 0 ? (
                      <div id="topicList">
                        <ul>
                          {procedureData.map((procedure) => (
                            <li>
                              <a href={procedure.url} target="_blank">
                                {procedure.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <></>
                )}

                {topicData.length > 0 ? (
                  <div className="nonEditable">
                    <h2 className="sub-heading" id="deliver">
                      Related Topics
                      <div className="nonEditableSection" tabIndex={0}>
                        <span className="nonEditableIcon"></span>
                        <div className="nonEditableIconToolTip">
                          <span className="nonEditablaToolTipArrow"></span>
                          <span>
                            You can not edit the contents of this text
                          </span>
                        </div>
                      </div>
                    </h2>
                    {topicData.length > 0 ? (
                      <div id="topicList">
                        <ul>
                          {topicData.map((topic) => (
                            <li>
                              <a href={topic.url} target="_blank">
                                {topic.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <></>
                )}

                <div className="addingTemplatesEditAcivity">
                  <h2 className="sub-heading" id="deliver">
                    <span aria-label="Deliverables">
                      Deliverables
                    </span>

                    <div className="contributorAddTemplates">
                      <div>
                        <span className="delTemplateAccessabilityIcon"></span>
                        <span>
                          Can't find the template you are looking for?
                        </span>
                      </div>
                      <span
                        className="contributorAddTemplatesButton"
                        onClick={() => {
                          setShowTemplateBuilder(true);
                        }}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            setShowTemplateBuilder(true);
                          }
                        }}
                        tabIndex={0}
                        title="Add a new Template"
                        role="button"
                        aria-label="Click to Add a new Template"
                      >
                        <span>Add a new Template</span>
                        <span className="phaseAddIcon"></span>
                      </span>
                    </div>
                  </h2>

                  <TemplateCrowdSourcing
                    templateData={templateData}
                    toolsSidebarHeight={templateSidebarHeight}
                    CkEditorCurrentUser={CkEditorCurrentUser}
                    templateSuggestions={templateSuggestions}
                    setTemplateSuggestions={setTemplateSuggestions}
                    setTemplateData={setTemplateData}
                    ckEditorUserMap={ckEditorUserMap}
                    reviewerTemplateSuggestions={reviewerTemplateSuggestions}
                  />

                  {/* {activityContent.activityDeliverables ? (
                    <div
                      id="activityDeliverables"
                      dangerouslySetInnerHTML={{
                        __html: htmlSanitizer(
                          activityContent.activityDeliverables
                        ),
                      }}
                    />
                  ) : null} */}

                  <div className="innerContent deliverables-container" id="innerContent">
                  <TemporaryCkAlert  width={'77'}/>
                    <h2
                      className="sub-heading"
                      id="desc2"
                      aria-label="Description"
                    >
                      Description
                    </h2>
                    
                    {contributorsData.deliverables ? (
                      <DeliverableCkEditor
                        users={ckEditorUsers}
                        currentUser={CkEditorCurrentUser}
                        initialData={contributorsData.deliverables}
                        setContent={setDeliverables}
                        initalSuggestions={
                          contributorsData.resultCalloutJson
                            ? contributorsData.resultCalloutJson.deliverables
                            : null
                        }
                        setSuggestions={setDeliverablesSuggestions}
                        setRejectedDeliverablesSuggestions={setRejectedDeliverablesSuggestions}
                        sectionName="deliverables"
                        licenseKey={licenseKey}
                      />
                    ) : (
                      <DeliverableCkEditor
                        users={ckEditorUsers}
                        currentUser={CkEditorCurrentUser}
                        initialData=" "
                        setContent={setDeliverables}
                        initalSuggestions={
                          contributorsData.resultCalloutJson
                            ? contributorsData.resultCalloutJson.deliverables
                            : null
                        }
                        setSuggestions={setDeliverablesSuggestions}
                        setRejectedDeliverablesSuggestions={setRejectedDeliverablesSuggestions}
                        sectionName="deliverables"
                        licenseKey={licenseKey}
                      />
                    )}
                  </div>
                </div>


                <h2
                  className="sub-heading"
                  id="tool"
                  aria-label="Tools"
                >
                  Tools
                </h2>

                <div className="customToolsEditor">
                  <div className="toolsEditorMainContainer">
                    <div className="toolsHeaderEditor selectTools">
                      <h6>Tools</h6>
                        <MultiSelectDropdownFluent
                        className="toolsAndGuidanceMultiSelectFilter"
                        value={selectedToolsAndGuidanceSuggestions}
                        setValue={setSelectedToolsAndGuidanceSuggestions}
                        setToolsAndGuidanceSuggestions={
                          setToolsAndGuidanceSuggestions
                        }
                        enableDropdown={true}
                        toolsAndGuidanceSuggestions={
                          toolsAndGuidanceSuggestions
                        }
                        adminToolsAndGuidance={adminToolsAndGuidance}
                        currentUser={CkEditorCurrentUser}
                        checkForAdminToolsUpdate={checkForAdminToolsUpdate}
                        setCheckForAdminToolsUpdate={
                          setCheckForAdminToolsUpdate
                        }
                        contributionToolsId={contributionToolsId}
                      />
                    </div>
                    <div className="ToolsContentContainer">
                      <ul>
                        {selectedToolsAndGuidanceSuggestions.map((item) => (
                          <>
                            {!adminToolsAndGuidance.includes(
                              JSON.parse(item).toolsId
                            ) ? (
                              <li
                                className={
                                  JSON.parse(item).suggestionType === "deletion"
                                    ? "userDeletedToolsAndGuidance"
                                    : "userSelectedToolsAndGuidance"
                                }
                              >
                                <div className="userAddedItem">
                                  <a href={toolsUrl[JSON.parse(item).toolsId]}>
                                    {JSON.parse(item).name}
                                  </a>
                                </div>
                              </li>
                            ) : (
                              <li
                                className={`adminToolsAndGuidance ${JSON.parse(item).suggestionType === "deletion"
                                  ? "userDeletedToolsAndGuidance"
                                  : "none"
                                  }`}
                              >
                                <a
                                  href={toolsUrl[JSON.parse(item).toolsId]}
                                  target="_blank"
                                >
                                  {JSON.parse(item).name}
                                </a>
                                <Icon
                                  iconName="Cancel"
                                  onClick={() =>
                                    removeToolsAndGuidance(
                                      "admin",
                                      JSON.parse(item)
                                    )
                                  }
                                  onKeyPress={(e) => {
                                    if (e.which === 13) {
                                      removeToolsAndGuidance(
                                        "admin",
                                        JSON.parse(item)
                                      );
                                    }
                                  }}
                                  tabIndex={0}
                                  ariaLabel={`click to ${JSON.parse(item).name
                                    } Remove`}
                                  role="button"
                                />
                              </li>
                            )}
                          </>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="toolsSidebar"
                    style={{ height: toolsSidebarHeight }}
                  >
                    {sidebarCard.map((card) => (
                      <>
                        {!adminToolsAndGuidance.includes(
                          JSON.parse(card).toolsId
                        ) ? (
                          <div
                            className={`sideBarCard ${JSON.parse(card).suggestionType === "insertion"
                              ? "userAddedItem"
                              : "userDeletedToolsAndGuidance"
                              }`}
                          >
                            <div className="sideBarCardHeader">
                              <img
                                className="userImg"
                                src={
                                  ckEditorUserMap[JSON.parse(card).authorId][1]
                                }
                                alt=""
                              />
                              <span>
                                {ckEditorUserMap[JSON.parse(card).authorId][0]}
                              </span>
                              {JSON.parse(card).authorId ===
                                CkEditorCurrentUser ? (
                                <Icon
                                  title="Reject Suggestion"
                                  aria-label={`click to Reject ${JSON.parse(card).name
                                    } as suggestion`}
                                  iconName="Cancel"
                                  onClick={() =>
                                    removeToolsAndGuidance(
                                      "insertion",
                                      JSON.parse(card)
                                    )
                                  }
                                  onKeyPress={(e) => {
                                    if (e.which === 13) {
                                      removeToolsAndGuidance(
                                        "insertion",
                                        JSON.parse(card)
                                      );
                                    }
                                  }}
                                  tabIndex={0}
                                  role="button"
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="itemStatus">
                              <span>
                                {JSON.parse(card).suggestionType === "insertion"
                                  ? "Add"
                                  : "Remove"}
                              </span>
                              &nbsp;"{JSON.parse(card).name}"
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`sideBarCard ${JSON.parse(card).suggestionType === "insertion"
                              ? "userAddedItem"
                              : "userDeletedToolsAndGuidance"
                              }`}
                          >
                            <div className="sideBarCardHeader">
                              <img
                                className="userImg"
                                src={
                                  ckEditorUserMap[JSON.parse(card).authorId][1]
                                }
                                alt=""
                              />
                              <span>
                                {ckEditorUserMap[JSON.parse(card).authorId][0]}
                              </span>
                              <Icon
                                title="Reject Suggestion"
                                aria-label={`click to Reject ${JSON.parse(card).name
                                  } as suggestion`}
                                iconName="Cancel"
                                onClick={() =>
                                  removeToolsAndGuidance(
                                    "adminToolsDeletion",
                                    JSON.parse(card)
                                  )
                                }
                                onKeyPress={(e) => {
                                  if (e.which === 13) {
                                    removeToolsAndGuidance(
                                      "insertion",
                                      JSON.parse(card)
                                    );
                                  }
                                }}
                                tabIndex={0}
                              />
                            </div>
                            <div className="itemStatus">
                              <span>Remove</span>&nbsp;"{JSON.parse(card).name}"
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </div>

                <h2 className="sub-heading" id="tipstech">
                  Guidance, Tips and Techniques
                </h2>
                <TemporaryCkAlert width={'77'}/>

                {contributorsData.tipsAndTechniques ? (
                  <TipsCkEditor
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    initialData={contributorsData.tipsAndTechniques}
                    setContent={setTipsAndTechniques}
                    initalSuggestions={
                      contributorsData.resultCalloutJson
                        ? contributorsData.resultCalloutJson.tipsAndTechniques
                        : null
                    }
                    setSuggestions={setTipsAndTechniquesSuggestions}
                    setRejectedTipsSuggestions={setRejectedTipsSuggestions}
                    sectionName="tipsAndTechniques"
                    licenseKey={licenseKey}
                  />
                ) : (
                  <TipsCkEditor
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    initialData=" "
                    setContent={setTipsAndTechniques}
                    initalSuggestions={
                      contributorsData.resultCalloutJson
                        ? contributorsData.resultCalloutJson.tipsAndTechniques
                        : null
                    }
                    setSuggestions={setTipsAndTechniquesSuggestions}
                    setRejectedTipsSuggestions={setRejectedTipsSuggestions}
                    sectionName="tipsAndTechniques"
                    licenseKey={licenseKey}
                  />
                )}

                {/* <Prompt
                  when={
                    leavingWithoutSave && !showDiscardPopUp && !isActivityLock
                  }
                  message="You have unsaved changes, are you sure you want to leave?"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showTemplateBuilder ? (
        <TemplateBuilder
          showActivityTemplateBuilder={showTemplateBuilder}
          setShowActivityTemplateBuilder={setShowTemplateBuilder}
          activityId={activityContent.activityId}
          complexityId={complexityId}
          pageType="crowdSource"
          addTemplateSuggestion={addTemplateToSuggestion}
          existingTemplates={getExistingTemplates()}
          stateUpdate={false}
        />
      ) : (
        <></>
      )}
      {enableTemplatePreview ? (
        <PreviewModal
          enableTemplatePreview={enableTemplatePreview}
          closeModal={() => setEnableTemplatePreview(false)}
          data={templatePreviewData}
        />
      ) : (
        <></>
      )}
      {enableFileDropModal ? (
        <FileDropModal
          enableFileDropModal={enableFileDropModal}
          closeModal={() => setEnableFileDropModal(false)}
          data={sampleTemplates.parentTemplate}
        />
      ) : (
        <></>
      )}
      <Modal
        show={sampleTemplates.enable}
        onHide={() => {
          setSampleTemplates({
            id: null,
            enable: false,
            parentTemplate: null,
          });
        }}
        centered
        dialogClassName="templateModal"
      >
        {sampleTemplates.enable ? (
          <SampleTemplateModal
            id={sampleTemplates.id}
            handleTemplatePreview={handleTemplatePreview}
            setEnableFileDropModal={setEnableFileDropModal}
            closeModal={() => {
              setSampleTemplates({
                id: null,
                enable: false,
                parentTemplate: null,
              });
            }}
            enableFileDropModal={enableFileDropModal}
            complexityId={complexityName.id}
          />
        ) : (
          <></>
        )}
      </Modal>
    </div>
  );
}

export default ContributorActivityCrowdSourcing;
