import React, { Component } from "react";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { getUserProfile } from "../../../helpers/GraphService";
import { ItemTypeMap, EventTypeMap } from "../../../static/TelemetryData";
import urlContains from "../../../config";

const { authority, instrumentationKey } = urlContains(window.location.hostname);

class Telemetry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                isAuthenticated: false,
                profile: {},
                error: '',
            },
        };

        this.reactPlugin = new ReactPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                extensions: [this.reactPlugin],
                extensionConfig: {
                    [this.reactPlugin.identifier]: {
                        // history,
                    },
                },
            },
        });
        this.appInsights.loadAppInsights();
    }

    async componentDidMount() {
        const { instance: msalInstance, accounts, inProgress } = this.props.msal;
        const isAuthenticated = this.props.isAuthenticated;

        if ((!this.state.user.isAuthenticated || !isAuthenticated) && inProgress === InteractionStatus.None) {
            await this.acquireTokenRedirectAndCallMSGraph(msalInstance, accounts);
        }

        if (this.state.user.isAuthenticated) {
            this.appInsights.setAuthenticatedUserContext(this.state.user.profile.mail);
        }
    }

    handleGraphRequest = async (tokenResponse) => {
        const profile = await getUserProfile(tokenResponse);
        if (profile.mail !== null) {
            this.setState({
                user: {
                    ...this.state.user,
                    isAuthenticated: true,
                    profile,
                },
            });
        }
    };

    acquireTokenRedirectAndCallMSGraph = async (msalInstance, accounts) => {
        const accessTokenRequest = {
            scopes: ['user.read'],
            authority: authority,
        };
        if (!accounts.length) {
            return msalInstance.acquireTokenRedirect(accessTokenRequest);
        }
        accessTokenRequest.account = accounts[0];
        try {
            const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
            this.handleGraphRequest(accessTokenResponse);
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                msalInstance.acquireTokenRedirect(accessTokenRequest);
            }
        }
    };

    track = (eventTypeId, itemTypeId, itemId, itemName, optional) => {
        const { pathname, search } = window.location;
        const { user } = this.state;
        const itemTypeIdList = [1, 2, 10, 15, 25, 126];

        let trackData = {
            itemTypeId,
            itemTypeName: ItemTypeMap[itemTypeId],
            itemId,
            itemName,
            userAlias: user.profile.mail,
            url: pathname + search,
        };

        if (optional !== undefined) {
            trackData = {
                ...trackData,
                ...optional,
            };
        }
        // if (eventTypeId === 2 && itemTypeIdList.includes(itemTypeId)) {
        //     saveRecentActivities(itemTypeId, itemId, optional);
        // } else if (itemTypeId === 112) {
        //     saveTopFiveDownload(itemId, optional);
        // } else if (itemTypeId === 126) {
        //     savePreviewAndDownload(itemId, optional);
        // }
        this.appInsights.trackEvent({ name: EventTypeMap[eventTypeId] }, trackData);
    };

    render() {
        return null; // This component does not render anything
    }
}

export default Telemetry;
export const track = (eventTypeId, itemTypeId, itemId, itemName, optional) => {
    const telemetryInstance = new Telemetry();
    telemetryInstance.track(eventTypeId, itemTypeId, itemId, itemName, optional);
};